// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CancellationToken } from '../../util/CancellationToken'
import { RequestChain } from './RequestChain'

/**
 * Encapsulates request-specific features/options.
 */
export class RequestChainImpl implements RequestChain {
  /**
   * The source cancellation token.
   */
  public readonly token: CancellationToken

  /**
   * Gets a `Map` of cached results associated with this `RequestChain`.
   */
  public readonly resultCache: Map<string, any>

  /**
   * Specifies a background session ID (Used for iOS only).
   * If set, will use background URLSession.
   * Background URLSession support is experimental and currently is not thread safe.
   */
  public backgroundSessionId: string

  /**
   * Specifies earliest begin date for background `URLSessionDownloadTask` (Used for iOS only).
   * Only used if `backgroundSessionId` is set and iOS version is greater than or equal to 11.
   */
  public earliestBeginDate: Date

  /**
   * Specifies count of bytes client expects to send for background `URLSessionDownloadTask` (Used for iOS only).
   * Only used if `backgroundSessionId` is set and iOS version is greater than or equal to 11.
   */
  public countOfBytesClientExpectsToSend: number

  /**
   * Specifies count of bytes client expects to receive for background `URLSessionDownloadTask` (Used for iOS only).
   * Only used if `backgroundSessionId` is set and iOS version is greater than or equal to 11.
   */
  public countOfBytesClientExpectsToReceive: number

  /**
   * Initializes a new `RequestChainImpl`.
   */
  constructor() {
    this.resultCache = new Map()
    this.token = new CancellationToken()
  }

  /**
   * Indicates if the SDK should make the request or just return the details that make up the request.
   */
  isDryRun = false

  /**
   * Cancels all tasks tied to the cancellation source.
   */
  public cancelRequests() {
    throw new Error('not implemented')
  }

  /**
   * Indicates whether to include rawData in service response.
   */
  public showApiDetails = false

  /**
   * Indicates whether to use RequestCache
   */
  public shouldUseRequestCache?: boolean

  /**
   * Any additional headers to include in the request.
   * User-Agent and Cookie headers are handled separately and will be ignored if included here.
   */
  public headers?: Map<string, string> = undefined
}
