// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// @typescript-eslint/no-explicit-any
/* eslint-disable */
import { ApiHttpService } from '../core/api/ApiHttpService'
import { CacheOptions } from '../core/cache/CacheOptions'
import { ServiceResponseCache } from '../core/cache/ServiceResponseCache'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { isNotNullOrUndefined, isNullOrEmptyList } from '../util/Helpers'
import { ClimatologyDayByLocationKeyRequest } from './requests/ClimatologyDayByLocationKeyRequest'
import { ClimatologyMonthByLocationKeyRequest } from './requests/ClimatologyMonthByLocationKeyRequest'
import { ClimoType } from './requests/ClimoType'
import { CurrentConditionsByLocationKeyRequest } from './requests/CurrentConditionsByLocationKeyRequest'
import { DailyForecastsByLocationKeyRequest } from './requests/DailyForecastsByLocationKeyRequest'
import { HourlyForecastsByLocationKeyRequest } from './requests/HourlyForecastsByLocationKeyRequest'
import { MinuteForecastByGeopositionRequest } from './requests/MinuteForecastByGeopositionRequest'
import { MinuteForecastByLocationKeyRequest } from './requests/MinuteForecastByLocationKeyRequest'
import { MinuteForecastPremiumByGeopositionRequest } from './requests/MinuteForecastPremiumByGeopositionRequest'
import { MinuteForecastPremiumByLocationKeyRequest } from './requests/MinuteForecastPremiumByLocationKeyRequest'
import { QuarterDayForecastsByLocationKeyRequest } from './requests/QuarterDayForecastsByLocationKeyRequest'
import { WeatherRouteResolver } from './WeatherRouteResolver'
import { WeatherService } from './WeatherService'

/**
 * Gets current conditions, daily forecasts, and climotology data for a specific location.
 * Also aggregates the different categories of weather data.
 */
export class WeatherServiceImpl implements WeatherService {
  protected readonly serviceName = 'WeatherService'

  protected readonly settings: SdkSettings
  protected readonly httpService: ApiHttpService
  private readonly allDbzColors = 'AllDbzColors'
  private readonly allDbzRangeColors = 'AllDbzRangeColors'
  private readonly fromlocalResource = 'from local resource'
  private readonly routeResolver: WeatherRouteResolver

  private readonly dbzColorsCache: ServiceResponseCache<any[]>
  private readonly rangeColorsCache: ServiceResponseCache<any[]>

  private _dbzColors: any = null
  private _dbzColorRanges: any = null

  constructor(
    sdkSettings: SdkSettings,
    httpService: ApiHttpService,
    routeResolver: WeatherRouteResolver,
  ) {
    this.settings = sdkSettings
    this.httpService = httpService
    this.routeResolver = routeResolver

    if (sdkSettings.cacheSettings.shouldCacheMinuteCastColors) {
      const cacheOptions = new CacheOptions(sdkSettings.cacheSettings.minuteCastColorsExpiry)
      this.dbzColorsCache = new ServiceResponseCache<any[]>(
        sdkSettings.persistentCache,
        cacheOptions,
      )
      this.rangeColorsCache = new ServiceResponseCache<any[]>(
        sdkSettings.persistentCache,
        cacheOptions,
      )
    }
  }

  // region current conditions
  /**
   * Gets current conditions data for a specific location.
   *
   * @param request The request.
   * @return A service response containing either a [CurrentConditions] or error information.
   */
  public async getCurrentConditionsByLocationKey(
    request: CurrentConditionsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    const response = await this.httpService.get(
      request,
      async (r, rc) => this.routeResolver.getCurrentConditionsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getCurrentConditionsByLocationKey'),
    )
    return response.takeFirst(requestChain)
  }

  /**
   * Gets the conditions over the last 6 hours for a specific location.
   *
   * @param request The request.
   * @return A service response containing either a list of [CurrentConditions]s or error information.
   */
  public async getHistoricalConditionsQuarterByLocationKey(
    request: CurrentConditionsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getHistoricalConditionsQuarterUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getHistoricalConditionsQuarterByLocationKey',
      ),
    )
  }

  /**
   * Gets the conditions over the last 24 hours for a specfic location.
   *
   * @param request The request.
   * @return A service response containing either a list of [CurrentConditions]s or error information.
   */
  public async getHistoricalConditionsDayByLocationKey(
    request: CurrentConditionsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getHistoricalConditionsDayUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getHistoricalConditionsDayByLocationKey',
      ),
    )
  }
  // endregion current conditions

  // region forecast
  /**
   * Gets daily forecast data for a specific location.
   *
   * @param request The request.
   * @return A service response containing either a [LocalForecast] or error information.
   */
  public async getDailyForecastsByLocationKey(
    request: DailyForecastsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getDailyForecastsByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getDailyForecastsByLocationKey'),
    )
  }

  /**
   * Gets hourly forecast data for a specific location.
   *
   * @param request The request.
   * @return A service response containing either a list of [HourlyForecast]s or error information.
   */
  public async getHourlyForecastsByLocationKey(
    request: HourlyForecastsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getHourlyForecastsByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getHourlyForecastsByLocationKey'),
    )
  }

  /**
   * Gets quarter-day forecast data for a specific location.
   *
   * @param request The request.
   * @return A service response containing either a list of [QuarterDayForecast]s or error information.
   */
  public async getQuarterDayForecastsByLocationKey(
    request: QuarterDayForecastsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getQuarterDayForecastsByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getQuarterDayForecastsByLocationKey'),
    )
  }

  /**
   * Gets the basic minutecast forecast for a specific geoposition.
   *
   * @param request The request.
   * @return A service response containing either a [MinuteForecast] or error information.
   */
  public async getMinuteForecastByGeoposition(
    request: MinuteForecastByGeopositionRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getMinuteForecastByGeopositionUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getMinuteForecastByGeoposition'),
    )
  }

  /**
   * Gets the basic minutecast forecast for a specific location.
   *
   * @param request The request.
   * @return A service response containing either a [MinuteForecast] or error information.
   */
  public async getMinuteForecastByLocationKey(
    request: MinuteForecastByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getMinuteForecastByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getMinuteForecastByLocationKey'),
    )
  }

  /**
   * Gets the premium minutecast forecast for a specific geoposition.
   *
   * @param request The request.
   * @return A service response containing either a [MinuteForecastPremium] or error information.
   */
  public async getMinuteForecastPremiumByGeoposition(
    request: MinuteForecastPremiumByGeopositionRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getMinuteForecastPremiumByGeopositionUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getMinuteForecastPremiumByGeoposition',
      ),
    )
  }

  /**
   * Gets the premium minutecast forecast for a specific location.
   *
   * @param request The request.
   * @return A service response containing either a [MinuteForecastPremium] or error information.
   */
  public async getMinuteForecastPremiumByLocationKey(
    request: MinuteForecastPremiumByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getMinuteForecastPremiumByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getMinuteForecastPremiumByLocationKey',
      ),
    )
  }

  /**
   * Sets the MinuteCast™ Premium dbz colors.
   * please use `getStaticMinuteColors` instead.
   * use getStaticMinuteColorRanges and getStaticMinuteColors to retrieve these colors
   *
   * @param dbzColorRanges The dbz Color Ranges json file.
   * @param dbzColors The dbz Color json file.
   * @return nothing.
   */
  public setStaticMinuteColors(dbzColorRanges: any, dbzColors: any): void {
    this._dbzColors = dbzColors
    this._dbzColorRanges = dbzColorRanges
  }

  /**
   * Gets the MinuteCast™ Premium dbz colors from a local resource.
   *
   * @param request The request.
   * @return A service response containing either a list of [DbzColor] or error information.
   */
  public getStaticMinuteColors(
    request: object,
    requestChain?: RequestChain,
  ): ServiceResponse<any[]> {
    if (isNullOrEmptyList(this._dbzColors)) {
      return ServiceResponse.fromError(new Error('unable to locate minute colors resource'))
    }
    return ServiceResponse.create(this._dbzColors, undefined, this.fromlocalResource)
  }

  /**
   * Gets the MinuteCast™ Premium dbz colors.
   * Unless it is necessary to use the API to get the dbz colors,
   * please use `getStaticMinuteColors` instead.
   *
   * @param request The request.
   * @return A service response containing either a list of [DbzColor] or error information.
   */
  public async getMinuteColors(
    request: object,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    if (isNotNullOrUndefined(this.dbzColorsCache)) {
      return this.dbzColorsCache.getOrAdd(
        this.allDbzColors,
        (r, rc) => this.getMinuteColorsHttp(r, rc),
        request,
        requestChain,
      )
    }
    return this.getMinuteColorsHttp(request, requestChain)
  }

  /**
   * Gets the MinuteCast™ Premium dbz color ranges from a local resource.
   *
   * @param request The request.
   * @return A service response containing either a list of [DbzRangeColor] or error information.
   */
  public getStaticMinuteColorRanges(
    request: object,
    requestChain?: RequestChain,
  ): ServiceResponse<any[]> {
    if (isNullOrEmptyList(this._dbzColorRanges)) {
      return ServiceResponse.fromError(new Error('unable to locate minute color ranges resource'))
    }
    return ServiceResponse.create(this._dbzColorRanges, undefined, this.fromlocalResource)
  }

  /**
   * Gets the MinuteCast™ Premium dbz color ranges.
   * Unless it is necessary to use the API to get the dbz color ranges,
   * please use `getStaticMinuteColorRanges` instead.
   *
   * @param request The request.
   * @return A service response containing either a list of [DbzRangeColor] or error information.
   */
  public async getMinuteColorRanges(
    request: object,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    if (isNotNullOrUndefined(this.rangeColorsCache)) {
      return this.rangeColorsCache.getOrAdd(
        this.allDbzRangeColors,
        (r, rc) => this.getMinuteColorRangesHttp(r, rc),
        request,
        requestChain,
      )
    }
    return this.getMinuteColorRangesHttp(request, requestChain)
  }
  // endregion forecast

  // region climo
  // region summary
  /**
   * Gets the past daily observations (request: actuals), daily records, and daily averages (request: normals) for a specific location and date.
   *
   * @param request The request.
   * @return A service response containing either a [ClimatologyDay] or error information.
   */
  public async getClimatologyDayByLocationKey(
    request: ClimatologyDayByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(request)) {
      request.climoType = ClimoType.Summary
    }
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getClimatologyDayByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getClimatologyDayByLocationKey'),
    )
  }

  /**
   * Gets the past daily observations (request: actuals), daily records, and daily averages (request: normals) for a specific location and month range.
   * Includes a summary for the range.
   *
   * @param request The request.
   * @return A service response containing either a [ClimatologyRange] or error information.
   */
  public async getClimatologyMonthByLocationKey(
    request: ClimatologyMonthByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(request)) {
      request.climoType = ClimoType.Summary
      request.details = true
    }
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getClimatologyMonthByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getClimatologyMonthByLocationKey'),
    )
  }

  /**
   * Gets a summary of past observations (request: actuals), records, and averages (request: normals) for a specific location and month range.
   *
   * @param request The request.
   * @return A service response containing either a [ClimatologyRangeSummary] or error information.
   */
  public async getClimatologyMonthSummaryByLocationKey(
    request: ClimatologyMonthByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(request)) {
      request.climoType = ClimoType.Summary
    }
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getClimatologyMonthByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getClimatologyMonthSummaryByLocationKey',
      ),
    )
  }
  // endregion summary

  // region actuals
  /**
   * Gets the past daily observations for a specific location and date.
   *
   * @param request The request.
   * @return A service response containing either a [ActualClimatology] or error information.
   */
  public async getActualClimatologyDayByLocationKey(
    request: ClimatologyDayByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(request)) {
      request.climoType = ClimoType.Actuals
    }
    // api doesn't always return actuals
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getClimatologyDayByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getActualClimatologyDayByLocationKey',
        true,
      ),
    )
  }

  /**
   * Gets the past daily observations for a specific location and month range.
   *
   * @param request The request.
   * @return A service response containing either a list of [ActualClimatology] or error information.
   */
  public async getActualClimatologyMonthByLocationKey(
    request: ClimatologyMonthByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    if (isNotNullOrUndefined(request)) {
      request.climoType = ClimoType.Actuals
    }
    // api doesn't always return [single] actuals, but we should at least get an empty list here
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getClimatologyMonthByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getActualClimatologyMonthByLocationKey',
      ),
    )
  }
  // endregion actuals

  // region normals
  /**
   * Gets the past daily averages for a specific location and date.
   *
   * @param request The request.
   * @return A service response containing either a [NormalClimatology] or error information.
   */
  public async getNormalClimatologyDayByLocationKey(
    request: ClimatologyDayByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(request)) {
      request.climoType = ClimoType.Normals
    }
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getClimatologyDayByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getNormalClimatologyDayByLocationKey',
      ),
    )
  }

  /**
   * Gets the past daily averages for a specific location and month range.
   *
   * @param request The request.
   * @return A service response containing either a list of [NormalClimatology] or error information.
   */
  public async getNormalClimatologyMonthByLocationKey(
    request: ClimatologyMonthByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    if (isNotNullOrUndefined(request)) {
      request.climoType = ClimoType.Normals
    }
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getClimatologyMonthByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getNormalClimatologyMonthByLocationKey',
      ),
    )
  }
  // endregion normals

  // region records
  /**
   * Gets the past daily records for a specific location and date.
   *
   * @param request The request.
   * @return A service response containing either a [RecordClimatology] or error information.
   */
  public async getRecordClimatologyDayByLocationKey(
    request: ClimatologyDayByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(request)) {
      request.climoType = ClimoType.Records
    }
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getClimatologyDayByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getRecordClimatologyDayByLocationKey',
      ),
    )
  }

  /**
   * Gets the past daily records for a specific location and month range.
   *
   * @param request The request.
   * @return A service response containing either a list of [RecordClimatology] or error information.
   */
  public async getRecordClimatologyMonthByLocationKey(
    request: ClimatologyMonthByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    if (isNotNullOrUndefined(request)) {
      request.climoType = ClimoType.Records
    }
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getClimatologyMonthByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getRecordClimatologyMonthByLocationKey',
      ),
    )
  }
  // endregion records
  // endregion climo

  private async getMinuteColorsHttp(
    request: object,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getMinuteColorsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getMinuteColors'),
    )
  }
  private async getMinuteColorRangesHttp(
    request: object,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getMinuteColorRangesUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getMinuteColorRanges'),
    )
  }
}
