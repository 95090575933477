// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { ApiTokens } from '../core/api/ApiTokens'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { GeopositionValidRule } from '../core/validation/GeoPositionValidRule'
import { LanguageValidRule } from '../core/validation/LanguageValidRule'
import { LocationKeyIsCityRule } from '../core/validation/LocationKeyIsCityRule'
import { LocationKeyValidRule } from '../core/validation/LocationKeyValidRule'
import { ObjectNotNullOrUndefinedRule } from '../core/validation/ObjectNotNullOrUndefinedRule'
import { SearchTermValidRule } from '../core/validation/SearchTermValidRule'
import { StringNotNullOrWhiteSpaceRule } from '../core/validation/StringNotNullOrWhiteSpaceRule'
import { isNotNullOrBlank } from '../util/Helpers'
import { TokenReplaceSettings } from '../util/templateParsing/TokenReplaceSettings'
import { LocationRouteResolver } from './LocationRouteResolver'
import { AdminAreasRequest } from './requests/AdminAreasRequest'
import { AutocompleteMode } from './requests/AutocompleteMode'
import { AutocompleteRequest } from './requests/AutocompleteRequest'
import { CitiesRequest } from './requests/CitiesRequest'
import { CityByGeopositionRequest } from './requests/CityByGeopositionRequest'
import { CityByIpAddressRequest } from './requests/CityByIpAddressRequest'
import { CityNeighborsByLocationKeyRequest } from './requests/CityNeighborsByLocationKeyRequest'
import { CountriesRequest } from './requests/CountriesRequest'
import { FindAdminAreasRequest } from './requests/FindAdminAreasRequest'
import { FindCitiesRequest } from './requests/FindCitiesRequest'
import { FindCountriesRequest } from './requests/FindCountriesRequest'
import { FindLocationsRequest } from './requests/FindLocationsRequest'
import { FindPointsOfInterestRequest } from './requests/FindPointsOfInterestRequest'
import { FindPostalCodeLocationsRequest } from './requests/FindPostalCodeLocationsRequest'
import { LocationByKeyRequest } from './requests/LocationByKeyRequest'
import { RegionsRequest } from './requests/RegionsRequest'
import { TopCitiesRequest } from './requests/TopCitiesRequest'

/**
 * Used to resolve routes for the [LocationService]
 */
export class LocationRouteResolverImpl implements LocationRouteResolver {
  private readonly includeAliasesToken = 'includeAliases'
  private readonly aliasModeToken = 'aliasMode'

  // region routes
  // region list
  private readonly regions = 'Regions'
  private readonly countries = 'Countries'
  private readonly countriesByRegion = 'CountriesByRegion'
  private readonly adminAreas = 'AdminAreas'
  private readonly adminAreasByCountry = 'AdminAreasByCountry'
  private readonly citiesByCountry = 'CitiesByCountry'
  private readonly citiesByAdminArea = 'CitiesByAdminArea'
  private readonly topCities = 'TopCities'
  // endregion list

  // region by key / ip / geoposition
  private readonly locationByKey = 'ByKey'
  private readonly cityNeighborsByLocationKey = 'CityNeighborsByLocationKey'
  private readonly urbanCityNeighborsByLocationKey = 'UrbanCityNeighborsByLocationKey'
  private readonly cityByIpAddress = 'CityByIpAddress'
  private readonly cityByGeoposition = 'CityByGeoposition'
  // endregion by key / ip / geoposition

  // region search
  private readonly findLocations = 'FindLocations'
  private readonly findLocationsWithTranslation = 'FindLocationsWithTranslation'
  private readonly findLocationsByCountry = 'FindLocationsByCountry'
  private readonly findLocationsByCountryWithTranslation = 'FindLocationsByCountryWithTranslation'
  private readonly findLocationsByAdminArea = 'FindLocationsByAdminArea'
  private readonly findLocationsByAdminAreaWithTranslation =
    'FindLocationsByAdminAreaWithTranslation'
  private readonly findCities = 'FindCities'
  private readonly findCitiesByCountry = 'FindCitiesByCountry'
  private readonly findCitiesByAdminArea = 'FindCitiesByAdminArea'
  private readonly findCitiesWithTranslation = 'FindCitiesWithTranslation'
  private readonly findCitiesByCountryWithTranslation = 'FindCitiesByCountryWithTranslation'
  private readonly findCitiesByAdminAreaWithTranslation = 'FindCitiesByAdminAreaWithTranslation'
  private readonly findPointsOfInterest = 'FindPointsOfInterest'
  private readonly findPointsOfInterestByCountry = 'FindPointsOfInterestByCountry'
  private readonly findPointsOfInterestByAdminArea = 'FindPointsOfInterestByAdmin'
  private readonly findPostalCodeLocations = 'FindPostalCodeLocations'
  private readonly findPostalCodeLocationsByCountry = 'FindPostalCodeLocationsByCountry'
  private readonly findCountries = 'FindCountries'
  private readonly findAdminAreas = 'FindAdminAreas'
  private readonly findAdminAreasByCountry = 'FindAdminAreasByCountry'
  private readonly autocompleteCities = 'AutocompleteCities'
  private readonly autocompleteCitiesByCountry = 'AutocompleteCitiesByCountry'
  private readonly autocompletePoi = 'AutocompletePoi'
  private readonly autocompletePoiByCountry = 'AutocompletePoiByCountry'
  private readonly autocompleteMixed = 'AutocompleteMixed'
  private readonly autocompleteMixedByCountry = 'AutocompleteMixedByCountry'
  // endregion

  private readonly routeTemplates: Map<string, string> = new Map([
    // list
    [this.regions, 'locations/v1/regions?apikey={apikey}&language={language}'],
    [this.countries, 'locations/v1/countries?apikey={apikey}&language={language}'],
    [
      this.countriesByRegion,
      'locations/v1/countries/{regionCode}?apikey={apikey}&language={language}',
    ],
    [this.adminAreas, 'locations/v1/adminareas?apikey={apikey}&language={language}'],
    [
      this.adminAreasByCountry,
      'locations/v1/adminareas/{countryCode}?apikey={apikey}&language={language}',
    ],
    [
      this.citiesByCountry,
      'locations/v1/cities/{countryCode}?apikey={apikey}&language={language}&details={details}&listLength={listLength}',
    ],
    [
      this.citiesByAdminArea,
      'locations/v1/cities/{countryCode}/{adminCode}?apikey={apikey}&language={language}&details={details}&listLength={listLength}',
    ],
    [
      this.topCities,
      'locations/v1/topcities/{count}?apikey={apikey}&language={language}&details={details}',
    ],

    // by key/ip/geoposition
    [
      this.locationByKey,
      'locations/v1/{locationKey}?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.cityNeighborsByLocationKey,
      'locations/v1/cities/neighbors/{locationKey}?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.urbanCityNeighborsByLocationKey,
      'locations/v1/cities/urbanneighbors/{locationKey}?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.cityByGeoposition,
      'locations/v1/cities/geoposition/search?apikey={apikey}&language={language}&details={details}&q={latitude},{longitude}',
    ],
    [
      this.cityByIpAddress,
      'locations/v1/cities/ipaddress?apikey={apikey}&q={q}&language={language}&details={details}',
    ],

    // search
    [
      this.findLocations,
      'locations/v1/search?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],
    [
      this.findLocationsWithTranslation,
      'locations/v1/translate?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],
    [
      this.findLocationsByCountry,
      'locations/v1/{countryCode}/search?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],
    [
      this.findLocationsByCountryWithTranslation,
      'locations/v1/{countryCode}/translate?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],
    [
      this.findLocationsByAdminArea,
      'locations/v1/{countryCode}/{adminCode}/search?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],
    [
      this.findLocationsByAdminAreaWithTranslation,
      'locations/v1/{countryCode}/{adminCode}/translate?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],

    [
      this.findCities,
      'locations/v1/cities/search?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],
    [
      this.findCitiesWithTranslation,
      'locations/v1/cities/translate?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],
    [
      this.findCitiesByCountry,
      'locations/v1/cities/{countryCode}/search?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],
    [
      this.findCitiesByCountryWithTranslation,
      'locations/v1/cities/{countryCode}/translate?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],
    [
      this.findCitiesByAdminArea,
      'locations/v1/cities/{countryCode}/{adminCode}/search?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],
    [
      this.findCitiesByAdminAreaWithTranslation,
      'locations/v1/cities/{countryCode}/{adminCode}/translate?apikey={apikey}&q={q}&language={language}&details={details}&alias={aliasMode}',
    ],

    [
      this.findPointsOfInterest,
      'locations/v1/poi/search?apikey={apikey}&q={q}&language={language}&type={type}&details={details}',
    ],
    [
      this.findPointsOfInterestByCountry,
      'locations/v1/poi/{countryCode}/search?apikey={apikey}&q={q}&language={language}&type={type}&details={details}',
    ],
    [
      this.findPointsOfInterestByAdminArea,
      'locations/v1/poi/{countryCode}/{adminCode}/search?apikey={apikey}&q={q}&language={language}&type={type}&details={details}',
    ],

    [
      this.findPostalCodeLocations,
      'locations/v1/postalcodes/search?apikey={apikey}&q={q}&language={language}&details={details}',
    ],
    [
      this.findPostalCodeLocationsByCountry,
      'locations/v1/postalcodes/{countryCode}/search?apikey={apikey}&q={q}&language={language}&details={details}',
    ],

    [this.findCountries, 'locations/v1/countries/search?apikey={apikey}&q={q}&language={language}'],
    [
      this.findAdminAreas,
      'locations/v1/adminareas/search?apikey={apikey}&q={q}&language={language}',
    ],
    [
      this.findAdminAreasByCountry,
      'locations/v1/adminareas/{countryCode}/search?apikey={apikey}&q={q}&language={language}',
    ],

    [
      this.autocompleteCities,
      'locations/v1/cities/autocomplete?apikey={apikey}&q={q}&language={language}',
    ],
    [
      this.autocompleteCitiesByCountry,
      'locations/v1/cities/{countryCode}/autocomplete?apikey={apikey}&q={q}&language={language}',
    ],
    [
      this.autocompletePoi,
      'locations/v1/poi/autocomplete?apikey={apikey}&q={q}&language={language}&includeAliases={includeAliases}',
    ],
    [
      this.autocompletePoiByCountry,
      'locations/v1/poi/{countryCode}/autocomplete?apikey={apikey}&q={q}&language={language}&includeAliases={includeAliases}',
    ],
    [
      this.autocompleteMixed,
      'locations/v1/autocomplete?apikey={apikey}&q={q}&language={language}&includeAliases={includeAliases}',
    ],
    [
      this.autocompleteMixedByCountry,
      'locations/v1/{countryCode}/autocomplete?apikey={apikey}&q={q}&language={language}&includeAliases={includeAliases}',
    ],
  ])
  // endregion routes

  // region validators
  private readonly regionCodeParam = 'region code'
  private readonly countryCodeParam = 'country code'
  private readonly adminCodeParam = 'admin code'
  private readonly countParam = 'count'
  private readonly ipAddressParam = 'ip address'

  private readonly urlEncodingTokenReplaceSettings: TokenReplaceSettings

  // region list
  private readonly regionsValidator: AsyncValidator<RegionsRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => LanguageValidRule.checkRule(r.language),
  ])
  private readonly countriesValidator: AsyncValidator<CountriesRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => LanguageValidRule.checkRule(r.language),
  ])
  private readonly countriesByRegionValidator: AsyncValidator<CountriesRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.regionCode, this.regionCodeParam),
    ])
  private readonly adminAreasValidator: AsyncValidator<AdminAreasRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => LanguageValidRule.checkRule(r.language),
  ])
  private readonly adminAreasByCountryValidator: AsyncValidator<AdminAreasRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
    ])
  private readonly citiesByCountryValidator: AsyncValidator<CitiesRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => LanguageValidRule.checkRule(r.language),
    (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
  ])
  private readonly citiesByAdminAreaValidator: AsyncValidator<CitiesRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => LanguageValidRule.checkRule(r.language),
    (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
    (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.adminCode, this.adminCodeParam),
  ])
  private readonly topCitiesValidator: AsyncValidator<TopCitiesRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => LanguageValidRule.checkRule(r.language),
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r.count, this.countParam),
  ])
  // endregion list

  // region by key / ip / geoposition
  private readonly locationByKeyValidator: AsyncValidator<LocationByKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])
  private readonly cityNeighborsByLocationKeyValidator: AsyncValidator<CityNeighborsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyIsCityRule.validate(r.locationKey),
    ])
  private readonly urbanCityNeighborsByLocationKeyValidator: AsyncValidator<CityNeighborsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])
  private readonly cityByIpAddressValidator: AsyncValidator<CityByIpAddressRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.ipAddress, this.ipAddressParam),
    ])
  private readonly cityByGeopositionValidator: AsyncValidator<CityByGeopositionRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => GeopositionValidRule.checkRule(r.latitude, r.longitude),
    ])
  // endregion

  // region search
  private readonly searchLocationsByNameValidator: AsyncValidator<FindLocationsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => SearchTermValidRule.checkRule(r.term),
    ])
  private readonly findLocationsByCountryValidator: AsyncValidator<FindLocationsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
      (r) => SearchTermValidRule.checkRule(r.term),
    ])
  private readonly findLocationsByAdminAreaValidator: AsyncValidator<FindLocationsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.adminCode, this.adminCodeParam),
      (r) => SearchTermValidRule.checkRule(r.term),
    ])
  private readonly findCitiesValidator: AsyncValidator<FindCitiesRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => LanguageValidRule.checkRule(r.language),
    (r) => SearchTermValidRule.checkRule(r.name),
  ])
  private readonly findCitiesByCountryValidator: AsyncValidator<FindCitiesRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
      (r) => SearchTermValidRule.checkRule(r.name),
    ])
  private readonly findCitiesByAdminAreaValidator: AsyncValidator<FindCitiesRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.adminCode, this.adminCodeParam),
      (r) => SearchTermValidRule.checkRule(r.name),
    ])

  // region Points of Interest
  private readonly findPointsOfInterestValidator: AsyncValidator<FindPointsOfInterestRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => SearchTermValidRule.checkRule(r.name),
    ])
  private readonly findPointsOfInterestByCountryValidator: AsyncValidator<FindPointsOfInterestRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
      (r) => SearchTermValidRule.checkRule(r.name),
    ])
  private readonly findPointsOfInterestByAdminAreaValidator: AsyncValidator<FindPointsOfInterestRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.adminCode, this.adminCodeParam),
      (r) => SearchTermValidRule.checkRule(r.name),
    ])
  // endregion

  // region Postal Codes
  private readonly postalCodeSearchValidator: AsyncValidator<FindPostalCodeLocationsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => SearchTermValidRule.checkRule(r.postalCode),
    ])
  private readonly postalCodeSearchByCountryValidator: AsyncValidator<FindPostalCodeLocationsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
      (r) => SearchTermValidRule.checkRule(r.postalCode),
    ])
  // endregion

  private readonly searchCountriesValidator: AsyncValidator<FindCountriesRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => SearchTermValidRule.checkRule(r.name),
    ])
  private readonly searchAdminAreasValidator: AsyncValidator<FindAdminAreasRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => SearchTermValidRule.checkRule(r.name),
    ])
  private readonly searchAdminAreasByCountryValidator: AsyncValidator<FindAdminAreasRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
      (r) => SearchTermValidRule.checkRule(r.name),
    ])

  // region autocomplete
  private readonly citiesAutocompleteValidator: AsyncValidator<AutocompleteRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => SearchTermValidRule.checkRule(r.term),
    ])
  private readonly citiesAutocompleteByCountryValidator: AsyncValidator<AutocompleteRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => SearchTermValidRule.checkRule(r.term),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
    ])
  private readonly poiAutocompleteValidator: AsyncValidator<AutocompleteRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => SearchTermValidRule.checkRule(r.term),
    ])
  private readonly poiAutocompleteByCountryValidator: AsyncValidator<AutocompleteRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
      (r) => SearchTermValidRule.checkRule(r.term),
    ])
  private readonly mixedAutocompleteValidator: AsyncValidator<AutocompleteRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => SearchTermValidRule.checkRule(r.term),
    ])
  private readonly mixedAutocompleteByCountryValidator: AsyncValidator<AutocompleteRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, this.countryCodeParam),
      (r) => SearchTermValidRule.checkRule(r.term),
    ])
  // endregion autocomplete
  // endregion search
  // endregion validators

  private readonly routeResolver: ApiRouteResolver
  private readonly sdkSettings: SdkSettings

  constructor(sdkSettings: SdkSettings) {
    this.sdkSettings = sdkSettings
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, this.sdkSettings)
    this.urlEncodingTokenReplaceSettings = new TokenReplaceSettings()
    this.urlEncodingTokenReplaceSettings.replaceNullWithEmpty = true
    this.urlEncodingTokenReplaceSettings.replaceUrlEncoded = true
  }

  // region list
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getRegionsUrl(
    request: RegionsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.regions,
      request,
      this.regionsValidator,
      requestChain,
      new Map<string, any>([[ApiTokens.language, request?.language]]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getCountriesUrl(
    request: CountriesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    let route = this.countries
    let validator = this.countriesValidator
    if (isNotNullOrBlank(request?.regionCode)) {
      route = this.countriesByRegion
      validator = this.countriesByRegionValidator
    }
    return await this.routeResolver.resolveAsync(
      route,
      request,
      validator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.regionCode, request?.regionCode],
        [ApiTokens.language, request?.language],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getAdminAreasUrl(
    request: AdminAreasRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    let route = this.adminAreas
    let validator = this.adminAreasValidator
    if (isNotNullOrBlank(request?.countryCode)) {
      route = this.adminAreasByCountry
      validator = this.adminAreasByCountryValidator
    }
    return await this.routeResolver.resolveAsync(
      route,
      request,
      validator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.countryCode, request?.countryCode],
        [ApiTokens.language, request?.language],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getCitiesUrl(
    request: CitiesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    let route = this.citiesByCountry
    let validator = this.citiesByCountryValidator
    if (isNotNullOrBlank(request?.adminCode)) {
      route = this.citiesByAdminArea
      validator = this.citiesByAdminAreaValidator
    }
    return await this.routeResolver.resolveAsync(
      route,
      request,
      validator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.countryCode, request?.countryCode],
        [ApiTokens.adminCode, request?.adminCode],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
        [ApiTokens.listLength, request?.listLength],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getTopCitiesUrl(
    request: TopCitiesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.topCities,
      request,
      this.topCitiesValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [ApiTokens.count, request?.count],
        [ApiTokens.details, request?.details],
      ]),
    )
  }
  // endregion list

  // region by key / ip / geoposition
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getLocationByKeyUrl(
    request: LocationByKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.locationByKey,
      request,
      this.locationByKeyValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getCityNeighborsByLocationKeyUrl(
    request: CityNeighborsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.cityNeighborsByLocationKey,
      request,
      this.cityNeighborsByLocationKeyValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getUrbanCityNeighborsByLocationKeyUrl(
    request: CityNeighborsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.urbanCityNeighborsByLocationKey,
      request,
      this.urbanCityNeighborsByLocationKeyValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getCityByIpAddressUrl(
    request: CityByIpAddressRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.cityByIpAddress,
      request,
      this.cityByIpAddressValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [ApiTokens.q, request?.ipAddress],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getCityByGeopositionUrl(
    request: CityByGeopositionRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.cityByGeoposition,
      request,
      this.cityByGeopositionValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.latitude, request?.latitude],
        [ApiTokens.longitude, request?.longitude],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }
  // endregion by key / ip / geoposition

  // region search
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async findLocationsUrl(
    request: FindLocationsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    let route: string
    let validator: AsyncValidator<FindLocationsRequest>
    if (isNotNullOrBlank(request?.adminCode)) {
      route = request?.shouldTranslate
        ? this.findLocationsByAdminAreaWithTranslation
        : this.findLocationsByAdminArea
      validator = this.findLocationsByAdminAreaValidator
    } else if (isNotNullOrBlank(request?.countryCode)) {
      route = request?.shouldTranslate
        ? this.findLocationsByCountryWithTranslation
        : this.findLocationsByCountry
      validator = this.findLocationsByCountryValidator
    } else {
      route = request?.shouldTranslate ? this.findLocationsWithTranslation : this.findLocations
      validator = this.searchLocationsByNameValidator
    }
    return await this.routeResolver.resolveAsync(
      route,
      request,
      validator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.q, request?.term],
        [ApiTokens.countryCode, request?.countryCode],
        [ApiTokens.adminCode, request?.adminCode],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
        [this.aliasModeToken, request?.aliasMode],
      ]),
      this.urlEncodingTokenReplaceSettings,
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async findCitiesUrl(
    request: FindCitiesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    let route: string
    let validator: AsyncValidator<FindCitiesRequest>
    if (isNotNullOrBlank(request?.adminCode)) {
      route = request?.shouldTranslate
        ? this.findCitiesByAdminAreaWithTranslation
        : this.findCitiesByAdminArea
      validator = this.findCitiesByAdminAreaValidator
    } else if (isNotNullOrBlank(request?.countryCode)) {
      route = request?.shouldTranslate
        ? this.findCitiesByCountryWithTranslation
        : this.findCitiesByCountry
      validator = this.findCitiesByCountryValidator
    } else {
      route = request?.shouldTranslate ? this.findCitiesWithTranslation : this.findCities
      validator = this.findCitiesValidator
    }
    return await this.routeResolver.resolveAsync(
      route,
      request,
      validator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.q, request?.name],
        [ApiTokens.countryCode, request?.countryCode],
        [ApiTokens.adminCode, request?.adminCode],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
        [this.aliasModeToken, request?.aliasMode],
      ]),
      this.urlEncodingTokenReplaceSettings,
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async findPointsOfInterestUrl(
    request: FindPointsOfInterestRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    let route: string
    let validator: AsyncValidator<FindPointsOfInterestRequest>
    if (isNotNullOrBlank(request?.adminCode)) {
      route = this.findPointsOfInterestByAdminArea
      validator = this.findPointsOfInterestByAdminAreaValidator
    } else if (isNotNullOrBlank(request?.countryCode)) {
      route = this.findPointsOfInterestByCountry
      validator = this.findPointsOfInterestByCountryValidator
    } else {
      route = this.findPointsOfInterest
      validator = this.findPointsOfInterestValidator
    }
    return await this.routeResolver.resolveAsync(
      route,
      request,
      validator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.q, request?.name],
        [ApiTokens.countryCode, request?.countryCode],
        [ApiTokens.adminCode, request?.adminCode],
        [ApiTokens.language, request?.language],
        [ApiTokens.type, request?.pointOfInterestType],
        [ApiTokens.details, request?.details],
      ]),
      this.urlEncodingTokenReplaceSettings,
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async findPostalCodeLocationsUrl(
    request: FindPostalCodeLocationsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    let route = this.findPostalCodeLocations
    let validator = this.postalCodeSearchValidator
    if (isNotNullOrBlank(request?.countryCode)) {
      route = this.findPostalCodeLocationsByCountry
      validator = this.postalCodeSearchByCountryValidator
    }
    return await this.routeResolver.resolveAsync(
      route,
      request,
      validator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.q, request?.postalCode],
        [ApiTokens.countryCode, request?.countryCode],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async findCountriesUrl(
    request: FindCountriesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.findCountries,
      request,
      this.searchCountriesValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [ApiTokens.q, request?.name],
      ]),
      this.urlEncodingTokenReplaceSettings,
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async findAdminAreasUrl(
    request: FindAdminAreasRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    let route = this.findAdminAreas
    let validator = this.searchAdminAreasValidator
    if (isNotNullOrBlank(request?.countryCode)) {
      route = this.findAdminAreasByCountry
      validator = this.searchAdminAreasByCountryValidator
    }
    return await this.routeResolver.resolveAsync(
      route,
      request,
      validator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.countryCode, request?.countryCode],
        [ApiTokens.language, request?.language],
        [ApiTokens.q, request?.name],
      ]),
      this.urlEncodingTokenReplaceSettings,
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async findByAutocompleteUrl(
    request: AutocompleteRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    const route = this.getAutocompleteRoute(request)
    const validator = this.getAutocompleteValidator(request)
    return await this.routeResolver.resolveAsync(
      route,
      request,
      validator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.q, request?.term],
        [ApiTokens.countryCode, request?.countryCode],
        [ApiTokens.language, request?.language],
        [this.includeAliasesToken, request?.includeAliases],
      ]),
    )
  }

  private getAutocompleteRoute(request: AutocompleteRequest): string {
    if (request?.mode === AutocompleteMode.Cities) {
      if (isNotNullOrBlank(request?.countryCode)) {
        return this.autocompleteCitiesByCountry
      } else {
        return this.autocompleteCities
      }
    }

    if (request?.mode === AutocompleteMode.PointsOfInterest) {
      if (isNotNullOrBlank(request?.countryCode)) {
        return this.autocompletePoiByCountry
      } else {
        return this.autocompletePoi
      }
    }

    if (isNotNullOrBlank(request?.countryCode)) {
      return this.autocompleteMixedByCountry
    } else {
      return this.autocompleteMixed
    }
  }

  private getAutocompleteValidator(
    request: AutocompleteRequest,
  ): AsyncValidator<AutocompleteRequest> {
    if (request?.mode === AutocompleteMode.Cities) {
      if (isNotNullOrBlank(request?.countryCode)) {
        return this.citiesAutocompleteByCountryValidator
      } else {
        return this.citiesAutocompleteValidator
      }
    }

    if (request?.mode === AutocompleteMode.PointsOfInterest) {
      if (isNotNullOrBlank(request?.countryCode)) {
        return this.poiAutocompleteByCountryValidator
      } else {
        return this.poiAutocompleteValidator
      }
    }

    if (isNotNullOrBlank(request?.countryCode)) {
      return this.mixedAutocompleteByCountryValidator
    } else {
      return this.mixedAutocompleteValidator
    }
  }
  // endregion search
}
