/**
 * Color table
 */
export enum ColorTable {
  /**
   * Off
   */
  Off = 'off',

  /**
   * On
   */
  On = 'on',
}
