// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { valueOrDefault } from '../../util/Helpers'
import { SdkHttpSettingsOverrides } from './SdkHttpSettingsOverrides'

/**
 * Settings for how to handle http connections.
 */
export class SdkHttpSettings {
  /**
   * Gets the `retrySleepDuration` multiplier to exponentially backoff when retrying.
   * Default is 1 (exponential backoff is disabled).
   */
  public get retryBackoffFactor(): number {
    return this.backoffFactor
  }

  /**
   * Sets the `retrySleepDuration` multiplier to exponentially backoff when retrying.
   * Values less than 1 will be ignored.
   */
  public set retryBackoffFactor(value: number) {
    this.backoffFactor = value < 1.0 ? 1.0 : value
  }

  /**
   * The initial duration between retry attempts, in milliseconds. Default is 500
   */
  public get retrySleepDuration(): number {
    return this._retrySleepDuration
  }
  public set retrySleepDuration(v: number) {
    this._retrySleepDuration = SdkHttpSettings.retrySleepDurationOrThrow(v)
  }

  /**
   * The max duration between retry attempts, in milliseconds. Default is 2500
   */
  public get maxRetrySleepDuration(): number {
    return this._maxRetrySleepDuration
  }
  public set maxRetrySleepDuration(v: number) {
    this._maxRetrySleepDuration = SdkHttpSettings.retrySleepDurationOrThrow(v)
  }

  public static retrySleepDurationOrThrow(retrySleepDuration: number): number {
    if (retrySleepDuration < this.minRetrySleepDuration) {
      throw Error(
        `Invalid retrySleepDuration, value must be greater than ${this.minRetrySleepDuration} millliseconds.`,
      )
    }
    return retrySleepDuration
  }

  private static minRetrySleepDuration = 100

  /**
   * The host address.
   */
  private baseAddress: string

  /**
   * Returns the host address adding a trailing slash if necessary.
   */
  public getBaseAddress(): string {
    if (!this.baseAddress.endsWith('/')) {
      this.baseAddress += '/'
    }
    return this.baseAddress
  }

  /**
   * True if the request should be retried, false otherwise. Default is true.
   */
  public shouldRetry = true

  /**
   * The overall connection timeout across the whole execution, in milliseconds.
   * Default is 3000
   */
  public overallTimeout = 3000

  /**
   * The connection timeout per-try for http requests, in milliseconds. Default is 250
   */
  public timeoutPerTry = 250

  /**
   * A custom name to append to the User-Agent header when making service requests. Optional.
   */
  public userAgentSuffix?: string = undefined

  /**
   * Any additional headers to include in the request.
   * User-Agent and Cookie headers are handled separately and will be ignored if included here.
   */
  public headers?: Map<string, string> = undefined

  private _retrySleepDuration = 500

  private _maxRetrySleepDuration = 2500

  private backoffFactor = 1.0
  /**
   * Initializes a new `BaseApiSettings`.
   * @param baseAddress the host address.
   */
  protected constructor(baseAddress: string) {
    this.baseAddress = baseAddress
  }

  /**
   * Merges optional settings.
   * @param overrides the override settings
   * @return A clone of the original settings, with any overrides applied.
   */
  public merge(overrides: SdkHttpSettingsOverrides): SdkHttpSettings {
    const clone = new SdkHttpSettings(valueOrDefault(overrides?.baseAddress, this.baseAddress))
    clone.shouldRetry = valueOrDefault(overrides?.shouldRetry, this.shouldRetry)
    clone.overallTimeout = valueOrDefault(overrides?.overallTimeout, this.overallTimeout)
    clone.timeoutPerTry = valueOrDefault(overrides?.timeoutPerTry, this.timeoutPerTry)
    clone.retrySleepDuration = valueOrDefault(
      overrides?.retrySleepDuration,
      this.retrySleepDuration,
    )
    clone.maxRetrySleepDuration = valueOrDefault(
      overrides?.maxRetrySleepDuration,
      this.maxRetrySleepDuration,
    )
    clone.retryBackoffFactor = valueOrDefault(
      overrides?.retryBackoffFactor,
      this.retryBackoffFactor,
    )
    clone.userAgentSuffix = valueOrDefault(overrides?.userAgentSuffix, this.userAgentSuffix)
    clone.headers = valueOrDefault(overrides?.headers, this.headers)
    return clone
  }
}
