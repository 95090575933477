/**
 * Specifies when alias locations should be included in the results.
 * By default, an alias will only be returned if no official match was found.
 *
 * Aliases are alternate names that are used to account for common alternate spellings
 * (Saint Louis, St. Louis, St Louis), historical names (Ho Chi Minh City, Saigon),
 * and accepted alternate names (Derry, Londonderry).
 */
export enum AliasMode {
  /**
   * An alias will only be returned if no official match was found.
   * This is the default.
   */
  NoOfficialMatchFound = 'NoOfficialMatchFound',

  /**
   * An alias will never be returned.
   */
  Never = 'Never',

  /**
   * Aliases will always be included.
   */
  Always = 'Always',
}
