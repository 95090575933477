import { BasinId } from '../../core/models/BasinId'

/**
 * Encapsulates the parameters used to make requests to the TropicalService.
 */
export class StormByIdRequest {
  /**
   * Gets the language (i.e. en-us) in which the response is returned.
   */
  public readonly language: string

  /**
   * Gets or sets the basin ID.
   */
  public readonly basinId: BasinId

  /**
   * Gets or sets the year.
   */
  public readonly year: number

  /**
   * Gets or sets the storm ID.
   */
  public readonly stormId: number

  /**
   * Gets or sets if details should be returned.
   */
  public details: boolean

  /**
   * Gets or sets if window geometry should be returned
   */
  public windowGeometry: boolean

  /**
   * Gets or sets if raddi geometry should be returned
   */
  public radiiGeometry: boolean

  constructor(
    language: string,
    basinId: BasinId = BasinId.EP,
    year: number = new Date().getFullYear(),
    stormId = 0,
    details = false,
    windowGeometry = false,
    radiiGeometry = false,
  ) {
    this.language = language
    this.basinId = basinId
    this.year = year
    this.stormId = stormId
    this.details = details
    this.windowGeometry = windowGeometry
    this.radiiGeometry = radiiGeometry
  }
}
