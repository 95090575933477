/**
 * Provides a collection of private vals
 * used to replace {tokens} in cms route templates.
 */
export class CmsTokens {
  /**
   * Used to replace {basin} in cms url templates.
   */
  public static readonly basin = 'basin'

  /**
   * Used to replace {blogs} in cms url templates.
   */
  public static readonly blogs = 'blogs'

  /**
   * Used to replace {category} in cms url templates.
   */
  public static readonly category = 'category'

  /**
   * Used to replace {city} in cms url templates.
   */
  public static readonly city = 'city'

  /**
   * Used to replace {country} in cms url templates.
   */
  public static readonly country = 'country'

  /**
   * Used to replace {dmaCode} in cms url templates.
   */
  public static readonly dmaCode = 'dmaCode'

  /**
   * Used to replace {dmaCodes} in cms url templates.
   */
  public static readonly dmaCodes = 'dmaCodes'

  /**
   * Used to replace {id} in cms url templates.
   */
  public static readonly id = 'id'

  /**
   * Used to replace {includeLegacyPress} in cms url templates.
   */
  public static readonly includeLegacyPress = 'includeLegacyPress'

  /**
   * Used to replace {locations} in cms url templates.
   */
  public static readonly locations = 'locations'

  /**
   * Used to replace {page} in cms url templates.
   */
  public static readonly page = 'page'

  /**
   * Used to replace {pageSize} in cms url templates.
   */
  public static readonly pageSize = 'pageSize'

  /**
   * Used to replace {previewKey} in cms url templates.
   */
  public static readonly previewKey = 'previewKey'

  /**
   * Used to replace {slug} in api cms templates.
   */
  public static readonly slug = 'slug'

  /**
   * Used to replace {tag} in cms url templates.
   */
  public static readonly tag = 'tag'

  /**
   * Used to replace {template} in cms url templates.
   */
  public static readonly template = 'template'

  /**
   * Used to replace {year} in cms url templates.
   */
  public static readonly year = 'year'

  /**
   * Used to replace {backfillNewsfeed} in cms url templates.
   */
  public static readonly backfillNewsFeed = 'backfillNewsFeed'

  /**
   * Used to replace {sortOrder} in cms url templates.
   */
  public static readonly sortOrder = 'sortOrder'

  /**
   * Used to replace {contentType} in cms url templates.
   */
  public static readonly contentType = 'contentType'
}
