import { StringNotNullOrWhiteSpaceRule } from './StringNotNullOrWhiteSpaceRule'

/**
 * Provides methods used to validate language ISOs.
 */
export class LanguageValidRule {
  /**
   * Checks whether a language code is not null or white space.
   * @param languageCode The language code to be validated.
   * @return If language is invalid, an exception; otherwise, undefined.
   */
  public static checkRule(languageCode: string): Error {
    return StringNotNullOrWhiteSpaceRule.checkRule(languageCode, 'languageCode')
  }
}
