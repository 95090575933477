/**
 * Encapsulates the available numbers of top cities to be returned.
 * @param value the integer representation of this enum.
 */
export enum TopCityCount {
  /**
   * Top 50
   */
  TOP_50 = 50,

  /**
   * Top 100
   */
  TOP_100 = 100,

  /**
   * Top 150
   */
  TOP_150 = 150,
}

/**
 * Encapsulates the parameters used to make requests to the appropriate API endpoint.
 * This request can be used to make requests to the LocationService.
 */
export class TopCitiesRequest {
  /**
   * The language (i.e. en-us) in which the response is returned.
   */
  public readonly language: string

  /**
   * The number of results to return.
   */
  public readonly count: TopCityCount

  /**
   * Gets or sets the details flag. True indicates that the response should include the full details.
   */
  public details: boolean

  constructor(language: string, count: TopCityCount, details = false) {
    this.language = language
    this.count = count
    this.details = details
  }
}
