// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 * Provides methods to convert distance values to other LinearUnits.
 *
 * See also AccuWeather.Common.Measurements (api)
 */
export abstract class DistanceConversion {
  /**
   * Converts a distance to miles.
   * @param value The original value to be converted.
   *
   */
  public abstract toMiles(value: number): number

  /**
   * Converts a distance to nautical miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in nautical miles.
   */
  public abstract toNauticalMiles(value: number): number

  /**
   * Converts a distance to feet.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in feet.
   */
  public abstract toFeet(value: number): number

  /**
   * Converts a distance to inches.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches.
   */
  public abstract toInches(value: number): number

  /**
   * Converts a distance to kilometers.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilometers.
   */
  public abstract toKilometers(value: number): number

  /**
   * Converts a distance to meters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in meters.
   */
  public abstract toMeters(value: number): number

  /**
   * Converts a distance to centimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in centimeters.
   */
  public abstract toCentimeters(value: number): number

  /**
   * Converts a distance to millimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters.
   */
  public abstract toMillimeters(value: number): number
}

/**
 * Provides methods to convert distances in miles to other LinearUnits.
 */
export class Miles extends DistanceConversion {
  /**
   * Converts a distance to miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in miles.
   * (no conversion)
   */
  public toMiles(value: number): number {
    return value
  }

  /**
   * Converts a distance to nautical miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in nautical miles.
   * 1 mile = 0.868976242 Nautical Miles
   */
  public toNauticalMiles(value: number): number {
    return value * 0.868976242
  }

  /**
   * Converts a distance to feet.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in feet.
   * 1 mile =  5280 ft
   */
  public toFeet(value: number): number {
    return value * 5280
  }

  /**
   * Converts a distance to inches.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches.
   * 1 mile =  (5280 * 12) Inches
   */
  public toInches(value: number): number {
    return value * 5280 * 12
  }

  /**
   * Converts a distance to kilometers.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilometers.
   * 1 mile = 1.609344 km
   */
  public toKilometers(value: number): number {
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    return value * 1.6093439999999998
  }

  /**
   * Converts a distance to meters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in meters.
   * 1 Mile = 1609.344 Meters
   */
  public toMeters(value: number): number {
    return value * 1609.344
  }

  /**
   * Converts a distance to centimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in centimeters.
   * 1 Mile = 1609.344 Meters * 100
   */
  public toCentimeters(value: number): number {
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    return value * 160934.39999999998
  }

  /**
   * Converts a distance to millimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters.
   * Millimeters = 1609.344 Meters/mile * 1000 mm/Meter
   */
  public toMillimeters(value: number): number {
    return value * 1609344
  }
}

/**
 * Provides methods to convert distances in nautical miles to other LinearUnits.
 */
export class NauticalMiles extends DistanceConversion {
  /**
   * Converts a distance to miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in miles.
   * 1 nautical mile = 1.15077945 miles
   */
  public toMiles(value: number): number {
    return value * 1.15077945
  }

  /**
   * Converts a distance to nautical miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in nautical miles.
   * (no conversion)
   */
  public toNauticalMiles(value: number): number {
    return value
  }

  /**
   * Converts a distance to feet.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in feet.
   * 1 nautical mile =  6076.11549 feet
   */
  public toFeet(value: number): number {
    return value * 6076.11549
  }

  /**
   * Converts a distance to inches.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches.
   * 1 nautical mile =  72913.3858 Inches
   */
  public toInches(value: number): number {
    return value * 6076.11549 * 12
  }

  /**
   * Converts a distance to kilometers.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilometers.
   * 1 nautical mile = 1.85200 kilometers
   */
  public toKilometers(value: number): number {
    return value * 1.852
  }

  /**
   * Converts a distance to meters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in meters.
   * 1 nautical mile = 1852 meters
   */
  public toMeters(value: number): number {
    return value * 1852
  }

  /**
   * Converts a distance to centimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in centimeters.
   * 1 nautical mile = 185200 centimeters
   */
  public toCentimeters(value: number): number {
    return value * 185200
  }

  /**
   * Converts a distance to millimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters.
   * 1 nautical mile = 1852000 millimeters
   */
  public toMillimeters(value: number): number {
    return value * 1852000
  }
}

/**
 * Provides methods to convert distances in feet to other LinearUnits.
 */
export class Feet extends DistanceConversion {
  /**
   * Converts a distance to miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in miles.
   * 1 mi = 5280 ft
   */
  public toMiles(value: number): number {
    return value / 5280
  }

  /**
   * Converts a distance to nautical miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in nautical miles.
   * 1 Foot = 0.000164578834 nautical miles
   */
  public toNauticalMiles(value: number): number {
    return value * 0.000164578834
  }

  /**
   * Converts a distance to feet.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in feet.
   * (no conversion)
   */
  public toFeet(value: number): number {
    return value
  }

  /**
   * Converts a distance to inches.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches.
   * 1 foot = 12 Inches
   */
  public toInches(value: number): number {
    return value * 12
  }

  /**
   * Converts a distance to kilometers.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilometers.
   * 1 foot = 0.3048 meter * 1/1000 km/m
   */
  public toKilometers(value: number): number {
    return (value * 0.3048) / 1000.0
  }

  /**
   * Converts a distance to meters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in meters.
   * 1 foot = 0.3048 meter
   */
  public toMeters(value: number): number {
    return value * 0.3048
  }

  /**
   * Converts a distance to centimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in centimeters.
   * 1 inch = 2.54 centimers 1 ft = 2.54 * 12
   */
  public toCentimeters(value: number): number {
    return value * 2.54 * 12
  }

  /**
   * Converts a distance to millimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters.
   * 1 Foot = 304.8 mm
   */
  public toMillimeters(value: number): number {
    return value * 304.8
  }
}

/**
 * Provides methods to convert distances in inches to other LinearUnits.
 */
export class Inches extends DistanceConversion {
  /**
   * Converts a distance to miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in miles.
   * 1 mile = (5280 * 12) inches 1 inch = 1/(5280 * 12)
   */
  public toMiles(value: number): number {
    return value / (5280 * 12)
  }

  /**
   * Converts a distance to nautical miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in nautical miles.
   * 1 inch = 1.37149028E-5 nautical miles
   */
  public toNauticalMiles(value: number): number {
    return value * 1.37149028e-5
  }

  /**
   * Converts a distance to feet.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in feet.
   * 1 foot = 12 Inches
   */
  public toFeet(value: number): number {
    return value * (1 / 12)
  }

  /**
   * Converts a distance to inches.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches.
   * (no conversion)
   */
  public toInches(value: number): number {
    return value
  }

  /**
   * Converts a distance to kilometers.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilometers.
   * 12 inches = 0.3048 meter * 1/1000 km/m
   */
  public toKilometers(value: number): number {
    return (value * (0.3048 / 12.0)) / 1000.0
  }

  /**
   * Converts a distance to meters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in meters.
   * 12 inches = 0.3048 meter
   */
  public toMeters(value: number): number {
    return value * (0.3048 / 12.0)
  }

  /**
   * Converts a distance to centimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in centimeters.
   * 1 inch = 2.54 centimers
   */
  public toCentimeters(value: number): number {
    return value * 2.54
  }

  /**
   * Converts a distance to millimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters.
   * 1 inch = 25.4 mm
   */
  public toMillimeters(value: number): number {
    return value * 25.4
  }
}

/**
 * Provides methods to convert distances in kilometers to other LinearUnits.
 */
export class Kilometers extends DistanceConversion {
  /**
   * Converts a distance to miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in miles.
   * 1 Mile = 1.609344 km
   */
  public toMiles(value: number): number {
    return value * 0.621371192237334
  }

  /**
   * Converts a distance to nautical miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in nautical miles.
   * 1 kilometer = 0.539956803 nautical miles
   */
  public toNauticalMiles(value: number): number {
    return value * 0.539956803
  }

  /**
   * Converts a distance to feet.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in feet.
   * 1 kilometer =  1000 * 3.2808399 feet
   */
  public toFeet(value: number): number {
    return value * 3280.839895013123
  }

  /**
   * Converts a distance to inches.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches.
   * 1 kilometer =  1000 * 3.2808399 * 12 Inches
   */
  public toInches(value: number): number {
    return value * 39370.07874015748
  }

  /**
   * Converts a distance to kilometers.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilometers.
   * (no conversion)
   */
  public toKilometers(value: number): number {
    return value
  }

  /**
   * Converts a distance to meters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in meters.
   * 1 km = 1000 meters
   */
  public toMeters(value: number): number {
    return value * 1000
  }

  /**
   * Converts a distance to centimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in centimeters.
   * (no conversion)
   */
  public toCentimeters(value: number): number {
    return value * 100000
  }

  /**
   * Converts a distance to millimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters.
   * 1 Millimeter = 1 / 1000 kilometers
   */
  public toMillimeters(value: number): number {
    return value * 1000000
  }
}

/**
 * Provides methods to convert distances in meters to other LinearUnits.
 */
export class Meters extends DistanceConversion {
  /**
   * Converts a distance to miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in miles.
   * 1 mile = 1,609.344 m 1 m = 1/1,609.344 mi
   */
  public toMiles(value: number): number {
    return value * 6.21371192237334e-4
  }

  /**
   * Converts a distance to nautical miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in nautical miles.
   * 1 meter = 0.000539956803 nautical miles
   */
  public toNauticalMiles(value: number): number {
    return value * 0.000539956803
  }

  /**
   * Converts a distance to feet.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in feet.
   * 1 meter =  3.2808399 feet
   */
  public toFeet(value: number): number {
    return value * 3.2808399
  }

  /**
   * Converts a distance to inches.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches.
   * 1 meter =  (3.2808399*12) Inches
   */
  public toInches(value: number): number {
    return value * 3.2808399 * 12
  }

  /**
   * Converts a distance to kilometers.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilometers.
   * 1 meter = 1/1000 kilometers
   */
  public toKilometers(value: number): number {
    return value * 0.001
  }

  /**
   * Converts a distance to meters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in meters.
   * (no conversion)
   */
  public toMeters(value: number): number {
    return value
  }

  /**
   * Converts a distance to centimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in centimeters.
   * 1 centimeter = 1/100 meters
   */
  public toCentimeters(value: number): number {
    return value * 100
  }

  /**
   * Converts a distance to millimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters.
   * 1 meter = 1000 millimeters
   */
  public toMillimeters(value: number): number {
    return value * 1000
  }
}

/**
 * Provides methods to convert distances in centimeters to other LinearUnits.
 */
export class Centimeters extends DistanceConversion {
  /**
   * Converts a distance to miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in miles.
   * 1 foot = 2.54 cm / inch * 12 in /ft * 5280 ft/mi
   */
  public toMiles(value: number): number {
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    return value * 6.213711922373339696174e-6
  }

  /**
   * Converts a distance to nautical miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in nautical miles.
   * 1 centimeter = 5.39956803E-6 nautical miles
   */
  public toNauticalMiles(value: number): number {
    return value * 5.39956803e-6
  }

  /**
   * Converts a distance to feet.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in feet.
   * 1 foot =  2.54 cm/inch * 12 in /ft
   */
  public toFeet(value: number): number {
    return value * 0.03280839895013123
  }

  /**
   * Converts a distance to inches.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches.
   * 1 inch =  2.54 cm
   */
  public toInches(value: number): number {
    return value * 0.39370078740157477
  }

  /**
   * Converts a distance to kilometers.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilometers.
   * 1 meter = 1/1000 kilometers 1 Centimeter = 1/100 meter
   */
  public toKilometers(value: number): number {
    return value * 0.00001
  }

  /**
   * Converts a distance to meters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in meters.
   * 1 meter = 100 cm
   */
  public toMeters(value: number): number {
    return value * 0.01
  }

  /**
   * Converts a distance to centimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in centimeters.
   * (no conversion)
   */
  public toCentimeters(value: number): number {
    return value
  }

  /**
   * Converts a distance to millimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters.
   * 1 cm = 10 mm
   */
  public toMillimeters(value: number): number {
    return value * 10
  }
}

/**
 * Provides methods to convert distances in millimeters to other LinearUnits.
 */
export class Millimeters extends DistanceConversion {
  /**
   * Converts a distance to miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in miles.
   */
  public toMiles(value: number): number {
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    return value * 6.2137119223733396962e-7
  }

  /**
   * Converts a distance to nautical miles.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in nautical miles.
   * 1 millimeter = 5.39956803455723E-7 nautical miles
   */
  public toNauticalMiles(value: number): number {
    return value * 5.39956803455723e-7
  }

  /**
   * Converts a distance to feet.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in feet.
   * 1 foot =  25.4 mm/inch * 12 in /ft
   */
  public toFeet(value: number): number {
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    return value * 0.0032808398950131233595800525
  }

  /**
   * Converts a distance to inches.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches.
   * 1 inch = 25.4 mm
   */
  public toInches(value: number): number {
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    return value * 0.03937007874015748031496062992126
  }

  /**
   * Converts a distance to kilometers.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilometers.
   * 1 meter = 1/1000 meter 1 millimeter = 1/1000 meters
   */
  public toKilometers(value: number): number {
    return value * 0.000001
  }

  /**
   * Converts a distance to meters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in meters.
   * 1 meter = 1000 mm
   */
  public toMeters(value: number): number {
    return value * 0.0001
  }

  /**
   * Converts a distance to centimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in centimeters.
   * 1 millimeter = 1/10 centimeters
   */
  public toCentimeters(value: number): number {
    return value * 0.1
  }

  /**
   * Converts a distance to millimeters.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters.
   * (no conversion)
   */
  public toMillimeters(value: number): number {
    return value
  }
}
