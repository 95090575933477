/* eslint-disable */
import { RequestChain } from '../../core/http/RequestChain'
import { AvailabilityLevel } from '../../core/support/AvailabilityLevel'
import { isNotNullOrBlank, isNullOrBlank } from '../../util/Helpers'
import { ProductAvailabilityInfoProvider } from './ProductAvailabilityInfoProvider'
import { ProductAvailabilityInfoRequest } from './ProductAvailabilityInfoRequest'

export class RadarAvailabilityProvider implements ProductAvailabilityInfoProvider {
  private readonly radarIncludedCountries = [
    // these were the only countries listed in the spreadsheet from ethan...
    'ad', // andorra
    'au', // australia
    'at', // austria
    'be', // belgium
    'bm', // bermuda
    'ba', // bosnia-herzgovina
    'bg', // bulgaria (partial)
    'ca', // canada
    'hr', // croatia
    'cz', // czech republic
    'dk', // denmark
    'ee', // estonia
    'fi', // finland
    'fr', // france
    'de', // germany
    'hu', // hungary
    'is', // iceland
    'ie', // ireland
    'jp', // japan
    'kr', // korea
    'xk', // kosovo
    'lv', // latvia (partial)
    'li', // liechtenstein
    'lt', // lithuania (partial)
    'lu', // luxemborg
    'md', // moldova
    'mc', // monaco
    'me', // montenegro
    'nl', // netherlands
    'no', // norway
    'pr', // puerto rico
    'pl', // poland
    'pt', // portugal
    'ro', // romania
    'gb', // scotland
    'rs', // serbia
    'sk', // slovakia
    'si', // slovenia
    'es', // spain
    'se', // sweden
    'ch', // switzerland
    'uk', // united kingdom
    'us', // us
    'vi', // us virgin islands

    // these were already in the code but WERE NOT in the spreadsheet from ethan
    'gi', // gibraltar
    'it', // italy
    'im', // isle of man
    'ai', // anguilla
    'mf', // saint martin (french)
    'bl', // saint barthelemy
    'bq', // bonaire, sint eustatius / saba
    'ag', // antigua / barbuda
    'kn', // saint kitts / nevis
    'ms', // montserrat
    'gp', // guadeloupe
    'dm', // dominica
    'mq', // martinique
    'lc', // saint lucia
    'vc', // saint vincent / grenadines
    'gd', // grenada
    'bb', // barbados
    'tt', // trinidad/tobago
    'ky', // cayman islands
    'gf', // french guiana
  ]

  private readonly radarExcludedAdminAreas = [
    // per ethan, alaska (us:ak) and hawaii (us:hi) now have radar
    'es:cn',
    'fr:cp',
    'fr:2a',
    'fr:2b',
    'uk:00-x02',
    'uk:00-x03',
    'gb:00-x02',
    'gb:00-x03',
  ]

  public getAvailability(
    request: ProductAvailabilityInfoRequest,
    requestChain?: RequestChain,
  ): AvailabilityLevel {
    if (
      isNotNullOrBlank(request?.countryCode) &&
      this.radarIncludedCountries.indexOf(request.countryCode.toLowerCase()) !== -1 &&
      (isNullOrBlank(request?.adminCode) ||
        this.radarExcludedAdminAreas.indexOf(
          `${request.countryCode.toLowerCase()}:${request.adminCode.toLowerCase()}`,
        ) === -1)
    ) {
      return AvailabilityLevel.Supported
    }
    return AvailabilityLevel.Unsupported
  }
}
