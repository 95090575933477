// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import '../../util/Extenstions'
import { isNotNullOrUndefined, isNullOrBlank } from '../../util/Helpers'

/**
 * Provides methods used to validate location keys.
 */
export class LocationKeyValidRule {
  /**
   * Checks whether a location key is valid.
   * @param locationKey The value to be validated.
   * @return If `locationKey` is invalid, an exception; otherwise, undefined.
   */
  public static checkRule(locationKey: string): Error {
    if (isNullOrBlank(locationKey)) {
      return Error('locationKey null, undefined, or blank')
    }

    for (const letter of locationKey) {
      if (!this.isValidCharacter(letter)) {
        return Error(`invalid locationKey character: ${letter}`)
      }
    }
    return undefined
  }

  /**
   * Checks whether a list of location keys is valid.
   * @param locationKeys The value to be validated.
   * @return If `locationKeys` is invalid, an exception; otherwise, undefined.
   */
  public static checkArrayRule(locationKeys: string[]): Error {
    if (locationKeys.length === 0) {
      return Error('locationKeys is empty')
    }

    let error: Error
    for (const key of locationKeys) {
      error = this.checkRule(key)
      if (isNotNullOrUndefined(error)) {
        return error
      }
    }
    return error
  }

  private static readonly validLocationKeyCharacters = ['-']
  private static isValidCharacter(letter: string): boolean {
    if (letter.isLetterDigitOrUnderscore()) {
      return true
    }
    for (const c of this.validLocationKeyCharacters) {
      if (letter === c) {
        return true
      }
    }
    return false
  }
}
