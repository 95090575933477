/* eslint-disable */
import { RequestChain } from '../../core/http/RequestChain'
import { AvailabilityLevel } from '../../core/support/AvailabilityLevel'
import { addHoursToDate, isNotNullOrBlank } from '../../util/Helpers'
import { ProductAvailabilityInfoProvider } from './ProductAvailabilityInfoProvider'
import { ProductAvailabilityInfoRequest } from './ProductAvailabilityInfoRequest'

export class HurricaneAvailabilityProvider implements ProductAvailabilityInfoProvider {
  private readonly hurricaneLanguages = ['en-us', 'en-gb']

  private readonly hurricaneStart: Date
  private readonly hurricaneEnd: Date

  constructor() {
    const now = new Date()
    this.hurricaneStart = new Date(now.getFullYear(), 5, 25)
    this.hurricaneEnd = new Date(now.getFullYear(), 10, 31)
  }

  public getAvailability(
    request: ProductAvailabilityInfoRequest,
    requestChain?: RequestChain,
  ): AvailabilityLevel {
    if (
      isNotNullOrBlank(request?.languageCode) &&
      this.hurricaneLanguages.indexOf(request.languageCode.toLowerCase()) !== -1 &&
      this.isHurricaneSeason(request.locationGmtOffset)
    ) {
      return AvailabilityLevel.Supported
    }
    return AvailabilityLevel.Unsupported
  }

  private isHurricaneSeason(locationGmtOffset: number): boolean {
    const date = addHoursToDate(locationGmtOffset)
    return date > this.hurricaneStart && date < this.hurricaneEnd
  }
}
