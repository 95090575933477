// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNullOrUndefined } from '../../util/Helpers'

/**
 * Provides a method used to validate an array.
 */
export class ArrayNotEmptyRule {
  /**
   * Checks whether an array is empty.
   * @param value The value to be validated.
   * @param name The parameter name. Defaults to "value".
   * @return If `value` is invalid, an exception; otherwise, undefined.
   */
  public static checkRule<T>(value: T[], name: string = this.parameterName): Error {
    if (isNullOrUndefined(value) || value.length === 0) {
      return Error(`${name} cannot be null, undefined or empty`)
    }
    return undefined
  }
  private static readonly parameterName = 'value'
}
