/**
 * Represents the Index Type IDs.
 */
export enum IndexType {
  /**
   * (not a 'real' database ID)
   */
  Unknown = 0,

  /**
   * Running
   */
  Running = 1,

  /**
   * Jogging
   */
  Jogging = 2,

  /**
   * Hiking
   */
  Hiking = 3,

  /**
   * Biking
   */
  Biking = 4,

  /**
   * Golf
   */
  Golf = 5,

  /**
   * Tennis
   */
  Tennis = 6,

  /**
   * Skateboarding
   */
  Skateboarding = 7,

  /**
   * Concert
   */
  Concert = 8,

  /**
   * Kite Flying Forecast
   */
  KiteFlyingForecast = 9,

  /**
   * Beach and Pool
   */
  BeachAndPool = 10,

  /**
   * Sailing
   */
  Sailing = 11,

  /**
   * Stargazing
   */
  Stargazing = 12,

  /**
   * Fishing Forecast
   */
  FishingForecast = 13,

  /**
   * Construction
   */
  Construction = 14,

  /**
   * Skiing
   */
  Skiing = 15,

  /**
   * Heart
   */
  Heart = 16,

  /**
   * Mosquito
   */
  Mosquito = 17,

  /**
   * Dust
   */
  Dust = 18,

  /**
   * Snow Days
   */
  SnowDays = 19,

  /**
   * Hunting
   */
  Hunting = 20,

  /**
   * Arthritis Day
   */
  ArthritisDay = 21,

  /**
   * Arthritis Night
   */
  ArthritisNight = 22,

  /**
   * Asthma
   */
  Asthma = 23,

  /**
   * BBQ
   */
  BBQ = 24,

  /**
   * Common Cold Forecast
   */
  CommonColdForecast = 25,

  /**
   * Flu
   */
  Flu = 26,

  /**
   * Migraine
   */
  Migraine = 27,

  /**
   * Lawn Mowing
   */
  LawnMowing = 28,

  /**
   * Outdoor
   */
  Outdoor = 29,

  /**
   * Sinus
   */
  Sinus = 30,

  /**
   * Air Travel
   */
  Flying = 31,

  /**
   * Field Readiness
   */
  FieldReadiness = 32,

  /**
   * Grass Growing
   */
  GrassGrowing = 33,

  /**
   * Soil Moisture
   */
  SoilMoisture = 34,

  /**
   * School Bus Stop
   */
  SchoolBusStop = 35,

  /**
   * Home Energy
   */
  HomeEnergy = 36,

  /**
   * Fuel Economy
   */
  FuelEconomy = 37,

  /**
   * Composting
   */
  Composting = 38,

  /**
   * Shopping Forecast
   */
  ShoppingForecast = 39,

  /**
   * Ground Travel
   */
  Driving = 40,

  /**
   * Thirst. Brought to you by Country Time.
   */
  Thirst = 41,

  /**
   * Hair Frizz
   */
  Frizz = 42,

  /**
   * Dog Walking
   */
  DogWalking = 43,

  /**
   * COPD
   */
  COPD = 44,

  /**
   * Indoor Pest Activity
   */
  IndoorPest = 45,

  /**
   * Outdoor Pest Activity
   */
  OutdoorPest = 46,

  /**
   * [Derived] UV index.
   * This is an international standard.
   * See <a href="https://en.wikipedia.org/wiki/UV_index">here</a> for more information.
   */
  UV = -15,

  /**
   * [Derived] Tree pollen count.
   */
  TreePollen = -14,

  /**
   * [Derived] Ragweed pollen count.
   */
  RagweedPollen = -13,

  /**
   * [Derived] Mold pollen count.
   */
  MoldPollen = -12,

  /**
   * [Derived] Grass pollen count.
   */
  GrassPollen = -11,

  /**
   * [Derived] Air Quality. EPA calculates this value.
   * See <a href="https://airnow.gov/index.cfm?action=aqibasics.aqi">here</a> for more information.
   */
  AirQuality = -10,

  /**
   * [Derived]
   */
  FlightDelay = -3,

  /**
   * [Derived] IndoorActivity
   */
  IndoorActivity = -2,

  /**
   * Morning Exercise (China/Huafeng).
   */
  MorningExercise = 100,

  /**
   * Clothing (China/Huafeng).
   */
  Clothing = 101,

  /**
   * Common Cold (China/Huafeng).
   */
  CommonCold = 102,

  /**
   * Comfort (China/Huafeng).
   */
  Comfort = 103,

  /**
   * Car Washing (China/Huafeng).
   */
  CarWashing = 104,

  /**
   * Heat Stroke (China/Huafeng).
   */
  HeatStroke = 105,

  /**
   * UV Intensity (China/Huafeng).
   */
  UVIntensity = 106,

  /**
   * Clothes Drying (China/Huafeng).
   */
  ClothesDrying = 107,

  /**
   * Air Pollution (China/Huafeng).
   */
  AirPollution = 108,

  /**
   * Tourism (China/Huafeng).
   */
  Tourism = 109,

  /**
   * Air Conditioning (China/Huafeng).
   */
  AirConditioning = 110,

  /**
   * Fishing (China/Huafeng).
   */
  Fishing = 111,

  /**
   * Sun Protection (China/Huafeng).
   */
  SunProtection = 112,

  /**
   * Shopping (China/Huafeng).
   */
  Shopping = 113,

  /**
   * Rowing (China/Huafeng).
   */
  Rowing = 114,

  /**
   * Traffic (China/Huafeng).
   */
  Traffic = 115,

  /**
   * Road Conditions (China/Huafeng).
   */
  RoadConditions = 116,

  /**
   * Umbrella (China/Huafeng).
   */
  Umbrella = 117,

  /**
   * Hair Dressing (China/Huafeng).
   */
  HairDressing = 118,

  /**
   * Night Life (China/Huafeng).
   */
  NightLife = 119,

  /**
   * Beer (China/Huafeng).
   */
  Beer = 120,

  /**
   * Kite Flying (China/Huafeng).
   */
  KiteFlying = 121,

  /**
   * Makeup (China/Huafeng).
   */
  Makeup = 122,

  /**
   * Wind Chills (China/Huafeng).
   */
  WindChills = 123,

  /**
   * Mood (China/Huafeng).
   */
  Mood = 124,

  /**
   * Exercise (China/Huafeng).
   */
  Exercise = 125,

  /**
   * Dating (China/Huafeng).
   */
  Dating = 126,

  /**
   * Allergy (China/Huafeng).
   */
  Allergy = 127,

  /**
   * Beach (China/Huafeng).
   */
  Beach = 128,

  /**
   * Sunglasses (China/Huafeng).
   */
  Sunglasses = 129,

  /**
   * Short Phrase (China/Huafeng).
   */
  ShortPhrase = 131,

  /**
   * Hypertension (China/Huafeng).
   */
  Hypertension = 132,

  /**
   * Dryness (China/Huafeng).
   */
  Dryness = 133,

  /**
   * Apparent Temperature (China/Huafeng).
   */
  ApparentTemperature = 134,

  /**
   *  (China/Huafeng).
   */
  PolutionPrevention = 135,

  /**
   * Food Poisoning (Korean).
   */
  FoodPoisoning = 136,

  /**
   * [Descending] Wind Chill (Korean).
   */
  WindChillDescending = 137,

  /**
   * Heat (Korean).
   */
  Heat = 138,

  /**
   * Discomfort (Korean).
   */
  Discomfort = 139,

  /**
   * [Descending] UV (Korean).
   */
  UVDescending = 140,

  /**
   * Pipe Freeze (Korean).
   */
  PipeFreeze = 141,

  /**
   * Atmospheric Dispersion (Korean).
   */
  AtmosphericDispersion = 142,

  /**
   * Heat Sensitivity - General (Korean).
   */
  HeatSensitivityGeneral = 143,

  /**
   * Heat Sensitivity - Elderly (Korean).
   */
  HeatSensitivityElderly = 144,

  /**
   * Heat Sensitivity - Children (Korean).
   */
  HeatSensitivityChildren = 145,

  /**
   * Heat Sensitivity - Outdoor Working (Korean).
   */
  HeatSensitivityOutdoorWorking = 146,

  /**
   * Heat Sensitivity - Farming (Korean).
   */
  HeatSensitivityFarming = 147,

  /**
   * Heat Sensitivity - Greenhouse (Korean).
   */
  HeatSensitivityGreenhouse = 148,

  /**
   * Heat Sensitivity Vulnerable - Residential (Korean).
   */
  HeatSensitivityVulnerableResidential = 149,

  /**
   * Asthma and Lung Disorder (Korean).
   */
  AsthmaAndLungDisorder = 150,

  /**
   * Stroke (Korean).
   */
  Stroke = 151,

  /**
   * [Descending] Common Cold (Korean).
   */
  CommonColdDescending = 153,

  /**
   * Pine Pollen (Korean).
   */
  PinePollen = 155,

  /**
   * Skin Sensitivity (Korean).
   */
  SkinSensitivity = 152,

  /**
   * Tree Pollen Levels (Korean).
   */
  TreePollenLevels = 154,

  /**
   * Weed and Pollen Levels (Korean).
   */
  WeedPollenLevels = 156,

  /**
   * Combines [ArthritisDay] and [ArthritisNight].
   * Not a real database ID.
   */
  Arthritis = 500,

  /**
   * The [Outdoor] index repurposed for the Home and Garden section.
   * Not a real database ID.
   */
  OutdoorHomeGarden = 501,

  /**
   * The [SoilMoisture] index repurporsed for the Home and Garden section.
   * Not a real database ID.
   */
  SoilHomeGarden = 502,

  /**
   * The [FieldReadiness] index repurporsed for the Home and Garden section.
   * Not a real database ID.
   */
  FieldHomeGarden = 503,
}
