// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import { isNullOrUndefined } from '../../../util/Helpers'

/**
 * class
 * Converts the CLDR patterns to Intl.DateTimeFormatOptions
 */

export class IntlOptions {
  /**
   * [[Weekday]]	"weekday"	"narrow", "short", "long"
   * [[Weekday]]	"weekday"	"EEE",    "E",     "EEEE"
   */
  public weekday: string = null

  /**
   * [[Era]]	"era"	"narrow", "short", "long"
   */
  public era: string

  /**
   * [[Year]]	"year"	"2-digit",  "numeric", "numeric"
   * [[Year]]	"year"	"yy",       "y",       "yyyy"
   */
  public year: string = null

  /**
   * [[Month]]	"month"	"2-digit", "numeric", "narrow", "short", "long", "long"
   * [[Month]]	"month"	"MM",       "M",      "",       "MMM",   "MMMM" "LLL:"
   */
  public month: string = null

  /**
   * [[Day]]	"day"	"2-digit", "numeric"
   * [[Day]]	"day"	"dd", "d"
   */
  public day: string = null

  /**
   * [[DayPeriod]]	"dayPeriod"	"narrow", "short", "long"
   * [[DayPeriod]]	"dayPeriod"	"narrow", "short", "long"
   */
  public dayPeriod: string = null

  /**
   * [[Hour]]	"hour"	"2-digit", "numeric"
   * [[Hour]]	"hour"	"hh", "h"
   */
  public hour: string = null

  /**
   * [[Minute]]	"minute"	"2-digit", "numeric"
   * [[Minute]]	"minute"	"mm", "m"
   */
  public minute: string = null

  /**
   * [[Second]]	"second"	"2-digit", "numeric"
   * [[Second]]	"second"	"ss", "s"
   */
  public second: string = null

  /**
   * [[FractionalSecondDigits]]	"fractionalSecondDigits"	1, 2, 3
   * public readonly fractionalSecondDigits: String = null;
   */

  /**
   * [[TimeZoneName]]	"timeZoneName"	"short", "long"
   * [[TimeZoneName]]	"timeZoneName"	"z", "zzzz"
   */
  public timeZoneName: string = null

  public options: any

  constructor(pattern: string) {
    if (isNullOrUndefined(pattern)) {
      return
    }
    this.options = {}
    this.setWeekDay(pattern)
    this.setEra(pattern)
    this.setYear(pattern)
    this.setSecond(pattern)
    this.setHour(pattern)
    this.setMinute(pattern)
    this.setDay(pattern)
    this.setTimezone(pattern)
    this.setMonth(pattern)
  }

  private setWeekDay(pattern: string): void {
    const searchStrings = new Map<string, string>([
      ['EEEE', 'long'],
      ['EEE', 'narrow'],
      ['E', 'short'],
    ])
    this.weekday = this.getValue(searchStrings, pattern)
    this.setOption('weekday', this.weekday)
  }
  
  private setEra(pattern: string): void {
    this.era = null
  }
  private setYear(pattern: string): void {
    const searchStrings = new Map<string, string>([
      ['yyyy', 'numeric'],
      ['yy', '2-digit'],
      ['y', 'numeric'],
    ])
    this.year = this.getValue(searchStrings, pattern)
    this.setOption('year', this.year)
  }
  private setSecond(pattern: string): void {
    const searchStrings = new Map<string, string>([
      ['ss', '2-digit'],
      ['s', 'numeric'],
    ])
    this.second = this.getValue(searchStrings, pattern)
    this.setOption('second', this.second)
  }
  private setHour(pattern: string): void {
    const searchStrings = new Map<string, string>([
      ['hh', '2-digit'],
      ['h', 'numeric'],
    ])
    this.hour = this.getValue(searchStrings, pattern)
    this.setOption('hour', this.hour)
  }
  private setMinute(pattern: string): void {
    const searchStrings = new Map<string, string>([
      ['mm', '2-digit'],
      ['m', 'numeric'],
    ])
    this.minute = this.getValue(searchStrings, pattern)
    this.setOption('minute', this.minute)
  }
  private setDay(pattern: string): void {
    const searchStrings = new Map<string, string>([
      ['dd', '2-digit'],
      ['d', 'numeric'],
    ])
    this.day = this.getValue(searchStrings, pattern)
    this.setOption('day', this.day)
  }
  private setTimezone(pattern: string): void {
    const searchStrings = new Map<string, string>([
      ['zzzz', 'long'],
      ['z', 'short'],
    ])
    this.timeZoneName = this.getValue(searchStrings, pattern)
    this.setOption('timeZoneName', this.timeZoneName)
  }

  private setMonth(pattern: string): void {
    const searchStrings = new Map<string, string>([
      ['MMMM', 'long'],
      ['MMM', 'short'],
      ['MM', '2-digit'],
      ['M', 'numeric'],
      ['LLLL', 'long'],
    ])
    // -Missing narrow
    this.month = this.getValue(searchStrings, pattern)
    this.setOption('month', this.month)
  }

  private getValue(searchStrings: Map<string, string>, pattern: string): string {
    // Iterate over map keys
    for (const key of searchStrings.keys()) {
      if (key !== undefined) {
        if (pattern.search(key) > -1) {
          return searchStrings.get(key)
        }
      }
    }

    return null
  }

  private setOption(key: any, value: string): void {
    if (isNullOrUndefined(value)) {
      return
    }
    this.options[key] = value
  }
}
