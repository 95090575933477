import { ProductType } from '../../core/support/ProductType'
import { ProductCollectionByLocationKeyRequest } from './ProductCollectionByLocationKeyRequest'

/**
 * Encapsulates the parameters used to make requests to ProductAvailabilityService.
 */
export class ProductAvailabilityByLocationKeyRequest extends ProductCollectionByLocationKeyRequest {
  /** The product. */
  public readonly product: ProductType

  constructor(product: ProductType, language: string, locationKey: string) {
    super(language, locationKey)
    this.product = product
  }
}
