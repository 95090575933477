import { BaseApiSettings } from './BaseApiSettings'

/**
 * Settings for how to connect to the CMS.
 */
export class CmsSettings extends BaseApiSettings {
  /**
   * Initializes a new `CmsSettings`.
   * @param cmsHost the CMS host base address.
   */
  constructor(cmsHost = 'https://cms.accuweather.com/') {
    super(cmsHost)
  }
}
