/**
 * API token constants.
 */
export class ApiTokens {
  /**
   * Used to replace {adminCode} in api url templates.
   */
  public static readonly adminCode = 'adminCode'

  /**
   * Used to replace {apikey} in api url templates.
   */
  public static readonly apiKey = 'apikey'

  /**
   * Used to replace {count} in api url templates.
   */
  public static readonly count = 'count'

  /**
   * Used to replace {countryCode} in api url templates.
   */
  public static readonly countryCode = 'countryCode'

  /**
   * Used to replace {day} in api url templates.
   */
  public static readonly day = 'day'

  /**
   * Used to replace {dayCount} in api url templates.
   */
  public static readonly dayCount = 'dayCount'

  /**
   * Used to replace {details} in api url templates.
   */
  public static readonly details = 'details'

  /**
   * Used to replace {endDate} in api url templates.
   */
  public static readonly endDate = 'endDate'

  /**
   * Used to replace {groupId} in api url templates.
   */
  public static readonly groupId = 'groupId'

  /**
   * Used to replace {hourCount} in api url templates.
   */
  public static readonly hourCount = 'hourCount'

  /**
   * Used to replace {indexId} in api url templates.
   */
  public static readonly indexId = 'indexId'

  /**
   * Used to replace {language} in api url templates.
   */
  public static readonly language = 'language'

  /**
   * Used to replace {latitude} in api url templates.
   */
  public static readonly latitude = 'latitude'

  /**
   * Used to replace {listLength} in api url templates.
   */
  public static readonly listLength = 'listLength'

  /**
   * Used to replace {limit} in api url templates.
   */
  public static readonly limit = 'limit'

  /**
   * Used to replace {locationKey} in api url templates.
   */
  public static readonly locationKey = 'locationKey'

  /**
   * Used to replace {longitude} in api url templates.
   */
  public static readonly longitude = 'longitude'

  /**
   * Used to replace {metric} in api url templates.
   */
  public static readonly metric = 'metric'

  /**
   * Used to replace {minuteInterval} in api url templates.
   */
  public static readonly minuteInterval = 'minuteInterval'

  /**
   * Used to replace {month} in api url templates.
   */
  public static readonly month = 'month'

  /**
   * Used to replace {offset} in api url templates.
   */
  public static readonly offset = 'offset'

  /**
   * Used to replace {q} in api url templates.
   */
  public static readonly q = 'q'

  /**
   * Used to replace {regionCode} in api url templates.
   */
  public static readonly regionCode = 'regionCode'

  /**
   * Used to replace {startDate} in api url templates.
   */
  public static readonly startDate = 'startDate'

  /**
   * Used to replace {type} in api url templates.
   */
  public static readonly type = 'type'

  /**
   * Used to replace {year} in api url templates.
   */
  public static readonly year = 'year'
}
