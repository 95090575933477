// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { ApiTokens } from '../core/api/ApiTokens'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { ProductType } from '../core/support/ProductType'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { GeopositionValidRule } from '../core/validation/GeoPositionValidRule'
import { LanguageValidRule } from '../core/validation/LanguageValidRule'
import { LocationKeyValidRule } from '../core/validation/LocationKeyValidRule'
import { ObjectNotNullOrUndefinedRule } from '../core/validation/ObjectNotNullOrUndefinedRule'
import { resolveLocationCacheInfoUrl } from '../locations/cache/RouteResolverExtensions'
import { LocationService } from '../locations/LocationService'
import { ProductAvailabilityService } from '../productavailability/ProductAvailabilityService'
import { ClimatologyDayByLocationKeyRequest } from './requests/ClimatologyDayByLocationKeyRequest'
import { ClimatologyMonthByLocationKeyRequest } from './requests/ClimatologyMonthByLocationKeyRequest'
import { CurrentConditionsByLocationKeyRequest } from './requests/CurrentConditionsByLocationKeyRequest'
import { DailyForecastsByLocationKeyRequest } from './requests/DailyForecastsByLocationKeyRequest'
import { HourlyForecastsByLocationKeyRequest } from './requests/HourlyForecastsByLocationKeyRequest'
import { MinuteForecastByGeopositionRequest } from './requests/MinuteForecastByGeopositionRequest'
import { MinuteForecastByLocationKeyRequest } from './requests/MinuteForecastByLocationKeyRequest'
import { MinuteForecastPremiumByGeopositionRequest } from './requests/MinuteForecastPremiumByGeopositionRequest'
import { MinuteForecastPremiumByLocationKeyRequest } from './requests/MinuteForecastPremiumByLocationKeyRequest'
import { QuarterDayForecastsByLocationKeyRequest } from './requests/QuarterDayForecastsByLocationKeyRequest'
import { WeatherRouteResolver } from './WeatherRouteResolver'

/**
 * Provides methods for building current conditions, forecast, and climatology api urls.
 */
export class WeatherRouteResolverImpl implements WeatherRouteResolver {
  // endregion climo

  private static checkValidDailyDayCountRule(request: DailyForecastsByLocationKeyRequest): Error {
    // request should already be checked for null before getting here
    if (
      request.dayCount === 90 ||
      request.dayCount === 45 ||
      request.dayCount === 25 ||
      request.dayCount === 15 ||
      request.dayCount === 10 ||
      request.dayCount === 5 ||
      request.dayCount === 1
    ) {
      return undefined
    }
    return Error('invalid day count. valid values are 1, 5, 10, 15, 25, 45, and 90.')
  }
  private static checkValidHourCountRule(request: HourlyForecastsByLocationKeyRequest): Error {
    // request should already be checked for null before getting here
    if (
      request.hourCount === 240 ||
      request.hourCount === 120 ||
      request.hourCount === 72 ||
      request.hourCount === 24 ||
      request.hourCount === 12 ||
      request.hourCount === 1
    ) {
      return undefined
    }
    return Error('invalid hour count. valid values are 1, 12, 24, 72, 120, and 240.')
  }
  private static checkValidQuarterDayCountRule(
    request: QuarterDayForecastsByLocationKeyRequest,
  ): Error {
    // request should already be checked for null before getting here
    if (
      request.dayCount === 15 ||
      request.dayCount === 10 ||
      request.dayCount === 5 ||
      request.dayCount === 1
    ) {
      return undefined
    }
    return Error('invalid day count. valid values are 1, 5, 10, and 15.')
  }
  /**
   * Used to replace {climoType} in climatology route templates.
   */
  protected climoTypeToken = 'climoType'

  protected readonly sdkSettings: SdkSettings
  protected readonly locationService: LocationService
  protected readonly productAvailabilityService: ProductAvailabilityService
  protected readonly routeResolver: ApiRouteResolver

  // region routes
  protected readonly currentConditionsByLocationKey = 'CurrentConditionsByLocationKey'
  protected readonly currentConditionsHistorical6ByLocationKey =
    'CurrentConditionsHistorical6ByLocationKey'
  protected readonly currentConditionsHistorical24ByLocationKey =
    'CurrentConditionsHistorical24ByLocationKey'

  protected readonly forecastDailyByLocationKey = 'ForecastDailyByLocationKey'
  protected readonly forecastHourlyByLocationKey = 'ForecastHourlyByLocationKey'
  protected readonly forecastQuarterDayByLocationKey = 'ForecastQuarterDayByLocationKey'
  protected readonly forecastMinuteByGeoposition = 'ForecastMinuteByGeoposition'
  protected readonly forecastMinutePremiumByGeoposition = 'ForecastMinutePremiumByGeoposition'
  protected readonly forecastMinuteColors = 'ForecastMinuteColors'
  protected readonly forecastMinuteColorRanges = 'ForecastMinuteColorRanges'

  protected readonly climatologyDay = 'ClimatologyDay'
  protected readonly climatologyMonth = 'ClimatologyMonth'

  protected readonly mixedThreeDay = 'MixedThreeDay'

  /**
   * Gets the map of route templates.
   */
  protected readonly routeTemplates: Map<string, string> = new Map([
    [
      this.currentConditionsByLocationKey,
      'currentconditions/v1/{locationKey}.json?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.currentConditionsHistorical6ByLocationKey,
      'currentconditions/v1/{locationKey}/historical.json?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.currentConditionsHistorical24ByLocationKey,
      'currentconditions/v1/{locationKey}/historical/24.json?apikey={apikey}&language={language}&details={details}',
    ],

    [
      this.forecastDailyByLocationKey,
      'forecasts/v1/daily/{dayCount}day/{locationKey}.json?apikey={apikey}&language={language}&details={details}&metric={metric}',
    ],
    [
      this.forecastHourlyByLocationKey,
      'forecasts/v1/hourly/{hourCount}hour/{locationKey}.json?apikey={apikey}&language={language}&details={details}&metric={metric}',
    ],
    [
      this.forecastQuarterDayByLocationKey,
      'forecasts/v1/daily/{dayCount}day/quarters/{locationKey}.json?apikey={apikey}&language={language}&metric={metric}',
    ],
    [
      this.forecastMinuteByGeoposition,
      'forecasts/v1/minute.json?apikey={apikey}&q={latitude},{longitude}&language={language}',
    ],
    [
      this.forecastMinutePremiumByGeoposition,
      'forecasts/v1/minute/{minuteInterval}minute.json?apikey={apikey}&q={latitude},{longitude}&language={language}&details={details}',
    ],
    [this.forecastMinuteColors, 'forecasts/v1/minute/colors.json?apikey={apikey}'],
    [this.forecastMinuteColorRanges, 'forecasts/v1/minute/colors/simple.json?apikey={apikey}'],
    [
      this.climatologyDay,
      'climo/v1/{climoType}/{year}/{month}/{day}/{locationKey}.json?apikey={apikey}&language={language}',
    ],
    [
      this.climatologyMonth,
      'climo/v1/{climoType}/{year}/{month}/{locationKey}.json?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.mixedThreeDay,
      '/applications/v1/threeday/{locationKey}?apikey={apikey}&language={language}&metric={metric}&daycount={dayCount}',
    ],
  ])
  // endregion routes

  // region validators
  // region current conditions
  /**
   * Gets the request validator.
   */
  protected readonly currentConditionsValidator: AsyncValidator<CurrentConditionsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])
  // endregion current conditions

  // region forecasts
  /**
   * Gets the request validator.
   */
  protected readonly dailyByLocationKeyValidator: AsyncValidator<DailyForecastsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => WeatherRouteResolverImpl.checkValidDailyDayCountRule(r),
    ])
  /**
   * Gets the request validator.
   */
  protected readonly hourlyByLocationKeyValidator: AsyncValidator<HourlyForecastsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => WeatherRouteResolverImpl.checkValidHourCountRule(r),
    ])
  /**
   * Gets the request validator.
   */
  protected readonly quarterDayByLocationKeyValidator: AsyncValidator<QuarterDayForecastsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => WeatherRouteResolverImpl.checkValidQuarterDayCountRule(r),
    ])
  /**
   * Gets the request validator.
   */
  protected readonly minuteForecastByGeopositionValidator: AsyncValidator<MinuteForecastByGeopositionRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => GeopositionValidRule.checkRule(r.latitude, r.longitude),
    ])
  /**
   * Gets the request validator.
   */
  protected readonly minuteForecastByLocationKeyValidator: AsyncValidator<MinuteForecastByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])
  /**
   * Gets the request validator.
   */
  protected minuteForecastPremiumByGeopositionValidator: AsyncValidator<MinuteForecastPremiumByGeopositionRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => GeopositionValidRule.checkRule(r.latitude, r.longitude),
    ])
  /**
   * Gets the request validator.
   */
  protected readonly minuteForecastPremiumByLocationKeyValidator: AsyncValidator<MinuteForecastPremiumByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])
  /**
   * Gets the request validator.
   */
  protected readonly minuteColorsRequestValidator: AsyncValidator<any> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
  ])
  /**
   * Gets the request validator.
   */
  // endregion forecasts

  // region climo
  /**
   * Gets the request validator.
   */
  protected readonly climoDayValidator: AsyncValidator<ClimatologyDayByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])
  /**
   * Gets the request validator.
   */
  protected readonly climoMonthValidator: AsyncValidator<ClimatologyMonthByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])
  // endregion validators

  constructor(
    sdkSettings: SdkSettings,
    productAvailabilityService: ProductAvailabilityService,
    locationService: LocationService,
  ) {
    this.sdkSettings = sdkSettings
    this.productAvailabilityService = productAvailabilityService
    this.locationService = locationService
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, this.sdkSettings)
    this.addValidatorRules()
  }

  // region current conditions
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getCurrentConditionsUrl(
    request: CurrentConditionsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.currentConditionsByLocationKey,
      request,
      this.currentConditionsValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getHistoricalConditionsQuarterUrl(
    request: CurrentConditionsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.currentConditionsHistorical6ByLocationKey,
      request,
      this.currentConditionsValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getHistoricalConditionsDayUrl(
    request: CurrentConditionsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.currentConditionsHistorical24ByLocationKey,
      request,
      this.currentConditionsValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }
  // endregion current conditions

  // region forecast
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getDailyForecastsByLocationKeyUrl(
    request: DailyForecastsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.forecastDailyByLocationKey,
      request,
      this.dailyByLocationKeyValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
        [ApiTokens.metric, request?.isMetric],
        [ApiTokens.dayCount, request?.dayCount],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getHourlyForecastsByLocationKeyUrl(
    request: HourlyForecastsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.forecastHourlyByLocationKey,
      request,
      this.hourlyByLocationKeyValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
        [ApiTokens.metric, request?.isMetric],
        [ApiTokens.hourCount, request?.hourCount],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getQuarterDayForecastsByLocationKeyUrl(
    request: QuarterDayForecastsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.forecastQuarterDayByLocationKey,
      request,
      this.quarterDayByLocationKeyValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.metric, request?.isMetric],
        [ApiTokens.dayCount, request?.dayCount],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getMinuteForecastByGeopositionUrl(
    request: MinuteForecastByGeopositionRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.forecastMinuteByGeoposition,
      request,
      this.minuteForecastByGeopositionValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.latitude, request?.latitude],
        [ApiTokens.longitude, request?.longitude],
        [ApiTokens.language, request?.language],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getMinuteForecastByLocationKeyUrl(
    request: MinuteForecastByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return resolveLocationCacheInfoUrl(
      this.routeResolver,
      this.locationService,
      this.forecastMinuteByGeoposition,
      request,
      this.minuteForecastByLocationKeyValidator,
      requestChain,
      (info) =>
        new Map<string, any>([
          [ApiTokens.latitude, info?.latitude],
          [ApiTokens.longitude, info?.longitude],
          [ApiTokens.language, request?.language],
        ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getMinuteForecastPremiumByGeopositionUrl(
    request: MinuteForecastPremiumByGeopositionRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.forecastMinutePremiumByGeoposition,
      request,
      this.minuteForecastPremiumByGeopositionValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.minuteInterval, request?.intervalType],
        [ApiTokens.latitude, request?.latitude],
        [ApiTokens.longitude, request?.longitude],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getMinuteForecastPremiumByLocationKeyUrl(
    request: MinuteForecastPremiumByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return resolveLocationCacheInfoUrl(
      this.routeResolver,
      this.locationService,
      this.forecastMinutePremiumByGeoposition,
      request,
      this.minuteForecastPremiumByLocationKeyValidator,
      requestChain,
      (info) =>
        new Map<string, any>([
          [ApiTokens.minuteInterval, request?.intervalType],
          [ApiTokens.latitude, info?.latitude],
          [ApiTokens.longitude, info?.longitude],
          [ApiTokens.language, request?.language],
          [ApiTokens.details, request?.details],
        ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getMinuteColorsUrl(
    request: object,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    // request is just an object here, it's just used as a placeholder
    return await this.routeResolver.resolveAsync(
      this.forecastMinuteColors,
      request,
      this.minuteColorsRequestValidator,
      requestChain,
      new Map<string, any>([]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getMinuteColorRangesUrl(
    request: object,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    // request is just an object here, it's just used as a placeholder
    return await this.routeResolver.resolveAsync(
      this.forecastMinuteColorRanges,
      request,
      this.minuteColorsRequestValidator,
      requestChain,
      new Map<string, any>([]),
    )
  }
  // endregion forecast

  // region climo
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getClimatologyDayByLocationKeyUrl(
    request: ClimatologyDayByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.climatologyDay,
      request,
      this.climoDayValidator,
      requestChain,
      new Map<string, any>([
        [this.climoTypeToken, request.climoType],
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.year, request?.year],
        [ApiTokens.month, request?.month],
        [ApiTokens.day, request?.day],
        [ApiTokens.language, request?.language],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getClimatologyMonthByLocationKeyUrl(
    request: ClimatologyMonthByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.climatologyMonth,
      request,
      this.climoMonthValidator,
      requestChain,
      new Map<string, any>([
        [this.climoTypeToken, request.climoType],
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.year, request?.year],
        [ApiTokens.month, request?.month],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request.details],
      ]),
    )
  }
  // endregion climo

  private addValidatorRules() {
    // minutecast endpoints use lat/lon, not location key
    // in order to validate for product availability, we will need to add lat/lon to locationCacheInfo
    this.minuteForecastByLocationKeyValidator.addAsyncRule((r, rc) =>
      this.productAvailabilityService.checkLocation(
        ProductType.MinuteCast,
        r.language,
        r.locationKey,
        rc,
      ),
    )
    this.minuteForecastPremiumByLocationKeyValidator.addAsyncRule((r, rc) =>
      this.productAvailabilityService.checkLocation(
        ProductType.MinuteCast,
        r.language,
        r.locationKey,
        rc,
      ),
    )
  }
}
