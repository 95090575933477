/**
 * The different modes when searching with autocomplete.
 */
export enum AutocompleteMode {
  /**
   * Cities only.
   */
  Cities = 'Cities',

  /**
   * Points of Interest only.
   */
  PointsOfInterest = 'PointsOfInterest',

  /**
   * Cities AND Points of Interest.
   */
  Mixed = 'Mixed',
}
