// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { MetaTemplate } from './MetaTemplate'
import { TemplateParser } from './TemplateParser'
import { TemplateParserImpl } from './TemplateParserImpl'
import { TemplateReplacer } from './TemplateReplacer'
import { TemplateReplacerImpl } from './TemplateReplacerImpl'
import { TemplateValues } from './TemplateValues'
import { TokenReplaceSettings } from './TokenReplaceSettings'

/**
 * Provides implementations of [TemplateParser] and [TemplateReplacer].
 */
export class Tokenizer implements TemplateParser, TemplateReplacer {
  private parser: TemplateParser = new TemplateParserImpl()
  private replacer: TemplateReplacer = new TemplateReplacerImpl()

  // region TemplateParser
  /**
   * Registers a tokenized template by key.
   * @param [key] The key used to retrieve the template.
   * @param [template] The template to add.
   * @return If the template is added without error, true; otherwise, false.
   */
  public addTemplate(key: string, template: string): boolean {
    return this.parser.addTemplate(key, template)
  }

  /**
   * Gets a template by key.
   * @param key The key.
   * @return If no template found with the key, null; otherwise, the found template.
   */
  public getTemplate(key: string): MetaTemplate {
    return this.parser.getTemplate(key)
  }

  /**
   * Creates a new set of values for the tokens contained in the template stored with the given key.
   * @param key The key.
   * @return If no template is found with the key, null; otherwise, an empty set of values to be populated for the found template.
   */
  public createValues(key: string): TemplateValues {
    return this.parser.createValues(key)
  }

  /**
   * Parses a template string into meta information.
   * @param template The template to parse.
   */
  public parse(template: string): MetaTemplate {
    return (this.parser as TemplateParserImpl).parse(template)
  }
  // endregion TemplateParser

  // region TokenReplacer
  /**
   * Builds a string using the given [TemplateValues].
   * @param templateValues The template values.
   * @param args The args.
   * @param replaceSettings The settings for how to handle token replacement.
   * @return
   */
  public replace(
    templateValues: TemplateValues,
    args: Map<string, any>,
    replaceSettings: TokenReplaceSettings,
  ): string {
    return this.replacer.replace(templateValues, args, replaceSettings)
  }

  /**
   * Sets [TemplateValues] values using the given args.
   * @param templateValues The template values.
   * @param args The args.
   * @param replaceSettings The settings for how to handle token replacement.
   * @return A [TemplateValues] with the values set.
   */
  public setValues(
    templateValues: TemplateValues,
    args: Map<string, any>,
    replaceSettings: TokenReplaceSettings,
  ): TemplateValues {
    return this.replacer.setValues(templateValues, args, replaceSettings)
  }

  /**
   * Sets the [TemplateValues] value for the given key.
   * @param templateValues The template values.
   * @param key The key.
   * @param value The value.
   * @param replaceSettings The settings for how to handle token replacement.
   * @return A [TemplateValues] with the value set.
   */
  public setValue(
    templateValues: TemplateValues,
    key: string,
    value: any,
    replaceSettings: TokenReplaceSettings,
  ): TemplateValues {
    return this.replacer.setValue(templateValues, key, value, replaceSettings)
  }
  // endregion TokenReplacer
}
