/**
 * Encapsulates the point of interest types.
 */
export enum PointOfInterestType {
  /**
   * Amusement Park
   */
  AmusementPark = 'AmusementPark',

  /**
   * Aquarium
   */
  Aquarium = 'Aquarium',

  /**
   * Art
   */
  Art = 'Art',

  /**
   * Balloon
   */
  Balloon = 'Balloon',

  /**
   * Stadium
   */
  Stadium = 'Stadium',

  /**
   * Botanical
   */
  Botanical = 'Botanical',

  /**
   * Brewery
   */
  Brewery = 'Brewery',

  /**
   * Winery
   */
  Winery = 'Winery',

  /**
   * Vineyard
   */
  Vineyard = 'Vineyard',

  /**
   * Distillery
   */
  Distillery = 'Distillery',

  /**
   * Building
   */
  Building = 'Building',

  /**
   * Casino
   */
  Casino = 'Casino',

  /**
   * Driving Range
   */
  DrivingRange = 'DrivingRange',

  /**
   * Embassy
   */
  Embassy = 'Embassy',

  /**
   * Observatory
   */
  Observatory = 'Observatory',

  /**
   * Performing Arts
   */
  PerformingArts = 'PerformingArts',

  /**
   * Planetarium
   */
  Planetarium = 'Planetarium',

  /**
   * Resort
   */
  Resort = 'Resort',

  /**
   * Shopping
   */
  Shopping = 'Shopping',

  /**
   * Zoo
   */
  Zoo = 'Zoo',

  /**
   * Tennis
   */
  Tennis = 'Tennis',

  /**
   * Sports
   */
  Sports = 'Sports',

  /**
   * Racing
   */
  Racing = 'Racing',

  /**
   * Polo
   */
  Polo = 'Polo',

  /**
   * Ski
   */
  Ski = 'Ski',

  /**
   * Camp
   */
  Camp = 'Camp',

  /**
   * County Park
   */
  CountyPark = 'CountyPark',

  /**
   * National Park
   */
  NationalPark = 'NationalPark',

  /**
   * Nature Preserve
   */
  NaturePreserve = 'NaturePreserve',

  /**
   * State Park
   */
  StatePark = 'StatePark',

  /**
   * Waterfall
   */
  Waterfall = 'Waterfall',

  /**
   * Bridge
   */
  Bridge = 'Bridge',

  /**
   * Historic
   */
  Historic = 'Historic',

  /**
   * Monument or Statue
   */
  MonumentOrStatue = 'MonumentOrStatue',

  /**
   * Museum
   */
  Museum = 'Museum',

  /**
   * State Capitol
   */
  StateCapitol = 'StateCapitol',

  /**
   * Random
   */
  Random = 'Random',

  /**
   * Airports
   */
  Airports = 'Airports',

  /**
   * Olympics
   */
  Olympics = 'Olympics',
}
