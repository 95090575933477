// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AlertRouteResolverImpl } from '../alerts/AlertRouteResolverImpl'
import { AlertService } from '../alerts/AlertService'
import { AlertServiceImpl } from '../alerts/AlertServiceImpl'
import { ContentHttpService } from '../content/ContentHttpService'
import { ContextualRouteResolverImpl } from '../contextual/ContextualRouteResolverImpl'
import { ContextualService } from '../contextual/ContextualService'
import { ContextualServiceImpl } from '../contextual/ContextualServiceImpl'
import { MapRouteResolverImpl } from '../maps/MapRouteResolverImpl'
import { MapService } from '../maps/MapService'
import { MapServiceImpl } from '../maps/MapServiceImpl'
import { ProductAvailabilityService } from '../productavailability/ProductAvailabilityService'
import { ProductAvailabilityServiceImpl } from '../productavailability/ProductAvailabilityServiceImpl'
import { isNullOrUndefined } from '../util/Helpers'
import { TropicalService } from '../tropical/TropicalService'
import { TropicalServiceImpl } from '../tropical/TropicalServiceImpl'
import { TropicalRouteResolverImpl } from '../tropical/TropicalRouteResolverImpl'
import { WeatherRouteResolverImpl } from '../weather/WeatherRouteResolverImpl'
import { WeatherService } from '../weather/WeatherService'
import { WeatherServiceImpl } from '../weather/WeatherServiceImpl'
import { ApiHttpService } from './api/ApiHttpService'
import { BaseServiceModule as BaseServiceModule } from './BaseServiceModule'
import { PersistentCache } from './cache/PersistentCache'
import { ServiceProvider } from './ServiceProvider'
import { SdkSettings } from './settings/SdkSettings'

export class ServiceModule extends BaseServiceModule implements ServiceProvider {
  public get alertService(): AlertService {
    return this.alertServiceImpl
  }
  public get contextualService(): ContextualService {
    return this.contextualServiceImpl
  }
  public get mapService(): MapService {
    return this.mapServiceImpl
  }
  public get productAvailabilityService(): ProductAvailabilityService {
    return this.productAvailabilityServiceImpl
  }
  public get tropicalService(): TropicalService {
    return this.tropicalServiceImpl
  }
  public get weatherService(): WeatherService {
    return this.weatherServiceImpl
  }
  public static create(sdkSettings: SdkSettings): ServiceProvider {
    if (isNullOrUndefined(ServiceModule.instance)) {
      ServiceModule.instance = new ServiceModule(sdkSettings)
    }
    return ServiceModule.instance
  }

  private static instance: ServiceProvider
  protected readonly settings: SdkSettings
  protected readonly cache: PersistentCache
  protected readonly apiHttpService: ApiHttpService
  protected readonly cmsHttpService: ContentHttpService

  private readonly alertServiceImpl: AlertService
  private readonly contextualServiceImpl: ContextualService
  private readonly mapServiceImpl: MapService
  private readonly productAvailabilityServiceImpl: ProductAvailabilityService
  private readonly tropicalServiceImpl: TropicalService
  private readonly weatherServiceImpl: WeatherService

  protected constructor(sdkSettings: SdkSettings) {
    super(sdkSettings)
    this.productAvailabilityServiceImpl = new ProductAvailabilityServiceImpl(
      this.settings,
      this.locationService,
    )
    this.alertServiceImpl = this.initAlertService()
    this.contextualServiceImpl = this.initContextualService()
    this.mapServiceImpl = this.initMapService()
    this.tropicalServiceImpl = this.initTropicalService()
    this.weatherServiceImpl = this.initWeatherService()
  }

  protected initAlertService(): AlertService {
    const alertRouteResolver = new AlertRouteResolverImpl(
      this.settings,
      this.productAvailabilityService,
    )
    return new AlertServiceImpl(this.apiHttpService, alertRouteResolver)
  }
  protected initContextualService(): ContextualService {
    const contextualRouteResolver = new ContextualRouteResolverImpl(
      this.settings,
      this.productAvailabilityService,
    )
    return new ContextualServiceImpl(this.settings, this.apiHttpService, contextualRouteResolver)
  }
  protected initMapService(): MapService {
    const mapRouteResolver = new MapRouteResolverImpl(this.settings)
    return new MapServiceImpl(this.apiHttpService, mapRouteResolver)
  }
  protected initTropicalService(): TropicalService {
    const tropicalRouteResolver = new TropicalRouteResolverImpl(this.settings)
    return new TropicalServiceImpl(this.apiHttpService, tropicalRouteResolver)
  }
  protected initWeatherService(): WeatherService {
    const weatherRouteResolver = new WeatherRouteResolverImpl(
      this.settings,
      this.productAvailabilityService,
      this.locationService,
    )
    return new WeatherServiceImpl(this.settings, this.apiHttpService, weatherRouteResolver)
  }
}
