/**
 * Information about an error.
 */
export class ServiceError {
  /**
   * The error.
   */
  public readonly error: Error

  /**
   * Initializes a new `ServiceError`.
   * @param error the error.
   */
  constructor(error: Error) {
    this.error = error
  }
}
