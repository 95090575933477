/**
 * Settings for cache behavior.
 */
export class CacheSettings {
  /**
   * Gets or sets how long to cache MinuteCast colors and color ranges, in milliseconds.
   * Default is 0, which will not cache.
   */
  public minuteCastColorsExpiry = 0
  /**
   * Gets or sets whether to cache MinuteCast colors and color ranges.
   */
  public get shouldCacheMinuteCastColors(): boolean {
    return this.shouldCache(this.minuteCastColorsExpiry)
  }

  /**
   * Gets or sets how long to cache language-specific Index and IndexGroup metadata, in milliseconds.
   * Default is 0, which will not cache.
   */
  public indexMetaExpiry = 0
  /**
   * Gets or sets whether to cache language-specific Index and IndexGroup metadata.
   */
  public get shouldCacheIndexMeta(): boolean {
    return this.shouldCache(this.indexMetaExpiry)
  }

  /**
   * Gets or sets how long to cache language-specific attribution source metadata, in milliseconds.
   * Default is 0, which will not cache.
   */
  public attributionSourcesExpiry = 0
  /**
   * Gets or sets whether to cache language-specific attribution source metadata.
   */
  public get shouldCacheAttributionSources(): boolean {
    return this.shouldCache(this.attributionSourcesExpiry)
  }

  /**
   * Gets or sets how long to cache languages and translation groups, in milliseconds.
   * Default is 0, which will not cache.
   */
  public languagesExpiry = 0
  /**
   * Gets or sets whether to cache languages and translation groups.
   */
  public get shouldCacheLanguages(): boolean {
    return this.shouldCache(this.languagesExpiry)
  }

  /**
   * Gets or sets how long to cache region, country, and administrative area metadata, in milliseconds.
   * Default is 0, which will not cache.
   */
  public areaMetaExpiry = 0
  /**
   * Gets or sets whether to cache region, country, and administrative area metadata.
   */
  public get shouldCacheAreaMeta(): boolean {
    return this.shouldCache(this.areaMetaExpiry)
  }

  /**
   * Gets or sets how long to cache location info.
   * Default is 5 minutes.
   */
  public locationInfoSlidingExpiry: number = 1000 * 60 * 5
  /**
   * Gets or sets whether to cache location info.
   * If true, will be a sliding expiry.
   */
  public get shouldCacheLocationInfo(): boolean {
    return this.shouldCache(this.locationInfoSlidingExpiry)
  }

  /**
   * Gets or sets how long to cache partner metadata, in milliseconds.
   * Default is 0, which will not cache.
   */
  public partnerMetaExpiration = 0
  /**
   * Gets or sets whether to cache partner metadata.
   */
  public get shouldCachePartnerMeta(): boolean {
    return this.shouldCache(this.partnerMetaExpiration)
  }

  /**
   * Gets whether to cache responses temporarily.
   * If `true`, the SDK will cache every response that
   * defines max-age in the Cache-Control HTTP headers.
   */
  public shouldUseCacheControlHeaders = true

  private shouldCache(ttl: number) {
    return ttl !== 0
  }

  /**
   * Gets whether to cache responses temporarily.
   * If `true`, the SDK will cache every response.
   */
  public shouldUseRequestCache = true
}
