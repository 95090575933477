import { Prop, Component, Vue } from 'vue-facing-decorator'

@Component
export default class LoadingScreenComponent extends Vue {
  @Prop className: string | undefined

  mounted() {
    //
  }
}
