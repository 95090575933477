/**
 * The different types of content available for search.
 */
export enum SearchContentType {
  /**
   * articles
   */
  Articles = 'articles',

  /**
   * videos
   */
  Videos = 'videos',

  /**
   * both
   */
  Both = 'both',
}
