import { isMetricLength } from '../../../core/models/measurements/LinearUnits'
import { isMetricPressure } from '../../../core/models/measurements/PressureUnits'
import { DistanceFormatOptions } from '../../options/units/DistanceFormatOptions'
import { PrecipitationDisplayType } from '../../options/units/PrecipitationDisplayType'
import { PrecipitationFormatOptions } from '../../options/units/PrecipitationFormatOptions'
import { PressureFormatOptions } from '../../options/units/PressureFormatOptions'

// see https://docs.google.com/document/d/1DKuiAobSgJt36Immae53cn3608VnD-EiQpSl2DpXBwI
// internal
export class RoundingProvider {
  //region temperature
  public readonly temperaturePrecision = 0
  public readonly dewpointPrecision = this.temperaturePrecision
  //endregion temperature

  //region wind / speed / direction
  public readonly speedPrecision = 0
  public readonly directionPrecision = 0

  public readonly windSpeedPrecision = this.speedPrecision
  public readonly windDirectionPrecision = this.directionPrecision
  public readonly gustPrecision = this.speedPrecision
  //endregion wind / speed / direction

  //region distance
  public readonly lengthPrecision = 0
  public readonly ceilingPrecision = this.lengthPrecision
  //endregion distance

  //region percent
  public readonly percentPrecision = 0
  public readonly precipitationProbabilityPrecision = this.percentPrecision
  public readonly humidityPrecision = this.percentPrecision
  public readonly cloudCoverPrecision = this.percentPrecision

  //region number-only (no unit)
  public readonly degreeDaySummaryPrecision = 0

  public readonly hoursOfPrecipitationPrecision = 1

  public readonly uvIndexPrecision = 0

  public readonly indexDataPrecision = 0

  public readonly decimalCoordinatePrecision = 2

  public getVisibilityPrecision(value: number, formatOptions: DistanceFormatOptions): number {
    if (isMetricLength(formatOptions.linearUnits)) {
      // >= 3 km should be rounded to a whole number
      // < 3 km should display 1 decimal place. Visibility should never display as 0 km
      return value >= 3 ? 0 : 1
    }

    // >= 2 mi should be rounded to a whole number
    // < 2 mi should display 2 decimal places. Visibility should never display as 0 mi
    return value >= 2 ? 0 : 2
  }

  public getPrecipitationPrecision(formatOptions: PrecipitationFormatOptions) {
    return formatOptions.precipitationType === PrecipitationDisplayType.Snow ||
      isMetricLength(formatOptions.linearUnits)
      ? 1
      : 2
  }
  //endregion percent

  public getPressurePrecision(formatOptions: PressureFormatOptions): number {
    return isMetricPressure(formatOptions.pressureUnits) ? 0 : 2
  }
  //endregion number-only (no unit)

  public getTideHeightPrecision(formatOptions: DistanceFormatOptions): number {
    return isMetricLength(formatOptions.linearUnits) ? 2 : 1
  }
}
