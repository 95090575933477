import { Module, ActionContext } from 'vuex'
import { RootState, ScreensaverState } from '../types'
import { RouteLocationNormalized } from 'vue-router'
import { Sdk } from '@/sdk/sdk'
import { getLocation } from '@/services/home.service'

const screensaver: Module<ScreensaverState, RootState> = {
  state: {
    currentConditions: undefined,
    location: undefined,
  },
  mutations: {
    setScreensaverData(state, data) {
      Object.assign(state, data)
    },
  },
  actions: {
    async fetchScreensaverData(
      { commit }: ActionContext<ScreensaverState, RootState>,
      p: RouteLocationNormalized | undefined,
    ) {
      const isMock = p?.query?.mock === 'true' ?? false
      const sdk = new Sdk(isMock)
      try {
        const location = await getLocation(sdk, p?.params)
        const locationKey = location?.Key
        const data = {
          currentConditions: await sdk.getCurrentConditions(locationKey),
          location: location,
        }
        commit('setScreensaverData', data)
        return data
      } catch (error) {
        console.error('Error al obtener datos de home:', error)
        return Promise.reject(error)
      }
    },
  },
}

export default screensaver
