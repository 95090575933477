import { HttpService } from '../core/http/HttpService'
import { SdkSettings } from '../core/settings/SdkSettings'

export class ContentHttpService extends HttpService {
  /**
   * Initializes a new [ContentHttpService].
   * @param sdkSettings the SDK settings.
   */
  constructor(sdkSettings: SdkSettings) {
    super(
      sdkSettings.cmsSettings,
      sdkSettings.cacheSettings,
      sdkSettings.persistentCache,
      sdkSettings.showApiDetails,
    )
  }
}
