import { isNullOrUndefined } from '../../util/Helpers'
import { IndexType } from '../IndexType'

/**
 * Provides methods used to validate index types.
 */
export class IndexTypeValidRule {
  public static checkRule(type: IndexType) {
    return isNullOrUndefined(type) || type !== IndexType.Unknown
      ? undefined
      : Error('invalid index type.')
  }
}
