/**
 * The different types of data that can be attributed.
 */
export enum AttributionSourceType {
  /**
   * Alerts
   */
  Alerts = 'Alerts',

  /**
   * Current Conditions
   */
  CurrentConditions = 'CurrentConditions',

  /**
   * Radar
   */
  Radar = 'Radar',

  /**
   * Air Quality
   */
  AirQuality = 'AirQuality',

  /**
   * Regional Air Quality
   */
  RegionalAirQuality = 'AirQuality-Regional',

  /**
   * Daily Air Quality Forecast
   */
  DailyAirQualityForecast = 'DailyAirQualityForecast',

  /**
   * Daily Forecast
   */
  DailyForecast = 'DailyForecast',

  /**
   * Daily Local Indices
   */
  DailyLocalIndices = 'DailyLocalIndices',

  /**
   * Hourly Local Indices
   */
  HourlyLocalIndices = 'HourlyLocalIndices',

  /**
   * Dangerous Thunderstorm Alert Push Notifications
   */
  DangerousThunderstormAlertPushNotifications = 'Dangerous Thunderstorm Alert Push Notifications',

  /**
   * Unknown
   */
  Unknown = '',
}
