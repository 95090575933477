import { isNullOrUndefined } from '../../../util/Helpers'
import { getQuantityUnitType, getQuantityValue, isQuantity } from './ModelHelpers'
import { UnitInfo } from './UnitInfo'

// internal
export class ConversionInfo<T> {
  public unitType: number
  public converter: T
  public conversion: (val: number, converter: T) => number

  constructor(unitType: number, converter: T, conversion: (val: number, converter: T) => number) {
    this.unitType = unitType
    this.converter = converter
    this.conversion = conversion
  }

  get unitLabel() {
    return UnitInfo.label(this.unitType)
  }
}

/**
 * Converts a quantity from one unit type to another.
 * @param quantity The quantity to convert.
 * @param type The unit type to convert to.
 * @param map The map of converters.
 * @return The converted quantity.
 */
export function convertQuantity(quantity: any, type: number, map: Map<number, any>) {
  if (!isQuantity(quantity)) {
    return
  }

  const currentType = getQuantityUnitType(quantity)
  if (currentType === type) {
    return quantity
  }

  const fromConversionInfo = map.get(currentType)
  if (isNullOrUndefined(fromConversionInfo)) {
    throw new Error('From conversion info is null')
  }

  const toConversionInfo = map.get(type)
  if (isNullOrUndefined(toConversionInfo)) {
    throw new Error('To conversion info is null')
  }

  // the converter is the FROM type (currentType)
  // we should be able to assume we have converter if we've made it this far
  const converter = fromConversionInfo.converter

  // the conversion is *by* the TO type, using the converter
  const value = toConversionInfo.conversion(getQuantityValue(quantity), converter)
  // the TO type
  const unitLabel = toConversionInfo.unitLabel
  if (isNullOrUndefined(unitLabel)) {
    throw new Error('Unit label is null')
  }

  // return an object with property names
  // exactly like the original ("Value" vs "value")
  if (Object.prototype.hasOwnProperty.call(quantity, 'Value')) {
    return {
      Unit: unitLabel,
      UnitType: type,
      Value: value,
    }
  } else {
    return {
      unit: unitLabel,
      unitType: type,
      value,
    }
  }
}
