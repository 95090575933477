/**
 * The list of (abbreviated) Cardinal Directions.
 */
export enum CardinalDirection {
  /**
   * North
   */
  N,

  /**
   * North-Northeast
   */
  NNE,

  /**
   * Northeast
   */
  NE,

  /**
   * East-Northeast
   */
  ENE,

  /**
   * East
   */
  E,

  /**
   * East-Southeast
   */
  ESE,

  /**
   * Southeast
   */
  SE,

  /**
   * South-Southeast
   */
  SSE,

  /**
   * South
   */
  S,

  /**
   * South-Southwest
   */
  SSW,

  /**
   * Southwest
   */
  SW,

  /**
   * West-Southwest
   */
  WSW,

  /**
   * West
   */
  W,

  /**
   * West-Northwest
   */
  WNW,

  /**
   * Northwest
   */
  NW,

  /**
   * North-Northwest
   */
  NNW,

  /**
   * Unknown
   */
  Unknown,
}
