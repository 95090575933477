// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import { ProductType } from '../../core/support/ProductType'
import { BaseLocationCacheInfo } from './BaseLocationCacheInfo'
import { LocationPartnerInfo } from './LocationPartnerInfo'

/**
 * Encapsulates the information used to cache location data.
 */
export class LocationCacheInfo {
  /**
   * The location key- used as the cache key.
   */
  public key: string

  /**
   * The url slug.
   */
  public urlSlug: string

  /**
   * The station code- used for most weather endpoints.
   */
  public stationCode: string

  /**
   * The station GMT offset- used for most weather endpoints.
   */
  public locationGmtOffset: number

  /**
   * The climo code- used for most climo endpoints.
   */
  public climo: string

  /**
   * The country code.
   */
  public countryCode: string

  /**
   * The admin code.
   */
  public adminCode: string

  /**
   * The DMA ID
   */
  public dmaCode: string

  /**
   * The latitude.
   */
  public latitude: number

  /**
   * The longitude.
   */
  public longitude: number

  /**
   * The time zone code.
   */
  public timeZoneCode: string

  /**
   * The list of available products.
   */
  public dataSets: ProductType[]

  /**
   * The branding partner id.
   */
  public partnerId: number

  constructor(baseInfo: BaseLocationCacheInfo, partnerInfo: LocationPartnerInfo) {
    this.key = baseInfo.key
    this.urlSlug = baseInfo.urlSlug
    this.stationCode = baseInfo.stationCode
    this.locationGmtOffset = baseInfo.locationGmtOffset
    this.climo = baseInfo.climo
    this.countryCode = baseInfo.countryCode
    this.adminCode = baseInfo.adminCode
    this.dmaCode = baseInfo.dmaCode
    this.latitude = baseInfo.latitude
    this.longitude = baseInfo.longitude
    this.timeZoneCode = baseInfo.timeZoneCode
    this.dataSets = baseInfo.dataSets
    this.partnerId = partnerInfo.partnerId
  }
}

const locationNameToken = '{location}'
const locationKeyToken = '{locationkey}'
/**
 * Resolves a url with location tokens.
 * @param url The url to resolve.
 * @param cacheInfo The location cache info.
 * @returns The resolved url.
 */
export function resolveLocationUrl(url: string, cacheInfo: LocationCacheInfo): string {
  return url
    ?.replace(locationNameToken, this.urlSlug ?? '')
    ?.replace(locationKeyToken, this.key ?? '')
}
