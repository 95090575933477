/**
 * Provides methods to convert pressure values to other PressureUnits.
 *
 * See also AccuWeather.Common.Measurements (api)
 */
export abstract class PressureConversion {
  /**
   * Converts a pressure to kilopascals.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilopascals.
   */
  public abstract toKiloPascals(value: number): number

  /**
   * Converts a pressure to millibars.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millibars.
   */
  public abstract toMillibars(value: number): number

  /**
   * Converts a pressure to inches of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches of mercury.
   */
  public abstract toMercuryInches(value: number): number

  /**
   * Converts a pressure to millimeters of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters of mercury.
   */
  public abstract toMercuryMillimeters(value: number): number

  /**
   * Converts a pressure to PSI.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in PSI.
   */
  public abstract toPoundsPerSquareInch(value: number): number
}

/**
 * Provides methods to convert pressure values from PSI to other PressureUnits.
 */
export class PoundsPerSquareInch extends PressureConversion {
  /**
   * Converts a pressure to PSI.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in PSI.
   */
  public toPoundsPerSquareInch(value: number): number {
    return value
  }

  /**
   * Converts a pressure to kilopascals.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilopascals.
   */
  public toKiloPascals(value: number): number {
    return value * 6.89476
  }

  /**
   * Converts a pressure to millibars.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millibars.
   */
  public toMillibars(value: number): number {
    return value * 68.9476
  }

  /**
   * Converts a pressure to inches of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches of mercury.
   */
  public toMercuryInches(value: number): number {
    return value * 2.03602
  }

  /**
   * Converts a pressure to millimeters of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters of mercury.
   */
  public toMercuryMillimeters(value: number): number {
    return value * 51.7149
  }
}

/**
 * Provides methods to convert pressure values from millibars to other PressureUnits.
 */
export class Millibars extends PressureConversion {
  /**
   * Converts a pressure to kilopascals.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilopascals.
   */
  public toKiloPascals(value: number): number {
    return value * 0.1
  }

  /**
   * Converts a pressure to millibars.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millibars.
   */
  public toMillibars(value: number): number {
    return value
  }

  /**
   * Converts a pressure to inches of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches of mercury.
   */
  public toMercuryInches(value: number): number {
    return value * 0.02953
  }

  /**
   * Converts a pressure to millimeters of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters of mercury.
   */
  public toMercuryMillimeters(value: number): number {
    return value * 0.750062
  }

  /**
   * Converts a pressure to PSI.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in PSI.
   */
  public toPoundsPerSquareInch(value: number): number {
    return value * 0.0145038
  }
}

/**
 * Provides methods to convert pressure values from millimeters of mercury to other PressureUnits.
 */
export class MercuryMillimeters extends PressureConversion {
  /**
   * Converts a pressure to kilopascals.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilopascals.
   */
  public toKiloPascals(value: number): number {
    return value * 0.1 * 0.1333224
  }

  /**
   * Converts a pressure to millibars.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millibars.
   */
  public toMillibars(value: number): number {
    return value * 1.333224
  }

  /**
   * Converts a pressure to inches of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches of mercury.
   */
  public toMercuryInches(value: number): number {
    return value * 0.03937008
  }

  /**
   * Converts a pressure to millimeters of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters of mercury.
   */
  public toMercuryMillimeters(value: number): number {
    return value
  }

  /**
   * Converts a pressure to PSI.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in PSI.
   */
  public toPoundsPerSquareInch(value: number): number {
    return value * 0.0193368
  }
}

/**
 * Provides methods to convert pressure values from inches of mercury to other PressureUnits.
 */
export class MercuryInches extends PressureConversion {
  /**
   * Converts a pressure to kilopascals.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilopascals.
   */
  public toKiloPascals(value: number): number {
    return value * 0.1 * 33.8639
  }

  /**
   * Converts a pressure to millibars.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millibars.
   */
  public toMillibars(value: number): number {
    return value * 33.8639
  }

  /**
   * Converts a pressure to inches of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches of mercury.
   */
  public toMercuryInches(value: number): number {
    return value
  }

  /**
   * Converts a pressure to millimeters of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters of mercury.
   */
  public toMercuryMillimeters(value: number): number {
    return value * 25.4
  }

  /**
   * Converts a pressure to PSI.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in PSI.
   */
  public toPoundsPerSquareInch(value: number): number {
    return value * 0.491154
  }
}

/**
 * Provides methods to convert pressure values from kilopascals to other PressureUnits.
 */
export class KiloPascals extends PressureConversion {
  /**
   * Converts a pressure to kilopascals.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in kilopascals.
   */
  public toKiloPascals(value: number): number {
    return value
  }

  /**
   * Converts a pressure to millibars.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millibars.
   */
  public toMillibars(value: number): number {
    return value * 10
  }

  /**
   * Converts a pressure to inches of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in inches of mercury.
   */
  public toMercuryInches(value: number): number {
    return value * 0.2953
  }

  /**
   * Converts a pressure to millimeters of mercury.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in millimeters of mercury.
   */
  public toMercuryMillimeters(value: number): number {
    return value * 7.50062
  }

  /**
   * Converts a pressure to PSI.
   * @param value The original value to be converted.
   *
   * @return The converted distance, in PSI.
   */
  public toPoundsPerSquareInch(value: number): number {
    return value * 0.145038
  }
}
