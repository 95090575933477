import { BaseWeatherEvent } from '../../models/BaseWeatherEvent'
import { ApiConfig, getBaseUrl } from './api-config'
import { Endpoint } from './api-endpoints'
import AWLocation from '../../models/Location'
import Index from '../../models/Index'
import { Position } from '@/models/Position'

export class AwxApi {
  async getWeatherEvents(
    location: AWLocation | undefined,
  ): Promise<BaseWeatherEvent[] | undefined> {
    if (!location?.Details?.StationCode) return undefined
    try {
      const stationCode = location.Details.StationCode
      const response = await this.getApiData({
        baseUrlKey: 'apiUri',
        endpoint: `${Endpoint.WeatherEvents}/${stationCode}.json`,
        queryParams: {
          apikey: process.env.VUE_APP_AW_TOKEN ?? '',
          locationOffset: location?.TimeZone?.GmtOffset?.toString(),
          language: 'en',
          metric: 'False',
          eventType: '',
          timezoneFormat: 'EST',
        },
      })
      const data = (await response.json()) as BaseWeatherEvent[]
      return data
    } catch (error) {
      // here comes the metric call
    }
  }

  async getLocationByIP(): Promise<AWLocation | undefined> {
    try {
      const response = await this.getApiData({
        baseUrlKey: 'apiUri',
        endpoint: Endpoint.LocationsByIP,
        queryParams: {
          apikey: process.env.VUE_APP_AW_TOKEN ?? '',
        },
      })

      return (await response.json()) as AWLocation
    } catch (error) {
      // here comes the metric call
      return
    }
  }

  async getLocationByCoordinates(): Promise<AWLocation | undefined> {
    try {
      const [latitude, longitude] = await this.getLatLon()
      const response = await this.getApiData({
        baseUrlKey: 'apiUri',
        endpoint: Endpoint.LocationByGeoposition,
        queryParams: {
          q: `${latitude},${longitude}`,
          apikey: process.env.VUE_APP_AW_TOKEN ?? '',
        },
      })
      return (await response.json()) as AWLocation
    } catch (error) {
      console.error(error)
      // here comes the metric call
      return await this.getLocationByIP()
    }
  }

  async getLifestyleIndices(location: AWLocation | undefined): Promise<Index[] | undefined> {
    try {
      if (!location?.Details?.StationCode) return undefined
      const stationCode = location.Details.StationCode
      const response = await this.getApiData({
        baseUrlKey: 'apiUri',
        endpoint: `${Endpoint.LifestyleIndices}/${stationCode}/groups/58.json`,
        queryParams: {
          locationOffset: location.TimeZone.GmtOffset.toString(),
          apikey: process.env.VUE_APP_AW_TOKEN ?? '',
          language: 'en-us',
          details: 'False',
        },
      })
      return (await response.json()) as Index[]
    } catch (error) {
      // here comes the metric call
      return undefined
    }
  }

  private async getApiData(config: ApiConfig): Promise<Response> {
    const urlParams = new URLSearchParams(config.queryParams)
    const baseUrl = getBaseUrl(config.baseUrlKey)
    const headers = new Headers()
    // eslint-disable-next-line no-extra-boolean-cast
    if (process.env.VUE_APP_USE_MOCK === 'true') {
      headers.append('workspace-id', process.env.VUE_APP_WORKSPACE_ID ?? '')
    }

    return fetch(`${baseUrl}${config.endpoint}?${urlParams.toString()}`, {
      headers: headers,
    })
  }

  async getLatLon(): Promise<[number, number]> {
    try {
      const position = await new Promise<Position>((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject),
      )
      return [position.coords.latitude, position.coords.longitude]
    } catch (error: any) {
      console.error('Error al obtener la ubicación:', error.message)
      throw error
    }
  }
}
