// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { isNotNullOrEmpty, isNotNullOrUndefined, isNullOrUndefined } from '../../util/Helpers'
import { Logger } from '../Logger'
import { HttpMethod, HttpRequestOptions } from './HttpRequestOptions'

/**
 * A wrapper for http requests, in order to return either data or error information rather than throwing exceptions.
 */
export class AccuHttpResponse {
  /**
   * The url.
   */
  public url: string

  /**
   * Gets the AxiosResponse object.
   */
  public get response(): AxiosResponse<any> {
    return this._response
  }
  /**
   * Gets the response status code.
   */
  public get statusCode(): number {
    return this._statusCode
  }
  /**
   * Gets the response headers.
   */
  public get headers(): any {
    return this._headers
  }
  /**
   * Gets the error.
   */
  public get error(): Error {
    return this._error
  }

  private get hasError() {
    return isNotNullOrUndefined(this.error)
  }

  /**
   * Gets whether the request completed successfully.
   * True if error is null or undefined and statusCode is 200, otherwise false.
   */
  public get isSuccess(): boolean {
    return !this.hasError && this.statusCode === 200
  }

  /**
   * Gets the response content.
   */
  public get content(): any {
    return isNullOrUndefined(this.response) ? '' : this.response.data
  }
  /**
   * Gets the Cache-Control max-age value if present in the response headers
   */
  public get maxAge(): number {
    let result: number
    try {
      const cacheControl = this.getHeaderValue(this.cacheControlKey)
        ?.toString()
        ?.replace(/^\[+|\]+$/g, '')
      if (isNotNullOrEmpty(cacheControl) && cacheControl.toLowerCase().includes('max-age=')) {
        // trim anything preceding 'max-age=' as well as 'max-age='
        const ma = cacheControl.substring(cacheControl.indexOf('max-age=') + 8, cacheControl.length)
        if (!ma.includes('no-cache')) {
          result = Number(ma)
        }
      }
    } catch (ex) {
      this.logger.warn(`Failed to parse Cache-Control header value: ${ex.message}`)
    }
    return result
  }

  private readonly logger: Logger = Logger.getInstance()

  /**
   * The AxiosRequestConfig.
   */
  private config: AxiosRequestConfig

  private _response: AxiosResponse<any>

  private _statusCode: number

  private _headers: any

  private _error: Error

  // region helper
  private cacheControlKey = 'cache-control'

  /**
   * Initializes a new AccuHttpResponse.
   * @param url the url.
   * @param axiosConfig the AxiosRequestConfig.
   */
  constructor(url: string, axiosConfig: AxiosRequestConfig) {
    this.url = url
    this.config = axiosConfig
  }

  /**
   * Performs the GET request, extracts the status code and headers, captures error info and returns itself.
   */
  public async sendRequest(options: HttpRequestOptions): Promise<AccuHttpResponse> {
    try {
      // this.logger.debug(`url: ${this.url}`) // added for debugging
      switch (options.method) {
        case HttpMethod.Post:
          this._response = await axios.post(this.url, options.body, this.config)
          break

        case HttpMethod.Get:
          this._response = await axios.get(this.url, this.config)
          break

        default:
          break
      }
      this._statusCode = this.response.status
      this._headers = this.response.headers
    } catch (error) {
      this.logger.error(error)
      this._error = error
    }
    return this
  }

  /**
   * Helper method to retrieve header values by key
   */
  private getHeaderValue(headerName: string): any {
    if (isNotNullOrUndefined(this.headers)) {
      return this.headers[headerName]
    }
  }

  // endregion helper
}
