/**
 * The basin IDs.
 */
export enum BasinId {
  /**
   * Atlantic
   */
  AL = 'AL',

  /**
   * East Pacific
   */
  EP = 'EP',

  /**
   * South Indian
   */
  SI = 'SI',

  /**
   * North Indian
   */
  NI = 'NI',

  /**
   * Central Pacific
   */
  CP = 'CP',

  /**
   * North Pacific
   */
  NP = 'NP',

  /**
   * South Pacific
   */
  SP = 'SP',
}
