/**
 * The different named day periods.
 */
export enum DayPeriod {
  /**
   * Midnight
   */
  Midnight = 'midnight',

  /**
   * AM
   */
  Am = 'am',

  /**
   * AM variant
   */
  AmVariant = 'am-alt-variant',

  /**
   * Noon
   */
  Noon = 'noon',

  /**
   * PM
   */
  Pm = 'pm',

  /**
   * PM Variant
   */
  PmVariant = 'pm-alt-variant',

  /**
   * Morning
   */
  Morning = 'morning1',

  /**
   * Afternoon
   */
  Afternoon = 'afternoon1',

  /**
   * Evening
   */
  Evening = 'evening1',

  /**
   * Night
   */
  Night = 'night1',
}
