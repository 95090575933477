export default {
  Basins: {
    AL: {
      Storms: {
        '2017': [
          'Arlene',
          'Bret',
          'Cindy',
          'Don',
          'Emily',
          'Franklin',
          'Gert',
          'Harvey',
          'Irma',
          'Jose',
          'Katia',
          'Lee',
          'Maria',
          'Nate',
          'Ophelia',
          'Philippe',
          'Rina',
          'Sean',
          'Tammy',
          'Vince',
          'Whitney',
        ],
        '2018': [
          'Alberto',
          'Beryl',
          'Chris',
          'Debby',
          'Ernesto',
          'Florence',
          'Gordon',
          'Helene',
          'Isaac',
          'Joyce',
          'Kirk',
          'Leslie',
          'Michael',
          'Nadine',
          'Oscar',
          'Patty',
          'Rafael',
          'Sara',
          'Tony',
          'Valerie',
          'William',
        ],
        '2019': [
          'Andrea',
          'Barry',
          'Chantal',
          'Dorian',
          'Erin',
          'Fernand',
          'Gabrielle',
          'Humberto',
          'Imelda',
          'Jerry',
          'Karen',
          'Lorenzo',
          'Melissa',
          'Nestor',
          'Olga',
          'Pablo',
          'Rebekah',
          'Sebastien',
          'Tanya',
          'Van',
          'Wendy',
        ],
        '2020': [
          'Arthur',
          'Bertha',
          'Cristobal',
          'Dolly',
          'Edouard',
          'Fay',
          'Gonzalo',
          'Hanna',
          'Isaias',
          'Josephine',
          'Kyle',
          'Laura',
          'Marco',
          'Nana',
          'Omar',
          'Paulette',
          'Rene',
          'Sally',
          'Teddy',
          'Vicky',
          'Wilfred',
        ],
        '2021': [
          'Ana',
          'Bill',
          'Claudette',
          'Danny',
          'Elsa',
          'Fred',
          'Grace',
          'Henri',
          'Ida',
          'Julian',
          'Kate',
          'Larry',
          'Mindy',
          'Nicholas',
          'Odette',
          'Peter',
          'Rose',
          'Sam',
          'Teresa',
          'Victor',
          'Wanda',
        ],
        '2022': [
          'Alex',
          'Bonnie',
          'Colin',
          'Danielle',
          'Earl',
          'Fiona',
          'Gaston',
          'Hermine',
          'Ian',
          'Julia',
          'Karl',
          'Lisa',
          'Martin',
          'Nicole',
          'Owen',
          'Paula',
          'Richard',
          'Shary',
          'Tobias',
          'Virginie',
          'Walter',
        ],
      },
    },
    EP: {
      Storms: {
        '2017': [
          'Adrian',
          'Beatriz',
          'Calvin',
          'Dora',
          'Eugene',
          'Fernanda',
          'Greg',
          'Hilary',
          'Irwin',
          'Jova',
          'Kenneth',
          'Lidia',
          'Max',
          'Norma',
          'Otis',
          'Pilar',
          'Ramon',
          'Selma',
          'Todd',
          'Veronica',
          'Wiley',
          'Xina',
          'York',
          'Zelda',
        ],
        '2018': [
          'Aletta',
          'Bud',
          'Carlotta',
          'Daniel',
          'Emilia',
          'Fabio',
          'Gilma',
          'Hector',
          'Ileana',
          'John',
          'Kristy',
          'Lane',
          'Miriam',
          'Norman',
          'Olivia',
          'Paul',
          'Rosa',
          'Sergio',
          'Tara',
          'Vicente',
          'Willa',
          'Xavier',
          'Yolanda',
          'Zeke',
        ],
        '2019': [
          'Alvin',
          'Barbara',
          'Cosme',
          'Dalila',
          'Erick',
          'Flossie',
          'Gil',
          'Henriette',
          'Ivo',
          'Juliette',
          'Kiko',
          'Lorena',
          'Mario',
          'Narda',
          'Octave',
          'Priscilla',
          'Raymond',
          'Sonia',
          'Tico',
          'Velma',
          'Wallis',
          'Xina',
          'York',
          'Zelda',
        ],
        '2020': [
          'Amanda',
          'Boris',
          'Cristina',
          'Douglas',
          'Elida',
          'Fausto',
          'Genevieve',
          'Hernan',
          'Iselle',
          'Julio',
          'Karina',
          'Lowell',
          'Marie',
          'Norbert',
          'Odalys',
          'Polo',
          'Rachel',
          'Simon',
          'Trudy',
          'Vance',
          'Winnie',
          'Xavier',
          'Yolanda',
          'Zeke',
        ],
        '2021': [
          'Andres',
          'Blanca',
          'Carlos',
          'Dolores',
          'Enrique',
          'Felicia',
          'Guillermo',
          'Hilda',
          'Ignacio',
          'Jimena',
          'Kevin',
          'Linda',
          'Marty',
          'Nora',
          'Olaf',
          'Pamela',
          'Rick',
          'Sandra',
          'Terry',
          'Vivian',
          'Waldo',
          'Xina',
          'York',
          'Zelda',
        ],
        '2022': [
          'Agatha',
          'Blas',
          'Celia',
          'Darby',
          'Estelle',
          'Frank',
          'Georgette',
          'Howard',
          'Ivette',
          'Javier',
          'Kay',
          'Lester',
          'Madeline',
          'Newton',
          'Orlene',
          'Paine',
          'Roslyn',
          'Seymour',
          'Tina',
          'Virgil',
          'Winifred',
          'Xavier',
          'Yolanda',
          'Zeke',
        ],
      },
    },
    CP: {
      Storms: {
        List1: [
          'Akoni',
          'Ema',
          'Hone',
          'Iona',
          'Keli',
          'Lala',
          'Moke',
          'Nolo',
          'Olana',
          'Pena',
          'Ulana',
          'Wale',
        ],
        List2: [
          'Aka',
          'Ekeka',
          'Hene',
          'Iolana',
          'Keoni',
          'Lino',
          'Mele',
          'Nona',
          'Oliwa',
          'Pama',
          'Upana',
          'Wene',
        ],
        List3: [
          'Alika',
          'Ele',
          'Huko',
          'Iopa',
          'Kika',
          'Lana',
          'Maka',
          'Neki',
          'Omeka',
          'Pewa',
          'Unala',
          'Wali',
        ],
        List4: [
          'Ana',
          'Ela',
          'Halola',
          'Iune',
          'Kilo',
          'Loke',
          'Malia',
          'Niala',
          'Oho',
          'Pali',
          'Ulika',
          'Walaka',
        ],
      },
    },
    NP: {},
    SP: {},
    NI: {},
    SI: {},
  },
}
