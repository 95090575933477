// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { AvailabilityLevel } from '../core/support/AvailabilityLevel'
import { ProductType } from '../core/support/ProductType'
import { ProductUnavailableError } from '../core/support/ProductUnavailableError'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { LanguageValidRule } from '../core/validation/LanguageValidRule'
import { LocationService } from '../locations/LocationService'
import { isNotNullOrUndefined, isNullOrUndefined } from '../util/Helpers'
import { HurricaneAvailabilityProvider } from './custom/HurricaneAvailabilityProvider'
import { ProductAvailabilityInfoProvider } from './custom/ProductAvailabilityInfoProvider'
import { ProductAvailabilityInfoRequest } from './custom/ProductAvailabilityInfoRequest'
import { RadarAvailabilityProvider } from './custom/RadarAvailabilityProvider'
import { ProductAvailabilityByAdminAreaRequest } from './requests/ProductAvailabilityByAdminAreaRequest'
import { ProductAvailabilityByCountryRequest } from './requests/ProductAvailabilityByCountryRequest'
import { ProductAvailabilityByLocationKeyRequest } from './requests/ProductAvailabilityByLocationKeyRequest'
import { ProductCollectionByAdminAreaRequest } from './requests/ProductCollectionByAdminAreaRequest'
import { ProductCollectionByCountryRequest } from './requests/ProductCollectionByCountryRequest'
import { ProductCollectionByLocationKeyRequest } from './requests/ProductCollectionByLocationKeyRequest'

/**
 * Provides methods to check what features are available for a given location or area.
 */
export class ProductAvailabilityServiceImpl {
  private readonly sdkSettings: SdkSettings
  private readonly locationService: LocationService

  private readonly countryCustomProviders = new Map<ProductType, ProductAvailabilityInfoProvider>([
    [ProductType.Radar, new RadarAvailabilityProvider()],
    [ProductType.Hurricane, new HurricaneAvailabilityProvider()],
  ])

  private readonly adminAreaCustomProviders = new Map<ProductType, ProductAvailabilityInfoProvider>(
    [
      [ProductType.Radar, new RadarAvailabilityProvider()],
      [ProductType.Hurricane, new HurricaneAvailabilityProvider()],
    ],
  )

  private readonly locationCustomProviders = new Map<ProductType, ProductAvailabilityInfoProvider>([
    // radar is only custom for country/admin levels, but it comes from the api here
    [ProductType.Hurricane, new HurricaneAvailabilityProvider()],
  ])

  private readonly productAvailabilityInfoRequestValidator =
    new AsyncValidator<ProductAvailabilityInfoRequest>([])

  constructor(sdkSettings: SdkSettings, locationService: LocationService) {
    this.sdkSettings = sdkSettings
    this.locationService = locationService
    this.productAvailabilityInfoRequestValidator.addRule((r) =>
      LanguageValidRule.checkRule(r.languageCode),
    )
  }

  // region available products
  /**
   * Gets a list of all available products for a location.
   *
   * @param request The request.
   * @return A service response containing either a list of [ProductType]s or error information.
   */
  public async getAvailableProductsByLocationKey(
    request: ProductCollectionByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<ProductType[]>> {
    const cacheResponse = await this.locationService.getCacheInfo(
      request.language,
      request.locationKey,
      requestChain,
    )
    if (isNullOrUndefined(cacheResponse?.data)) {
      return cacheResponse.transformError()
    }
    const infoRequest = ProductAvailabilityInfoRequest.fromLocationCachInfo(
      request.language,
      cacheResponse.data,
    )
    return this.getFullProducts(this.locationCustomProviders, infoRequest, requestChain)
  }

  /**
   * Gets a list of all available products for a country.
   *
   * @param request The request.
   * @return A service response containing either a list of [ProductType]s or error information.
   */
  public async getAvailableProductsByCountry(
    request: ProductCollectionByCountryRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<ProductType[]>> {
    const infoRequest = new ProductAvailabilityInfoRequest(
      request.language,
      request.countryCode,
      undefined,
      0,
      undefined,
    )
    return this.getFullProducts(this.countryCustomProviders, infoRequest, requestChain)
  }

  /**
   * Gets a list of all available products for an admin area.
   *
   * @param request The request.
   * @return A service response containing either a list of [ProductType]s or error information.
   */
  public async getAvailableProductsByAdminArea(
    request: ProductCollectionByAdminAreaRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<ProductType[]>> {
    const infoRequest = new ProductAvailabilityInfoRequest(
      request.language,
      request.countryCode,
      request.adminCode,
      0,
      undefined,
    )
    return this.getFullProducts(this.adminAreaCustomProviders, infoRequest, requestChain)
  }
  // endregion available products

  // region per product
  /**
   * Gets product availability for a location.
   *
   * @param request The request.
   * @return A service response containing either an [AvailabilityLevel] or error information.
   */
  public async getAvailabilityByLocationKey(
    request: ProductAvailabilityByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<AvailabilityLevel>> {
    const productsResponse = await this.getAvailableProductsByLocationKey(request, requestChain)
    return this.getAvailability(request.product, productsResponse)
  }

  /**
   * Gets product availability for a country.
   *
   * @param request The request.
   * @return A service response containing either an [AvailabilityLevel] or error information.
   */
  public async getAvailabilityByCountry(
    request: ProductAvailabilityByCountryRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<AvailabilityLevel>> {
    const productsResponse = await this.getAvailableProductsByCountry(request, requestChain)
    return this.getAvailability(request.product, productsResponse)
  }

  /**
   * Gets product availability for an admin area.
   *
   * @param request The request.
   * @return A service response containing either an [AvailabilityLevel] or error information.
   */
  public async getAvailabilityByAdminArea(
    request: ProductAvailabilityByAdminAreaRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<AvailabilityLevel>> {
    const productsResponse = await this.getAvailableProductsByAdminArea(request, requestChain)
    return this.getAvailability(request.product, productsResponse)
  }
  // endregion internal

  public async checkLocation(
    product: ProductType,
    language: string,
    locationKey: string,
    requestChain?: RequestChain,
  ): Promise<Error> {
    const productResponse = await this.getAvailabilityByLocationKey(
      new ProductAvailabilityByLocationKeyRequest(product, language, locationKey),
      requestChain,
    )
    if (productResponse.hasError) {
      return productResponse.error.error
    }

    const availabilityLevel = productResponse.data as unknown as AvailabilityLevel
    if (availabilityLevel === AvailabilityLevel.Unsupported) {
      return new ProductUnavailableError(product, locationKey)
    }
  }
  // endregion per product

  // region internal
  private async getFullProducts(
    customProviders: Map<ProductType, ProductAvailabilityInfoProvider>,
    request: ProductAvailabilityInfoRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<ProductType[]>> {
    const error = await this.productAvailabilityInfoRequestValidator.validate(request)
    if (isNotNullOrUndefined(error)) {
      return ServiceResponse.fromError(error)
    }

    const result: ProductType[] = []
    if (isNotNullOrUndefined(request?.dataSets) && request.dataSets.length > 0) {
      for (const dataSet of request.dataSets) {
        result.push(dataSet)
      }
    }

    const arr = Array.from(customProviders.entries())
    for (const provider of arr) {
      const availability = provider[1].getAvailability(request, requestChain)
      if (availability === AvailabilityLevel.Supported) {
        result.push(ProductType[provider[0]])
      }
    }
    return ServiceResponse.create(result, null, null, 'No template')
  }

  protected getAvailability(
    productType: ProductType,
    products: ServiceResponse<ProductType[]>,
  ): ServiceResponse<AvailabilityLevel> {
    if (isNullOrUndefined(products?.data)) {
      return products.transformError()
    }

    // if products doesn't have errors, we know it has data- even if it's an empty list
    // we don't need to check productType.HasValue- the only way that happens is if request was null
    // if request is null, one (both) of the previous conditions are true and we won't get this far anyway
    const productArray: ProductType[] = products.data
    const level = this.getAvailibilityForProducts(productType, productArray)
    return ServiceResponse.create(level, null, level.toString(), 'No request')
  }

  protected getAvailibilityForProducts(
    productType: ProductType,
    products: ProductType[],
  ): AvailabilityLevel {
    if (isNullOrUndefined(products)) {
      return AvailabilityLevel.Unsupported
    }
    return products.indexOf(productType) !== -1
      ? AvailabilityLevel.Supported
      : AvailabilityLevel.Unsupported
  }
}
