// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { WeatherEventType } from '../..'
import { isNullOrUndefined } from '../../util/Helpers'

/**
 * Provides a method used to validate an event type.
 */
export class EventTypeValidRule {
  private static readonly nullError = Error('event type cannot be null')
  private static readonly unknownError = Error('unknown event type')
  private static readonly notApiError = Error('type not valid for use in API')

  /**
   * Verifies that an event type is valid.
   * @param eventType The event type.
   * @param allowNull Whether to treat `null` as valid.
   */
  public static validate(eventType: WeatherEventType, allowNull = true): Error {
    if (!isNullOrUndefined(eventType)) {
      if (eventType === WeatherEventType.Unknown) return this.unknownError
    } else if (!allowNull) {
      return this.nullError
    }
    // return null;
  }

  /**
   * Validates an event type in an API context.
   * @param eventType The event type.
   * @param allowNull Whether to treat `null` as valid.
   */
  public static validateForApi(eventType: WeatherEventType, allowNull = true): Error {
    const error = this.validate(eventType, allowNull)
    if (!isNullOrUndefined(error)) {
      return error
    }
    if (eventType === WeatherEventType.Winter) {
      return this.notApiError
    }
    // return null;
  }
}
