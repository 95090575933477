import { AlertRouteResolverImpl } from './src/alerts/AlertRouteResolverImpl'
import { AlertService } from './src/alerts/AlertService'
import { AlertServiceImpl } from './src/alerts/AlertServiceImpl'
import {
  ActiveStormsRequest,
  AlertsByLocationKeyRequest,
  CurrentConditionsByLocationKeyRequest,
  DailyForecastsByLocationKeyRequest,
  FindPostalCodeLocationsRequest,
  HourlyForecastsByLocationKeyRequest,
  LocationByKeyRequest,
  MinuteForecastIntervalType,
  RequestChain,
  SdkSettings,
  StormByIdRequest,
} from './src'
import { ApiHttpService } from './src/core/api/ApiHttpService'
import { LocationServiceImpl } from './src/locations/LocationServiceImpl'
import { LocationRouteResolverImpl } from './src/locations/LocationRouteResolverImpl'
import { ProductAvailabilityServiceImpl } from './src/productavailability/ProductAvailabilityServiceImpl'
import { WeatherServiceImpl } from './src/weather/WeatherServiceImpl'
import { WeatherRouteResolverImpl } from './src/weather/WeatherRouteResolverImpl'
import { MinuteForecastPremiumByLocationKeyRequest } from './src/weather/requests/MinuteForecastPremiumByLocationKeyRequest'
import CurrentConditions from '../models/CurrentConditions'
import Daily from '../models/Daily'
import HourlyForescast from '../models/HourlyForecast'
import AWLocation from '../models/Location'
import Minutecast from '../models/Minutecast'
import { mapDataToMinutecast } from './mappers/minute-forecast.map'
import { Alert } from '../models/Alert'
import { AwxApi } from './api/awx-api'
import { MapServiceImpl } from './src/maps/MapServiceImpl'
import { MapRouteResolverImpl } from './src/maps/MapRouteResolverImpl'
import { TropicalServiceImpl } from './src/tropical/TropicalServiceImpl'
import { TropicalRouteResolverImpl } from './src/tropical/TropicalRouteResolverImpl'
import Storm from '../models/Storm'
import StormForecast from '../models/StormForecast'
import { GetsdkSettings } from './api/api-config'
import { RequestChainImpl } from './src/core/http/RequestChainImpl'

export class Sdk extends AwxApi {
  private sdkSettings: SdkSettings
  private weatherService!: WeatherServiceImpl
  private alertService!: AlertService
  private locationService!: LocationServiceImpl
  private mapService!: MapServiceImpl
  private tropicalService!: TropicalServiceImpl
  private requestChain!: RequestChain

  constructor(isMock = false) {
    super()
    this.sdkSettings = GetsdkSettings(isMock)
    this.sdkSettings.cacheSettings.shouldUseRequestCache = false
    const httpService = new ApiHttpService(this.sdkSettings)
    const locationRouteResolver = new LocationRouteResolverImpl(this.sdkSettings)
    this.locationService = new LocationServiceImpl(
      this.sdkSettings,
      httpService,
      locationRouteResolver,
    )
    const productAvailabilityService = new ProductAvailabilityServiceImpl(
      this.sdkSettings,
      this.locationService,
    )
    const weatherRouteResolver = new WeatherRouteResolverImpl(
      this.sdkSettings,
      productAvailabilityService,
      this.locationService,
    )
    const routeResolver = new AlertRouteResolverImpl(this.sdkSettings, productAvailabilityService)
    const mapRouteResolver = new MapRouteResolverImpl(this.sdkSettings)
    const tropicalRouteResolver = new TropicalRouteResolverImpl(this.sdkSettings)

    this.weatherService = new WeatherServiceImpl(
      this.sdkSettings,
      httpService,
      weatherRouteResolver,
    )
    this.alertService = new AlertServiceImpl(httpService, routeResolver)

    this.mapService = new MapServiceImpl(httpService, mapRouteResolver)

    this.tropicalService = new TropicalServiceImpl(httpService, tropicalRouteResolver)

    this.requestChain = new RequestChainImpl()
  }

  async getMinutecast(
    locationKey: string | undefined,
    gmtOffset?: number | undefined,
  ): Promise<Minutecast | undefined> {
    if (!locationKey) return undefined

    const request = new MinuteForecastPremiumByLocationKeyRequest('en', locationKey)
    request.intervalType = MinuteForecastIntervalType.OneMinute
    request.details = true
    const response = await this.weatherService.getMinuteForecastPremiumByLocationKey(request)
    if (response.hasError) {
      // here comes the metric call
      return undefined
    }
    return mapDataToMinutecast(response.data, gmtOffset)
  }

  async getCurrentConditions(
    locationKey: string | undefined,
    hasDetails = false,
  ): Promise<CurrentConditions | undefined> {
    if (!locationKey) return undefined

    const request = new CurrentConditionsByLocationKeyRequest('en', locationKey)
    request.details = hasDetails
    const response = await this.weatherService.getCurrentConditionsByLocationKey(request)
    if (response.hasError) {
      // here comes the metric call
      return undefined
    }

    return response.data as CurrentConditions
  }

  async getDailyWeather(locationKey: string | undefined, days: number): Promise<Daily | undefined> {
    if (!locationKey) return undefined
    console.log(locationKey)
    const request = new DailyForecastsByLocationKeyRequest('en', locationKey)
    request.details = true
    const response = await this.weatherService.getDailyForecastsByLocationKey(request)
    if (response.hasError) {
      // here comes the metric call
      return undefined
    }

    const daily = response.data as Daily
    daily.DailyForecasts = daily.DailyForecasts.splice(0, days)
    return daily
  }

  async getHourlyForecast(
    locationKey: string | undefined,
    hours: number,
  ): Promise<HourlyForescast[] | undefined> {
    if (!locationKey) return undefined

    const request = new HourlyForecastsByLocationKeyRequest('en', locationKey)
    request.details = true
    const response = await this.weatherService.getHourlyForecastsByLocationKey(request)
    if (response.hasError) {
      // here comes the metric call
      return undefined
    }

    return (response.data as HourlyForescast[]).slice(0, hours)
  }

  async getLocationByKey(locationKey: string | undefined): Promise<AWLocation | undefined> {
    if (!locationKey) return undefined

    const request = new LocationByKeyRequest('en', locationKey)
    request.details = true
    const response = await this.locationService.getLocationByKey(request)
    if (response.hasError) {
      // here comes the metric call
      return undefined
    }

    return response.data as AWLocation
  }

  async getAlertsByKey(locationKey: string | undefined): Promise<Alert[] | undefined> {
    if (!locationKey) return undefined

    const request = new AlertsByLocationKeyRequest('en', locationKey)
    const response = await this.alertService.getAlertsByLocationKey(request)
    if (response.hasError) return undefined

    return response.data as Alert[]
  }

  async getActiveStorms(): Promise<Storm[] | undefined> {
    const request = new ActiveStormsRequest('en')
    const response = await this.tropicalService.getActiveStorms(request)
    if (response.hasError) {
      // here comes the metric call
      return undefined
    }

    return response.data as Storm[]
  }

  async getStormForecast(storm: Storm | undefined): Promise<void | undefined> {
    if (!storm) return undefined

    const request = new StormByIdRequest('en', storm.basinId, storm.year, storm.govId, true)
    request.details = true
    const response = await this.tropicalService.getStormForecasts(request)

    if (response.hasError) {
      // here comes the metric call
      return undefined
    }

    storm.stormForecast = response.data as StormForecast[]
  }

  async getStormData(storm: Storm | undefined): Promise<void | undefined> {
    if (!storm) return undefined

    const request = new StormByIdRequest('en', storm.basinId, storm.year, storm.govId, true)
    request.details = true
    const response = await this.tropicalService.getStormById(request)

    if (response.hasError) {
      // here comes the metric call
      return undefined
    }
    storm.status = response.data?.peakIntensity?.status
    storm.localizedStatus = response.data?.peakIntensity?.localizedStatus
    storm.position = response.data?.peakIntensity?.position
  }

  async getLocationByZipCode(zipCode: string | undefined): Promise<AWLocation | undefined> {
    if (!zipCode) return undefined

    const request = new FindPostalCodeLocationsRequest('en', zipCode, 'US')
    request.details = true
    const response = await this.locationService.findPostalCodeLocations(request)
    if (response.hasError) {
      console.error(
        'NETWORK',
        'LOCATION_ZIPCODE_FETCH_ERROR',
        'error fetching location by zip code',
        true,
      )
      return undefined
    }

    return response.data[0] as AWLocation
  }
}
