// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined } from '../../util/Helpers'
import { GetServiceResponse } from '../GetServiceResponse'
import { RequestChain } from '../http/RequestChain'
import { ServiceResponse } from '../ServiceResponse'
import { CacheOptions } from './CacheOptions'
import { PersistentCache } from './PersistentCache'

/**
 * Provides a way to cache the data from a service response.
 * @param TData The type of data being cached.
 */
export class ServiceResponseCache<TData> {
  private readonly cache: PersistentCache
  private readonly options: CacheOptions

  constructor(cache: PersistentCache, options: CacheOptions = new CacheOptions()) {
    this.cache = cache
    this.options = options
  }

  /**
   * Adds data to the cache if it doesn't already exist.
   *
   * @param TRequest The type of request used to fetch the data.
   * @param key The cache key.
   * @param getData The method used to fetch the data.
   * @param request The request used to fetch the data.
   * @param requestChain The request chain.
   * @return A ServiceResponse with the data.
   */
  public async getOrAdd<TRequest>(
    key: any,
    getData: GetServiceResponse<TData, TRequest>,
    request: TRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<TData>> {
    const fromCache = this.cache.get(key)
    if (isNotNullOrUndefined(fromCache)) {
      const data = fromCache as TData
      return ServiceResponse.create(
        data,
        null,
        ServiceResponse.rawDataFromCache,
        ServiceResponse.rawDataFromCache,
      )
    }
    const response = await getData(request, requestChain)
    return this.add(key, response)
  }

  /**
   * Adds the response data to the cache, overwriting any existing value if necessary.
   *
   * @param key The cache key.
   * @param serviceResponse The response with the data to be cached.
   * @return A ServiceResponse with the data.
   */
  public add(key: any, serviceResponse: ServiceResponse<TData>): ServiceResponse<TData> {
    if (!serviceResponse.hasError && isNotNullOrUndefined(serviceResponse.data)) {
      this.cache.set(key, serviceResponse.data, this.options)
    }
    return serviceResponse
  }
}
