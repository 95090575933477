/**
 * In some languages, flexible formats may need to differentiate between nominative forms and genitive (or related) forms of month and day names.
 * To understand which pattern characters are used for standalone forms (nominative) versus format forms (genitive or related, such as partitive),
 * @see <a href="http://cldr.unicode.org/translation/date-time#TOC-Stand-Alone-vs.-Format-Styles" />
 */
export enum ArticleCaseType {
  /**
   * Nominative case.
   * Used for standalone cases or when the article is the subject.
   */
  StandAlone = 'stand-alone',

  /**
   * Genitive case.
   * Used when the article is serving as an owner or relative.
   */
  Relative = 'format',
}
