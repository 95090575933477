// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiHttpService } from '../core/api/ApiHttpService'
import { CacheOptions } from '../core/cache/CacheOptions'
import { ServiceResponseCache } from '../core/cache/ServiceResponseCache'
import { ServiceResponseTransformCache } from '../core/cache/ServiceResponseTransformCache'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { RequestChainImpl } from '../core/http/RequestChainImpl'
import { Logger } from '../core/Logger'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { ProductType } from '../core/support/ProductType'
import {
  isNotNullOrBlank,
  isNotNullOrUndefined,
  isNullOrBlank,
  isNullOrUndefined,
} from '../util/Helpers'
import { UrlHelper } from '../util/UrlHelper'
import { BaseLocationCacheInfo } from './cache/BaseLocationCacheInfo'
import { LocationCacheInfo } from './cache/LocationCacheInfo'
import { LocationPartnerInfo } from './cache/LocationPartnerInfo'
import { LocationRouteResolver } from './LocationRouteResolver'
import { LocationService } from './LocationService'
import { AdminAreasRequest } from './requests/AdminAreasRequest'
import { AutocompleteRequest } from './requests/AutocompleteRequest'
import { CitiesRequest } from './requests/CitiesRequest'
import { CityByGeopositionRequest } from './requests/CityByGeopositionRequest'
import { CityByIpAddressRequest } from './requests/CityByIpAddressRequest'
import { CityNeighborsByLocationKeyRequest } from './requests/CityNeighborsByLocationKeyRequest'
import { CountriesRequest } from './requests/CountriesRequest'
import { FindAdminAreasRequest } from './requests/FindAdminAreasRequest'
import { FindCitiesRequest } from './requests/FindCitiesRequest'
import { FindCountriesRequest } from './requests/FindCountriesRequest'
import { FindLocationsRequest } from './requests/FindLocationsRequest'
import { FindPointsOfInterestRequest } from './requests/FindPointsOfInterestRequest'
import { FindPostalCodeLocationsRequest } from './requests/FindPostalCodeLocationsRequest'
import { LocationByKeyRequest } from './requests/LocationByKeyRequest'
import { RegionsRequest } from './requests/RegionsRequest'
import { TopCitiesRequest } from './requests/TopCitiesRequest'

/**
 * Used to find information about locations - which may be cities, points of interest, or postal code locations.
 * Also provides information regarding regions, countries, and administrative areas.
 * See <a href="http://apidev.accuweather.com/developers/locations here for more information.
 */
export class LocationServiceImpl implements LocationService {
  protected readonly serviceName = 'LocationService'
  private readonly logger: Logger = Logger.getInstance()
  private readonly httpService: ApiHttpService
  private readonly routeResolver: LocationRouteResolver

  private readonly regionsCache: ServiceResponseCache<any[]>
  private readonly countriesByRegionCache: ServiceResponseCache<any[]>
  private readonly adminAreasByCountryCache: ServiceResponseCache<any[]>
  private readonly baseInfoCache: ServiceResponseTransformCache<any, BaseLocationCacheInfo>
  private readonly partnerIdsCache: ServiceResponseTransformCache<any, LocationPartnerInfo>

  private readonly countryCodeUS = 'US'

  constructor(
    settings: SdkSettings,
    httpService: ApiHttpService,
    routeResolver: LocationRouteResolver,
  ) {
    this.httpService = httpService
    this.routeResolver = routeResolver

    if (settings.cacheSettings.shouldCacheAreaMeta) {
      const areaMetaOptions = new CacheOptions(settings.cacheSettings.areaMetaExpiry)
      this.regionsCache = new ServiceResponseCache(settings.persistentCache, areaMetaOptions)
      this.countriesByRegionCache = new ServiceResponseCache(
        settings.persistentCache,
        areaMetaOptions,
      )
      this.adminAreasByCountryCache = new ServiceResponseCache(
        settings.persistentCache,
        areaMetaOptions,
      )
    }

    if (settings.cacheSettings.shouldCacheLocationInfo) {
      const cacheInfoOptions = new CacheOptions(
        settings.cacheSettings.locationInfoSlidingExpiry,
        true,
      )
      this.baseInfoCache = new ServiceResponseTransformCache(
        (l) => this.toBaseLocationCacheInfo(l),
        settings.persistentCache,
        cacheInfoOptions,
      )
      this.partnerIdsCache = new ServiceResponseTransformCache(
        (l) => this.toLocationPartnerInfo(l),
        settings.persistentCache,
        cacheInfoOptions,
      )
    }
  }

  // region list
  /**
   * Returns basic information about all regions.
   * Note that this list is cached.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Region]s or error information.
   */
  public async getRegions(
    request: RegionsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    if (isNotNullOrUndefined(this.regionsCache)) {
      return this.regionsCache.getOrAdd(
        `Regions:${request.language}`,
        (r, rc) => {
          return this.getRegionsHttp(r, rc)
        },
        request,
        requestChain,
      )
    }
    return this.getRegionsHttp(request, requestChain)
  }

  /**
   * Returns basic information about countries.
   * The results may be narrowed by region.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Area]s or error information.
   */
  public async getCountries(
    request: CountriesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    if (isNotNullOrUndefined(this.countriesByRegionCache)) {
      return this.countriesByRegionCache.getOrAdd(
        `CountriesByRegion:${request.language}|${request.regionCode}`,
        (r, rc) => this.getCountriesHttp(r, rc),
        request,
        requestChain,
      )
    }
    return this.getCountriesHttp(request, requestChain)
  }

  /**
   * Returns basic information about administrative areas.
   * The results may be narrowed by country.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Area]s or error information.
   */
  public async getAdminAreas(
    request: AdminAreasRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    if (isNotNullOrUndefined(this.adminAreasByCountryCache)) {
      return this.adminAreasByCountryCache.getOrAdd(
        `AdminAreasByCountry:${request.language}|${request.countryCode}`,
        (r, rc) => this.getAdminAreasHttp(r, rc),
        request,
        requestChain,
      )
    }
    return this.getAdminAreasHttp(request, requestChain)
  }

  /**
   * Returns cities for a specific country.
   * Results may be narrowed by admin area.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Location]s or error information.
   */
  public async getCities(
    request: CitiesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getCitiesUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getCities'),
    )
  }

  /**
   * Returns basic information for the top 50, 100, or 150 cities, worldwide.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Location]s or error information.
   */
  public async getTopCities(
    request: TopCitiesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getTopCitiesUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getTopCities'),
    )
  }
  // endregion list

  // region by key / ip / geoposition
  /**
   * Returns information about a specific location, by location key.
   * You must know the location key to perform this query.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a [Location] object or error information.
   */
  public async getLocationByKey(
    request: LocationByKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    const response = await this.getLocationByKeyHttp(request, requestChain)
    const dataSets: ProductType[] = response.data?.DataSets
    if (isNotNullOrUndefined(response.data) && dataSets?.indexOf(ProductType.Unknown) !== -1) {
      this.logger.warn(
        `Unknown product type for location: ${response.data.Key} (${response.data.EnglishName})`,
      )
    }
    // this will only cache if request.details === true (and if we are caching)
    const cacheResponse = this.ensureLocation(request, response)
    if (isNotNullOrUndefined(cacheResponse) && cacheResponse.hasError) {
      this.logger.warn(`error caching for location key: ${request.locationKey}`)
    }
    return response
  }

  /**
   * Returns information about neighboring cities, by location key.
   * Only 'City' location keys (i.e. not Point of Interest) are accepted.
   * You must know the location key to perform this query.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Location]s or error information.
   */
  public async getCityNeighborsByLocationKey(
    request: CityNeighborsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getCityNeighborsByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getCityNeighborsByLocationKey'),
    )
  }

  /**
   * Returns information about urban neighboring cities, by location key.
   * You must know the location key to perform this query.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Location]s or error information.
   */
  public async getUrbanCityNeighborsByLocationKey(
    request: CityNeighborsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getUrbanCityNeighborsByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getUrbanCityNeighborsByLocationKey'),
    )
  }

  /**
   * Returns the city associated with the specified IP address.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a [Location] object or error information.
   */
  public async getCityByIpAddress(
    request: CityByIpAddressRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getCityByIpAddressUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getCityByIpAddress'),
    )
  }

  /**
   * Returns information about a specific location, by latitude and longitude.
   * Response includes cities.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a [Location] object or error information.
   */
  public async getCityByGeoposition(
    request: CityByGeopositionRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getCityByGeopositionUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getCityByGeoposition'),
    )
  }
  // endregion by key / ip / geoposition

  // region search
  /**
   * Returns information for locations of any type (request: city, points of interest, postal code) and returns results in the specified language.
   * The results must match the given search term exactly and may be narrowed by country or admin area.
   * The search may be performed in the specied language or in all languages, using the [FindLocationsRequest.shouldTranslate] property.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Location]s or error information.
   */
  public async findLocations(
    request: FindLocationsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.findLocationsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'findLocations'),
    )
  }

  /**
   * Searches for the queried city name and returns results in the specified language.
   * The results must match the given search term exactly and may be narrowed by country or admin area.
   * The search may be performed in the specied language or in all languages, using the [FindCitiesRequest.shouldTranslate] property.
   * For example, compare the responses obtained when searching for "Roma" with and without translation.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Location]s or error information.
   */
  public async findCities(
    request: FindCitiesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.findCitiesUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'findCities'),
    )
  }

  /**
   * Searches the queried point of interest name and returns results in the specified language.
   * The results must match the given search term exactly and may be narrowed by country or admin area.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Location]s or error information.
   */
  public async findPointsOfInterest(
    request: FindPointsOfInterestRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.findPointsOfInterestUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'findPointsOfInterest'),
    )
  }

  /**
   * Returns information for an array of postal codes that are an exact match to the search text.
   * The results may be narrowed by country.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Location]s or error information.
   */
  public async findPostalCodeLocations(
    request: FindPostalCodeLocationsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.findPostalCodeLocationsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'findPostalCodeLocations'),
    )
  }

  /**
   * Returns the countries that have a name that exactly matches the given name.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Area]s or error information.
   */
  public async findCountries(
    request: FindCountriesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.findCountriesUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'findCountries'),
    )
  }

  /**
   * Returns all of the admin areas for the given country where the admin area name exactly matches the given name.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [Area]s or error information.
   */
  public async findAdminAreas(
    request: FindAdminAreasRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.findAdminAreasUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'findAdminAreas'),
    )
  }

  /**
   * Gets basic information about cities or points of interest (request: or both) matching an autocomplete of the search text.
   * Results may be narrowed by country code.
   *
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a (request: potentially empty) list of [AutocompleteLocation]s or error information.
   */
  public async findByAutocomplete(
    request: AutocompleteRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.findByAutocompleteUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'findByAutocomplete'),
    )
  }
  // endregion search

  // region cache info
  /**
   * Returns location cache info for a given language and location key.
   * @param language The language.
   * @param locationKey The location key.
   * @param requestChain The request chain.
   * @return A service response containing either a `LocationCacheInfo` object or error information.
   */
  public async getCacheInfo(
    language: string,
    locationKey: string,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<LocationCacheInfo>> {
    // should this take a LocationByKeyRequest (request: or should there be an overload)?
    // providers that use this internally will already have some other request and would need to create a new one
    if (isNotNullOrUndefined(this.baseInfoCache)) {
      return this.getOrAddCacheInfo(language, locationKey, requestChain)
    }

    // we must request details to get all the info we need
    const request = new LocationByKeyRequest(language, locationKey, true)
    const response = await this.getLocationByKey(request, requestChain)

    if (response.hasError || isNullOrUndefined(response)) {
      return response.transformError()
    }

    const cacheInfo = this.toLocationCacheInfo(response.data)
    if (isNullOrUndefined(cacheInfo)) {
      return response.transformError(Error('unable to create cache info'))
    }
    return ServiceResponse.create(cacheInfo, null, response.rawData)
  }

  private async getRegionsHttp(
    request: RegionsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getRegionsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getRegions'),
    )
  }
  private async getCountriesHttp(
    request: CountriesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getCountriesUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getCountries'),
    )
  }
  private async getAdminAreasHttp(
    request: AdminAreasRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getAdminAreasUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getAdminAreas'),
    )
  }
  private async getLocationByKeyHttp(
    request: LocationByKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return await this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getLocationByKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getLocationByKey'),
    )
  }

  private async getOrAddCacheInfo(
    language: string,
    locationKey: string,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<LocationCacheInfo>> {
    // we need to ask for details to get everything we need for the cache,
    // but we probably don't want to modify the original request
    // we still need to request in-language tho, for partnerId
    const request = new LocationByKeyRequest(language, locationKey)
    request.details = true

    // if we don't have a request chain, create one
    // so the call to cache the partner id doesn't make a second api request
    const reqChain = requestChain || new RequestChainImpl()

    if (reqChain.isDryRun) {
      // don't make the (potentially http) request for a location
      // isDryRun means we won't have data anyway
      const baseInfo = new BaseLocationCacheInfo()
      baseInfo.key = locationKey
      const partnerInfo = new LocationPartnerInfo()
      return ServiceResponse.create(new LocationCacheInfo(baseInfo, partnerInfo))
    }

    const baseInfoResponse = await this.baseInfoCache.getOrAdd(
      `LocationCacheInfoBase:${request.locationKey}`,
      (r, rc) => this.getLocationByKeyHttp(r, rc),
      request,
      reqChain,
    )

    if (baseInfoResponse.hasError || isNullOrUndefined(baseInfoResponse.data)) {
      return baseInfoResponse.transformError()
    }

    const partnerIdResponse = await this.partnerIdsCache.getOrAdd(
      `LocationCacheInfoPartners:${request.language}|${request.locationKey}`,
      (r, rc) => this.getLocationByKeyHttp(r, rc),
      request,
      reqChain,
    )

    if (partnerIdResponse.hasError || isNullOrUndefined(baseInfoResponse.data)) {
      return partnerIdResponse.transformError()
    }

    return ServiceResponse.create(
      new LocationCacheInfo(baseInfoResponse.data, partnerIdResponse.data),
      partnerIdResponse.URL,
      partnerIdResponse.rawData,
    )
  }

  private ensureLocation(
    request: LocationByKeyRequest,
    serviceResponse: ServiceResponse<any>,
  ): ServiceResponse<LocationCacheInfo> {
    if (isNullOrUndefined(this.baseInfoCache)) {
      return
    } // undefined
    const baseInfoResponse = this.baseInfoCache.add(
      `LocationCacheInfoBase:${request.locationKey}`,
      serviceResponse,
    )
    if (baseInfoResponse.hasError) {
      return baseInfoResponse.transformError()
    }

    let result: LocationCacheInfo
    if (isNotNullOrUndefined(baseInfoResponse.data)) {
      const partnerIdResponse = this.partnerIdsCache.add(
        `LocationCacheInfoPartners:${request.language}|${request.locationKey}`,
        serviceResponse,
      )
      if (partnerIdResponse.hasError) {
        return partnerIdResponse.transformError()
      }
      if (isNotNullOrUndefined(partnerIdResponse.data)) {
        result = new LocationCacheInfo(baseInfoResponse.data, partnerIdResponse.data)
      }
    }
    return ServiceResponse.create(result, baseInfoResponse.URL, baseInfoResponse.rawData)
  }

  private toBaseLocationCacheInfo(location: any): BaseLocationCacheInfo {
    if (isNullOrBlank(location?.Details?.StationCode as string)) {
      return undefined
    }
    const details = location.Details
    const info = new BaseLocationCacheInfo()
    info.key = location.Key
    info.urlSlug = this.urlName(location)
    info.stationCode = details.StationCode
    info.locationGmtOffset = location.TimeZone?.GmtOffset
    info.climo = details.Climo
    info.countryCode = location.Country?.ID
    info.adminCode = location.AdministrativeArea?.ID
    info.dmaCode = details.DMA?.ID
    info.latitude = location.GeoPosition?.Latitude
    info.longitude = location.GeoPosition?.Longitude
    info.timeZoneCode = location.TimeZone?.Code
    info.dataSets = location.DataSets
    return info
  }
  private toLocationPartnerInfo(location: any): LocationPartnerInfo {
    const partner = new LocationPartnerInfo()
    partner.partnerId = location?.Details?.PartnerID
    return partner
  }

  private toLocationCacheInfo(location: any): LocationCacheInfo {
    const baseInfo = this.toBaseLocationCacheInfo(location)
    if (isNullOrUndefined(baseInfo)) {
      return undefined
    }
    return new LocationCacheInfo(baseInfo, this.toLocationPartnerInfo(location))
  }

  // region isCountry
  private isUnitedStates(location: any): boolean {
    return this.isCountry(location, this.countryCodeUS)
  }
  private isCountry(location: any, countryCode: string): boolean {
    return (
      isNotNullOrBlank(location?.Country?.ID) &&
      location.Country.ID.toLowerCase() === countryCode?.toLowerCase()
    )
  }
  // endregion isCountry

  private urlName(location: any, isLocalized = false): string {
    // start with the base; use localized if we were explicitly told to do so
    // typically we'll just want english names for urls (for now, at least)
    let name =
      isLocalized || isNullOrBlank(location.EnglishName)
        ? location.LocalizedName
        : location.EnglishName
    if (this.isUnitedStates(location)) {
      name += '-' + location.AdministrativeArea?.ID?.toLowerCase()
    }

    return UrlHelper.urlFriendly(name)
  }
  // endregion cache info
}
