// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined } from '../../util/Helpers'
import { BaseApiSettings } from '../settings/BaseApiSettings'
import { SdkHttpSettings } from '../settings/SdkHttpSettings'
import { AccuAxiosRequestConfig } from './AccuAxiosRequestConfig'
import { AccuHttpResponse } from './AccuHttpResponse'
import { HttpRequestOptions } from './HttpRequestOptions'
import { RequestChain } from './RequestChain'

/**
 * Provides methods tom ake http requests.
 */
export class AccuHttpClient {
  private readonly settings: BaseApiSettings

  /**
   * Initializes a new `AccuHttpClient`.
   * @param settings the api settings.
   */
  constructor(settings: BaseApiSettings) {
    this.settings = settings
  }

  /**
   * Get the `AccuHttpResponse` from the specified url.
   * @param url the relative url path from which to request data.
   * @param callerName the method caller name.
   * @returns An object with information about the http response and any error information.
   */
  public getResponse(url: string, options: HttpRequestOptions): Promise<AccuHttpResponse> {
    // This does not need to be marked async because there is no await used in the code.
    const httpSettings = this.getHttpSettings(options)

    // httpSettings.baseAddress will always end in "/", ensured when the value is set.
    const fullUrl = this.isAbsolutUrl(url) ? url : `${httpSettings.getBaseAddress()}${url}`
    const axiosConfig = new AccuAxiosRequestConfig(httpSettings)

    // set headers
    axiosConfig.headers = Object.fromEntries(this.getHeaders(httpSettings, options.requestChain))

    if (isNotNullOrUndefined(options?.authCookie)) {
      axiosConfig.headers['Cookie'] = options.authCookie
      axiosConfig['withCredentials'] = true // apparently this is needed to pass cookies
    }
    const response = new AccuHttpResponse(fullUrl, axiosConfig)
    return response.sendRequest(options)
  }

  private isAbsolutUrl(url: string): boolean {
    return url.startsWith('http:') || url.startsWith('https:')
  }
  private getHttpSettings(options: HttpRequestOptions): SdkHttpSettings {
    if (
      isNotNullOrUndefined(options.callerMethodName) &&
      this.settings.httpSettingsOverrides.has(options.callerMethodName)
    ) {
      const overrides = this.settings.httpSettingsOverrides.get(options.callerMethodName)
      return this.settings.merge(overrides)
    }
    if (
      isNotNullOrUndefined(options.callerServiceName) &&
      this.settings.httpSettingsOverrides.has(options.callerServiceName)
    ) {
      const overrides = this.settings.httpSettingsOverrides.get(options.callerServiceName)
      return this.settings.merge(overrides)
    }
    return this.settings
  }

  private static excludedHeaders = ['User-Agent', 'Cookie']
  private getHeaders(
    httpSettings: SdkHttpSettings,
    requestChain?: RequestChain,
  ): Map<string, string> {
    const headers: Map<string, string> = new Map()
    if (isNotNullOrUndefined(httpSettings?.headers)) {
      // set from settings
      httpSettings.headers.forEach((val, key) => {
        if (!AccuHttpClient.excludedHeaders.includes(key)) headers.set(key, val)
      })
    }
    if (isNotNullOrUndefined(requestChain?.headers)) {
      // (re)set from requestChain
      requestChain.headers.forEach((val, key) => {
        if (!AccuHttpClient.excludedHeaders.includes(key)) headers.set(key, val)
      })
    }
    return headers
  }
}
