/**
 * Types of pressure tendency.
 */
export enum PressureTendencyCode {
  /**
   * Unknown.
   */
  Unknown = 'U',

  /**
   * Rising.
   */
  Rising = 'R',

  /**
   * Falling.
   */
  Falling = 'F',

  /**
   * Steady.
   */
  Steady = 'S',
}
