/**
 * Represents a subset of api unit types dealing with pressure.
 * These values map to api unit type IDs.
 * @see <a href="https://developer.accuweather.com/unit-types">here</a> for more information.
 */
export enum PressureUnits {
  /**
   * HectoPascals
   */
  HectoPascals = 11,

  /**
   * Inches of mercury
   */
  InchesOfMercury = 12,

  /**
   * Kilopascals
   */
  KiloPascals = 13,

  /**
   * Millibars
   */
  Millibars = 14,

  /**
   * Millimeters of mercury
   */
  MillimetersOfMercury = 15,

  /**
   * Pounds per square inch
   */
  PoundsPerSquareInch = 16,
}

/**
 * Gets whether the [PressureUnits] is metric.
 */
export function isMetricPressure(units: PressureUnits): boolean {
  switch (units) {
    case PressureUnits.InchesOfMercury:
    case PressureUnits.PoundsPerSquareInch:
      return false
    default:
      return true
  }
}
