/**
 * The different sorting types.
 */
export enum SortType {
  /**
   * newest
   */
  Newest = 'newest',

  /**
   * oldest
   */
  Oldest = 'oldest',

  /**
   * relevance
   */
  Relevance = 'relevance',
}
