// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { ApiTokens } from '../core/api/ApiTokens'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { GeopositionValidRule } from '../core/validation/GeoPositionValidRule'
import { LanguageValidRule } from '../core/validation/LanguageValidRule'
import { NumberBetweenRangeRule } from '../core/validation/NumberBetweenRangeRule'
import { ObjectNotNullOrUndefinedRule } from '../core/validation/ObjectNotNullOrUndefinedRule'
import { StringNotNullOrWhiteSpaceRule } from '../core/validation/StringNotNullOrWhiteSpaceRule'
import { Validator } from '../core/validation/Validator'
import { isNotNullOrUndefined } from '../util/Helpers'
import { MapRouteResolver } from './MapRouteResolver'
import { MapServiceImpl } from './MapServiceImpl'
import { MapType } from './MapType'
import { ProductValues } from './ProductValues'
import { AccuCastRequest } from './requests/AccuCastRequest'
import { ActiveProductsRequest } from './requests/ActiveProductsRequest'
import { AvailableProductsRequest } from './requests/AvailableProductsRequest'
import { BlankTilesRequest } from './requests/BlankTilesRequest'
import { ColorTable } from './requests/ColorTable'
import { FramesByBoundingBoxRequest } from './requests/FramesByBoundingBoxRequest'
import { FramesByProductsRequest } from './requests/FramesByProductsRequest'
import { MapTokens } from './requests/MapTokens'
import { ProductsByBoundingBoxRequest } from './requests/ProductsByBoundingBoxRequest'
import { RadarCoverageRequest } from './requests/RadarCoverageRequest'
import { RadarProductsMetaRequest } from './requests/RadarProductsMetaRequest'
import { StaticMapRequest, StaticMapTheme } from './requests/StaticMapRequest'
import { ThunderstormAlertsRequest } from './requests/ThunderstormAlertsRequest'
import { TileJsonRequest } from './requests/TileJsonRequest'
import { TilesByQuadkeyRequest } from './requests/TilesByQuadkeyRequest'
import { TilesByZxyRequest } from './requests/TilesByZxyRequest'
import { TilesByZyxRequest } from './requests/TilesByZyxRequest'
import { WorldWindRequest } from './requests/WorldWindRequest'

/**
 * Resolves routes for [MapService] methods.
 */
export class MapRouteResolverImpl implements MapRouteResolver {
  private static mapTypeValidForRequest(mapType: MapType, isStatic = false): Error {
    if (isStatic) {
      if (mapType === MapType.Radar || mapType === MapType.Satellite) {
        return undefined
      }
    } else {
      switch (mapType) {
        case MapType.Tropical:
        case MapType.WatchesAndWarnings:
          break
        default:
          return undefined
      }
    }
    return Error(`${mapType} is not valid for this request.`)
  }
  protected readonly routeResolver: ApiRouteResolver
  private readonly baseAddress: string

  // region routes
  private readonly blankTiles = 'BlankTiles'
  private readonly tilesByQuadkey = 'TilesByQuadkey'
  private readonly tropicalTiles = 'TropicalTiles'
  private readonly tilesByZyx = 'TilesByZyx'
  private readonly tilesByZxy = 'TilesByZxy'
  private readonly productsByBoundingBox = 'ProductsByBoundingBox'
  private readonly activeProducts = 'ActiveProducts'
  private readonly availableProducts = 'AvailableProducts'
  private readonly frames = 'Frames'
  private readonly framesByBoundingBox = 'FramesByBoundingBox'
  private readonly framesByProducts = 'FramesByProducts'
  private readonly radarCoverage = 'RadarCoverage'
  private readonly radarProductsMeta = 'RadarProductsMeta'
  private readonly warnings = 'Warnings'
  private readonly thunderstormAlerts = 'ThunderstormAlerts'
  private readonly snowfallAccumulationTileJson = 'SnowfallAccumulationTileJson'
  private readonly lightningTileJson = 'LightningTileJson'
  private readonly accuCast = 'AccuCast'
  private readonly staticMaps = 'StaticMaps'
  private readonly worldWind = 'WorldWind'

  private readonly radarPath = 'radar/globalSIR'
  private readonly satellitePath = 'satellite/globalIR'
  private readonly globalColorSatellitePath = 'satellite/globalColor'
  private readonly futurePath = 'radar/futureSIR'
  private readonly modelsUfdbPath = 'models/ufdb/precip'
  private readonly past24HourSnowfallPath = 'current/snowfall/accumulated'
  private readonly modelsGfsPath = 'models/gfs'
  private readonly globalVisPath = 'satellite/globalVIS'
  private readonly globalWaterVaporPath = 'satellite/globalWV'
  private readonly airQualityCurrentPath = 'current/airquality/PlumeIndex'
  private readonly staticRadarPath = 'radar/static/globalSIR'
  private readonly staticSatellitePath = 'satellite/static/globalIR'

  private readonly routeTemplates: Map<string, string> = new Map([
    // ApiHttpService is used for AccuCast, even though it isn't api.accuwx.com
    // (httpClient ignores base address if the url is absolute)
    [
      this.accuCast,
      'https://observations.skynalysis.com/2/accuobs/obs_map.json?box={toplat},{leftlon},{bottomlat},{rightlon}&limit={limit}',
    ],
    // tiles
    // the satellite versions of zyx/zxy don't take `&colortable={colorTable}`
    // we can pass them anyway, and it should not matter, but if it does we can define the templates separately
    [
      this.blankTiles,
      '{baseurl}maps/v1/{mapType}/blank.png?apikey={apikey}&display_mode={displayMode}',
    ],
    [
      this.tilesByQuadkey,
      '{baseurl}maps/v1/{mapType}/quadkey/{quadkey}.png?apikey={apikey}&colortable={colorTable}&display_mode={displayMode}',
    ],
    [
      this.tropicalTiles,
      '{baseurl}maps/v1/tropical/hurricane/zxy/{z}/{x}/{y}.png?apikey={apikey}&categories={categories}&eventKey={eventKey}&basin_ids={basinIds}',
    ],
    [
      this.warnings,
      '{baseurl}maps/v1/alerts/globalWarnings/zxy/{z}/{x}/{y}.png?apikey={apikey}&borders={showBorders}',
    ],
    [
      this.tilesByZyx,
      '{baseurl}maps/v1/{mapType}/zxy/tile/{z}/{x}/{y}.png?apikey={apikey}&colortable={colorTable}&display_mode={displayMode}&categories={categories}',
    ],
    [
      this.tilesByZxy,
      '{baseurl}maps/v1/{mapType}/zxy/{date}/{z}/{x}/{y}.png?apikey={apikey}&colortable={colorTable}&display_mode={displayMode}&display_products={products}',
    ],
    // products
    [
      this.productsByBoundingBox,
      'maps/v1/{mapType}/preferred_tile_products?apikey={apikey}&x1={x1}&y1={y1}&x2={x2}&y2={y2}&z={zoom}&attribute={attribute}',
    ],
    [
      this.activeProducts,
      'maps/v1/{mapType}/preferred_box_products?apikey={apikey}&toplat={toplat}&bottomlat={bottomlat}&rightlon={rightlon}&leftlon={leftlon}&zoom={zoom}&attribute={attribute}',
    ],
    [
      this.availableProducts,
      'maps/v1/{mapType}/request_products?apikey={apikey}&locations={includeBoundingBox}',
    ],
    // frames
    [
      this.frames,
      'maps/v1/{mapType}/preferred_product_frames?apikey={apikey}&attribute={attribute}&cbt={cbt}',
    ],
    [
      this.framesByBoundingBox,
      'maps/v1/{mapType}/preferred_product_frames?apikey={apikey}&toplat={toplat}&bottomlat={bottomlat}&rightlon={rightlon}&leftlon={leftlon}&zoom={zoom}&attribute={attribute}&cbt={cbt}',
    ],
    [
      this.framesByProducts,
      'maps/v1/{mapType}/preferred_product_frames?apikey={apikey}&products={products}&attribute={attribute}&cbt={cbt}',
    ],
    // coverage (radar only)
    [this.radarCoverage, 'maps/v1/radar/globalSIR/global_coverage?apikey={apikey}'],
    [
      this.radarProductsMeta,
      'maps/v1/radar/globalSIR/request_products?apikey={apikey}&locations={includeBoundingBox}',
    ],
    // overlay
    [
      this.thunderstormAlerts,
      'enhancedWeather/v1/alerts/thunderstorms?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.snowfallAccumulationTileJson,
      'maps/v1/current/snowfall/accumulated/totals/vector/data/tiles.json?apikey={apikey}',
    ],
    [this.lightningTileJson, 'maps/v1/lightning/vector/data/tiles.json?apikey={apikey}'],
    // static
    [
      this.staticMaps,
      '{baseurl}maps/v1/{mapType}/tile?apikey={apikey}&language={language}&imgwidth={width}&imgheight={height}&base_data={staticType}&lat={latitude}&lon={longitude}&zoom={zoom}',
    ],
    [
      this.worldWind,
      '{baseurl}maps/v1/models/gfs/custom?apikey={apikey}&topmerc={topmerc}&bottommerc={bottommerc}&rightmerc={rightmerc}&leftmerc={leftmerc}&imgheight={height}&imgwidth={width}&display_mode={displayMode}&blend=1&display_products=26-1020&frametime={frame}',
    ],
  ])
  // endregion routes

  // region validators
  private readonly accuCastRequestValidator: AsyncValidator<AccuCastRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
  ])

  private readonly thunderstormAlertsRequestValidator: AsyncValidator<ThunderstormAlertsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
    ])

  private readonly warningsRequestValidator: Validator<TilesByZxyRequest> = new Validator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
  ])

  private readonly radarProductsMetaRequestValidator: AsyncValidator<RadarProductsMetaRequest> =
    new AsyncValidator([(r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request')])

  private readonly radarCoverageRequestValidator: Validator<RadarCoverageRequest> = new Validator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
  ])

  private readonly framesByProductsRequestValidator: AsyncValidator<FramesByProductsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => MapRouteResolverImpl.mapTypeValidForRequest(r.mapType),
    ])

  private readonly framesByBoundingBoxRequestValidator: AsyncValidator<FramesByBoundingBoxRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => MapRouteResolverImpl.mapTypeValidForRequest(r.mapType),
    ])

  private readonly availableProductsRequestValidator: AsyncValidator<AvailableProductsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => MapRouteResolverImpl.mapTypeValidForRequest(r.mapType),
    ])

  private readonly activeProductsRequestValidator: AsyncValidator<ActiveProductsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => MapRouteResolverImpl.mapTypeValidForRequest(r.mapType),
    ])

  private readonly productsByBoundingBoxRequestValidator: AsyncValidator<ProductsByBoundingBoxRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => MapRouteResolverImpl.mapTypeValidForRequest(r.mapType),
    ])

  private readonly tilesByZxyRequestValidator: Validator<TilesByZxyRequest> = new Validator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.date, 'date'),
  ])

  private readonly tilesByZyxRequestValidator: Validator<TilesByZyxRequest> = new Validator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => MapRouteResolverImpl.mapTypeValidForRequest(r.mapType),
  ])

  private readonly tropicalTilesRequestValidator: Validator<TilesByZxyRequest> = new Validator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
  ])

  private readonly tilesByQuadkeyRequestValidator: Validator<TilesByQuadkeyRequest> = new Validator(
    [
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => MapRouteResolverImpl.mapTypeValidForRequest(r.mapType),
    ],
  )

  private readonly blankTilesRequestValidator: Validator<BlankTilesRequest> = new Validator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => MapRouteResolverImpl.mapTypeValidForRequest(r.mapType),
  ])

  private readonly staticMapRequestValidator: Validator<StaticMapRequest> = new Validator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => LanguageValidRule.checkRule(r.language),
    (r) => GeopositionValidRule.checkRule(r.latitude, r.longitude),
    (r) => NumberBetweenRangeRule.checkRule(r.width, 1, 1024, 'width'),
    (r) => NumberBetweenRangeRule.checkRule(r.height, 1, 1024, 'height'),
    (r) => NumberBetweenRangeRule.checkRule(r.zoomLevel, 2, 20, 'zoom'),
    (r) => MapRouteResolverImpl.mapTypeValidForRequest(r.mapType, true),
  ])

  private readonly worldWindRequestValidator: Validator<WorldWindRequest> = new Validator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => NumberBetweenRangeRule.checkRule(r.width, 1, 1024, 'width'),
    (r) => NumberBetweenRangeRule.checkRule(r.height, 1, 1024, 'height'),
    (r) => NumberBetweenRangeRule.checkRule(r.bottommerc, -3.1415, 0, 'bottommerc'),
    (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.frame, 'frame'),
    (r) => NumberBetweenRangeRule.checkRule(r.leftmerc, -3.1415, 0, 'leftmerc'),
    (r) => NumberBetweenRangeRule.checkRule(r.rightmerc, 0, 3.1415, 'rightmerc'),
    (r) => NumberBetweenRangeRule.checkRule(r.topmerc, 0, 3.1415, 'topmerc'),
  ])

  private readonly tileJsonRequestValidator: AsyncValidator<TileJsonRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
  ])
  // endregion accucast

  // region static
  private readonly staticTypeToken = 'staticType'
  private readonly themeToken = 'theme'
  private readonly latToken = 'latitude'
  private readonly lonToken = 'longitude'

  private readonly darkTheme = 'dark'

  private readonly radar = 'radar'
  private readonly satellite = 'satellite'
  // endregion validators

  constructor(sdkSettings: SdkSettings) {
    const overrides = sdkSettings.apiSettings.httpSettingsOverrides.get(
      MapServiceImpl.mapServiceName,
    )
    this.baseAddress = isNotNullOrUndefined(overrides)
      ? sdkSettings.apiSettings.merge(overrides).getBaseAddress()
      : sdkSettings.apiSettings.getBaseAddress()
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, sdkSettings)
  }

  // region tiles
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public getTilesByZxyUrl(
    request: TilesByZxyRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    switch (request?.mapType) {
      case MapType.Tropical:
        return this.routeResolver.resolve(
          this.tropicalTiles,
          request,
          this.tropicalTilesRequestValidator,
          requestChain,
          new Map<string, any>([
            [MapTokens.baseUrl, this.baseAddress],
            [MapTokens.categories, request?.categories?.join() ?? ''],
            [MapTokens.eventKey, request?.eventKey],
            [MapTokens.basinIds, request?.basinIds?.join() ?? ''],
            [ApiTokens.apiKey, request?.apiKey],
          ]),
        )
      case MapType.WatchesAndWarnings:
        return this.routeResolver.resolve(
          this.warnings,
          request,
          this.warningsRequestValidator,
          requestChain,
          new Map<string, any>([
            [MapTokens.baseUrl, this.baseAddress],
            [MapTokens.showBorders, request?.showBorders],
            [ApiTokens.apiKey, request?.apiKey],
          ]),
        )
      default:
        return this.routeResolver.resolve(
          this.tilesByZxy,
          request,
          this.tilesByZxyRequestValidator,
          requestChain,
          new Map<string, any>([
            [MapTokens.baseUrl, this.baseAddress],
            [MapTokens.mapType, this.mapType(request?.mapType)],
            [MapTokens.date, request?.date],
            [MapTokens.colorTable, this.colorTable(request?.showColorTable)],
            [MapTokens.displayMode, request?.displayMode],
            [MapTokens.products, this.productList(request?.products)],
            [ApiTokens.apiKey, request?.apiKey],
          ]),
        )
    }
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public getTilesByZyxUrl(
    request: TilesByZyxRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.resolve(
      this.tilesByZyx,
      request,
      this.tilesByZyxRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.baseUrl, this.baseAddress],
        [MapTokens.mapType, this.mapType(request?.mapType)],
        [MapTokens.colorTable, this.colorTable(request?.showColorTable)],
        [MapTokens.displayMode, request?.displayMode],
        // [MapTokens.categories, request ?.categories]
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public getTilesByQuadkeyUrl(
    request: TilesByQuadkeyRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.resolve(
      this.tilesByQuadkey,
      request,
      this.tilesByQuadkeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.baseUrl, this.baseAddress],
        [MapTokens.mapType, this.mapType(request?.mapType)],
        [MapTokens.quadkey, request?.quadkey],
        [MapTokens.colorTable, this.colorTable(request?.showColorTable)],
        [MapTokens.displayMode, request?.displayMode],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public getBlankTilesUrl(
    request: BlankTilesRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.resolve(
      this.blankTiles,
      request,
      this.blankTilesRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.baseUrl, this.baseAddress],
        [MapTokens.mapType, this.mapType(request?.mapType)],
        [MapTokens.displayMode, request?.displayMode],
      ]),
    )
  }
  // endregion tiles

  // region products
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getAvailableProductsUrl(
    request: AvailableProductsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.availableProducts,
      request,
      this.availableProductsRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.mapType, this.mapType(request?.mapType)],
        [MapTokens.includeBoundingBox, request?.includeBoundingBox],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getActiveProductsUrl(
    request: ActiveProductsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.activeProducts,
      request,
      this.activeProductsRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.mapType, this.mapType(request?.mapType)],
        [MapTokens.northernLatitude, request?.northernLatitude],
        [MapTokens.southernLatitude, request?.southernLatitude],
        [MapTokens.easternLongitude, request?.easternLongitude],
        [MapTokens.westernLongitude, request?.westernLongitude],
        [MapTokens.zoom, request?.zoomLevel],
        [MapTokens.includeAttribution, request?.includeAttribution],
        [MapTokens.cacheBuster, this.getCacheBuster(request?.refreshCache)],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getProductsByBoundingBoxUrl(
    request: ProductsByBoundingBoxRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.productsByBoundingBox,
      request,
      this.productsByBoundingBoxRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.mapType, this.mapType(request?.mapType)],
        [MapTokens.x1, request?.upperLeft[0]],
        [MapTokens.y1, request?.upperLeft[1]],
        [MapTokens.x2, request?.lowerRight[0]],
        [MapTokens.y2, request?.lowerRight[1]],
        [MapTokens.zoom, request?.zoomLevel],
        [MapTokens.includeAttribution, request?.includeAttribution],
        [MapTokens.cacheBuster, this.getCacheBuster(request?.refreshCache)],
      ]),
    )
  }
  // endregion products

  // region frames
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getFramesByProductsUrl(
    request: FramesByProductsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    const routeName = request.products.length === 0 ? this.frames : this.framesByProducts
    return await this.routeResolver.resolveAsync(
      routeName,
      request,
      this.framesByProductsRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.mapType, this.mapType(request?.mapType)],
        [MapTokens.products, this.productList(request?.products)],
        [MapTokens.includeAttribution, request?.includeAttribution],
        [MapTokens.cacheBuster, this.getCacheBuster(request?.refreshCache)],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getFramesByBoundingBoxUrl(
    request: FramesByBoundingBoxRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.framesByBoundingBox,
      request,
      this.framesByBoundingBoxRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.mapType, this.mapType(request?.mapType)],
        [MapTokens.northernLatitude, request?.northernLatitude],
        [MapTokens.southernLatitude, request?.southernLatitude],
        [MapTokens.easternLongitude, request?.easternLongitude],
        [MapTokens.westernLongitude, request?.westernLongitude],
        [MapTokens.zoom, request?.zoomLevel],
        [MapTokens.includeAttribution, request?.includeAttribution],
        [MapTokens.cacheBuster, this.getCacheBuster(request?.refreshCache)],
      ]),
    )
  }
  // endregion frames

  // region global coverage (radar only)
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getProductsMetaUrl(
    request: RadarProductsMetaRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.radarProductsMeta,
      request,
      this.radarProductsMetaRequestValidator,
      requestChain,
      new Map<string, any>([[MapTokens.includeBoundingBox, request?.includeBoundingBox]]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public getRadarCoverageUrl(
    request: RadarCoverageRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.resolve(
      this.radarCoverage,
      request,
      this.radarCoverageRequestValidator,
      requestChain,
      new Map<string, any>([]),
    )
  }
  // endregion global coverage (radar only)

  // region overlay
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getThunderstormAlertsUrl(
    request: ThunderstormAlertsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.thunderstormAlerts,
      request,
      this.thunderstormAlertsRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getSnowfallTileJsonUrl(
    request: TileJsonRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.snowfallAccumulationTileJson,
      request,
      this.tileJsonRequestValidator,
      requestChain,
      new Map<string, any>(),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getLightningTileJsonUrl(
    request: TileJsonRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.lightningTileJson,
      request,
      this.tileJsonRequestValidator,
      requestChain,
      new Map<string, any>(),
    )
  }
  // endregion overlay

  // region accucast
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getAccuCastUrl(
    request: AccuCastRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.accuCast,
      request,
      this.accuCastRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.northernLatitude, request?.northernLatitude],
        [MapTokens.southernLatitude, request?.southernLatitude],
        [MapTokens.easternLongitude, request?.easternLongitude],
        [MapTokens.westernLongitude, request?.westernLongitude],
        [MapTokens.limit, request?.limit],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public getStaticMapUrl(
    request: StaticMapRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.resolve(
      this.staticMaps,
      request,
      this.staticMapRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.baseUrl, this.baseAddress],
        [MapTokens.mapType, this.mapType(request?.mapType, true)],
        [ApiTokens.language, request?.language],
        [this.latToken, request?.latitude],
        [this.lonToken, request?.longitude],
        [MapTokens.width, request?.width],
        [MapTokens.height, request?.height],
        [MapTokens.zoom, request?.zoomLevel],
        [this.staticTypeToken, this.getStaticMapBaseData(request?.mapType)],
        [this.themeToken, this.getStaticMapTheme(request?.theme)],
      ]),
    )
  }
  public getStaticMapTheme(theme: StaticMapTheme): string {
    if (isNotNullOrUndefined(theme) && theme === StaticMapTheme.Dark) {
      return this.darkTheme
    }
  }
  public getStaticMapBaseData(mapType: MapType): string {
    // for now, these are the only types supported for static maps
    if (isNotNullOrUndefined(mapType) && mapType === MapType.Satellite) {
      return this.satellite
    }
    return this.radar
  }
  // endregion static

  // region internal
  private colorTable(showColorTable = false): string {
    return showColorTable ? ColorTable.On : ColorTable.Off
  }

  private mapType(mapType: MapType, isStatic = false): string {
    switch (mapType) {
      case MapType.StandardSatellite:
        return this.satellitePath
      case MapType.Satellite:
        return isStatic ? this.staticSatellitePath : this.satellitePath
      case MapType.GlobalColorSatellite: // fallthrough
      case MapType.EnhancedGlobalColorSatellite:
        return this.globalColorSatellitePath
      case MapType.FutureRadar:
        return this.futurePath
      case MapType.Precipitation: // fallthrough
      case MapType.TwentyFourHourRainfallForecast: // fallthrough
      case MapType.TwentyFourHourIceForecast: // fallthrough
      case MapType.TwentyFourHourSnowfallForecast:
        return this.modelsUfdbPath
      case MapType.PastTwentyFourHourSnowfall:
        return this.past24HourSnowfallPath
      case MapType.TemperatureContour:
        return this.modelsGfsPath
      case MapType.WorldWind:
        return this.modelsGfsPath
      case MapType.VisibleSatellite:
        return this.globalVisPath
      case MapType.WaterVapor:
        return this.globalWaterVaporPath
      case MapType.AirQualityCurrent:
        return this.airQualityCurrentPath
      case MapType.Radar:
      default:
        return isStatic ? this.staticRadarPath : this.radarPath
    }
  }

  private productList(products: ProductValues[] = []): string {
    const s: string[] = []
    for (const product of products) {
      if (isNotNullOrUndefined(product)) {
        s.push(this.productText(product))
      }
    }
    return s.join()
  }

  private productText(product: ProductValues): string {
    return `${product.major}-${product.minor}`
  }

  private getCacheBuster(refreshCache: boolean): string {
    if (!refreshCache) {
      return ''
    }
    const d = new Date()
    return `${d.getFullYear()}${
      d.getMonth() + 1
    }${d.getDate()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}`
  }
  // endregion internal

  /**
   * Returns the appropriate api url for the method/request.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either a URL or error information.
   */
  public getWorldWindUrl(
    request: WorldWindRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.resolve(
      this.worldWind,
      request,
      this.worldWindRequestValidator,
      requestChain,
      new Map<string, any>([
        [MapTokens.baseUrl, this.baseAddress],
        [MapTokens.width, request?.width],
        [MapTokens.height, request?.height],
        [MapTokens.bottommerc, request?.bottommerc],
        [MapTokens.displayMode, request?.displayMode],
        [MapTokens.frame, request?.frame],
        [MapTokens.height, request?.height],
        [MapTokens.leftmerc, request?.leftmerc],
        [MapTokens.rightmerc, request?.rightmerc],
        [MapTokens.topmerc, request?.topmerc],
        [MapTokens.width, request?.width],
        [ApiTokens.apiKey, request?.apiKey],
      ]),
    )
  }
}
