/**
 * The different types of climo response data.
 */
export enum ClimoType {
  /**
   * Actuals.
   */
  Actuals = 'Actuals',

  /**
   * Normals.
   */
  Normals = 'Normals',

  /**
   * Records.
   */
  Records = 'Records',

  /**
   * Summary.  Includes all the rest.
   */
  Summary = 'Summary',
}
