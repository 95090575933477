// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiHttpService } from '../core/api/ApiHttpService'
import { CacheOptions } from '../core/cache/CacheOptions'
import { ServiceResponseMapCache } from '../core/cache/ServiceResponseMapCache'
import { ServiceResponseTransformCache } from '../core/cache/ServiceResponseTransformCache'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { isNotNullOrEmpty, isNotNullOrUndefined, isNullOrUndefined } from '../util/Helpers'
import { ContextualRouteResolver } from './ContextualRouteResolver'
import { ContextualService } from './ContextualService'
import { IndexType } from './IndexType'
import { CurrentAirQualityByLocationKeyRequest } from './requests/CurrentAirQualityByLocationKeyRequest'
import { DailyAirQualityByLocationKeyRequest } from './requests/DailyAirQualityByLocationKeyRequest'
import { GroupIndexValuesByLocationKeyRequest } from './requests/GroupIndexValuesByLocationKeyRequest'
import { HourlyAirQualityByLocationKeyRequest } from './requests/HourlyAirQualityByLocationKeyRequest'
import { IndexGroupsRequest } from './requests/IndexGroupsRequest'
import { IndexInfoByTypeRequest } from './requests/IndexInfoByTypeRequest'
import { IndexTypeValidRule } from './requests/IndexTypeValidRule'
import { IndexValuesByLocationKeyRequest } from './requests/IndexValuesByLocationKeyRequest'
import { IndicesInfoByGroupRequest } from './requests/IndicesInfoByGroupRequest'
import { IndicesInfoRequest } from './requests/IndicesInfoRequest'
import { LocalDailyIndexValuesByLocationKeyRequest } from './requests/LocalDailyIndexValuesByLocationKeyRequest'
import { LocalDailyIndicesValuesByLocationKeyRequest } from './requests/LocalDailyIndicesValuesByLocationKeyRequest'
import { LocalHourlyIndexValuesByLocationKeyRequest } from './requests/LocalHourlyIndexValuesByLocationKeyRequest'
import { LocalHourlyIndicesValuesByLocationKeyRequest } from './requests/LocalHourlyIndicesValuesByLocationKeyRequest'

/**
 * Provides methods to get index metadata and daily values and air quality for a specific location.
 * Index and Air Quality availability varies by location.
 * See http://apidev.accuweather.com/developers/airQuality for more information on international air quality.
 * See http://apidev.accuweather.com/developers/indices for more information on indices.
 */
export class ContextualServiceImpl implements ContextualService {
  protected readonly serviceName = 'ContextualService'
  protected readonly settings: SdkSettings
  protected readonly httpService: ApiHttpService
  protected readonly routeResolver: ContextualRouteResolver

  private readonly groupsCache: ServiceResponseMapCache<any, any>
  private readonly infoCache: ServiceResponseMapCache<any, any>
  private readonly infoByGroupCache: ServiceResponseTransformCache<any, any>

  constructor(
    sdkSettings: SdkSettings,
    httpService: ApiHttpService,
    routeResolver: ContextualRouteResolver,
  ) {
    this.settings = sdkSettings
    this.httpService = httpService
    this.routeResolver = routeResolver

    if (sdkSettings.cacheSettings.shouldCacheIndexMeta) {
      const cacheOptions = new CacheOptions(sdkSettings.cacheSettings.indexMetaExpiry)
      // cache at the top-level by language, then make a dictionary using indexType as key
      this.infoCache = new ServiceResponseMapCache(
        (i) => i.ID,
        sdkSettings.persistentCache,
        cacheOptions,
      )
      // cache at the top-level by language, then make a dictionary using indexGroupType as key
      this.groupsCache = new ServiceResponseMapCache(
        (g) => g.ID,
        sdkSettings.persistentCache,
        cacheOptions,
      )
      // cache just the indexTypes by group (language-agnostic)
      // we can use this.info to get the actual language-specific indices using the indexTypes cached here
      this.infoByGroupCache = new ServiceResponseTransformCache(
        (list) => {
          const types: IndexType[] = []
          for (const type of list) {
            types.push(type.ID)
          }
          return types
        },
        sdkSettings.persistentCache,
        cacheOptions,
      )
    }
  }

  // region index meta
  /**
   * Gets metadata for the requested index.
   * @param request The request.
   * @return A service response containing either a IndexInfo or error information.
   */
  public async getIndexInfoByType(
    request: IndexInfoByTypeRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNullOrUndefined(this.infoCache) || isNotNullOrUndefined(request)) {
      return await this.getIndexInfoByTypeHttp(request, requestChain)
    }

    const error = IndexTypeValidRule.checkRule(request.indexType)
    if (isNotNullOrUndefined(error)) {
      return ServiceResponse.fromError(error)
    }

    const key = this.infoKey(request.language)
    const infoReq = new IndicesInfoRequest(request.language)
    return await this.infoCache.getOrAdd(
      key,
      (r, rc) => this.getIndicesInfoHttp(r, rc),
      infoReq,
      request.indexType,
      requestChain,
    )
  }

  /**
   * Gets metadata for all indices in a specified group.
   * @param request The request.
   * @return A service response containing either a list of [IndexInfo]s or error information.
   */
  public async getIndicesInfoByGroup(
    request: IndicesInfoByGroupRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNullOrUndefined(this.infoByGroupCache) || isNullOrUndefined(request)) {
      return await this.getIndicesInfoByGroupHttp(request, requestChain)
    }

    // get info for ALL indices -- we don't need these results directly
    // we are just forcing the cache to be created for this language (if it wasn't already)
    const infoReq = new IndicesInfoRequest(request?.language)
    const allIndicesResponse = await this.getIndicesInfo(infoReq, requestChain)
    if (isNullOrUndefined(allIndicesResponse) || allIndicesResponse.hasError) {
      return allIndicesResponse
    }

    // get info for this group -- this returns a tuple including
    // 	- the transformed data (just the index types), and
    // 	- the original api response before being transformed (the full index info)
    // the original data will ONLY be included on the first request
    // once it's cached, we'll only get the transformed data

    const indexTypesResponse = await this.infoByGroupCache.getOrAddWithOriginal(
      `IndicesByGroup:${request?.indexGroupType}`,
      (r, rc) => this.getIndicesInfoByGroupHttp(r, rc),
      request,
      requestChain,
    )
    if (isNullOrUndefined(indexTypesResponse) || indexTypesResponse.hasError) {
      return indexTypesResponse.transformError()
    }

    if (isNotNullOrEmpty(indexTypesResponse.data[1])) {
      // this was the first time we called for the group info
      // just return the group info without iterating/requesting by type
      return ServiceResponse.create(
        indexTypesResponse.data[1],
        indexTypesResponse.URL,
        indexTypesResponse.rawData,
        indexTypesResponse.template,
      )
    }

    // if we've made it this far, we have to construct a list from cache- but we should have everything we need
    // for each indexType in this group, get the cached info by language
    const tasks = this.getIndexInfoForGroupTasks(indexTypesResponse.data[0], request, requestChain)
    const responses = await Promise.all(tasks)

    const info = []
    let url = ''
    let rawData = ''
    for (const response of responses) {
      if (response.hasError) {
        return response.transformError()
      }
      if (url === '' || rawData === '') {
        url = response.URL
        rawData = response.rawData
      }
      info.push(response.data)
    }
    //
    return ServiceResponse.create(info, url, rawData, indexTypesResponse.template)
  }

  /**
   * Gets metadata for a specific index type.
   * @param request The request.
   * @return A service response containing either a list of [IndexInfo]s or error information.
   */
  public async getIndicesInfo(
    request: IndicesInfoRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(this.infoCache)) {
      const key = this.infoKey(request?.language)
      return await this.infoCache.getArrayOrAdd(
        key,
        (r, rc) => this.getIndicesInfoHttp(r, rc),
        request,
        requestChain,
      )
    }
    return await this.getIndicesInfoHttp(request, requestChain)
  }

  /**
   * Gets metadata for all index groups.
   * @param request The request.
   * @return A service response containing either a list of [IndexGroupInfo]s or error information.
   */
  public async getGroups(
    request: IndexGroupsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(this.groupsCache)) {
      return this.groupsCache.getArrayOrAdd(
        `IndexGroupsInfo:${request?.language}`,
        (r, rc) => this.getGroupsHttp(r, rc),
        request,
        requestChain,
      )
    }
    return await this.getGroupsHttp(request, requestChain)
  }
  // endregion index meta

  // region index data
  /**
   * Gets local hourly index values for a specific index for the given location and date range.
   * @param request The request.
   * @return A service response containing either a list of [IndexData]s or error information.
   */
  public async getLocalHourlyIndexValuesByLocationKey(
    request: LocalHourlyIndexValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getLocalHourlyIndexValuesByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getLocalHourlyIndexValuesByLocationKey',
      ),
    )
  }

  /**
   * Gets all local hourly index values for the given location and date range.
   * @param request The request.
   * @return A service response containing either a list of [IndexData]s or error information.
   */
  public async getLocalHourlyIndicesValuesByLocationKey(
    request: LocalHourlyIndicesValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getLocalHourlyIndicesValuesByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getLocalHourlyIndicesValuesByLocationKey',
      ),
    )
  }

  /**
   * Gets local daily index values for a specific index for the given location and date range.
   * @param request The request.
   * @return A service response containing either a list of [IndexData]s or error information.
   */
  public async getLocalDailyIndexValuesByLocationKey(
    request: LocalDailyIndexValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getLocalDailyIndexValuesByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getLocalDailyIndexValuesByLocationKey',
      ),
    )
  }

  /**
   * Gets all local daily index values for the given location and date range.
   * @param request The request.
   * @return A service response containing either a list of [IndexData]s or error information.
   */
  public async getLocalDailyIndicesValuesByLocationKey(
    request: LocalDailyIndicesValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getLocalDailyIndicesValuesByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getLocalDailyIndicesValuesByLocationKey',
      ),
    )
  }

  /**
   * Gets all the index values for a specific [IndexGroupType] for the given location and date range. Available for 1, 5, 10, or 15 days.
   * @param request The request.
   * @return A service response containing either a list of [IndexData]s or error information.
   */
  public async getGroupValuesByLocationKey(
    request: GroupIndexValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getGroupValuesByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getGroupValuesByLocationKey'),
    )
  }

  /**
   * Gets a list of index values for a specific [IndexType] for the given location and date range. Available for 1, 5, 10, or 15 days.
   * @param request The request.
   * @return A service response containing either a list of [IndexData]s or error information.
   */
  public async getIndexValuesByLocationKey(
    request: IndexValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getIndexValuesByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getIndexValuesByLocationKey'),
    )
  }
  // endregion index data

  // region air quality
  /**
   * Gets the current air quality by location key.
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a ApiResult<AirQualityData> or error information.
   */
  public async getCurrentAirQuality(
    request: CurrentAirQualityByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.getV2(
      request,
      (r, rc) => this.routeResolver.getCurrentAirQualityUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getCurrentAirQuality'),
    )
  }

  /**
   * Gets daily air quality forecasts by location key.
   * Available for 1, 2, 3, and 4 days.
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a list of [AirQualityData] or error information.
   */
  public async getDailyAirQualityForecasts(
    request: DailyAirQualityByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.getV2(
      request,
      (r, rc) => this.routeResolver.getDailyAirQualityForecastsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getDailyAirQualityForecasts'),
    )
  }

  /**
   * Gets hourly air quality forecasts by location key.
   * Available for 1, 12, 24, 48, 72, and 96 hours.
   * @param request The request.
   * @param requestChain The request chain.
   * @return A service response containing either a list of [AirQualityData] or error information.
   */
  public async getHourlyAirQualityForecasts(
    request: HourlyAirQualityByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.getV2(
      request,
      (r, rc) => this.routeResolver.getHourlyAirQualityForecastsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getHourlyAirQualityForecasts'),
    )
  }
  // endregion air quality

  // region internal
  private infoKey(language: string): string {
    return `IndicesInfo:${language}`
  }
  private async getIndexInfoByTypeHttp(
    request: IndexInfoByTypeRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getIndexInfoByTypeUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getIndexInfoByType'),
    )
  }
  private async getIndicesInfoByGroupHttp(
    request: IndicesInfoByGroupRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getIndicesInfoByGroupUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getIndicesInfoByGroup'),
    )
  }
  private getIndexInfoForGroupTasks(
    indexTypes: IndexType[],
    request: IndicesInfoByGroupRequest,
    requestChain?: RequestChain,
  ): Array<Promise<ServiceResponse<any>>> {
    const result = []
    for (const indexType of indexTypes) {
      const req = new IndexInfoByTypeRequest(request?.language, indexType)
      result.push(() => this.getIndexInfoByType(req, requestChain)) // todo: sonarqube wants this function defined outside the loop
    }
    return result
  }
  private async getIndicesInfoHttp(
    request: IndicesInfoRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return await this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getIndicesInfoUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getIndicesInfo'),
    )
  }
  private async getGroupsHttp(
    request: IndexGroupsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getGroupsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getGroups'),
    )
  }
  // endregion internal
}
