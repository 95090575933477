<template>
  <LoadingScreen v-if="loading" />
  <router-view :key="$route.fullPath" />
</template>

<script lang="ts">
import { mapState } from 'vuex'
import LoadingScreen from './components/core/loading-screen/LoadingScreen.vue'
import store from './store'
import router from './router'

export default {
  components: {
    LoadingScreen,
  },
  computed: {
    ...mapState(['loading']),
  },
  watch: {
    '$route.query.key': {
      handler(newLoc: string | undefined) {
        const route = router.currentRoute.value.path?.toLowerCase()
        console.log(route)
        if (
          route.includes('location') &&
          store.state.home.location !== undefined &&
          store.state.home.location?.Key !== newLoc &&
          !newLoc?.includes('-')
        ) {
          window.location.reload()
        }
      },
    },
  },
}
</script>
<style lang="scss">
#app {
  font-family: 'Solis';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

@font-face {
  font-family: 'Solis';
  src: url('./assets/fonts/Solis-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
</style>
