export class TimeSpan {
  /**
   * The total number of milliseconds represented by this instance.
   */
  public readonly totalMilliseconds: number

  /**
   * The total number of days represented by this instance.
   */
  public readonly totalDays: number

  /**
   * The day component of the current TimeSpan structure.
   */
  public readonly days: number

  /**
   * The total number of hours represented by this instance.
   */
  public readonly totalHours: number

  /**
   * The hour component of the current instance.
   * The return value ranges from 0 through 23.
   */
  public readonly hours: number

  /**
   * The total number of minutes represented by this instance.
   */
  public readonly totalMinutes: number

  /**
   * The minute component of the current instance.
   * The return value ranges from 0 through 59.
   */
  public readonly minutes: number

  /**
   * The total number of seconds represented by this instance.
   */
  public readonly totalSeconds: number

  /**
   * The second component of the current instance.
   * The return value ranges from 0 through 59.
   */
  public readonly seconds: number

  /**
   * The millisecond component of the current instance.
   * The return value ranges from 0 through 999.
   */
  public readonly milliseconds: number
  private secondsInMs = 1000
  private minutesInMs = this.secondsInMs * 60
  private hoursInMs = this.minutesInMs * 60
  private daysInMs = this.hoursInMs * 24

  /**
   * Initializes a new `TimeSpan`.
   * @param totalMilliseconds The total number of milliseconds represented by this instance.
   */
  public constructor(totalMilliseconds: number) {
    this.totalMilliseconds = totalMilliseconds ?? 0

    // use totalMilliseconds to get totalXyz
    this.totalDays = this.totalMilliseconds / this.daysInMs
    this.totalHours = this.totalMilliseconds / this.hoursInMs
    this.totalMinutes = this.totalMilliseconds / this.minutesInMs
    this.totalSeconds = this.totalMilliseconds / this.secondsInMs

    // get the components- start with totalMilliseconds
    let diff = this.totalMilliseconds

    this.days = Math.trunc(diff / this.daysInMs)
    diff = diff % this.daysInMs

    this.hours = Math.trunc(diff / this.hoursInMs)
    diff = diff % this.hoursInMs

    this.minutes = Math.trunc(diff / this.minutesInMs)
    diff = diff % this.minutesInMs

    this.seconds = Math.trunc(diff / this.secondsInMs)
    diff = diff % this.secondsInMs

    this.milliseconds = Math.trunc(diff)
  }
}
