import { isNullOrUndefined } from '../../util/Helpers'
import { AttributionSourceType } from '../AttributionSourceType'

/**
 * Provides methods used to validate attribution source types.
 */
export class AttributionSourceTypeValidRule {
  public static checkRule(type: AttributionSourceType) {
    return isNullOrUndefined(type) || type !== AttributionSourceType.Unknown
      ? undefined
      : Error('invalid attribution source type.')
  }
}
