import { ConversionInfo, convertQuantity } from './ConversionInfo'
import { Celsius, Fahrenheit, Kelvin, TemperatureConversion } from './TemperatureConversion'
import { TemperatureType } from './TemperatureType'

const temperatureInfo: Map<TemperatureType, ConversionInfo<TemperatureConversion>> = new Map([
  [
    TemperatureType.Fahrenheit,
    new ConversionInfo(TemperatureType.Fahrenheit, new Fahrenheit(), (v: number, c: any) =>
      c.toFahrenheit(v),
    ),
  ],
  [
    TemperatureType.Celsius,
    new ConversionInfo(TemperatureType.Celsius, new Celsius(), (v: number, c: any) =>
      c.toCelsius(v),
    ),
  ],
  [
    TemperatureType.Kelvin,
    new ConversionInfo(TemperatureType.Kelvin, new Kelvin(), (v: number, c: any) => c.toKelvin(v)),
  ],
])

/**
 * Converts a temperature from one unit type to another.
 * @param temperature The temperature to convert.
 * @param type The unit type to convert to.
 * @return The converted temperature.
 */
export function convertTemperature(temperature: any, type: TemperatureType) {
  return convertQuantity(temperature, type, temperatureInfo)
}
