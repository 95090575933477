// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined } from '../../../util/Helpers'
import { ConcentrationUnits } from './ConcentrationUnits'
import { LinearUnits } from './LinearUnits'
import { NumberType } from './NumberType'
import { PressureUnits } from './PressureUnits'
import { SpeedUnits } from './SpeedUnits'
import { TemperatureType } from './TemperatureType'

/**
 * Provides a map of unit-type ID to label.
 * @see <a href="https://developer.accuweather.com/unit-types">here</a> for more information.
 */
export class UnitInfo {
  /**
   * Gets a label for a given unit type, if found.
   * @param unitType The unit type key to search for
   * @return If found, the label for the given key; otherwise, null.
   */
  public static label(unitType: number): string {
    if (UnitInfo.map.has(unitType)) {
      return UnitInfo.map.get(unitType)
    }
  }

  /**
   * Gets the unit type for the given label, if found.
   * @param label The label to search for.
   * @return If found, the unit type for the given label; otherwise, -1.
   */
  public static key(label: string): number {
    // int label is empty, so can't check isNullOrEmpty()
    if (isNotNullOrUndefined(label)) {
      for (const i in UnitInfo.map) {
        if (UnitInfo.map.values[i] === label) {
          return UnitInfo.map.keys[i]
        }
      }
    }
  }

  private static readonly map: Map<number, string> = new Map([
    [Number(LinearUnits.Feet), 'ft'],
    [Number(LinearUnits.Inches), 'in'],
    [Number(LinearUnits.Miles), 'mi'],
    [Number(LinearUnits.Millimeters), 'mm'],
    [Number(LinearUnits.Centimeters), 'cm'],
    [Number(LinearUnits.Meters), 'm'],
    [Number(LinearUnits.Kilometers), 'km'],
    [Number(LinearUnits.NauticalMiles), 'nm'], // NauticalMiles is not actually an api unit type
    [Number(SpeedUnits.KilometersPerHour), 'km/h'],
    [Number(SpeedUnits.Knots), 'kts'],
    [Number(SpeedUnits.MilesPerHour), 'mi/h'],
    [Number(SpeedUnits.MetersPerSecond), 'm/s'],
    [Number(PressureUnits.HectoPascals), 'hPa'],
    [Number(PressureUnits.InchesOfMercury), 'inHg'],
    [Number(PressureUnits.KiloPascals), 'kPa'],
    [Number(PressureUnits.Millibars), 'mb'],
    [Number(PressureUnits.MillimetersOfMercury), 'mmHg'],
    [Number(PressureUnits.PoundsPerSquareInch), 'psi'],
    [Number(TemperatureType.Celsius), 'C'],
    [Number(TemperatureType.Fahrenheit), 'F'],
    [Number(TemperatureType.Kelvin), 'K'],
    [Number(NumberType.Percent), '%'],
    [Number(NumberType.Float), 'f'],
    [Number(NumberType.Integer), ''], // what is the label for int?? where/how is this used by the api?
    [Number(ConcentrationUnits.MicrogramsPerCubicMeter), 'µg/m³'],
  ])
}
