import Minutecast from '../../models/Minutecast'
import { DateTime } from '../../utils/extensions/date-extension'

export function mapDataToMinutecast(
  data: {
    Summary: { Phrase_60: string }
    Intervals: {
      StartDateTime: string
      Dbz: number
      PrecipitationType: 'Rain' | 'Snow' | 'Ice' | 'Mix' | 'Dry' | undefined
    }[]
  },
  gmtOffset?: number | undefined,
): Minutecast {
  const phrase60 = data.Summary.Phrase_60
  let init = data.Intervals.findIndex(
    (i) => DateTime(i.StartDateTime).getMinutes() === DateTime().getMinutes(),
  )
  init = init === -1 ? 0 : init
  const intervals = data.Intervals.slice(init, init + 61)
  const minutes = intervals.map((interval) => {
    return {
      time: DateTime(interval.StartDateTime).toTimeString(true, true, gmtOffset),
      dbz: interval.Dbz,
      precipitationType: interval.PrecipitationType,
    }
  })

  return {
    phrase60,
    minutes,
  }
}
