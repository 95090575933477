/**
 * The different types of text a url can contain.
 * @see [TextMeta]
 */
export enum TextMetaType {
  /**
   * The string is plain text.
   */
  Text,

  /**
   * The string contains a {token}.
   */
  Token,

  /**
   * The string is "?".
   */
  QuerySeparator,

  /**
   * The string is i.e. "http://", "https://", "ftp://", etc.
   */
  Protocol,

  /**
   * The string is i.e. "(sub).domain.com".
   */
  Host,

  /**
   * The string is i.e. ":8080".
   */
  Port,

  /**
   * The string is "#".
   */
  AnchorSeparator,
}
