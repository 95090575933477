import { HttpService } from '../http/HttpService'
import { SdkSettings } from '../settings/SdkSettings'

/**
 * Provides an http service for most api calls.
 */
export class ApiHttpService extends HttpService {
  /**
   * Initializes a new ApiHttpService.
   * @param sdkSettings the SDK settings.
   */
  constructor(sdkSettings: SdkSettings) {
    super(
      sdkSettings.apiSettings,
      sdkSettings.cacheSettings,
      sdkSettings.persistentCache,
      sdkSettings.showApiDetails,
    )
  }
}
