// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { ApiTokens } from '../core/api/ApiTokens'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { ArrayNotEmptyRule } from '../core/validation/ArrayNotEmptyRule'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { LanguageValidRule } from '../core/validation/LanguageValidRule'
import { LocationKeyValidRule } from '../core/validation/LocationKeyValidRule'
import { NumberBetweenRangeRule } from '../core/validation/NumberBetweenRangeRule'
import { ObjectNotNullOrUndefinedRule } from '../core/validation/ObjectNotNullOrUndefinedRule'
import { StringNotNullOrWhiteSpaceRule } from '../core/validation/StringNotNullOrWhiteSpaceRule'
import { isNotNullOrUndefined } from '../util/Helpers'
import { ContentRouteResolver } from './ContentRouteResolver'
import { AlertsFeedRequest } from './requests/AlertsFeedRequest'
import { ArticleByIdRequest } from './requests/ArticleByIdRequest'
import { BlogsRequest } from './requests/BlogsRequest'
import { CmsTokens } from './requests/CmsTokens'
import { FindVideosRequest } from './requests/FindVideosRequest'
import { GalleryByIdRequest } from './requests/GalleryByIdRequest'
import { NewsFeedByCountryRequest } from './requests/NewsFeedByCountryRequest'
import { NewsFeedByDmaRequest } from './requests/NewsFeedByDmaRequest'
import { NewsFeedByIdRequest } from './requests/NewsFeedByIdRequest'
import { NewsFeedByLocationKeyRequest } from './requests/NewsFeedByLocationKeyRequest'
import { PageBySlugRequest } from './requests/PageBySlugRequest'
import { PageByTemplateRequest } from './requests/PageByTemplateRequest'
import { PartnersRequest } from './requests/PartnersRequest'
import { ProTipsByCountryRequest } from './requests/ProTipsByCountryRequest'
import { ProTipsByDmaRequest } from './requests/ProTipsByDmaRequest'
import { ProTipsByLocationKeyRequest } from './requests/ProTipsByLocationKeyRequest'
import { SearchRequest } from './requests/SearchRequest'
import { TropicalFeedRequest } from './requests/TropicalFeedRequest'
import { VideoByIdRequest } from './requests/VideoByIdRequest'
import { VideosByPlaylistIdRequest } from './requests/VideosByPlaylistIdRequest'
import { VideosByTagsRequest } from './requests/VideosByTagsRequest'

/**
 * Resolves routes for [ContentService] methods.
 */
export class ContentRouteResolverImpl implements ContentRouteResolver {
  private readonly sdkSettings: SdkSettings
  private readonly routeResolver: ApiRouteResolver

  private readonly stormToken = 'storm'
  private readonly excludedPostId = 'excludedPostId'

  // region routes
  private readonly pageByTemplate = 'PageByTemplate'
  private readonly pageBySlug = 'PageBySlug'
  private readonly videoById = 'VideoById'
  private readonly findVideos = 'FindVideos'
  private readonly videosByTags = 'VideosByTags'
  private readonly videosByPlaylistId = 'VideosByPlaylistId'
  private readonly partners = 'Partners'
  private readonly protips = 'Protips'
  private readonly tropicalFeed = 'TropicalFeed'
  private readonly alertsFeed = 'AlertsFeed'
  private readonly articleById = 'ArticleById'
  private readonly blogs = 'Blogs'
  private readonly newsFeedByLocation = 'NewsFeedByLocation'
  private readonly newsFeedById = 'NewsFeedById'
  private readonly galleryById = 'GalleryById'
  private readonly search = 'Search'

  private readonly routeTemplates: Map<string, string> = new Map([
    [
      this.pageByTemplate,
      'wp-json/data/v1/landing-pages?language={language}&locationKey={locationKey}&template={template}&dmas={dmaCodes}&blogs={blogs}&locations={locations}&storm={storm}&awx-preview={previewKey}&excluded_post_id={excludedPostId}',
    ],
    [
      this.pageBySlug,
      'wp-json/data/v1/landing-pages/{slug}?language={language}&locationKey={locationKey}&awx-preview={previewKey}',
    ],
    [this.videoById, 'wp-json/data/v1/videos/{id}'],
    [this.findVideos, 'wp-json/data/v1/videos?search={q}&page={page}'],
    [this.videosByTags, 'wp-json/data/v1/videos?tags={tag}&page={page}'],
    [this.videosByPlaylistId, 'wp-json/data/v1/videos/playlist/{id}'],
    [this.partners, 'wp-json/data/v1/partners?language={language}'],
    [this.tropicalFeed, 'wp-json/data/v1/storms?language={language}'],
    [this.alertsFeed, 'wp-json/data/v1/alerts?language={language}&locationKey={locationKey}'],
    [
      this.articleById,
      'wp-json/data/v1/articles/{id}?language={language}&category={category}&title-slug={slug}&awx-preview={previewKey}&legacy-press={includeLegacyPress}',
    ],
    [this.blogs, 'wp-json/data/v1/blogs?language={language}'],
    [this.newsFeedById, 'wp-json/data/v1/newsfeeds/{id}?language={language}'],
    [
      this.newsFeedByLocation,
      'wp-json/data/v1/dma-newsfeed?language={language}&locationKey={locationKey}&dmas={dmaCodes}&locations={locations}&backfill-newsfeed={backfillNewsFeed}',
    ],
    [
      this.protips,
      'wp-json/data/v1/pro-tips?language={language}&locationKey={locationKey}&dmas={dmaCodes}&locations={locations}',
    ],
    [this.galleryById, 'wp-json/data/v1/galleries/{id}?language={language}'],
    [
      this.search,
      'wp-json/data/v1/content-search?q={q}&num-results={count}&page={page}&sort-order={sortOrder}&content-type={contentType}',
    ],
  ])
  // endregion routes

  // region validators
  private readonly protipsByDmaRequestValidator: AsyncValidator<ProTipsByDmaRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => ArrayNotEmptyRule.checkRule(r.dmaCodes, 'dma codes'),
      (r) => StringNotNullOrWhiteSpaceRule.checkArrayRule(r.dmaCodes, 'dma code'),
    ])

  private readonly protipsByCountryRequestValidator: AsyncValidator<ProTipsByCountryRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, 'country code'),
    ])

  private readonly protipsByLocationKeyRequestValidator: AsyncValidator<ProTipsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])

  private readonly galleryByIdRequestValidator: AsyncValidator<GalleryByIdRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.id, 'gallery id'),
    ])

  private readonly newsFeedByIdRequestValidator: AsyncValidator<NewsFeedByIdRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.id, 'news feed id'),
    ])

  private readonly newsFeedByDmaRequestValidator: AsyncValidator<NewsFeedByDmaRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => ArrayNotEmptyRule.checkRule(r.dmaCodes, 'dma codes'),
      (r) => StringNotNullOrWhiteSpaceRule.checkArrayRule(r.dmaCodes, 'dma code'),
    ])

  private readonly newsFeedByCountryRequestValidator: AsyncValidator<NewsFeedByCountryRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.countryCode, 'country code'),
    ])

  private readonly newsFeedByLocationKeyRequestValidator: AsyncValidator<NewsFeedByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])

  private readonly blogsRequestValidator: AsyncValidator<BlogsRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => LanguageValidRule.checkRule(r.language),
  ])

  private readonly articleByIdRequestValidator: AsyncValidator<ArticleByIdRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.id, 'article id'),
    ])

  private readonly alertsFeedRequestValidator: AsyncValidator<AlertsFeedRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
    ])

  private readonly tropicalFeedRequestValidator: AsyncValidator<TropicalFeedRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
    ])

  private readonly partnersRequestValidator: AsyncValidator<PartnersRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => LanguageValidRule.checkRule(r.language),
  ])

  private readonly videosByPlaylistIdRequestValidator: AsyncValidator<VideosByPlaylistIdRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.playlistId, 'video playlist id'),
    ])

  private readonly videosByTagsRequestValidator: AsyncValidator<VideosByTagsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkArrayRule(r.tags, 'tags'),
    ])

  private readonly findVideosRequestValidator: AsyncValidator<FindVideosRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
    ])

  private readonly videoByIdRequestValidator: AsyncValidator<VideoByIdRequest> = new AsyncValidator(
    [
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.id, 'video id'),
    ],
  )

  private readonly pageBySlugRequestValidator: AsyncValidator<PageBySlugRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.slug, 'slug'),
    ])

  private readonly pageByTemplateRequestValidator: AsyncValidator<PageByTemplateRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.template, 'template'),
    ])

  private readonly searchRequestValidator: AsyncValidator<SearchRequest> = new AsyncValidator([
    (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
    (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.query),
    (r) => NumberBetweenRangeRule.checkRule(r.count, 0, 100),
  ])
  // endregion validators

  constructor(sdkSettings: SdkSettings) {
    this.sdkSettings = sdkSettings
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, this.sdkSettings)
  }

  // region landing page
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getPageBySlugUrl(
    request: PageBySlugRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.pageBySlug,
      request,
      this.pageBySlugRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.slug, request?.slug],
        [CmsTokens.previewKey, request?.previewKey],
        [ApiTokens.locationKey, request?.locationKey],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getPageByTemplateUrl(
    request: PageByTemplateRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.pageByTemplate,
      request,
      this.pageByTemplateRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.template, request?.template],
        [CmsTokens.dmaCodes, this.commaSeparated(request?.dmaCodes)],
        [CmsTokens.blogs, this.commaSeparated(request?.blogs)],
        [CmsTokens.locations, this.commaSeparated(request?.locations)],
        [this.stormToken, request?.storm],
        [this.excludedPostId, request?.excludedPostId],
        [CmsTokens.previewKey, request?.previewKey],
        [ApiTokens.locationKey, request?.locationKey],
      ]),
    )
  }
  // endregion landing page

  // region videos
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getVideosByPlaylistIdUrl(
    request: VideosByPlaylistIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.videosByPlaylistId,
      request,
      this.videosByPlaylistIdRequestValidator,
      requestChain,
      new Map<string, any>([[CmsTokens.id, request?.playlistId]]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getVideosByTagsUrl(
    request: VideosByTagsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.videosByTags,
      request,
      this.videosByTagsRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.tag, this.commaSeparated(request?.tags)],
        [CmsTokens.page, request?.page],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async findVideosUrl(
    request: FindVideosRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.findVideos,
      request,
      this.findVideosRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [ApiTokens.q, request?.term],
        [CmsTokens.page, request?.page],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getVideoByIdUrl(
    request: VideoByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.videoById,
      request,
      this.videoByIdRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.id, request?.id],
      ]),
    )
  }
  // endregion videos

  // region partners
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getPartnersUrl(
    request: PartnersRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.partners,
      request,
      this.partnersRequestValidator,
      requestChain,
      new Map<string, any>([[ApiTokens.language, request?.language]]),
    )
  }
  // endregion partners

  // region pro tips
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getProTipsByDmaUrl(
    request: ProTipsByDmaRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.protips,
      request,
      this.protipsByDmaRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.dmaCodes, this.commaSeparated(request?.dmaCodes)],
        [CmsTokens.locations, this.commaSeparated(request?.countryCodes)],
        [ApiTokens.locationKey, request?.locationKey],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getProTipsByCountryUrl(
    request: ProTipsByCountryRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.protips,
      request,
      this.protipsByCountryRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.dmaCodes, null], // dma code not used here
        [CmsTokens.locations, request?.countryCode],
        [ApiTokens.locationKey, request?.locationKey],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getProTipsByLocationKeyUrl(
    request: ProTipsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.protips,
      request,
      this.protipsByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.dmaCodes, null],
        [CmsTokens.locations, null],
        [ApiTokens.locationKey, request?.locationKey],
      ]),
    )
  }
  // endregion pro tips

  // region news/feeds
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getGalleryByIdUrl(
    request: GalleryByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.galleryById,
      request,
      this.galleryByIdRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.id, request?.id],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getNewsFeedByIdUrl(
    request: NewsFeedByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.newsFeedById,
      request,
      this.newsFeedByIdRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.id, request?.id],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getNewsFeedByDmaUrl(
    request: NewsFeedByDmaRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.newsFeedByLocation,
      request,
      this.newsFeedByDmaRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.dmaCodes, this.commaSeparated(request?.dmaCodes)],
        [CmsTokens.locations, this.commaSeparated(request?.countryCodes)],
        [CmsTokens.backfillNewsFeed, request?.backfillNewsFeed],
        [ApiTokens.locationKey, request?.locationKey],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getNewsFeedByCountryUrl(
    request: NewsFeedByCountryRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.newsFeedByLocation,
      request,
      this.newsFeedByCountryRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.dmaCodes, null], // dma code not used here
        [CmsTokens.locations, request?.countryCode],
        [CmsTokens.backfillNewsFeed, request?.backfillNewsFeed],
        [ApiTokens.locationKey, request?.locationKey],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getNewsFeedByLocationKeyUrl(
    request: NewsFeedByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.newsFeedByLocation,
      request,
      this.newsFeedByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.dmaCodes, null],
        [CmsTokens.locations, null],
        [ApiTokens.locationKey, request?.locationKey],
        [CmsTokens.backfillNewsFeed, request?.backfillNewsFeed],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getBlogsUrl(
    request: BlogsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.blogs,
      request,
      this.blogsRequestValidator,
      requestChain,
      new Map<string, any>([[ApiTokens.language, request?.language]]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getArticleByIdUrl(
    request: ArticleByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    const legacyPress = isNotNullOrUndefined(request) && request.includeLegacyPress ? '1' : ''
    return await this.routeResolver.resolveAsync(
      this.articleById,
      request,
      this.articleByIdRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.id, request?.id],
        [CmsTokens.slug, request?.titleSlug],
        [CmsTokens.previewKey, request?.previewKey],
        [CmsTokens.includeLegacyPress, legacyPress],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getAlertsFeedUrl(
    request: AlertsFeedRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.alertsFeed,
      request,
      this.alertsFeedRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [ApiTokens.locationKey, request?.locationKey],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getTropicalFeedUrl(
    request: TropicalFeedRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.tropicalFeed,
      request,
      this.tropicalFeedRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [CmsTokens.year, request?.year],
        [CmsTokens.basin, request?.basin],
      ]),
    )
  }
  // endregion news/feeds

  // region search
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getSearchUrl(
    request: SearchRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.search,
      request,
      this.searchRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.q, request?.query],
        [ApiTokens.count, request?.count],
        [CmsTokens.page, request?.page],
        [CmsTokens.sortOrder, request?.sortType],
        [CmsTokens.contentType, request?.contentType],
      ]),
    )
  }
  // endregion search

  private commaSeparated(list?: string[]): string {
    return list?.join()
  }
}
