import { enumValues } from '../../util/Helpers'

/**
 * The different types of weather events.
 */
export enum WeatherEventType {
  /**
   * Rain
   */
  Rain = 'rain',

  /**
   * Severe Thunderstorm
   */
  SevereThunderstorm = 'severe thunderstorm',

  /**
   * Snow
   */
  Snow = 'snow',

  /**
   * Thunderstorm
   */
  Thunderstorm = 'thunderstorm',

  /**
   * Tornado
   */
  Tornado = 'tornado',

  /**
   * Tropical
   */
  Tropical = 'tropical',

  /**
   * Wind
   */
  Wind = 'wind',

  /**
   * Unknown
   */
  Unknown = 'unknown',

  /**
   * Hail
   */
  Hail = 'hail',

  /**
   * Flooding
   */
  Flooding = 'flooding',

  /**
   * Flash Flooding
   */
  FlashFlooding = 'flash flooding',

  /**
   * Coastal Flooding
   */
  CoastalFlooding = 'coastal flooding',

  /**
   * River Flooding
   */
  RiverFlooding = 'river flooding',

  /**
   * Winter
   * Not valid for use in API methods.
   */
  Winter = 'winter',
}

export class WeatherEventTypes {
  public static readonly valid = enumValues(WeatherEventType).filter(
    (type) => type !== WeatherEventType.Unknown,
  )
  public static readonly apiValid = enumValues(WeatherEventType).filter(
    (type) => type !== WeatherEventType.Unknown && type !== WeatherEventType.Winter,
  )
}
