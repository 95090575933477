/**
 * Represents a subset of api unit types dealing with length/distance.
 * These values map to api unit type IDs.
 * @see <a href="https://developer.accuweather.com/unit-types">here</a> for more information.
 */
export enum LinearUnits {
  /**
   * Feet
   */
  Feet = 0,

  /**
   * Inches
   */
  Inches = 1,

  /**
   * Miles
   */
  Miles = 2,

  /**
   * Millimeters
   */
  Millimeters = 3,

  /**
   * Centimeters
   */
  Centimeters = 4,

  /**
   * Meters
   */
  Meters = 5,

  /**
   * Kilometers
   */
  Kilometers = 6,

  /**
   * Nautical miles
   */
  NauticalMiles = 999, // no api unit type  = unused
}

/**
 * Gets whether the [LinearUnits] is metric.
 */
export function isMetricLength(units: LinearUnits): boolean {
  switch (units) {
    case LinearUnits.Millimeters:
    case LinearUnits.Centimeters:
    case LinearUnits.Meters:
    case LinearUnits.Kilometers:
      return true
    default:
      return false
  }
}
