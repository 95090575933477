/* eslint-disable no-unused-vars */
import { IndexDataFormatOptions, PressureTendencyCode, SdkSettings } from '..'
import { DateFormatter } from './cldr/dates/DateFormatter'
import { NumbFormatter } from './cldr/numbers/NumbFormatter'
import { MeasurementFormatter } from './cldr/units/MeasurementFormatter'
import { LocalizationService } from './LocalizationService'
import { CustomDateFormatOptions } from './options/dates/CustomDateFormatOptions'
import { DateFormatOptions } from './options/dates/DateFormatOptions'
import { DurationFormatOptions } from './options/dates/DurationFormatOptions'
import { PeriodFormatOptions } from './options/dates/PeriodFormatOptions'
import { CoordinatesFormatOptions } from './options/units/CoordinatesFormatOptions'
import { DirectionFormatOptions } from './options/units/DirectionFormatOptions'
import { DistanceFormatOptions } from './options/units/DistanceFormatOptions'
import { PercentFormatOptions } from './options/units/PercentFormatOptions'
import { PrecipitationFormatOptions } from './options/units/PrecipitationFormatOptions'
import { PressureFormatOptions } from './options/units/PressureFormatOptions'
import { SpeedFormatOptions } from './options/units/SpeedFormatOptions'
import { TemperatureFormatOptions } from './options/units/TemperatureFormatOptions'
import { WindFormatOptions } from './options/units/WindFormatOptions'

/**
 * Provides localization services for dates, times, numbers, and units of measure.
 */
export class LocalizationServiceImpl implements LocalizationService {
  private readonly measurementFormatter: MeasurementFormatter
  private readonly dateFormatter = new DateFormatter()
  private readonly numbFormatter: NumbFormatter

  constructor(settings: SdkSettings) {
    this.measurementFormatter = new MeasurementFormatter(settings)
    this.numbFormatter = new NumbFormatter()
  }

  public setLanguages(
    dates: Map<string, any>,
    units: Map<string, any>,
    numbers: Map<string, any>,
  ): void {
    this.dateFormatter.setMap(dates)
    this.measurementFormatter.setMap(units, numbers)
    this.numbFormatter.setMap(numbers)
  }

  // region dates
  // region period names
  public monthNames(formatOptions: PeriodFormatOptions): string[] {
    return this.dateFormatter.monthNames(formatOptions)
  }

  public monthName(date: Date, formatOptions: PeriodFormatOptions): string {
    return this.dateFormatter.monthName(date, formatOptions)
  }

  public dayNames(formatOptions: PeriodFormatOptions): string[] {
    return this.dateFormatter.dayNames(formatOptions)
  }

  public dayName(date: Date, formatOptions: PeriodFormatOptions): string {
    return this.dateFormatter.dayName(date, formatOptions)
  }
  // endregion period names

  // region datetime format
  public date(date: Date, formatOptions: DateFormatOptions): string {
    return this.dateFormatter.date(date, formatOptions)
  }

  public time(date: Date, formatOptions: DateFormatOptions): string {
    return this.dateFormatter.time(date, formatOptions)
  }

  public datetime(date: Date, formatOptions: DateFormatOptions): string {
    return this.dateFormatter.datetime(date, formatOptions)
  }

  public datetimeCustom(date: Date, formatOptions: CustomDateFormatOptions): string {
    return this.dateFormatter.datetimeCustom(date, formatOptions)
  }
  // endregion datetime format

  // region duration
  /**
   * Formats the duration per the given options.
   * @param duration The duration, in milliseconds, to be formatted.
   * @param formatOptions The format options.
   * @return The formatted date text, or null if an error occurred.
   */
  public duration(duration: number, formatOptions: DurationFormatOptions): string {
    return this.dateFormatter.duration(duration, formatOptions)
  }
  // endregion duration
  // endregion dates

  // region measurements
  /**
   * Formats the temperature per the given options.
   * By default, the temperature will be rounded to the closest integer value.
   * @param value The value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted temperature text, or null if an error occurred.
   */
  public temperature(value: number, formatOptions: TemperatureFormatOptions): string {
    return this.measurementFormatter.temperature(value, formatOptions)
  }
  /**
   * Formats the temperature range per the given options.
   * @param minValue The range low end value to be formatted.
   * @param maxValue The range high end value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted temperature text, or null if an error occurred.
   */
  public temperatureRange(
    minValue: number,
    maxValue: number,
    formatOptions: TemperatureFormatOptions,
  ): string {
    return this.measurementFormatter.temperatureRange(minValue, maxValue, formatOptions)
  }

  /**
   * Formats the precipitation per the given options.
   * By default, the precipitation amount will be rounded per these rules (unit === #decimal-places):
   * Rain: mm=0, cm=1, in=2.
   * Snow: mm=0, cm=1, in=1.
   * Ice: mm=0, cm=1, in=2.
   * @param value The value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted precipitation text, or null if an error occurred.
   */
  public precipitation(value: number, formatOptions: PrecipitationFormatOptions): string {
    return this.measurementFormatter.precipitation(value, formatOptions)
  }
  /**
   * Formats the precipitation range per the given options.
   * @param minValue The range low end value to be formatted.
   * @param maxValue The range high end value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted precipitation text, or null if an error occurred.
   */
  public precipitationRange(
    minValue: number,
    maxValue: number,
    formatOptions: PrecipitationFormatOptions,
  ): string {
    return this.measurementFormatter.precipitationRange(minValue, maxValue, formatOptions)
  }

  /**
   * Formats the length per the given options.
   * By default, the length will be rounded to the closest integer value.
   * @param value The value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted length text, or null if an error occurred.
   */
  public distance(value: number, formatOptions: DistanceFormatOptions): string {
    return this.measurementFormatter.distance(value, formatOptions)
  }
  /**
   * Formats the length range per the given options.
   * @param minValue The range low end value to be formatted.
   * @param maxValue The range high end value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted length text, or null if an error occurred.
   */
  public distanceRange(
    minValue: number,
    maxValue: number,
    formatOptions: DistanceFormatOptions,
  ): string {
    return this.measurementFormatter.distanceRange(minValue, maxValue, formatOptions)
  }

  /**
   * Formats the speed per the given options.
   * By default, the speed will be rounded to the closest integer value.
   * @param value The value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted speed text, or null if an error occurred.
   */
  public speed(value: number, formatOptions: SpeedFormatOptions): string {
    return this.measurementFormatter.speed(value, formatOptions)
  }
  /**
   * Formats the speed range per the given options.
   * @param minValue The range low end value to be formatted.
   * @param maxValue The range high end value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted speed text, or null if an error occurred.
   */
  public speedRange(minValue: number, maxValue: number, formatOptions: SpeedFormatOptions): string {
    return this.measurementFormatter.speedRange(minValue, maxValue, formatOptions)
  }

  /**
   * Formats the direction per the given options.
   * By default, cardinal direction is used- not degrees.
   * If formatOptions.IsExact is set, degrees will be used instead
   * (degrees will be rounded to the nearest integer value).
   * @param direction The compass direction to be formatted.
   * @param formatOptions The format options.
   * @return The formatted direction text, or null if an error occurred.
   */
  public direction(direction: any, formatOptions: DirectionFormatOptions): string {
    return this.measurementFormatter.direction(direction, formatOptions)
  }

  /**
   * Formats the wind per the given options.
   * By default, the speed (and degrees, if using exact direction) will be rounded to the closest integer value.
   * @param speed The wind speed to be formatted.
   * @param direction The compass direction to be formatted.
   * @param formatOptions The format options.
   * @return The formatted wind text, or null if an error occurred.
   */
  public wind(speed: number, direction: any, formatOptions: WindFormatOptions): string {
    return this.measurementFormatter.wind(speed, direction, formatOptions)
  }

  /**
   * Formats the gust per the given options.
   * By default, the speed will be rounded to the closest integer value.
   * @param speed The gust speed to be formatted.
   * @param formatOptions The format options.
   * @return The formatted gust text, or null if an error occurred.
   */
  public gust(speed: number, formatOptions: SpeedFormatOptions): string {
    return this.measurementFormatter.gust(speed, formatOptions)
  }

  /**
   * Formats the pressure per the given options.
   * By default, the pressure will be rounded to two decimal places.
   * @param value The value to be formatted.
   * @param tendency The pressure tendency code.
   * @param formatOptions The format options.
   * @return The formatted pressure text, or null if an error occurred.
   */
  public pressure(
    value: number,
    tendency: PressureTendencyCode,
    formatOptions: PressureFormatOptions,
  ): string {
    return this.measurementFormatter.pressure(value, tendency, formatOptions)
  }
  /**
   * Formats the pressure range per the given options.
   * @param minValue The range low end value to be formatted.
   * @param maxValue The range high end value to be formatted.
   * @param tendency The pressure tendency code.
   * @param formatOptions The format options.
   * @return The formatted pressure text, or null if an error occurred.
   */
  public pressureRange(
    minValue: number,
    maxValue: number,
    tendency: PressureTendencyCode,
    formatOptions: PressureFormatOptions,
  ): string {
    return this.measurementFormatter.pressureRange(minValue, maxValue, tendency, formatOptions)
  }

  /**
   * Formats the index value per the given options.
   * @param value The value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted index value, or null if an error occurred.
   */
  public indexData(value: number, formatOptions: IndexDataFormatOptions): string {
    return this.measurementFormatter.indexData(value, formatOptions)
  }

  /**
   * Formats the percentage per the given options.
   * By default, the percent will be rounded to the nearest integer value.
   * @param value The value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted text, or null if an error occurred.
   */
  public percentage(value: number, formatOptions: PercentFormatOptions): string {
    return this.measurementFormatter.percentage(value, formatOptions)
  }
  /**
   * Formats the range per the given options.
   * @param minValue The range low end value to be formatted.
   * @param maxValue The range high end value to be formatted.
   * @param formatOptions The format options.
   * @return The formatted text, or null if an error occurred.
   */
  public percentageRange(
    minValue: number,
    maxValue: number,
    formatOptions: PercentFormatOptions,
  ): string {
    return this.measurementFormatter.percentageRange(minValue, maxValue, formatOptions)
  }
  // endregion measurements

  /**
   * Formats the coordinates per the given options.
   * @param latlon The latitude/longitude pair.
   * @param formatOptions The format options.
   * @return The formatted text.
   */
  public coordinates(latlon: [number, number], formatOptions: CoordinatesFormatOptions): string {
    return this.measurementFormatter.coordinates(latlon, formatOptions)
  }

  public formatNumber(value: number, language: string): string {
    return this.numbFormatter.formatNumber(value, language)
  }
}
