/* eslint-disable no-unused-vars */
/**
 * The types of rounding behaviors.
 */
export enum RoundingMethod {
  /**
   * The raw value will be used.
   */
  None,

  /**
   * The value will be rounded using the default precision.
   */
  Default,

  /**
   * The value will be rounded using a specified precision.
   */
  CustomPrecision,

  /**
   * The value will be truncated rather than rounded.
   */
  Floor,

  /**
   * The value will be rounded to the nearest value divisible by 5.
   */
  NearestFive,
}
