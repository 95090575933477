import { ProductType } from './ProductType'

/**
 * Provides a custom exception type for errors when requesting data for a
 * product that is not available for a given location/area.
 */
export class ProductUnavailableError implements Error {
  /** Implemented from Error */
  public name: string
  public message: string
  public stack?: string

  /**
   * The product type.
   */
  public readonly productType: ProductType

  /**
   * The location.
   */
  public readonly location: string

  constructor(productType: ProductType, location: string) {
    this.productType = productType
    this.location = location
    this.name = 'ProductUnavailableError'
    this.message = `Product ${productType} not supported for location: ${location}`
  }
}
