// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
declare global {
  interface String {
    isBlank(): boolean
    isLetterDigitOrUnderscore(): boolean
  }
}

/**
 * Checks if a string is empty or all whitespace.
 */
String.prototype.isBlank = function (): boolean {
  return !/\S/.test(this)
}

/** */
String.prototype.isLetterDigitOrUnderscore = function (): boolean {
  return /\w/.test(this)
}

export {}
