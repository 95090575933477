import { SdkHttpSettings } from './SdkHttpSettings'
import { SdkHttpSettingsOverrides } from './SdkHttpSettingsOverrides'

/**
 * Settings for how to connect to an API.
 */
export abstract class BaseApiSettings extends SdkHttpSettings {
  /**
   * Gets the dictionary of http settings to be used for specific service methods.
   */
  public httpSettingsOverrides: Map<string, SdkHttpSettingsOverrides> = new Map<
    string,
    SdkHttpSettingsOverrides
  >()
}
