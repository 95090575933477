// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined, isNullOrUndefined } from '../../util/Helpers'
import { CacheOptions } from '../cache/CacheOptions'
import { PersistentCache } from '../cache/PersistentCache'
import { Logger } from '../Logger'
import { ServiceResponse } from '../ServiceResponse'
import { BaseApiSettings } from '../settings/BaseApiSettings'
import { CacheSettings } from '../settings/CacheSettings'
import { ProductUnavailableError } from '../support/ProductUnavailableError'
import { AccuHttpClient } from './AccuHttpClient'
import { AccuHttpResponse } from './AccuHttpResponse'
import { HttpMethod, HttpRequestOptions } from './HttpRequestOptions'
import { RequestChain } from './RequestChain'

/**
 * Provides an abstract http service to make requests
 */
export abstract class HttpService {
  private readonly cacheSettings: CacheSettings
  private readonly httpClient: AccuHttpClient
  private readonly cache: PersistentCache
  private readonly showApiDetails: boolean
  private readonly logger: Logger = Logger.getInstance()

  /**
   * Initializes a new `HttpService`.
   * @param apiSettings the api settings.
   */
  constructor(
    apiSettings: BaseApiSettings,
    cacheSettings: CacheSettings,
    persistentCache: PersistentCache,
    showApiDetails: boolean,
  ) {
    this.httpClient = new AccuHttpClient(apiSettings)
    this.cacheSettings = cacheSettings
    this.cache = persistentCache
    this.showApiDetails = showApiDetails
  }

  /**
   * Gets a response from a given request and getUrl function.
   */
  public async getV2<TData, TRequest>(
    request: TRequest,
    getUrl: (request: TRequest, requestChain: RequestChain) => Promise<ServiceResponse<string>>,
    options: HttpRequestOptions,
  ): Promise<ServiceResponse<TData>> {
    const apiResultOptions = options.clone()
    // our defaultIfEmpty should be an 'apiResult', with a `data` property that has the 'real' default
    apiResultOptions.getDefaultIfEmpty = () => {
      const result = { data: null }
      if (isNotNullOrUndefined(options.getDefaultIfEmpty)) {
        result.data = options.getDefaultIfEmpty() as TData
      }
      return result
    }
    // get the response, we're expecting the result to be an 'api result', not TData
    const response = await this.get<any, TRequest>(request, getUrl, apiResultOptions)
    if (response.hasError) {
      // we should have already logged error messages
      // does the apiResult include different error messages?
      return response.transformError()
    }

    const showApiDetails = this.showApiDetails || (options?.requestChain?.showApiDetails ?? false)
    const rawData = showApiDetails ? response.rawData : undefined

    // strip the data from the api result
    const data = response.data.data
    return ServiceResponse.create(data, response.URL, rawData, response.template)
  }

  /**
   * Gets a response from a given request and getUrl function.
   */
  public async get<TData, TRequest>(
    request: TRequest,
    getUrl: (request: TRequest, requestChain: RequestChain) => Promise<ServiceResponse<string>>,
    options: HttpRequestOptions,
  ): Promise<ServiceResponse<TData>> {
    const urlResponse: ServiceResponse<string> = await this.getUrl(request, getUrl, options)
    if (urlResponse.hasError) {
      return urlResponse.transformError()
    }

    if (isNullOrUndefined(urlResponse.data)) {
      if (options.allowEmpty) {
        const template = isNullOrUndefined(urlResponse.template)
          ? 'No template'
          : urlResponse.template
        return ServiceResponse.create(null, urlResponse.URL, null, template)
      } else {
        return urlResponse.transformError<TData>(new Error(`unable to resolve url from ${getUrl}`)) // getUrl will evaluate to the string representation of the function
      }
    }

    return await this.getData<TData>(urlResponse, options)
  }

  protected async getUrl<TRequest>(
    request: TRequest,
    getUrl: (request: TRequest, requestChain: RequestChain) => Promise<ServiceResponse<string>>,
    options: HttpRequestOptions,
  ): Promise<ServiceResponse<string>> {
    try {
      const urlResponse = await getUrl(request, options.requestChain)
      if (urlResponse.hasError) {
        const urlError = urlResponse.error.error
        if (isNotNullOrUndefined(urlError as ProductUnavailableError)) {
          // ProductUnavailableError isn't really an 'error' OR even a 'warning'
          // - at least as far as logging is concerned; just log as info/debug
          this.logger.debug(urlError)
        } else {
          // otherwise, we have _more of_ an error... but still just a warning
          this.logger.warn(urlError)
        }
      }
      return urlResponse
    } catch (error) {
      this.logger.error(error)
      return ServiceResponse.fromError(error)
    }
  }

  protected async getHeaders(urlResponse: ServiceResponse<string>): Promise<ServiceResponse<any>> {
    const options = new HttpRequestOptions(null)
    options.method = HttpMethod.Head
    const response = await this.httpClient.getResponse(urlResponse.data, options)
    if (response.isSuccess) {
      return isNullOrUndefined(response.headers)
        ? ServiceResponse.fromError(new Error('headers not found'))
        : ServiceResponse.create(response.headers)
    } else {
      return ServiceResponse.fromError(response.error)
    }
  }

  protected handleError<TData>(response: AccuHttpResponse): ServiceResponse<TData> {
    const error = isNotNullOrUndefined(response.error)
      ? response.error
      : new Error(
          `An unknown error occurred. API returned (${response.statusCode}) ${response.content}`,
        )
    return ServiceResponse.fromError(error)
  }

  protected async getData<TData>(
    urlResponse: ServiceResponse<string>,
    options: HttpRequestOptions,
  ): Promise<ServiceResponse<TData>> {
    let result: ServiceResponse<TData>

    if (isNotNullOrUndefined(options.requestChain) && options.requestChain.isDryRun) {
      return ServiceResponse.create(null, urlResponse.URL, null, urlResponse.template)
    }

    // if we already made this request *during this page request* - return the cached results
    // this could happen i.e. when 2 separate requests are made to build location cache info
    // otherwise, if we already made this request and still have a result in external cache, use that
    const cachedResult =
      this.getCachedForRequestResult<TData>(urlResponse, options.requestChain) ||
      this.getCachedResult<TData>(urlResponse)
    if (isNotNullOrUndefined(cachedResult)) {
      return cachedResult
    } // we have a cached result that has not yet expired

    const response = await this.httpClient.getResponse(urlResponse.data, options)
    try {
      if (response.isSuccess) {
        // we have a response AND no error, get content...
        const data = response.content
        result = this.getDataResponse(
          data,
          options,
          options.getDefaultIfEmpty,
          response.url,
          urlResponse.template,
        )
        if (!result.hasError) {
          this.tryCache(urlResponse.data, result.data, response)
        }
      } else {
        result = this.handleError(response)
      }
      this.tryCacheForRequest(urlResponse.data, result, response, options.requestChain)
    } catch (error) {
      this.logger.error(error)
      result = ServiceResponse.fromError(error, urlResponse.URL, urlResponse.template)
    }

    if (result.hasError) {
      this.logger.error(result.error)
    }

    return result
  }

  private getDataResponse<TData>(
    data: TData,
    options: HttpRequestOptions,
    getDefaultIfEmpty: () => TData,
    url: string,
    template: string,
  ): ServiceResponse<TData> {
    const showApiDetails = this.showApiDetails || (options?.requestChain?.showApiDetails ?? false)
    if (isNotNullOrUndefined(data)) {
      return ServiceResponse.create(
        data,
        url,
        showApiDetails ? JSON.stringify(data) : undefined,
        template,
      )
    }

    if (options?.allowEmpty === true) {
      // null/empty is allowed, try to get default value if we have a way
      if (isNullOrUndefined(getDefaultIfEmpty)) {
        return ServiceResponse.create(null, url, null, template)
      } else {
        return ServiceResponse.create(
          getDefaultIfEmpty(),
          url,
          showApiDetails ? String(getDefaultIfEmpty()) : undefined,
          template,
        )
      }
    }

    // we don't have data, and null/empty is not allowed
    return ServiceResponse.fromError(new Error('null or empty response not allowed'), url)
  }

  // region cache
  private getCachedResult<TData>(urlResponse: ServiceResponse<string>): ServiceResponse<TData> {
    if (
      this.cacheSettings.shouldUseCacheControlHeaders &&
      this.cache.containsKey(urlResponse.data)
    ) {
      const data = this.cache.get<string, TData>(urlResponse.data)
      this.logger.info(`Returning cached result for (${urlResponse.data})`)
      return ServiceResponse.create(
        data,
        urlResponse.URL,
        ServiceResponse.rawDataFromCache,
        urlResponse.template,
      )
    }
  }
  private tryCache(url: string, data: any, response: AccuHttpResponse) {
    // if we're caching based on headers, ensure there's a max age and it's greater than 0
    if (this.cacheSettings.shouldUseCacheControlHeaders) {
      // max-age is in seconds, convert to millis
      this.cache.set(url, data, new CacheOptions(response.maxAge * 1000))
    }
  }

  private getCachedForRequestResult<TData>(
    url: ServiceResponse<string>,
    requestChain?: RequestChain,
  ): ServiceResponse<TData> {
    if (isNotNullOrUndefined(requestChain?.resultCache) && requestChain.resultCache.has(url.data)) {
      return requestChain.resultCache.get(url.data) as ServiceResponse<TData>
    }
  }
  private tryCacheForRequest(
    url: string,
    result: any,
    response: AccuHttpResponse,
    requestChain?: RequestChain,
  ) {
    const shouldUseRequestCache =
      requestChain?.shouldUseRequestCache ?? this.cacheSettings.shouldUseRequestCache
    if (
      shouldUseRequestCache &&
      isNotNullOrUndefined(requestChain) &&
      isNotNullOrUndefined(response.error)
    ) {
      requestChain.resultCache.set(url, result)
    }
  }
  // endregion cache
}
