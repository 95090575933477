import { EventType } from '@/models/enums/EventType'
import { Sdk } from '@/sdk/sdk'
import { LocationQuery, RouteParams } from 'vue-router'
import AWLocation from '@/models/Location'
import { BaseWeatherEvent } from '@/models/BaseWeatherEvent'
import Storm from '@/models/Storm'
import store from '@/store'

export async function getLocation(
  data: Sdk,
  query?: LocationQuery,
  storedLocation?: AWLocation,
  index = 0,
): Promise<AWLocation | undefined> {
  // getting location
  let lbc = undefined
  let location

  let locationKeyParam = undefined
  if (query && query?.key) locationKeyParam = String(query?.key).split('-')

  if (locationKeyParam && !isNaN(Number(locationKeyParam[index]))) {
    // if we have in params locationKey available
    if (!storedLocation?.Key || storedLocation.Key !== locationKeyParam[index]) {
      // if location key from params does not match with current stored location
      location = await data.getLocationByKey(locationKeyParam[index])
    } else {
      // if location key from params matches with stored location
      location = storedLocation
    }
  } else {
    // if no params with location key
    if (storedLocation?.Key) {
      // if already stored location
      location = storedLocation
    } else {
      // if no params or stored location
      lbc = await data.getLocationByIP()
      location = await data.getLocationByKey(lbc?.Key)
    }
  }
  // storing final location
  if (location?.Key) {
    localStorage.setItem('location', JSON.stringify(location))
  }

  return location
}

export async function getHomeData(
  params?: RouteParams | undefined,
  query?: LocationQuery | undefined,
  storedLocation?: AWLocation,
  include = { hourly: true, daily: true },
  locationIndex = 0,
) {
  const isMock = query?.mock === 'true' ?? false
  const data = new Sdk(isMock)
  console.log(query)
  const location = await getLocation(
    data,
    query,
    locationIndex > 0 ? undefined : storedLocation,
    locationIndex,
  )
  const weatherEvents = await data.getWeatherEvents(location)
  const currentStorm = null
  // get current conditions
  const currentConditions = await data.getCurrentConditions(location?.Key, true)
  const daily = include.daily
    ? data.getDailyWeather(location?.Key, 2)
    : store.state.home.dailyForecast
  const hourly = include.hourly
    ? data.getHourlyForecast(location?.Key, 6)
    : store.state.home.hourlyForescast

  return await Promise.all([
    data.getMinutecast(location?.Key, location?.TimeZone.GmtOffset),
    currentConditions,
    weatherEvents,
    daily,
    hourly,
    data.getAlertsByKey(location?.Key),
    currentStorm,
    weatherEvents?.some(
      (event) =>
        event.event === EventType.Ice ||
        event.event === EventType.Snow ||
        event.event === EventType.Winter,
    ),
    location,
  ])
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getCurrentStorm(
  weatherEvents: BaseWeatherEvent[],
  data: Sdk,
): Promise<Storm | null> {
  const tropicalEvents = weatherEvents?.filter((e) => e.event === EventType.Tropical)
  if (tropicalEvents?.length) {
    const storms = await data.getActiveStorms()
    // add storm forecast
    if (storms?.length) {
      await storms.forEach((storm) => {
        data.getStormForecast(storm)
        data.getStormData(storm)
      })
      return storms.find((storm) => storm.eventKey === tropicalEvents[0]?.eventKey) ?? null
    }
  }
  return null
}
