/* eslint-disable no-unused-vars */
/**
 * The types of distance formats.
 */
export enum DistanceFormatType {
  /**
   * Generic.
   */
  Generic,

  /**
   * Ceiling.
   */
  Ceiling,

  /**
   * Visibility.
   */
  Visibility,

  /**
   * Precipitation.
   */
  Precipitation,
}
