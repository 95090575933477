/**
 * Provides methods to convert speed values to other SpeedUnits.
 *
 * See also AccuWeather.Common.Measurements (api)
 */
export abstract class SpeedConversion {
  /**
   * Converts a speed to MPH.
   * @param value The original value to be converted.
   * @return The converted speed, in MPH.
   */
  public abstract toMilesPerHour(value: number): number

  /**
   * Converts a speed to KPH.
   * @param value The original value to be converted.
   * @return The converted speed, in KPH.
   */
  public abstract toKilometersPerHour(value: number): number

  /**
   * Converts a speed to mps.
   * @param value The original value to be converted.
   * @return The converted speed, in mps.
   */
  public abstract toMetersPerSecond(value: number): number

  /**
   * Converts a speed to knots.
   * @param value The original value to be converted.
   * @return The converted speed, in knots.
   */
  public abstract toKnots(value: number): number
}

/**
 * Provides methods to convert speeds in KPH to other SpeedUnits.
 */
export class KilometersPerHour extends SpeedConversion {
  /**
   * Converts a speed to MPH.
   * @param value The original value to be converted.
   * @return The converted speed, in MPH.
   */
  public toMilesPerHour(value: number): number {
    return value / 1.609
  }

  /**
   * Converts a speed to KPH.
   * @param value The original value to be converted.
   * @return The converted speed, in KPH.
   */
  public toKilometersPerHour(value: number): number {
    return value
  }

  /**
   * Converts a speed to mps.
   * @param value The original value to be converted.
   * @return The converted speed, in mps.
   */
  public toMetersPerSecond(value: number): number {
    return value / 3.6
  }

  /**
   * Converts a speed to knots.
   * @param value The original value to be converted.
   * @return The converted speed, in knots.
   */
  public toKnots(value: number): number {
    return value / 1.852
  }
}

/**
 * Provides methods to convert speeds in knots to other SpeedUnits.
 */
export class Knots extends SpeedConversion {
  /**
   * Converts a speed to MPH.
   * @param value The original value to be converted.
   * @return The converted speed, in MPH.
   */
  public toMilesPerHour(value: number): number {
    return value * 1.151
  }

  /**
   * Converts a speed to KPH.
   * @param value The original value to be converted.
   * @return The converted speed, in KPH.
   */
  public toKilometersPerHour(value: number): number {
    return value * 1.852
  }

  /**
   * Converts a speed to mps.
   * @param value The original value to be converted.
   * @return The converted speed, in mps.
   */
  public toMetersPerSecond(value: number): number {
    return value / 1.944
  }

  /**
   * Converts a speed to knots.
   * @param value The original value to be converted.
   * @return The converted speed, in knots.
   */
  public toKnots(value: number): number {
    return value
  }
}

/**
 * Provides methods to convert speeds in MPH to other SpeedUnits.
 */
export class MilesPerHour extends SpeedConversion {
  /**
   * Converts a speed to MPH.
   * @param value The original value to be converted.
   * @return The converted speed, in MPH.
   */
  public toMilesPerHour(value: number): number {
    return value
  }

  /**
   * Converts a speed to KPH.
   * @param value The original value to be converted.
   * @return The converted speed, in KPH.
   */
  public toKilometersPerHour(value: number): number {
    return value * 1.609
  }

  /**
   * Converts a speed to mps.
   * @param value The original value to be converted.
   * @return The converted speed, in mps.
   */
  public toMetersPerSecond(value: number): number {
    return value / 2.237
  }

  /**
   * Converts a speed to knots.
   * @param value The original value to be converted.
   * @return The converted speed, in knots.
   */
  public toKnots(value: number): number {
    return value / 1.151
  }
}

/**
 * Provides methods to convert speeds in mps to other SpeedUnits.
 */
export class MetersPerSecond extends SpeedConversion {
  /**
   * Converts a speed to MPH.
   * @param value The original value to be converted.
   * @return The converted speed, in MPH.
   */
  public toMilesPerHour(value: number): number {
    return value * 2.237
  }

  /**
   * Converts a speed to KPH.
   * @param value The original value to be converted.
   * @return The converted speed, in KPH.
   */
  public toKilometersPerHour(value: number): number {
    return value * 3.6
  }

  /**
   * Converts a speed to mps.
   * @param value The original value to be converted.
   * @return The converted speed, in mps.
   */
  public toMetersPerSecond(value: number): number {
    return value
  }

  /**
   * Converts a speed to knots.
   * @param value The original value to be converted.
   * @return The converted speed, in knots.
   */
  public toKnots(value: number): number {
    return value * 1.944
  }
}
