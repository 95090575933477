// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { SdkSettings, SearchRequest } from '..'
import { CacheOptions } from '../core/cache/CacheOptions'
import { ServiceResponseMapCache } from '../core/cache/ServiceResponseMapCache'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { LocationService } from '../locations/LocationService'
import { isNotNullOrUndefined, isNullOrUndefined } from '../util/Helpers'
import { ContentHttpService } from './ContentHttpService'
import { ContentRouteResolver } from './ContentRouteResolver'
import { ContentService } from './ContentService'
import { AlertsFeedRequest } from './requests/AlertsFeedRequest'
import { ArticleByIdRequest } from './requests/ArticleByIdRequest'
import { BlogsRequest } from './requests/BlogsRequest'
import { FindVideosRequest } from './requests/FindVideosRequest'
import { GalleryByIdRequest } from './requests/GalleryByIdRequest'
import { NewsFeedByCountryRequest } from './requests/NewsFeedByCountryRequest'
import { NewsFeedByDmaRequest } from './requests/NewsFeedByDmaRequest'
import { NewsFeedByIdRequest } from './requests/NewsFeedByIdRequest'
import { NewsFeedByLocationKeyRequest } from './requests/NewsFeedByLocationKeyRequest'
import { PageBySlugRequest } from './requests/PageBySlugRequest'
import { PageByTemplateRequest } from './requests/PageByTemplateRequest'
import { PartnerByIdRequest } from './requests/PartnerByIdRequest'
import { PartnerByLocationKeyRequest } from './requests/PartnerByLocationKeyRequest'
import { PartnersRequest } from './requests/PartnersRequest'
import { ProTipsByCountryRequest } from './requests/ProTipsByCountryRequest'
import { ProTipsByDmaRequest } from './requests/ProTipsByDmaRequest'
import { ProTipsByLocationKeyRequest } from './requests/ProTipsByLocationKeyRequest'
import { TropicalFeedRequest } from './requests/TropicalFeedRequest'
import { VideoByIdRequest } from './requests/VideoByIdRequest'
import { VideosByPlaylistIdRequest } from './requests/VideosByPlaylistIdRequest'
import { VideosByTagsRequest } from './requests/VideosByTagsRequest'

/**
 * Provides methods to get articles, videos, and other content.
 */
export class ContentServiceImpl implements ContentService {
  protected readonly serviceName = 'ContentService'
  private readonly locationService: LocationService
  private readonly httpService: ContentHttpService
  private readonly routeResolver: ContentRouteResolver
  private readonly partnersCache: ServiceResponseMapCache<string, any>

  constructor(
    sdkSettings: SdkSettings,
    locationService: LocationService,
    httpService: ContentHttpService,
    routeResolver: ContentRouteResolver,
  ) {
    this.locationService = locationService
    this.httpService = httpService
    this.routeResolver = routeResolver
    if (sdkSettings.cacheSettings.shouldCachePartnerMeta) {
      this.partnersCache = new ServiceResponseMapCache(
        (p) => p.partnerId,
        sdkSettings.persistentCache,
        new CacheOptions(sdkSettings.cacheSettings.partnerMetaExpiration),
      )
    }
  }

  // region landing page
  /**
   * Gets a landing page using a specified slug.
   * @param request The request.
   * @return A service response containing either a LandingePage or error information.
   */
  public async getPageBySlug(
    request: PageBySlugRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getPageBySlugUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getPageBySlug'),
    )
  }

  /**
   * Gets a landing page using a specified page ID.
   * @param request The request.
   * @return A service response containing either a LandingPage or error information.
   */
  public async getPageByTemplate(
    request: PageByTemplateRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getPageByTemplateUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getPageByTemplate'),
    )
  }
  // endregion landing page

  // region videos
  /**
   * Gets a video feed by playlist id.
   * @param request The request.
   * @return A service response containing either a list of [VideoPlayerBlock]s or error information.
   */
  public async getVideosByPlaylistId(
    request: VideosByPlaylistIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getVideosByPlaylistIdUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getVideosByPlaylistId'),
    )
  }

  /**
   * Gets a video feed by tags.
   * @param request The request.
   * @return A service response containing either a list of [VideoPlayerBlock]s or error information.
   */
  public async getVideosByTags(
    request: VideosByTagsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getVideosByTagsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getVideosByTags'),
    )
  }

  /**
   * Gets a video feed by query.
   * @param request The request.
   * @return A service response containing either a list of [VideoPlayerBlock]s or error information.
   */
  public async findVideos(
    request: FindVideosRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.findVideosUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'findVideos'),
    )
  }

  /**
   * Gets a video using a specified ID.
   * @param request The request.
   * @return A service response containing either a VideoPlayerBlock or error information.
   */
  public async getVideoById(
    request: VideoByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getVideoByIdUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getVideoById'),
    )
  }
  // endregion videos

  // region partners
  /**
   * Gets a list of all partners.
   * @param request The request.
   * @return A service response containing either a list of [PartnerBlock]s or error information.
   */
  public async getPartners(
    request: PartnersRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(this.partnersCache)) {
      return await this.partnersCache.getArrayOrAdd(
        `PartnerInfo:${request?.language}`,
        (r, rc) => this.getPartnersHttp(r, rc),
        request,
        requestChain,
      )
    }
    return await this.getPartnersHttp(request, requestChain)
  }

  /**
   * Gets a partner using a specified location key.
   * @param request The request.
   * @return A service response containing either a PartnerBlock or error information.
   */
  public async getPartnerByLocationKey(
    request: PartnerByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    // nothing from the cms partner info includes locationKey
    // we need to transform locationKey -> partnerId through location cache
    const infoResponse = await this.locationService.getCacheInfo(
      request?.language,
      request?.locationKey,
      requestChain,
    )
    if (infoResponse.hasError || isNullOrUndefined(infoResponse.data)) {
      return infoResponse.transformError()
    }

    if (isNullOrUndefined(infoResponse.data.partnerId)) {
      return ServiceResponse.create(null, null, null, 'No partner for location')
    }

    const partnerId = infoResponse.data.partnerId.toString()

    if (isNotNullOrUndefined(this.partnersCache)) {
      return await this.partnersCache.getOrAdd(
        `PartnerInfo:${request?.language}`,
        (r, rc) => this.getPartnersHttp(r, rc),
        new PartnersRequest(request.language),
        partnerId,
        requestChain,
      )
    }

    return await this.getPartnerByIdHttp(new PartnerByIdRequest(request.language, partnerId))
  }
  // endregion partners

  // region pro tips
  /**
   * Gets a collection of pro tips using a dma code or list of dma codes.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a list of ProTips or error information.
   */
  public async getProTipsByDma(
    request: ProTipsByDmaRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getProTipsByDmaUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getProTipsByDma'),
    )
  }

  /**
   * Gets a collection of pro tips using a country code.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a list of ProTips or error information.
   */
  public async getProTipsByCountry(
    request: ProTipsByCountryRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getProTipsByCountryUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getProTipsByCountry'),
    )
  }

  /**
   * Gets a collection of pro tips for the location with the given location key.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a list of ProTips or error information.
   */
  public async getProTipsByLocationKey(
    request: ProTipsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getProTipsByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getProTipsByLocationKey'),
    )
  }
  // endregion pro tips

  // region news/feeds
  /**
   * Gets a gallery using a specified ID.
   * @param request The request.
   * @return A service response containing either a GalleryBlock or error information.
   */
  public async getGalleryById(
    request: GalleryByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getGalleryByIdUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getGalleryById'),
    )
  }

  /**
   * Gets a news feed using the feed id.
   * @param request The request.
   * @return A service response containing either a NewsFeedBlock or error information.
   */
  public async getNewsFeedById(
    request: NewsFeedByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getNewsFeedByIdUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getNewsFeedById'),
    )
  }

  /**
   * Gets a news feed using a dma code or list of dma codes.
   * @param request The request.
   * @return A service response containing either a NewsFeedBlock or error information.
   */
  public async getNewsFeedByDma(
    request: NewsFeedByDmaRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getNewsFeedByDmaUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getNewsFeedByDma'),
    )
  }

  /**
   * Gets a news feed using a country code.
   * @param request The request.
   * @return A service response containing either a NewsFeedBlock or error information.
   */
  public async getNewsFeedByCountry(
    request: NewsFeedByCountryRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getNewsFeedByCountryUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getNewsFeedByCountry'),
    )
  }

  /**
   * Gets a news feed for the location with the given location key.
   * @param request The request.
   * @return A service response containing either a NewsFeedBlock or error information.
   */
  public async getNewsFeedByLocationKey(
    request: NewsFeedByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getNewsFeedByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getNewsFeedByLocationKey'),
    )
  }

  /**
   * Gets the list of blogs.
   * @param request The request.
   * @return A service response containing either a list of [Blog]s or error information.
   */
  public async getBlogs(
    request: BlogsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getBlogsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getBlogs'),
    )
  }

  /**
   * Gets an article using a specified ID.
   * @param request The request.
   * @return A service response containing either a ArticlePage or error information.
   */
  public async getArticleById(
    request: ArticleByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getArticleByIdUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getArticleById'),
    )
  }

  /**
   * Gets a list of alert component information.
   * @param request The request.
   * @return A service response containing either a list of [Alert]s or error information.
   */
  public async getAlertsFeed(
    request: AlertsFeedRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getAlertsFeedUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getAlertsFeed'),
    )
  }

  /**
   * Gets a hurricane news feed.
   * @param request The request.
   * @return A service response containing either a NewsFeedBlock or error information.
   */
  public async getTropicalFeed(
    request: TropicalFeedRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getTropicalFeedUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getTropicalFeed'),
    )
  }
  // endregion news/feeds

  // region http/internal
  private async getPartnersHttp(
    request: PartnersRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return await this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getPartnersUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getPartners'),
    )
  }
  private async getPartnerByIdHttp(
    request: PartnerByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    // the api returns a list...
    const response = await this.getPartnersHttp(
      new PartnersRequest(request?.language),
      requestChain,
    )
    // ... but just take the one with the matching id
    return response.take((r) => {
      return r.partnerId === request?.id
    }, requestChain)
  }
  // endregion http/internal

  // region search
  /**
   * Performs a search.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a SearchResult or error information.
   */
  public async search(
    request: SearchRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getSearchUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'search'),
    )
  }
  // endregion search
}
