// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined, isNullOrBlank, isNullOrUndefined } from './Helpers'

/**
 * Provides helper methods for url formatting.
 */
export class UrlHelper {
  private static dash = '-'

  private static punctuationChars = new Set<string>(['.', ',', ':', '?', "'", '`', ';', '\\', '%'])
  private static dashChars = new Set<string>(['-', '(', ')', '+', '/', '&', '*'])

  /**
   * Replaces any non-url-friendly characters in a string.
   * @param original The original text.
   * @return The url-friendly string.
   */
  public static urlFriendly(original: string): string {
    if (isNullOrUndefined(original)) return ''

    const sb = []
    const size = original.length
    let i = UrlHelper.getIndexOfNextValidChar(original, 0, size)
    while (i < size) {
      const c = original[i]
      if (!UrlHelper.shouldSkipChar(c)) {
        // either replace with dash, or make lowercase
        const charOrDash = UrlHelper.getChar(c, original, i, size)
        // if dash, may advance the index to prevent multiple dashes
        i = charOrDash[1]
        if (isNotNullOrUndefined(charOrDash[0])) {
          // will be null if the remainder of text would be dashes
          sb.push(charOrDash[0])
        }
      }
      i++
    }
    return sb.join('')
  }

  private static getChar(
    c: string,
    original: string,
    start: number,
    size: number,
  ): [string, number] {
    let char: string
    let end: number

    if (!UrlHelper.shouldReplaceChar(c)) {
      char = c.toLowerCase()
      end = start
    } else {
      // move the index to right before the next valid char
      // so we only replace with one dash at most
      // (or zero dashes, if there are no more valid chars left)
      end = UrlHelper.getIndexOfNextValidChar(original, start + 1, size) - 1
      // if there are more valid chars coming append a dash
      char = end < size ? UrlHelper.dash : null
    }

    return [char, end]
  }

  private static getIndexOfNextValidChar(original: string, start: number, size: number): number {
    for (let end = start; end < size; end++) {
      const c = original[end]
      if (!UrlHelper.shouldSkipChar(c) && !UrlHelper.shouldReplaceChar(c)) {
        return end // found one before the end; return that index
      }
    }
    return size + 1 // didn't find one- push the index out of bounds
  }

  private static shouldSkipChar(c: string): boolean {
    return UrlHelper.punctuationChars.has(c)
  }
  private static shouldReplaceChar(c: string): boolean {
    return isNullOrBlank(c) || UrlHelper.dashChars.has(c)
  }
}
