// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiHttpService } from '../core/api/ApiHttpService'
import { CacheOptions } from '../core/cache/CacheOptions'
import { ServiceResponseCache } from '../core/cache/ServiceResponseCache'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { isNotNullOrUndefined, isNullOrUndefined } from '../util/Helpers'
import { AttributionSourceType } from './AttributionSourceType'
import { DataAttributionRouteResolver } from './DataAttributionRouteResolver'
import { DataAttributionService } from './DataAttributionService'
import { AttributionSourcesRequest } from './requests/AttributionSourcesRequest'
import { AttributionSourceTypeValidRule } from './requests/AttributionSourceTypeValidRule'

/**
 * Provides methods for ...
 */
export class DataAttributionServiceImpl implements DataAttributionService {
  protected readonly serviceName = 'DataAttributionService'
  private readonly httpService: ApiHttpService
  private readonly routeResolver: DataAttributionRouteResolver
  private readonly sourcesCache: ServiceResponseCache<any[]>

  constructor(
    sdkSettings: SdkSettings,
    httpService: ApiHttpService,
    routeResolver: DataAttributionRouteResolver,
  ) {
    this.httpService = httpService
    this.routeResolver = routeResolver
    if (sdkSettings.cacheSettings.shouldCacheAttributionSources) {
      this.sourcesCache = new ServiceResponseCache(
        sdkSettings.persistentCache,
        new CacheOptions(sdkSettings.cacheSettings.attributionSourcesExpiry),
      )
    }
  }

  // region sources
  /**
   * Gets a list of data attribution sources.
   * @param request The request.
   * @return A service response containing either a list of [AttributionSource]s or error information.
   */
  public async getAttributionSources(
    request: AttributionSourcesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNullOrUndefined(this.sourcesCache) || isNullOrUndefined(request)) {
      return await this.getAttributionSourcesHttp(request, requestChain)
    }
    // check if this is an invalid request type
    const error = AttributionSourceTypeValidRule.checkRule(request.attributionSourceType)
    if (isNotNullOrUndefined(error)) {
      return ServiceResponse.fromError(error)
    }

    // get info for ALL attribution sources
    const allReq = new AttributionSourcesRequest(request.language) // new request that doesn't filter by type (just in case)
    const allAttributionSources = await this.sourcesCache.getOrAdd(
      `AttributionSources:${request.language}`,
      (r, rc) => this.getAttributionSourcesHttp(r, rc),
      allReq,
      requestChain,
    )

    // if we received an error, a null value, or we're not filtering on type, return allAttributionSources
    if (
      allAttributionSources.hasError ||
      isNullOrUndefined(allAttributionSources.data) ||
      isNullOrUndefined(request.attributionSourceType)
    ) {
      return allAttributionSources
    }

    // build a list of filtered values
    const filteredValues = []
    for (const source of allAttributionSources.data) {
      const cloned = this.cloneSourceAndFilterTypes(source, request.attributionSourceType)
      if (isNotNullOrUndefined(cloned?.dataTypes)) {
        const dataTypes = cloned.dataTypes as any[]
        if (isNotNullOrUndefined(dataTypes) && dataTypes.length > 0) {
          filteredValues.push(cloned)
        }
      }
    }
    return ServiceResponse.create(
      filteredValues,
      allAttributionSources.URL,
      allAttributionSources.rawData,
      allAttributionSources.template,
    )
  }

  private async getAttributionSourcesHttp(
    request: AttributionSourcesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return await this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getAttributionSourcesUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getAttributionSources'),
    )
  }

  private cloneSourceAndFilterTypes(from: any, type: AttributionSourceType): any {
    const clone = {}
    if (isNotNullOrUndefined(from)) {
      clone['id'] = from.id
      clone['name'] = from.name
      clone['attributionText'] = from.attributionText
      clone['url'] = from.url
      const filteredDataTypes = []
      if (isNotNullOrUndefined(from.dataTypes)) {
        for (const dataType of from.dataTypes as any[]) {
          if (dataType?.dataType === type) {
            filteredDataTypes.push(dataType)
          }
        }
      }
      clone['dataTypes'] = filteredDataTypes
    }
    return clone
  }
  // endregion sources
}
