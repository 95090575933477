// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CardinalDirection, PressureTendencyCode, SdkSettings } from '../../..'
import {
  getDirectionDegrees,
  getDirectionEnglishText,
  getDirectionLocalizedText,
  getPressureTendencySymbol,
} from '../../../core/models/measurements/ModelHelpers'
import { formatString, isNotNullOrEmpty, isNotNullOrUndefined } from '../../../util/Helpers'
import { CoordinateFormatType } from '../../options/units/CoordinateFormatType'
import { CoordinatesFormatOptions } from '../../options/units/CoordinatesFormatOptions'
import { DirectionFormatOptions } from '../../options/units/DirectionFormatOptions'
import { DistanceFormatOptions } from '../../options/units/DistanceFormatOptions'
import { DistanceFormatType } from '../../options/units/DistanceFormatType'
import { IndexDataFormatOptions } from '../../options/units/IndexDataFormatOptions'
import { PercentFormatOptions } from '../../options/units/PercentFormatOptions'
import { PercentFormatType } from '../../options/units/PercentFormatType'
import { PrecipitationFormatOptions } from '../../options/units/PrecipitationFormatOptions'
import { PressureFormatOptions } from '../../options/units/PressureFormatOptions'
import { RoundingMethod } from '../../options/units/RoundingMethod'
import { SpeedFormatOptions } from '../../options/units/SpeedFormatOptions'
import { TemperatureFormatOptions } from '../../options/units/TemperatureFormatOptions'
import { UnitFormatOptions } from '../../options/units/UnitFormatOptions'
import { WindFormatOptions } from '../../options/units/WindFormatOptions'
import { NumbFormatter } from '../numbers/NumbFormatter'
import { RoundingProvider } from './RoundingProvider'
import { UnitFormatProvider } from './UnitFormatProvider'

// internal
export class MeasurementFormatter {
  private readonly unitFormatProvider: UnitFormatProvider
  private readonly roundingProvider = new RoundingProvider()
  private readonly numbFormatter = new NumbFormatter()
  constructor(settings: SdkSettings) {
    this.unitFormatProvider = new UnitFormatProvider(settings)
  }

  public setMap(unitMaps: Map<string, any>, numberMaps: Map<string, any>) {
    this.unitFormatProvider.setMap(unitMaps)
    this.numbFormatter.setMap(numberMaps)
  }

  public temperature(value: number, formatOptions: TemperatureFormatOptions): string {
    const pattern = this.unitFormatProvider.getTemperaturePattern(value, formatOptions)
    if (isNotNullOrUndefined(pattern)) {
      // we expect value to be non-null if we have a formatPattern
      const defaultPrecision = this.roundingProvider.temperaturePrecision
      const rounded = this.round(value, defaultPrecision, formatOptions)
      return formatString(pattern, rounded)
    }
  }
  public temperatureRange(
    minValue: number,
    maxValue: number,
    formatOptions: TemperatureFormatOptions,
  ): string {
    if (isNotNullOrUndefined(minValue) && isNotNullOrUndefined(maxValue)) {
      const pattern = this.unitFormatProvider.getTemperatureRangePattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        const maxPrecision = this.roundingProvider.temperaturePrecision
        const minPrecision = minValue === 0 ? 0 : maxPrecision
        const max = this.round(maxValue, maxPrecision, formatOptions)
        const min = this.round(minValue, minPrecision, formatOptions)
        return formatString(pattern, min, max)
      }
    }
  }

  public precipitation(value: number, formatOptions: PrecipitationFormatOptions): string {
    // are the precip formatters responsible for handling 'trace'?
    const pattern = this.unitFormatProvider.getLengthPattern(value, formatOptions)
    if (isNotNullOrUndefined(pattern)) {
      // we expect value to be non-null if we have a formatPattern
      const defaultPrecision = this.roundingProvider.getPrecipitationPrecision(formatOptions)
      const rounded = this.round(value, defaultPrecision, formatOptions)
      return formatString(pattern, rounded)
    }
  }
  public precipitationRange(
    minValue: number,
    maxValue: number,
    formatOptions: PrecipitationFormatOptions,
  ): string {
    if (isNotNullOrUndefined(minValue) && isNotNullOrUndefined(maxValue)) {
      const pattern = this.unitFormatProvider.getLengthRangePattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        const maxPrecision = this.roundingProvider.getPrecipitationPrecision(formatOptions)
        const minPrecision = minValue === 0 ? 0 : maxPrecision
        const max = this.round(maxValue, maxPrecision, formatOptions)
        const min = this.round(minValue, minPrecision, formatOptions)
        return formatString(pattern, min, max)
      }
    }
  }

  public distance(value: number, formatOptions: DistanceFormatOptions): string {
    const pattern = this.unitFormatProvider.getLengthPattern(value, formatOptions)
    if (isNotNullOrUndefined(pattern)) {
      // we expect value to be non-null if we have a formatPattern
      const defaultPrecision = this.getLengthPrecision(value, formatOptions)
      const rounded = this.round(value, defaultPrecision, formatOptions)
      return formatString(pattern, rounded)
    }
  }
  public distanceRange(
    minValue: number,
    maxValue: number,
    formatOptions: DistanceFormatOptions,
  ): string {
    if (isNotNullOrUndefined(minValue) && isNotNullOrUndefined(maxValue)) {
      const pattern = this.unitFormatProvider.getLengthRangePattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        const maxPrecision = this.getLengthPrecision(maxValue, formatOptions)
        const minPrecision = minValue === 0 ? 0 : this.getLengthPrecision(minValue, formatOptions)
        const max = this.round(maxValue, maxPrecision, formatOptions)
        const min = this.round(minValue, minPrecision, formatOptions)
        return formatString(pattern, min, max)
      }
    }
  }

  public speed(value: number, formatOptions: SpeedFormatOptions): string {
    const pattern = this.unitFormatProvider.getSpeedPattern(value, formatOptions)
    if (isNotNullOrUndefined(pattern)) {
      // we expect value to be non-null if we have a formatPattern
      const defaultPrecision = this.roundingProvider.speedPrecision
      const rounded = this.round(value, defaultPrecision, formatOptions)
      return formatString(pattern, rounded)
    }
  }
  public speedRange(minValue: number, maxValue: number, formatOptions: SpeedFormatOptions): string {
    if (isNotNullOrUndefined(minValue) && isNotNullOrUndefined(maxValue)) {
      const pattern = this.unitFormatProvider.getSpeedRangePattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        const maxPrecision = this.roundingProvider.speedPrecision
        const minPrecision = minValue === 0 ? 0 : maxPrecision
        const max = this.round(maxValue, maxPrecision, formatOptions)
        const min = this.round(minValue, minPrecision, formatOptions)
        return formatString(pattern, min, max)
      }
    }
  }

  public direction(direction: any, formatOptions: DirectionFormatOptions): string {
    if (isNotNullOrUndefined(direction) && isNotNullOrUndefined(formatOptions)) {
      if (!formatOptions.isExact) {
        return getDirectionLocalizedText(direction) || getDirectionEnglishText(direction)
      }
      const pattern = this.unitFormatProvider.getDegreesPattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        const defaultPrecision = this.roundingProvider.directionPrecision
        const rounded = this.round(getDirectionDegrees(direction), defaultPrecision, formatOptions)
        return formatString(pattern, rounded)
      }
    }
  }

  public wind(speed: number, direction: any, formatOptions: WindFormatOptions): string {
    if (isNotNullOrUndefined(formatOptions)) {
      // are the wind formatters responsible for handling 'calm'?
      const speedText = this.speed(speed, formatOptions.toSpeedFormatOptions())
      if (isNotNullOrEmpty(speedText)) {
        // if wind speed is 0, direction should not be displayed
        const directionText =
          speed === 0
            ? undefined
            : this.direction(direction, formatOptions.toDirectionFormatOptions())
        return directionText === undefined ? speedText : `${directionText} ${speedText}`
      }
    }
  }

  public gust(speed: number, formatOptions: SpeedFormatOptions): string {
    return this.speed(speed, formatOptions)
  }

  public pressure(
    value: number,
    tendency: PressureTendencyCode,
    formatOptions: PressureFormatOptions,
  ): string {
    const pattern = this.unitFormatProvider.getPressurePattern(value, formatOptions)
    if (isNotNullOrUndefined(pattern)) {
      // we expect value to be non-null if we have a formatPattern
      const defaultPrecision = this.roundingProvider.getPressurePrecision(formatOptions)
      const rounded = this.round(value, defaultPrecision, formatOptions)
      const pressure = formatString(pattern, rounded)
      return this.pressureWithTendency(pressure, tendency)
    }
  }
  public pressureRange(
    minValue: number,
    maxValue: number,
    tendency: PressureTendencyCode,
    formatOptions: PressureFormatOptions,
  ): string {
    if (isNotNullOrUndefined(minValue) && isNotNullOrUndefined(maxValue)) {
      const pattern = this.unitFormatProvider.getPressureRangePattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        const maxPrecision = this.roundingProvider.getPressurePrecision(formatOptions)
        const minPrecision = minValue === 0 ? 0 : maxPrecision
        const max = this.round(maxValue, maxPrecision, formatOptions)
        const min = this.round(minValue, minPrecision, formatOptions)
        const range = formatString(pattern, min, max)
        return this.pressureWithTendency(range, tendency)
      }
    }
  }
  private pressureWithTendency(pressure: string, tendency: PressureTendencyCode): string {
    if (isNotNullOrEmpty(pressure)) {
      const symbol = getPressureTendencySymbol(tendency)
      if (isNotNullOrEmpty(symbol)) {
        return `${pressure} ${symbol}`
      }
    }
    return pressure
  }

  private coordinatesSeparator = ', '
  public coordinates(latlon: [number, number], formatOptions: CoordinatesFormatOptions): string {
    if (isNotNullOrUndefined(latlon)) {
      const lat = this.coordinate(
        latlon[0],
        latlon[0] < 0 ? CardinalDirection.S : CardinalDirection.N,
        formatOptions,
      )
      if (isNotNullOrUndefined(lat)) {
        const lon = this.coordinate(
          latlon[1],
          latlon[1] < 0 ? CardinalDirection.W : CardinalDirection.E,
          formatOptions,
        )
        if (isNotNullOrUndefined(lon)) {
          return lat + this.coordinatesSeparator + lon
        }
      }
    }
  }
  private coordinate(
    value: number,
    direction: CardinalDirection,
    formatOptions: CoordinatesFormatOptions,
  ): string {
    const formatPattern = this.unitFormatProvider.getCoordinatePattern(
      value,
      direction,
      formatOptions,
    )
    if (isNotNullOrUndefined(formatPattern)) {
      const [degrees, minutes, seconds] = this.splitCoordinate(value, formatOptions)
      return formatString(formatPattern, degrees, minutes, seconds)
    }
  }
  private splitCoordinate(
    latlon: number,
    formatOptions: CoordinatesFormatOptions,
  ): [string, string, string] {
    if (
      formatOptions.coordinateFormatType === CoordinateFormatType.DecimalDegrees ||
      formatOptions.coordinateFormatType === CoordinateFormatType.DecimalGeneric
    ) {
      const defaultPrecision = this.roundingProvider.decimalCoordinatePrecision
      const rounded = this.round(latlon, defaultPrecision, formatOptions)
      return [rounded, null, null]
    }

    // todo: verify String()/Math.floor do enough rounding to int after float math

    // we need to split the double into integer/fraction parts
    const wholeDegrees = Math.trunc(latlon)
    // fractional degrees is the base for minutes
    // use absolute value in case latlon is negative
    const fractionalDegrees = Math.abs(latlon - wholeDegrees)
    const totalMinutes = fractionalDegrees * 60
    // truncate not necessary now- minutes should always be positive
    const wholeMinutes = Math.floor(totalMinutes)

    const latlonText = String(wholeDegrees)
    const minutesText = String(wholeMinutes)
    if (formatOptions.coordinateFormatType === CoordinateFormatType.Minutes) {
      return [latlonText, minutesText, null]
    }

    // fractional minutes is the base for seconds
    const fractionalMinutes = totalMinutes - wholeMinutes
    // we don't care about fractional seconds
    const wholeSeconds = Math.floor(fractionalMinutes * 60)
    return [latlonText, minutesText, String(wholeSeconds)]
  }

  public indexData(value: number, formatOptions: IndexDataFormatOptions): string {
    // we have no formatPattern here; there is no 'unit' (just an arbitrary value)
    // the sole purpose here is 'rounding' (truncating)
    if (isNotNullOrUndefined(value)) {
      // can't be less than [minimumValue]
      const adjustedValue = Math.max(formatOptions.minimumValue, value)
      const defaultPrecision = this.roundingProvider.indexDataPrecision
      return this.round(adjustedValue, defaultPrecision, formatOptions)
    }
  }

  public percentage(value: number, formatOptions: PercentFormatOptions): string {
    const pattern = this.unitFormatProvider.getPercentPattern(value, formatOptions)
    if (isNotNullOrUndefined(pattern)) {
      // we expect value to be non-null if we have a formatPattern
      const defaultPrecision = this.getPercentPrecision(formatOptions)
      const rounded = this.round(value, defaultPrecision, formatOptions)
      return formatString(pattern, rounded)
    }
  }
  public percentageRange(
    minValue: number,
    maxValue: number,
    formatOptions: PercentFormatOptions,
  ): string {
    if (isNotNullOrUndefined(minValue) && isNotNullOrUndefined(maxValue)) {
      const pattern = this.unitFormatProvider.getPercentRangePattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        const maxPrecision = this.getPercentPrecision(formatOptions)
        const minPrecision = minValue === 0 ? 0 : maxPrecision
        const max = this.round(maxValue, maxPrecision, formatOptions)
        const min = this.round(minValue, minPrecision, formatOptions)
        return formatString(pattern, min, max)
      }
    }
  }
  private getLengthPrecision(value: number, formatOptions: DistanceFormatOptions): number {
    switch (formatOptions.distanceFormatType) {
      case DistanceFormatType.Ceiling:
        return this.roundingProvider.ceilingPrecision
      case DistanceFormatType.Visibility:
        return this.roundingProvider.getVisibilityPrecision(value ?? 0, formatOptions)
      default:
        return this.roundingProvider.lengthPrecision
    }
  }

  private getPercentPrecision(formatOptions: PercentFormatOptions): number {
    switch (formatOptions.percentFormatType) {
      case PercentFormatType.CloudCover:
        return this.roundingProvider.cloudCoverPrecision
      case PercentFormatType.Humidity:
        return this.roundingProvider.humidityPrecision
      case PercentFormatType.PrecipitationProbability:
        return this.roundingProvider.precipitationProbabilityPrecision
      default:
        return this.roundingProvider.percentPrecision
    }
  }

  private round(value: number, defaultPrecision: number, formatOptions: UnitFormatOptions): string {
    if (formatOptions.roundingMethod === RoundingMethod.None) {
      return value.toString()
    }

    let precision = 0
    let rounded: number

    if (formatOptions.roundingMethod === RoundingMethod.Floor) {
      rounded = Math.floor(value)
    } else if (formatOptions.roundingMethod === RoundingMethod.NearestFive) {
      rounded = Math.round(value / 5.0) * 5
    } else {
      precision =
        formatOptions.roundingMethod === RoundingMethod.CustomPrecision
          ? formatOptions.customPrecision
          : defaultPrecision
      const factor = Math.pow(10, precision)
      rounded = Math.round(value * factor) / factor
    }

    if (formatOptions.formatNumber) {
      return this.numbFormatter.formatNumber(value, formatOptions.languageCode)
    }
    // we still need to show n decimal places, so we use Number.toFixed()
    // todo: toFixed() will also perform rounding; do we need to round manually?
    return Number(rounded).toFixed(precision)
  }
}
