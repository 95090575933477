import { createStore } from 'vuex'
import home from './modules/home'
import { RootState } from './types'
import screensaver from './modules/screensaver'

export default createStore<RootState>({
  getters: {},
  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    },
    setIsMapFailed(state, payload) {
      state.isMapFailed = payload
    },
    setHomeSelectedElement(state, el) {
      state.homeSelectedElement = el
    },
    setMenuOpened(state, payload) {
      state.isMenuOpened = payload
    },
  },
  actions: {},
  modules: {
    home,
    screensaver,
  },
})
