// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { LanguageValidRule } from '../core/validation/LanguageValidRule'
import { ObjectNotNullOrUndefinedRule } from '../core/validation/ObjectNotNullOrUndefinedRule'
import { LocationService } from '../locations/LocationService'
import { isNullOrUndefined } from '../util/Helpers'
import { PartnerRouteResolver } from './PartnerRouteResolver'
import { BrandingPartnerByLocationKeyRequest } from './requests/BrandingPartnerByLocationKeyRequest'
import { BrandingPartnersRequest } from './requests/BrandingPartnersRequest'

/**
 * Resolves routes for [PartnerService] methods.
 */
export class PartnerRouteResolverImpl implements PartnerRouteResolver {
  private readonly partnerIdToken = 'partnerId'

  private readonly sdkSettings: SdkSettings
  private readonly routeResolver: ApiRouteResolver
  private readonly locationService: LocationService

  // region routes
  private readonly brandingPartners = 'BrandingPartners'
  private readonly brandingPartnerByLocationKey = 'BrandingPartnerByLocationKey'

  private readonly routeTemplates: Map<string, string> = new Map([
    [this.brandingPartners, 'locations/v1/partners?apikey={apikey}'],
    [this.brandingPartnerByLocationKey, 'locations/v1/partners/{partnerId}?apikey={apikey}'],
  ])
  // endregion routes

  // region validators
  private readonly brandingPartnerByLocationKeyRequestValidator: AsyncValidator<BrandingPartnerByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
    ])

  private readonly brandingPartnersRequestValidator: AsyncValidator<BrandingPartnersRequest> =
    new AsyncValidator([(r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request')])
  // endregion validators

  constructor(sdkSettings: SdkSettings, locationService: LocationService) {
    this.sdkSettings = sdkSettings
    this.locationService = locationService
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, this.sdkSettings)
  }

  // region branding partners
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getBrandingPartnerByLocationKeyUrl(
    request: BrandingPartnerByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    const infoResponse = await this.locationService.getCacheInfo(
      request?.language,
      request?.locationKey,
    )
    // if (isNullOrUndefined(infoResponse))
    //     return ServiceResponse.fromError<string>(new Error("unable to get location info"))

    const info = infoResponse.data
    if (infoResponse.hasError || isNullOrUndefined(info)) {
      // this IS an error; return the error
      return infoResponse.transformError()
    }

    if (isNullOrUndefined(info.partnerId)) {
      // this IS NOT an error; just return an empty result
      return ServiceResponse.create()
    }

    return await this.routeResolver.resolveAsync(
      this.brandingPartnerByLocationKey,
      request,
      this.brandingPartnerByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([[this.partnerIdToken, info.partnerId]]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getBrandingPartnersUrl(
    request: BrandingPartnersRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.brandingPartners,
      request,
      this.brandingPartnersRequestValidator,
      requestChain,
      new Map<string, any>([]),
    )
  }
  // endregion branding partners
}
