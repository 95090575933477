// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export enum LogLevel {
  debug = 0,
  info = 1,
  warn = 2,
  error = 3,
  none = 4,
}

export class Logger {
  public static getInstance(): Logger {
    return new Logger()
  }

  public static setLevel(level: LogLevel) {
    Logger.levelToLog = level
  }

  private static levelToLog: LogLevel = LogLevel.info

  private static nameMap: Map<LogLevel, string> = new Map([
    [LogLevel.debug, 'debug'],
    [LogLevel.info, 'info'],
    [LogLevel.warn, 'warn'],
    [LogLevel.error, 'error'],
  ])

  protected constructor() {
    // unused
  }

  public debug(msg: any) {
    this.writeToConsole(LogLevel.debug, msg)
  }
  public info(msg: any) {
    this.writeToConsole(LogLevel.info, msg)
  }
  public warn(msg: any) {
    this.writeToConsole(LogLevel.warn, msg)
  }
  public error(msg: any) {
    this.writeToConsole(LogLevel.error, msg)
  }

  private writeToConsole(level: LogLevel, msg: any) {
    if (level >= Logger.levelToLog) {
      console[Logger.nameMap.get(level)](msg)
    }
  }
}
