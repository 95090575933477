/**
 * Used to make requests to the ITranslationService.
 */
export class ActiveStormsRequest {
  /**
   * Gets the language (i.e. en-us) in which the response is returned.
   */
  public readonly language: string

  /**
   * @param language the language in which the response is returned.
   */
  constructor(language: string) {
    this.language = language
  }
}
