/**
 * Encapsulates the parameters used to make requests to the appropriate API endpoint.
 * This request can be used to make requests to the LocationService to find postal codes locations.
 */
export class FindPostalCodeLocationsRequest {
  /**
   * The language (i.e. en-us) in which the response is returned.
   */
  public readonly language: string

  /**
   * The postal code.
   */
  public readonly postalCode: string

  /**
   * Gets or sets the country code by which to filter the results.
   */
  public countryCode: string

  /**
   * Gets or sets the details flag. True indicates that the response should include the full details.
   */
  public details: boolean

  constructor(language: string, postalCode: string, countryCode = '', details = false) {
    this.language = language
    this.postalCode = postalCode
    this.countryCode = countryCode
    this.details = details
  }
}
