import { MapType } from '../MapType'

export enum StaticMapTheme {
  /**
   * Standard.
   */
  Standard,

  /**
   * Dark.
   */
  Dark,
}

/**
 * Encapsulates parameters used to make requests to [MapService].
 */
export class StaticMapRequest {
  /**
   * Gets or sets the language (i.e. en-us) for which the response should be returned in.
   */
  public readonly language: string

  /**
   * Gets or sets the latitude of the map center.
   */
  public readonly latitude: number

  /**
   * Gets or sets the longitude of the map center.
   */
  public readonly longitude: number

  /**
   * Gets or sets the map type.
   * Optional, defaults to [MapType.Radar].
   * Currently, only [MapType.Radar] and [MapType.Satellite] are supported.
   */
  public readonly mapType: MapType

  /**
   * Gets or sets the viewing height or distance the viewer appears to be from the map surface.
   * Defaults to 7.
   */
  public zoomLevel = 7

  /**
   * Gets or sets the theme.
   * Defaults to [StaticMapTheme.Standard].
   */
  public theme: StaticMapTheme = StaticMapTheme.Standard

  /**
   * Gets or sets the width.
   * Max is 1024. Defaults to 480.
   */
  public width = 480

  /**
   * Gets or sets the height.
   * Max is 1024. Defaults to 270.
   */
  public height = 270

  /**
   * Initializes a new [StaticMapRequest]
   * @param language the language for which the response should be returned.
   * @param latitude The latitude of the map center.
   * @param longitude The longitude of the map center.
   * @param mapType The map type. Optional, defaults to [MapType.Radar]. Currently, only [MapType.Radar] and [MapType.Satellite] are supported.
   */
  constructor(
    language: string,
    latitude: number,
    longitude: number,
    mapType: MapType = MapType.Radar,
  ) {
    this.language = language
    this.latitude = latitude
    this.longitude = longitude
    this.mapType = mapType
  }
}
