// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined, isNullOrBlank } from '../../util/Helpers'

/**
 * Provides a method used to validate an object.
 */
export class StringNotNullOrWhiteSpaceRule {
  /**
   * Checks whether a string is null, undefined, or whitespace.
   * @param value The value to be validated.
   * @param name The parameter name. Defaults to "value".
   * @return If `value` is invalid, an exception; otherwise, undefined.
   */
  public static checkRule(value: string, name: string = this.parameterName): Error {
    if (isNullOrBlank(value)) {
      return Error(`${name} cannot be null, undefined, or empty. supplied value: ${value}`)
    } else {
      return undefined
    }
  }

  /**
   * Checks whether each string in a collection is null, undefined, or whitespace.
   * @param values The values to be validated.
   * @param itemName The item name. Defaults to "value".
   * @return If `values` is invalid, an exception; otherwise, undefined.
   */
  public static checkArrayRule(values: string[], itemName: string = this.parameterName): Error {
    for (const value of values) {
      const error = this.checkRule(value, itemName)
      if (isNotNullOrUndefined(error)) {
        return error
      }
    }
    return undefined
  }
  private static readonly parameterName = 'value'
}
