// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { RequestChain } from '..'
import { firstOrDefault, isNotNullOrUndefined } from '../util/Helpers'
import { ServiceError } from './ServiceError'

/**
 * A wrapper for service calls, in order to return either data or error information rather than throwing exceptions.
 */
export class ServiceResponse<T> {
  /**
   * Constant for indication from Cache.
   */
  public static get rawDataFromCache(): string {
    return 'From Cache'
  }

  /**
   * Gets whether error is not null or undefined.
   */
  public get hasError(): boolean {
    return isNotNullOrUndefined(this.error)
  }

  /**
   * Creates a response with the supplied data.
   * @param data the data.
   * @param url request URL.
   * @param rawData response content.
   */
  public static create<T>(
    data?: T,
    url?: string,
    rawData?: string,
    template?: string,
  ): ServiceResponse<T> {
    return new ServiceResponse(data, null, url, rawData, template)
  }

  /**
   * Creates a `ServiceResponse` using the given error.
   * @param error the error.
   * @param url request URL.
   */
  public static fromError<T>(error: Error, url?: string, template?: string): ServiceResponse<T> {
    return new ServiceResponse(undefined, new ServiceError(error), url, template)
  }

  /**
   * Creates a `ServiceResponse` using the given `ServiceError`.
   * @param error the `ServiceError`.
   */
  public static fromServiceError<T>(
    error: ServiceError,
    url?: string,
    template?: string,
  ): ServiceResponse<T> {
    return new ServiceResponse(undefined, error, url, template)
  }

  /**
   * The response content.
   */
  public readonly rawData: string

  /**
   * The request URL.
   */
  public readonly URL: string

  /**
   * The request Template.
   */
  public template: string

  /**
   * The response model object.
   */
  public readonly data: T

  /**
   * The `ServiceError` object, if available.
   */
  public readonly error: ServiceError

  /**
   * Initializes a new `ServiceResponse`.
   * @param data the response model object.
   * @param error the ServiceError if available.
   */
  constructor(data: T, error?: ServiceError, url?: string, rawData?: string, template?: string) {
    this.data = data
    this.error = error
    this.URL = url
    this.rawData = rawData
    this.template = template
  }

  /**
   * Converts a `ServiceResponse` of type `T` into another response of type `R`.
   * @param error The error.
   */
  public transformError<R>(error?: Error): ServiceResponse<R> {
    // there was an error, but we need to convert from one return type to another.
    // if we don't have an error in the original response, we have another problem, but we don't know what it is...
    const err = this.hasError
      ? this.error
      : new ServiceError(isNotNullOrUndefined(error) ? error : new Error('unknown error'))
    return ServiceResponse.fromServiceError(err, this.URL, this.template)
  }

  /**
   * Converts a response with a list of items into a response with one item, using the first item in the list.
   * If `this.data` is null, undefined or empty, the result will be an error response if `allowEmpty` is `false`.
   * @param requestChain Optional. RequestChain.isDryRun is used.
   * @param error Optional. The new error to use, if the original response has errors but they are not to be used for the transformed response.
   */
  public takeFirst(
    requestChain?: RequestChain,
    error?: Error,
    allowEmpty = false,
  ): ServiceResponse<object> {
    const arr = this.data as unknown as any[]
    return this.take(
      function (r) {
        return arr.indexOf(r) === 0
      },
      requestChain,
      error,
      allowEmpty,
    )
  }

  /**
   * Converts a response with a list of items into a response with one item, using the first item that matches predicate.
   * If `this.data` is null, undefined or empty, the result will be an error response if `allowEmpty` is `false`.
   * @param predicate The predicate used to find a match.
   * @param requestChain Optional. RequestChain.isDryRun is used.
   * @param error Optional. The new error to use, if the original response has errors but they are not to be used for the transformed response.
   * @param allowEmpty Optional. Indicates whether to allow an empty response.
   */
  public take(
    predicate: (t: any) => boolean,
    requestChain?: RequestChain,
    error?: Error,
    allowEmpty = false,
  ): ServiceResponse<object> {
    // error response is error
    if (this.hasError) {
      return this.transformError<object>(isNotNullOrUndefined(error) ? error : this.error.error)
    }

    // if we have a list, try to find the item
    const arr = this.data as unknown as any[]
    if (isNotNullOrUndefined(arr) && arr.length > 0) {
      const item = firstOrDefault(arr, predicate)
      if (isNotNullOrUndefined(item)) {
        return ServiceResponse.create(item, this.URL, this.rawData, this.template)
      }
    }

    // we didn't find an item...
    const allowEmptyInternal = allowEmpty || (requestChain?.isDryRun ?? false)
    if (allowEmptyInternal) {
      // ...and that's ok
      return ServiceResponse.create(null, this.URL, null, this.template)
    }

    // ...but empty not allowed, so that's also an error
    return this.transformError<object>(
      isNotNullOrUndefined(error) ? error : Error('cannot take first- no data'),
    )
  }
}
