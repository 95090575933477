// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { ApiTokens } from '../core/api/ApiTokens'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { ProductType } from '../core/support/ProductType'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { LanguageValidRule } from '../core/validation/LanguageValidRule'
import { LocationKeyValidRule } from '../core/validation/LocationKeyValidRule'
import { ObjectNotNullOrUndefinedRule } from '../core/validation/ObjectNotNullOrUndefinedRule'
import { ProductAvailabilityService } from '../productavailability/ProductAvailabilityService'
import { AlertRouteResolver } from './AlertRouteResolver'
import { AlertsByLocationKeyRequest } from './requests/AlertsByLocationKeyRequest'

/**
 * Resolves routes for [AlertService] methods.
 */
export class AlertRouteResolverImpl implements AlertRouteResolver {
  protected readonly sdkSettings: SdkSettings
  protected readonly routeResolver: ApiRouteResolver
  protected readonly productAvailabilityService: ProductAvailabilityService

  // region routes
  private readonly alertsByLocationKey = 'AlertsByLocationKey'
  private readonly accuAlertsByLocationKey = 'AccuAlertsByLocationKey'

  private readonly routeTemplates: Map<string, string> = new Map([
    [
      this.alertsByLocationKey,
      'alerts/v1/{locationKey}.json?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.accuAlertsByLocationKey,
      'alerts/v1/accu/{locationKey}.json?apikey={apikey}&language={language}&details={details}',
    ],
  ])
  // endregion routes

  // region validators
  private readonly alertsByLocationKeyRequestValidator: AsyncValidator<AlertsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])
  private readonly accuAlertsByLocationKeyRequestValidator: AsyncValidator<AlertsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])
  // endregion validators

  constructor(sdkSettings: SdkSettings, productAvailabilityService: ProductAvailabilityService) {
    this.sdkSettings = sdkSettings
    this.productAvailabilityService = productAvailabilityService
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, this.sdkSettings)
    this.addValidatorRules()
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getAlertsByLocationKeyUrl(
    request: AlertsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.alertsByLocationKey,
      request,
      this.alertsByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getAccuAlertsByLocationKeyUrl(
    request: AlertsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.accuAlertsByLocationKey,
      request,
      this.accuAlertsByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  private addValidatorRules() {
    this.alertsByLocationKeyRequestValidator.addAsyncRule((r, rc) =>
      this.productAvailabilityService.checkLocation(
        ProductType.Alerts,
        r.language,
        r.locationKey,
        rc,
      ),
    )

    // new product type?
    // this.accuAlertsByLocationKeyRequestValidator.addAsyncRule((r, rc) =>
    //   this.productAvailabilityService.checkLocation(ProductType.Alerts, r.language, r.locationKey, rc)
    // );
  }
}
