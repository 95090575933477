import { ApiSettings, SdkSettings } from '../src'

export interface ApiConfig {
  baseUrlKey: 'apiUri' | 'awxApi'
  endpoint: string
  queryParams?: Record<string, string>
}

export function GetsdkSettings(isMock: boolean | undefined = false): SdkSettings {
  const apiSettings = new ApiSettings(
    process.env.VUE_APP_AW_TOKEN ?? '',
    getBaseUrl('apiUri', isMock),
  )
  apiSettings.timeoutPerTry = 4000
  apiSettings.overallTimeout = 8000

  const useMockenabled = process.env.VUE_APP_USE_MOCK === 'true'
  const headers = new Map<string, string>()
  headers.set('workspace-id', process.env.VUE_APP_WORKSPACE_ID ?? '')

  apiSettings.headers = useMockenabled ? headers : undefined
  return new SdkSettings(apiSettings)
}

export function getBaseUrl(uri: 'apiUri' | 'awxApi', isMock: boolean | undefined = false): string {
  // eslint-disable-next-line no-extra-boolean-cast
  if (process.env.VUE_APP_USE_MOCK === 'true' || isMock) return process.env.VUE_APP_MOCK_URI ?? ''
  return uri === 'apiUri' ? process.env.VUE_APP_API_URI ?? '' : process.env.VUE_APP_AWX_API ?? ''
}
