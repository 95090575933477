import { AlmanacRouteResolverImpl } from '../almanac/AlmanacRouteResolverImpl'
import { AlmanacService } from '../almanac/AlmanacService'
import { AlmanacServiceImpl } from '../almanac/AlmanacServiceImpl'
import { DataAttributionRouteResolverImpl } from '../attribution/DataAttributionRouteResolverImpl'
import { DataAttributionService } from '../attribution/DataAttributionService'
import { DataAttributionServiceImpl } from '../attribution/DataAttributionServiceImpl'
import { ContentHttpService } from '../content/ContentHttpService'
import { ContentRouteResolverImpl } from '../content/ContentRouteResolverImpl'
import { ContentService } from '../content/ContentService'
import { ContentServiceImpl } from '../content/ContentServiceImpl'
import { LocalizationService } from '../i18n/LocalizationService'
import { LocalizationServiceImpl } from '../i18n/LocalizationServiceImpl'
import { LocationRouteResolverImpl } from '../locations/LocationRouteResolverImpl'
import { LocationService } from '../locations/LocationService'
import { LocationServiceImpl } from '../locations/LocationServiceImpl'
import { PartnerRouteResolverImpl } from '../partners/PartnerRouteResolverImpl'
import { PartnerService } from '../partners/PartnerService'
import { PartnerServiceImpl } from '../partners/PartnerServiceImpl'
import { TranslationRouteResolverImpl } from '../translations/TranslationRouteResolverImpl'
import { TranslationService } from '../translations/TranslationService'
import { TranslationServiceImpl } from '../translations/TranslationServiceImpl'
import { ApiHttpService } from './api/ApiHttpService'
import { BaseServiceProvider } from './BaseServiceProvider'
import { Logger } from './Logger'
import { SdkSettings } from './settings/SdkSettings'

export abstract class BaseServiceModule implements BaseServiceProvider {
  public get almanacService(): AlmanacService {
    return this.almanacServiceImpl
  }
  public get dataAttributionService(): DataAttributionService {
    return this.attributionServiceImpl
  }
  public get contentService(): ContentService {
    return this.contentServiceImpl
  }
  public get partnerService(): PartnerService {
    return this.partnerServiceImpl
  }
  public get localizationService(): LocalizationService {
    return this.i18nService
  }
  public get locationService(): LocationService {
    return this.locationServiceImpl
  }
  public get translationService(): TranslationService {
    return this.translationServiceImpl
  }

  protected readonly settings: SdkSettings
  protected readonly apiHttpService: ApiHttpService
  protected readonly cmsHttpService: ContentHttpService

  private readonly almanacServiceImpl: AlmanacService
  private readonly attributionServiceImpl: DataAttributionService
  private readonly contentServiceImpl: ContentService
  private readonly partnerServiceImpl: PartnerService
  private readonly i18nService: LocalizationService
  private readonly locationServiceImpl: LocationService
  private readonly translationServiceImpl: TranslationService

  protected constructor(sdkSettings: SdkSettings) {
    this.settings = sdkSettings
    this.apiHttpService = new ApiHttpService(this.settings)
    this.cmsHttpService = new ContentHttpService(this.settings)

    // this sequence is important for dependencies...
    // location service is used by most other services
    this.locationServiceImpl = this.initLocationService()

    // product availability service is used by other services, and needs location service

    this.almanacServiceImpl = this.initAlmanacService()
    this.attributionServiceImpl = this.initDataAttributionService()
    this.contentServiceImpl = this.initContentService()
    this.partnerServiceImpl = this.initPartnerService()
    this.translationServiceImpl = this.initTranslationService()

    this.i18nService = new LocalizationServiceImpl(this.settings)

    // set the log level
    Logger.setLevel(this.settings.logLevel)
  }

  protected initAlmanacService(): AlmanacService {
    const almanacRouteResolver = new AlmanacRouteResolverImpl(this.settings)
    return new AlmanacServiceImpl(this.apiHttpService, almanacRouteResolver)
  }
  protected initContentService(): ContentService {
    const contentRouteResolver = new ContentRouteResolverImpl(this.settings)
    return new ContentServiceImpl(
      this.settings,
      this.locationService,
      this.cmsHttpService,
      contentRouteResolver,
    )
  }
  protected initDataAttributionService(): DataAttributionService {
    const routeResolver = new DataAttributionRouteResolverImpl(this.settings)
    return new DataAttributionServiceImpl(this.settings, this.apiHttpService, routeResolver)
  }
  protected initLocationService(): LocationService {
    const locationRouteResolver = new LocationRouteResolverImpl(this.settings)
    return new LocationServiceImpl(this.settings, this.apiHttpService, locationRouteResolver)
  }
  protected initPartnerService(): PartnerService {
    const partnerRouteResolver = new PartnerRouteResolverImpl(this.settings, this.locationService)
    return new PartnerServiceImpl(this.apiHttpService, partnerRouteResolver)
  }
  protected initTranslationService(): TranslationService {
    const translationRouteResolver = new TranslationRouteResolverImpl(this.settings)
    return new TranslationServiceImpl(this.settings, this.apiHttpService, translationRouteResolver)
  }
}
