/**
 * Represents a subset of api unit types dealing with numbers.
 * These values map to api unit type IDs.
 * @see <a href="https://developer.accuweather.com/unit-types">here</a> for more information.
 */
export enum NumberType {
  /**
   * Percent
   */
  Percent = 20,

  /**
   * Float
   */
  Float = 21,

  /**
   * Integer
   */
  Integer = 22,
}
