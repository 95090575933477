/**
 * Encapsulates the tropical tile categories.
 */
export enum TropicalTileCategory {
  /**
   * Hurricane cone
   */
  HurricaneCone = 'HURRICANE_CONE',

  /**
   * Hurricane cone no track
   */
  HurricaneConeNoTrack = 'HURRICANE_CONE_NO_TRACK',

  /**
   * Wind Radii
   */
  WindRadii = 'WIND_RADII',

  /**
   * Rainfall
   */
  Rainfall = 'RAINFALL',

  /**
   * Watch warn advisory
   */
  WatchWarnAdvisory = 'WATCH_WARN_ADVISORY',

  /**
   * Probability hurricane force winds
   */
  PROBABILITY_HURRICANE_FORCE_WINDS = 'PROBABILITY_HURRICANE_FORCE_WINDS',

  /**
   * Risk to life property
   */
  RiskToLifeProperty = 'RISK_TO_LIFE_PROPERTY',

  /**
   * Maximum wind gusts
   */
  MaximumWindGusts = 'MAXIMUM_WIND_GUSTS',

  /**
   * Maximum sustained winds
   */
  MaximumSustainedWinds = 'MAXIMUM_SUSTAINED_WINDS',

  /**
   * Storm surge
   */
  StormSurge = 'STORM_SURGE',
}
