import { DayPeriodWidth } from './DayPeriodWidth'
import { PeriodFormatOptions } from './PeriodFormatOptions'
import { PeriodNameFormatWidth } from './PeriodNameFormatWidth'

/**
 * Encapsulates the parameters necessary for making formatting requests of the [LocalizationService].
 */
export class DayPeriodFormatOptions extends PeriodFormatOptions {
  /**
   * Gets or sets the day period width.
   */
  public dayPeriodWidth: DayPeriodWidth

  /**
   * Initializes a new [DayPeriodFormatOptions].
   * @param languageCode The language code.
   * @param dayPeriodWidth The day period width.
   * @param formatWidth The format width.
   */
  constructor(
    languageCode: string,
    dayPeriodWidth: DayPeriodWidth = DayPeriodWidth.Short,
    formatWidth: PeriodNameFormatWidth = PeriodNameFormatWidth.Short,
  ) {
    super(languageCode, formatWidth)
    this.dayPeriodWidth = dayPeriodWidth
  }
}
