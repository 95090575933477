export enum EventType {
  Ice = 'ice',
  Rain = 'rain',
  SevereThunderstorm = 'severe thunderstorm',
  Snow = 'snow',
  SustainedWind = 'sustained wind',
  Thunderstorm = 'thunderstorm',
  Tornado = 'tornado',
  Tropical = 'tropical',
  Wind = 'wind',
  WindGust = 'wind gust',
  Unknown = 'unknown',
  Hail = 'hail',
  Flooding = 'flooding',
  FlashFlooding = 'flash flooding',
  CoastalFlooding = 'coastal flooding',
  RiverFlooding = 'river flooding',
  Winter = 'winter',
}
