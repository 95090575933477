import { isNotNullOrUndefined } from '../../../util/Helpers'
import { ConversionInfo, convertQuantity } from './ConversionInfo'
import {
  KilometersPerHour,
  Knots,
  MetersPerSecond,
  MilesPerHour,
  SpeedConversion,
} from './SpeedConversion'
import { SpeedUnits } from './SpeedUnits'

const speedInfo: Map<SpeedUnits, ConversionInfo<SpeedConversion>> = new Map([
  [
    SpeedUnits.MilesPerHour,
    new ConversionInfo(SpeedUnits.MilesPerHour, new MilesPerHour(), (v: number, c: any) =>
      c.toMilesPerHour(v),
    ),
  ],
  [
    SpeedUnits.KilometersPerHour,
    new ConversionInfo(SpeedUnits.KilometersPerHour, new KilometersPerHour(), (v: number, c: any) =>
      c.toKilometersPerHour(v),
    ),
  ],
  [
    SpeedUnits.MetersPerSecond,
    new ConversionInfo(SpeedUnits.MetersPerSecond, new MetersPerSecond(), (v: number, c: any) =>
      c.toMetersPerSecond(v),
    ),
  ],
  [
    SpeedUnits.Knots,
    new ConversionInfo(SpeedUnits.Knots, new Knots(), (v: number, c: any) => c.toKnots(v)),
  ],
])

/**
 * Converts a speed from one unit type to another.
 * @param speed The speed to convert.
 * @param type The unit type to convert to.
 * @return The converted speed.
 */
export function convertSpeed(speed: any, type: SpeedUnits) {
  return convertQuantity(speed, type, speedInfo)
}

/**
 * Converts a vector speed from one unit type to another.
 * @param speed The vector to convert.
 * @param type The unit type to convert to.
 * @return The converted vector.
 */
export function convertVector(vector: any, type: SpeedUnits) {
  if (isNotNullOrUndefined(vector)) {
    if (isNotNullOrUndefined(vector.Speed)) {
      vector.Speed = convertSpeed(vector.Speed, type)
    } else if (isNotNullOrUndefined(vector.speed)) {
      vector.speed = convertSpeed(vector.speed, type)
    }
  }
  return vector
}

/**
 * Converts a wind speed from one unit type to another.
 * @param speed The wind to convert.
 * @param type The unit type to convert to.
 * @return The converted wind.
 */
export function convertWind(wind: any, type: SpeedUnits) {
  return convertVector(wind, type)
}
