/**
 * Represents product types.
 */
export enum ProductType {
  /**
   * Unknown. Used when the API adds a value of which the SDK is not yet aware
   * (or other serizliation failures).
   * Custom (not from api)
   */
  Unknown = 'Unknown',

  /**
   * Alerts
   */
  Alerts = 'Alerts',

  /**
   * AccuWeather Alerts
   */
  AccuWeatherAlerts = 'AccuWeatherAlerts',

  /**
   * Premium Air Quality
   */
  PremiumAirQuality = 'PremiumAirQuality',

  /**
   * Air Quality
   */
  AirQuality = 'AirQuality',

  /**
   * Air Quality Current Conditions
   */
  AirQualityCurrentConditions = 'AirQualityCurrentConditions',

  /**
   * Air Quality Forecasts
   */
  AirQualityForecasts = 'AirQualityForecasts',

  /**
   * Air Quality Observations
   */
  AirQualityObservations = 'AirQualityObservations',

  /**
   * MinuteCast
   */
  MinuteCast = 'MinuteCast',

  /**
   * Forecast Confidence
   */
  ForecastConfidence = 'ForecastConfidence',

  /**
   * Regional Air Quality
   */
  AirQualityRegional = 'AirQuality-Regional',

  /**
   * Daily Local Indices
   */
  DailyLocalIndices = 'DailyLocalIndices',

  /**
   * Hourly Local Indices
   */
  HourlyLocalIndices = 'HourlyLocalIndices',

  /**
   * Daily Air Quality Forecast
   */
  DailyAirQualityForecast = 'DailyAirQualityForecast',

  /**
   * Daily Pollen Forecast
   */
  DailyPollenForecast = 'DailyPollenForecast',

  /**
   * Radar
   */
  Radar = 'Radar',

  /**
   * Future Radar
   */
  FutureRadar = 'FutureRadar',

  /**
   * Hurricane
   * Custom (not from api)
   */
  Hurricane = 'Hurricane',

  /**
   * Maximum sustained winds
   */
  MaximumSustainedWinds = 'MAXIMUM_SUSTAINED_WINDS',

  /**
   * Maximum wind gusts
   */
  MaximumWindGusts = 'MAXIMUM_WIND_GUSTS',

  /**
   * Probability hurricane force winds
   */
  ProbabilityHurricaneForceWinds = 'PROBABILITY_HURRICANE_FORCE_WINDS',

  /**
   * Rainfall
   */
  Rainfall = 'RAINFALL',

  /**
   * Risk to life property
   */
  RiskToLifeProperty = 'RISK_TO_LIFE_PROPERTY',

  /**
   * Storm surge
   */
  StormSurge = 'STORM_SURGE',

  /**
   * Watch warn advisory
   */
  WatchWarnAdvisory = 'WATCH_WARN_ADVISORY',
}
