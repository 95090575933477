// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined, isNullOrUndefined } from '../Helpers'
import { TemplateReplacer } from './TemplateReplacer'
import { TemplateValues } from './TemplateValues'
import { TextMeta } from './TextMeta'
import { TextMetaType } from './TextMetaType'
import { TokenReplaceSettings } from './TokenReplaceSettings'

/**
 * A default implementation of [TemplateReplacer].
 */
export class TemplateReplacerImpl implements TemplateReplacer {
  private tokenOpen = '{'
  private tokenClose = '}'

  //region TemplateReplacer
  /**
   * Builds a string using the given [TemplateValues].
   * @param templateValues The template values.
   * @param args The args.
   * @param replaceSettings The settings for how to handle token replacement.
   * @return
   */
  public replace(
    templateValues: TemplateValues,
    args: Map<string, any>,
    replaceSettings: TokenReplaceSettings,
  ) {
    if (isNullOrUndefined(templateValues)) {
      return undefined
    }

    this.setValues(templateValues, args, replaceSettings)
    return this.replaceTokens(templateValues, replaceSettings)
  }

  /**
   * Sets [TemplateValues] values using the given args.
   * @param templateValues The template values.
   * @param args The args.
   * @param replaceSettings The settings for how to handle token replacement.
   * @return A [TemplateValues] with the values set.
   */
  public setValues(
    templateValues: TemplateValues,
    args: Map<string, any>,
    replaceSettings: TokenReplaceSettings,
  ): TemplateValues {
    for (const arg of Array.from(args.entries())) {
      this.setValue(templateValues, arg[0], arg[1], replaceSettings)
    }
    return templateValues
  }

  /**
   * Sets the [TemplateValues] value for the given key.
   * @param templateValues The template values.
   * @param key The key.
   * @param value The value.
   * @param replaceSettings The settings for how to handle token replacement.
   * @return A [TemplateValues] with the value set.
   */
  public setValue(
    templateValues: TemplateValues,
    key: string,
    value: any,
    replaceSettings: TokenReplaceSettings,
  ): TemplateValues {
    if (templateValues && key && key.length > 0) {
      const config = this.safeSettings(replaceSettings)
      if (!templateValues.getValue(key) || replaceSettings.overwriteIfSet) {
        // we don't already have a value, or settings say to overwrite anyway
        // get the replacement value
        const replacement = this.getTokenReplaceValue(value, config)
        if (replacement || replaceSettings.replaceNullWithEmpty) {
          // the value is not null, or settings say to replace null with empty
          templateValues.setValue(key, replacement || '')
        }
      }
    }
    return templateValues
  }
  //endregion TemplateReplacer

  private replaceTokens(templateValues: TemplateValues, settings: TokenReplaceSettings): string {
    const sb: string[] = []

    if (templateValues.meta.length > 0) {
      const config = this.safeSettings(settings)
      for (const meta of templateValues.meta) {
        const text = this.getMetaText(meta, templateValues, config)
        if (text !== null) {
          sb.push(text)
        }
      }
    }

    return sb.join('')
  }

  private getMetaText(
    meta: TextMeta,
    templateValues: TemplateValues,
    settings: TokenReplaceSettings,
  ): any {
    if (
      meta.type === TextMetaType.Protocol &&
      (settings.excludeProtocol || settings.ensureRelativeUrl)
    ) {
      return undefined
    }

    if (
      (meta.type === TextMetaType.Host || meta.type === TextMetaType.Port) &&
      settings.ensureRelativeUrl
    ) {
      return undefined
    }

    if (meta.type === TextMetaType.Token) {
      return templateValues.hasKey(meta.text)
        ? templateValues.getValue(meta.text)
        : this.tokenOpen + meta.text + this.tokenClose
    }

    return meta.text
  }

  private getTokenReplaceValue(value: any, settings: TokenReplaceSettings): string {
    if (isNotNullOrUndefined(value)) {
      let v = value.toString()
      if (v.length > 0 && settings.replaceUrlEncoded) {
        v = encodeURIComponent(v)
      }
      return v
    }
  }

  private safeSettings(settings: TokenReplaceSettings): TokenReplaceSettings {
    return settings || TokenReplaceSettings.default
  }
}
