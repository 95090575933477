// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CardinalDirection } from '../../..'
import { Logger } from '../../../core/Logger'
import { LinearUnits } from '../../../core/models/measurements/LinearUnits'
import { PressureUnits } from '../../../core/models/measurements/PressureUnits'
import { SpeedUnits } from '../../../core/models/measurements/SpeedUnits'
import { TemperatureType } from '../../../core/models/measurements/TemperatureType'
import { SdkSettings } from '../../../core/settings/SdkSettings'
import {
  formatString,
  isNotNullOrEmpty,
  isNotNullOrUndefined,
  isNullOrUndefined,
} from '../../../util/Helpers'
import { CoordinateFormatType } from '../../options/units/CoordinateFormatType'
import { CoordinatesFormatOptions } from '../../options/units/CoordinatesFormatOptions'
import { DistanceFormatOptions } from '../../options/units/DistanceFormatOptions'
import { PressureFormatOptions } from '../../options/units/PressureFormatOptions'
import { SpeedFormatOptions } from '../../options/units/SpeedFormatOptions'
import { TemperatureFormatOptions } from '../../options/units/TemperatureFormatOptions'
import { UnitFormatOptions } from '../../options/units/UnitFormatOptions'
import { UnitFormatWidth } from '../../options/units/UnitFormatWidth'

/**
 * @see <a href="http://cldr.unicode.org/translation/units" />
 * @see <a href="http://cldr.unicode.org/translation/plurals" />
 * @see <a href="http://www.unicode.org/reports/tr35/tr35-general.html#perUnitPatterns" />
 */
// internal
export class UnitFormatProvider {
  private readonly shouldUseCustomPressureLabel: boolean

  private readonly inchesOfMercuryLongLabel = ' of mercury'
  private readonly inchesOfMercuryShortLabel = ' Hg'
  private readonly inchesOfMercuryNarrowLabel = 'Hg'
  private readonly millibarsLongLabel2 = 'millibars'
  private readonly millibarsLongLabel = 'millibar'
  private readonly millibarsNarrowLabel = 'mbar'
  private readonly millibarsHackLabel = 'mb'

  private readonly logger: Logger = Logger.getInstance()

  private _map: Map<string, any>

  constructor(settings: SdkSettings) {
    this.shouldUseCustomPressureLabel =
      settings?.i18nSettings?.shouldUseCustomPressureUnitLabel ?? false
  }

  public setMap(map: Map<string, any>) {
    this._map = map
  }

  // region formats
  public getPercentPattern(value: number, formatOptions: UnitFormatOptions): string {
    if (isNotNullOrUndefined(value) && isNotNullOrUndefined(formatOptions)) {
      const info = this.getPercentPatternInfo(formatOptions)
      return this.getCountPattern(value, info)
    }
  }
  public getPercentRangePattern(formatOptions: UnitFormatOptions): string {
    return this.getRangePattern(formatOptions, (opts) => this.getPercentPatternInfo(opts))
  }

  public getTemperaturePattern(value: number, formatOptions: TemperatureFormatOptions): string {
    if (isNotNullOrUndefined(value) && isNotNullOrUndefined(formatOptions)) {
      const info = this.getTemperaturePatternInfo(formatOptions)
      return this.getTemperatureCountPattern(value, info)
    }
  }
  public getTemperatureRangePattern(formatOptions: TemperatureFormatOptions): string {
    return this.getRangePattern(formatOptions, (opts) => this.getTemperaturePatternInfo(opts))
  }

  public getLengthPattern(value: number, formatOptions: DistanceFormatOptions): string {
    if (isNotNullOrUndefined(value) && isNotNullOrUndefined(formatOptions)) {
      const info = this.getLengthPatternInfo(formatOptions)
      return this.getCountPattern(value, info)
    }
  }
  public getLengthRangePattern(formatOptions: DistanceFormatOptions): string {
    return this.getRangePattern(formatOptions, (opts) => this.getLengthPatternInfo(opts))
  }

  public getSpeedPattern(value: number, formatOptions: SpeedFormatOptions): string {
    if (isNotNullOrUndefined(value) && isNotNullOrUndefined(formatOptions)) {
      const info = this.getSpeedPatternInfo(formatOptions)
      return this.getCountPattern(value, info)
    }
  }
  public getSpeedRangePattern(formatOptions: SpeedFormatOptions): string {
    return this.getRangePattern(formatOptions, (opts) => this.getSpeedPatternInfo(opts))
  }

  public getPressurePattern(value: number, formatOptions: PressureFormatOptions): string {
    if (isNotNullOrUndefined(value) && isNotNullOrUndefined(formatOptions)) {
      const useCustom = formatOptions.shouldUseCustomLabel ?? this.shouldUseCustomPressureLabel

      const info = this.getPressurePatternInfo(formatOptions, useCustom)
      const pattern = this.getCountPattern(value, info)

      return pattern
    }
  }
  public getPressureRangePattern(formatOptions: PressureFormatOptions): string {
    return this.getRangePattern(formatOptions, (opts) => this.getPressurePatternInfo(opts, false))
  }

  // helper function used to get the ° symbol
  public getDegreesPattern(formatOptions: UnitFormatOptions): string {
    const patterns = this.getUnitPatternSettings(formatOptions)
    if (isNotNullOrUndefined(patterns) && isNotNullOrUndefined(patterns['temperature-generic'])) {
      return patterns['temperature-generic']['unitPattern-count-other']
    }
  }

  // region pressure hack
  private getInchesOfMercuryHackPattern(
    pattern: string,
    formatOptions: PressureFormatOptions,
  ): string {
    switch (formatOptions.formatWidth) {
      // {0} inches of mercury
      // -or-
      // {0} inch of mercury
      case UnitFormatWidth.Long:
        return pattern?.replace(this.inchesOfMercuryLongLabel, '')

      // {0} inHg
      case UnitFormatWidth.Short:
        return pattern?.replace(this.inchesOfMercuryNarrowLabel, '')

      // {0}″ Hg
      case UnitFormatWidth.Narrow:
      default:
        return pattern?.replace(this.inchesOfMercuryShortLabel, '')
    }
  }
  private getMillibarHackPattern(pattern: string, formatOptions: PressureFormatOptions): string {
    switch (formatOptions.formatWidth) {
      // {0} millibars
      // -or-
      // {0} millibar
      case UnitFormatWidth.Long:
        return pattern
          ?.replace(this.millibarsLongLabel, this.millibarsHackLabel)
          ?.replace(this.millibarsLongLabel2, this.millibarsHackLabel)
      // {0} mbar
      case UnitFormatWidth.Short:
        return pattern?.replace(this.millibarsNarrowLabel, this.millibarsHackLabel)

      // {0}mb
      // already there
      case UnitFormatWidth.Narrow:
      default:
        return pattern
    }
  }
  // endregion pressure hack

  public getCoordinatePattern(
    degrees: number,
    cardinalDirection: CardinalDirection,
    formatOptions: CoordinatesFormatOptions,
  ): string {
    if (isNotNullOrUndefined(degrees) && isNotNullOrUndefined(formatOptions)) {
      const patterns = this.getUnitPatternSettings(formatOptions)
      if (isNotNullOrUndefined(patterns)) {
        const info = this.getPatternInfo(formatOptions, patterns)
        const directionPattern = this.getCoordinateDirectionPattern(cardinalDirection, patterns)
        const countPattern = this.getCountPattern(degrees, info)
        // 'embed' the one format into the other
        return formatString(directionPattern, countPattern)
      }
    }
  }
  // endregion formats

  // region internal
  private getPercentPatternInfo(formatOptions: UnitFormatOptions) {
    const patterns = this.getUnitPatternSettings(formatOptions)
    if (isNotNullOrUndefined(patterns)) {
      return patterns['concentr-percent']
    }
  }

  private getTemperaturePatternInfo(formatOptions: TemperatureFormatOptions) {
    const patterns = this.getUnitPatternSettings(formatOptions)
    if (isNotNullOrUndefined(patterns)) {
      if (!formatOptions.includeUnitLabel) {
        return patterns['temperature-generic']
      }
      switch (formatOptions.temperatureType) {
        case TemperatureType.Celsius:
          return patterns['temperature-celsius']
        case TemperatureType.Fahrenheit:
          return patterns['temperature-fahrenheit']
        case TemperatureType.Kelvin:
          return patterns['temperature-kelvin']
      }
    }
  }

  private getLengthPatternInfo(formatOptions: DistanceFormatOptions) {
    const patterns = this.getUnitPatternSettings(formatOptions)
    if (isNotNullOrUndefined(patterns)) {
      switch (formatOptions.linearUnits) {
        case LinearUnits.Miles:
          return patterns['length-mile']
        case LinearUnits.Millimeters:
          return patterns['length-millimeter']
        case LinearUnits.Centimeters:
          return patterns['length-centimeter']
        case LinearUnits.Meters:
          return patterns['length-meter']
        case LinearUnits.Kilometers:
          return patterns['length-kilometer']
        case LinearUnits.NauticalMiles:
          return patterns['length-nautical-mile']
        case LinearUnits.Feet:
          return patterns['length-foot']
        case LinearUnits.Inches:
          return patterns['length-inch']
      }
    }
  }

  private getSpeedPatternInfo(formatOptions: SpeedFormatOptions) {
    const patterns = this.getUnitPatternSettings(formatOptions)
    if (isNotNullOrUndefined(patterns)) {
      switch (formatOptions.speedUnits) {
        case SpeedUnits.KilometersPerHour:
          return patterns['speed-kilometer-per-hour']
        case SpeedUnits.Knots:
          return patterns['speed-knot']
        case SpeedUnits.MetersPerSecond:
          return patterns['speed-meter-per-second']
        case SpeedUnits.MilesPerHour:
          return patterns['speed-mile-per-hour']
      }
    }
  }

  private getPressurePatternInfo(formatOptions: PressureFormatOptions, useCustom: boolean) {
    const patterns = this.getUnitPatternSettings(formatOptions)
    if (isNotNullOrUndefined(patterns)) {
      switch (formatOptions.pressureUnits) {
        case PressureUnits.HectoPascals:
          return patterns['pressure-hectopascal']
        case PressureUnits.InchesOfMercury:
          if (useCustom) {
            return patterns['pressure-inch-ofhg-custom']
          } else {
            return patterns['pressure-inch-ofhg']
          }
        case PressureUnits.KiloPascals:
          return patterns['pressure-kilopascal']
        case PressureUnits.Millibars:
          if (useCustom) {
            return patterns['pressure-millibars-ofhg-custom']
          } else {
            return patterns['pressure-millibar']
          }

        case PressureUnits.MillimetersOfMercury:
          return patterns['pressure-millimeter-ofhg']
        case PressureUnits.PoundsPerSquareInch:
          return patterns['pressure-pound-force-per-square-inch']
      }
    }
  }

  private getUnitPatternSettings(formatOptions: UnitFormatOptions) {
    const settings = this.getUnitSettings(formatOptions.languageCode)
    if (isNotNullOrUndefined(settings)) {
      switch (formatOptions.formatWidth) {
        case UnitFormatWidth.Long:
          return settings.long
        case UnitFormatWidth.Narrow:
          return settings.narrow
        case UnitFormatWidth.Short:
          return settings.short
      }
    }
  }
  // endregion internal

  private getPatternInfo(formatOptions: CoordinatesFormatOptions, patterns: any) {
    switch (formatOptions.coordinateFormatType) {
      case CoordinateFormatType.DecimalDegrees:
        return patterns['coordinate-decimal-degrees']
      case CoordinateFormatType.Minutes:
        return patterns['coordinate-decimal-generic']
      case CoordinateFormatType.Seconds:
        return patterns['coordinate-minutes']
      case CoordinateFormatType.DecimalGeneric:
        return patterns['coordinate-seconds']
    }
  }
  private getCoordinateDirectionPattern(
    cardinalDirection: CardinalDirection,
    patterns: any,
  ): string {
    const info = patterns['coordinateUnit']
    if (cardinalDirection === CardinalDirection.N) return info['north']
    if (cardinalDirection === CardinalDirection.S) return info['south']
    if (cardinalDirection === CardinalDirection.E) return info['east']
    if (cardinalDirection === CardinalDirection.W) return info['west']
  }

  private getUnitSettings(language: string): any {
    if (isNullOrUndefined(this._map)) {
      this.logger.error('No map created need to pass at least 1 language to the units')
      throw new Error('No map created need to pass at least 1 language to the units')
    } else if (!this._map.has(language)) {
      this.logger.error(`Couldn't find a language pack for: ${language}`)
      throw new Error(`Couldn't find a language pack for: ${language}`)
    }
    return this._map.get(language)
  }

  // region count pattern
  private getCountPattern(value: number, patternInfo: any): string {
    // patternInfo is UnitPatternInfo
    if (isNotNullOrUndefined(patternInfo)) {
      return value === 1 && isNotNullOrUndefined(patternInfo['unitPattern-count-one'])
        ? patternInfo['unitPattern-count-one']
        : patternInfo['unitPattern-count-other']
    }
  }

  private getTemperatureCountPattern(temperature: number, patternInfo: any): string {
    // patterninfo is TemperaturePatternInfo
    if (isNotNullOrUndefined(patternInfo)) {
      // if the temperature value is 0, 1, or 2: use the specific pattern (if it exists)
      // if the temperature is another value (or the specific patterns aren't available): use 'other'
      // not used: patternInfo.CountFew and patternInfo.countMany
      return (
        this.getSpecificTemperatureCountPattern(
          temperature,
          0,
          patternInfo['unitPattern-count-zero'],
        ) ||
        this.getSpecificTemperatureCountPattern(
          temperature,
          1,
          patternInfo['unitPattern-count-one'],
        ) ||
        this.getSpecificTemperatureCountPattern(
          temperature,
          2,
          patternInfo['unitPattern-count-two'],
        ) ||
        patternInfo['unitPattern-count-other']
      )
    }
  }
  private getSpecificTemperatureCountPattern(
    temperature: number,
    specificValue: number,
    pattern: string,
  ): string {
    if (temperature === specificValue && isNotNullOrEmpty(pattern)) {
      return pattern
    }
  }

  private getRangePattern<T>(options: T, getPatternInfo: (T) => any): string {
    if (isNotNullOrUndefined(options)) {
      const info = getPatternInfo(options)
      if (isNotNullOrUndefined(options)) {
        // unitPattern-count-other is formatted e.g. "{0} mph", so we're adding the range value placeholders here
        return formatString(info['unitPattern-count-other'], '{0}-{1}')
      }
    }
  }
  // endregion count pattern
}
