// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ProductType } from '../../core/support/ProductType'
import { LocationCacheInfo } from '../../locations/cache/LocationCacheInfo'
import { isNullOrUndefined } from '../../util/Helpers'

export class ProductAvailabilityInfoRequest {
  public static fromLocationCachInfo(
    languageCode: string,
    cacheInfo: LocationCacheInfo,
  ): ProductAvailabilityInfoRequest {
    if (isNullOrUndefined(cacheInfo)) {
      return undefined
    }
    return new ProductAvailabilityInfoRequest(
      languageCode,
      cacheInfo.countryCode,
      cacheInfo.adminCode,
      cacheInfo.locationGmtOffset,
      cacheInfo.dataSets,
    )
  }

  /** The language code. */
  public readonly languageCode: string

  /** The country code. */
  public readonly countryCode: string

  /** The admin code. */
  public readonly adminCode: string

  /** The location gmt offset */
  public readonly locationGmtOffset: number

  /** The list of api products. */
  public readonly dataSets: ProductType[]

  /**
   * @param countryCode the country code.
   * @param adminCode the admin code.
   * @param locationGmtOffset the location gmt offset.
   * @param languageCode the language code.
   * @param dataSets the list of api products.
   */
  constructor(
    languageCode: string,
    countryCode: string,
    adminCode: string,
    locationGmtOffset: number,
    dataSets: ProductType[],
  ) {
    this.languageCode = languageCode
    this.countryCode = countryCode
    this.adminCode = adminCode
    this.locationGmtOffset = locationGmtOffset
    this.dataSets = dataSets
  }
}
