import { isNullOrUndefined } from '../../util/Helpers'
import { IndexGroupType } from '../IndexGroupType'

/**
 * Provides methods used to validate index group types.
 */
export class IndexGroupTypeValidRule {
  public static checkRule(type: IndexGroupType) {
    return isNullOrUndefined(type) || type !== IndexGroupType.Unknown
      ? undefined
      : Error('invalid index group type.')
  }
}
