import { IndexType } from '../IndexType'

/**
 * Encapsulates parameters used to make requests to [ContextualService].
 */
export class IndexInfoByTypeRequest {
  /**
   * Gets or sets the language (i.e. en-us) for which the response should be returned in.
   */
  public readonly language: string

  /**
   * Gets or sets the index type.
   */
  public readonly indexType: IndexType

  /**
   * Initializes a new [IndexInfoByTypeRequest].
   * @param language The language (i.e. en-us) for which the response should be returned in.
   * @param indexType The index type.
   */
  constructor(language: string, indexType: IndexType) {
    this.language = language
    this.indexType = indexType
  }
}
