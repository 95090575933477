// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { RequestChain } from './RequestChain'

/**
 * Encapsulates the options for making http requests.
 */
export class HttpRequestOptions {
  /** Gets or sets the request chain. */
  public requestChain: RequestChain

  /** Indicates whether to allow an empty response. */
  public allowEmpty = false

  /**
   * A function that will create a default value if the response is empty.
   * Only used is <see cref="AllowEmpty"/> is <c>true</c>.
   */
  public getDefaultIfEmpty: () => any

  /**
   * The ID for the schema to use to validate the response.
   * If empty, the response will not be validated.
   */
  public schemaId: string

  /** Gets or sets the HTTP request method. */
  public method: HttpMethod = HttpMethod.Get

  /** Gets or sets the request body. Used for POST/PUT. */
  public body: any

  /** Gets or sets the HTTP content type. Used for POST/PUT. */
  public contentType: HttpContentType = HttpContentType.Json

  /**
   * Gets or sets a method to configure an <see cref="HttpRequestMessage"/>
   * to add any custom headers, etc, before making the request.
   */
  public configureRequest: (xmlHttpRequest) => void

  /** Gets or sets the caller method name. */
  public callerMethodName: string // should be internal

  /** Gets or sets the caller service name. */
  public callerServiceName: string // should be internal

  /** Authorization cookie used for cookie based authorization. */
  public authCookie: string = undefined

  /**
   * Initializes a new `HttpRequestOptions`.
   * @param requestChain the request chain.
   * @param callerMethodName the caller method name.
   * @param allowEmpty should allow empty.
   * @param getDefaultIfEmpty function which returns default value if empty.
   */
  public constructor(
    requestChain: RequestChain,
    callerServiceName?: string,
    callerMethodName?: string,
    allowEmpty?: boolean,
    getDefaultIfEmpty?: () => any,
  ) {
    this.requestChain = requestChain
    this.callerServiceName = callerServiceName
    this.callerMethodName = callerMethodName
    this.allowEmpty = allowEmpty ?? false
    this.getDefaultIfEmpty = getDefaultIfEmpty
  }

  public clone(): HttpRequestOptions {
    const options = new HttpRequestOptions(
      this.requestChain,
      this.callerServiceName,
      this.callerMethodName,
      this.allowEmpty,
      this.getDefaultIfEmpty,
    )
    options.schemaId = this.schemaId
    options.method = this.method
    options.body = this.body
    options.contentType = this.contentType
    options.configureRequest = this.configureRequest
    return options
  }
}

/**
 *
 */
export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Head = 'HEAD',
}

/**
 * The HTTP content types.
 */
export enum HttpContentType {
  Json = 'application/json',

  // todo: add others
}
