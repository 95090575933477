/**
 * The different lengths of month and day names.
 * There are four types: abbreviated, narrow, short, and wide.
 */
export enum PeriodNameFormatWidth {
  /**
   * The abbreviated name, i.e. "Sun" (Sunday) or "Jan" (January).
   */
  Abbreviated,

  /**
   * The shortest abbreviated name, usually just the first initial,
   * i.e. "S" (Sunday) or "J" (January).
   */
  Narrow,

  /**
   * Only day names have [SHORT] options,
   * i.e. "Su" (Sunday), "Mo" (Monday), or "Tu" (Tuesday).
   * When requesting [SHORT] format options for a period type that
   * doesn't support them, [NARROW] format options will be used.
   */
  Short,

  /**
   * The full name, i.e. "Sunday" or "January".
   */
  Wide,
}
