/* eslint-disable no-unused-vars */
/**
 * The types of coordinate formats.
 */
export enum CoordinateFormatType {
  /**
   * Decimal Degrees.
   */
  DecimalDegrees,

  /**
   * Decimal Generic.
   */
  DecimalGeneric,

  /**
   * Minutes.
   */
  Minutes,

  /**
   * Seconds.
   */
  Seconds,
}
