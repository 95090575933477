/**
 * Represents the Index Group IDs.
 */
export enum IndexGroupType {
  /**
   * (not a 'real' database ID)
   */
  Unknown = 0,

  /**
   * All API
   */
  ApiAll = 1,

  /**
   * Aches and Pains API
   */
  ApiAchesAndPains = 2,

  /**
   * Respiratory API
   */
  ApiRespiratory = 3,

  /**
   * Gardening API
   */
  ApiGardening = 4,

  /**
   * Environmental API
   */
  ApiEnvironmental = 5,

  /**
   * Outdoor Living API
   */
  ApiOutdoorLiving = 6,

  /**
   * Beach and Marine API
   */
  ApiBeachAndMarine = 7,

  /**
   * Sportsman API
   */
  ApiSportsman = 8,

  /**
   * Farming API
   */
  ApiFarming = 9,

  /**
   * Health API
   */
  ApiHealth = 10,

  /**
   * Outdoor API
   */
  ApiOutdoor = 11,

  /**
   * Sporting API
   */
  ApiSporting = 12,

  /**
   * Home API
   */
  ApiHome = 13,

  /**
   * Pollen
   */
  Pollen = 30,

  /**
   * OperaTV
   */
  OperaTV = 31,

  /**
   * Lifestyle Allergies
   */
  LifestyleAllergies = 32,

  /**
   * Lifestyle Cold and Flu
   */
  LifestyleColdAndFlu = 33,

  /**
   * Lifestyle Driving
   */
  LifestyleDriving = 34,

  /**
   * Lifestyle Lawn and Garden
   */
  LifestyleLawnAndGarden = 35,

  /**
   * Lifestyle Entertaining
   */
  LifestyleEntertaining = 36,

  /**
   * Lifestyle Sun and Sand
   */
  LifestyleSunAndSand = 37,

  /**
   * Lifestyle Air Travel
   */
  LifestyleAirTravel = 38,

  /**
   * Lifestyle Arthritis
   */
  LifestyleArthritis = 39,

  /**
   * Lifestyle Respiratory
   */
  LifestyleRespiratory = 40,

  /**
   * Lifestyle Astronomy
   */
  LifestyleAstronomy = 41,

  /**
   * Lifestyle Biking
   */
  LifestyleBiking = 42,

  /**
   * Lifestyle DIY
   */
  LifestyleDIY = 43,

  /**
   * Lifestyle Events
   */
  LifestyleEvents = 44,

  /**
   * Lifestyle Fishing
   */
  LifestyleFishing = 45,

  /**
   * Lifestyle Golf
   */
  LifestyleGolf = 46,

  /**
   * Lifestyle Hair Day
   */
  LifestyleHairDay = 47,

  /**
   * Lifestyle Hiking
   */
  LifestyleHiking = 48,

  /**
   * Lifestyle Home Energy
   */
  LifestyleHomeEnergy = 49,

  /**
   * Lifestyle Hunting
   */
  LifestyleHunting = 50,

  /**
   * Lifestyle Migraine
   */
  LifestyleMigraine = 51,

  /**
   * Lifestyle Running
   */
  LifestyleRunning = 52,

  /**
   * Lifestyle Sailing
   */
  LifestyleSailing = 53,

  /**
   * Lifestyle School Day
   */
  LifestyleSchoolDay = 54,

  /**
   * Lifestyle Sinus
   */
  LifestyleSinus = 55,

  /**
   * Lifestyle Ski
   */
  LifestyleSki = 56,

  /**
   * Lifestyle Snow Days
   */
  LifestyleSnowDays = 57,

  /**
   * Web All
   */
  WebAll = 58,

  /**
   * Mosquito
   */
  Mosquito = 59,

  /**
   * WinterCast
   */
  WinterCast = 60,

  /**
   * Pest Activity
   */
  Pest = 61,

  /**
   * China Indices
   */
  China = 100,

  /**
   * Korean Indices
   */
  Korea = 102,
}
