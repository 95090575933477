// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Logger } from '../../core/Logger'
import { isNotNullOrUndefined } from '../Helpers'
import { MetaTemplate } from './MetaTemplate'
import { TemplateParser } from './TemplateParser'
import { TemplateValues } from './TemplateValues'
import { TextMeta } from './TextMeta'
import { TextMetaType } from './TextMetaType'

/**
 * Provides methods for registering tokenized templates and creating values used for replacing the tokens in those templates.
 */
export class TemplateParserImpl implements TemplateParser {
  private static hostMustContainDoubleSlash = 'malformed url: host must contain double slash'
  private readonly logger: Logger = Logger.getInstance()

  /** The query separator character. */
  private querySeparator = '?'
  /** The port separator character. */
  private portSeparator = ':'
  /** The forward slash character. */
  private slash = '/'
  /** The anchor separator character. */
  private anchorSeparator = '#'
  /** The double slash string. */
  private doubleSlash = '//'
  /** The http schema string. */
  private httpSchema = 'http:'
  /** The https schema string. */
  private httpsSchema = 'https:'
  /** The token open char. */
  private tokenOpen = '{'
  /** The token close char. */
  private tokenClose = '}'

  private cache = new Map<string, MetaTemplate>()

  //region TemplateParser
  /**
   * Registers a tokenized template by key.
   * @param [key] The key used to retrieve the template.
   * @param [template] The template to add.
   * @return If the template is added without error, true; otherwise, false.
   */
  public addTemplate(key: string, template: string): boolean {
    let valid = this.isValid(key, template)
    if (valid) {
      try {
        const meta: MetaTemplate = this.parse(template)
        this.cache.set(key, meta)
      } catch (ex) {
        this.logger.error(`Unable to add template: (${template}). The template is malformed.`)
        valid = false
      }
    }
    return valid
  }

  /**
   * Gets a template by key.
   * @param key The key.
   * @return If no template found with the key, null; otherwise, the found template.
   */
  public getTemplate(key: string): MetaTemplate {
    if (key && key.length > 0 && this.cache.has(key)) {
      return this.cache.get(key)
    }
  }

  /**
   * Creates a new set of values for the tokens contained in the template stored with the given key.
   * @param key The key.
   * @return If no template is found with the key, null; otherwise, an empty set of values to be populated for the found template.
   */
  public createValues(key: string): TemplateValues {
    const template = this.getTemplate(key)
    if (isNotNullOrUndefined(template)) {
      template.key = key
      return new TemplateValues(template)
    }

    this.logger.error(`unable to locate template for key: ${key}`)
    // leave 'undefined'
  }
  //endregion TemplateParser

  //region parse
  public parse(template: string): MetaTemplate {
    const pair = this.getParsedMeta(template)
    const meta = pair[0]
    const hasQuerySeparator: boolean = pair[1]

    const isHttp = template.startsWith(this.httpSchema)
    const isHttps = template.startsWith(this.httpsSchema)
    const isAbsoluteUrl = template.startsWith(this.doubleSlash) || isHttp || isHttps

    return {
      hasQuerySeparator,
      isAbsoluteUrl,
      isHttp,
      isHttps,
      meta,
      template,
    }
  }

  private getBaseMeta(template: string): [TextMeta[], number] {
    let index = -1
    const result: TextMeta[] = []

    if (template && template.length > 0) {
      index = 0
      let isAbsoluteUrl = false
      if (template.startsWith(this.doubleSlash)) {
        isAbsoluteUrl = true
        const hostPair = this.parseHost(template, index)
        index = hostPair[1]
        result.push(new TextMeta(TextMetaType.Host, hostPair[0]))
      } else if (template.startsWith(this.httpsSchema)) {
        isAbsoluteUrl = true
        result.push(new TextMeta(TextMetaType.Protocol, this.httpsSchema))
        const httpsPair = this.parseHost(template, 6)
        index = httpsPair[1]
        result.push(new TextMeta(TextMetaType.Host, httpsPair[0]))
      } else if (template.startsWith(this.httpSchema)) {
        isAbsoluteUrl = true
        result.push(new TextMeta(TextMetaType.Protocol, this.httpSchema))
        const httpPair = this.parseHost(template, 5)
        index = httpPair[1]
        result.push(new TextMeta(TextMetaType.Host, httpPair[0]))
      }

      // if this is an absolute url (and there is more left to parse), check for port info
      if (isAbsoluteUrl && template.length > index && template[index] === this.portSeparator) {
        const portPair = this.parsePort(template, index)
        index = portPair[1]
        result.push(new TextMeta(TextMetaType.Port, portPair[0]))
      }
    }
    return [result, index]
  }

  private getParsedMeta(template: string): [TextMeta[], boolean] {
    let hasQuerySeparator = false

    // will give a new list, but inits i as -1 if template is null/empty
    const baseMeta = this.getBaseMeta(template)
    const result = baseMeta[0]
    let i: number = baseMeta[1]

    if (i > -1) {
      let type: TextMetaType
      let text: string

      while (i < template.length) {
        const c = template[i]

        if (c === this.querySeparator) {
          type = TextMetaType.QuerySeparator
          text = c.toString()
          hasQuerySeparator = true
        } else if (c === this.anchorSeparator) {
          type = TextMetaType.AnchorSeparator
          text = c.toString()
        } else if (c === this.tokenOpen) {
          type = TextMetaType.Token
          const tokenPair = this.parseToken(template, i)
          text = tokenPair[0]
          i = tokenPair[1] // bump the index
        } else {
          type = TextMetaType.Text
          const textPair = this.parseText(template, i)
          text = textPair[0]
          i = textPair[1] // bump the index
        }

        result.push(new TextMeta(type, text))
        i++
      }
    }

    return [result, hasQuerySeparator]
  }

  private parseText(template: string, start: number): [string, number] {
    let text = ''
    let end: number = start

    while (end < template.length) {
      if (
        template[end] === this.tokenOpen ||
        template[end] === this.querySeparator ||
        template[end] === this.anchorSeparator
      ) {
        // we are already at the start of the next tokenType; back up the index 1
        text = template.substring(start, end--)
        return [text, end]
      }
      end++
    }
    if (text.length === 0) {
      // all text from start on
      text = template.substring(start, end)
    }

    return [text, end]
  }

  private parseToken(template: string, start: number): [string, number] {
    let text = ''
    let end: number = start
    while (end < template.length) {
      if (template[end] === this.tokenClose || template[end] === this.querySeparator) {
        // exclude the actual token open/close chars
        text = template.substring(start + 1, end)
        return [text, end]
      }
      end++
    }

    return [text, end]
  }
  private parseHost(template: string, start: number): [string, number] {
    if (template.substring(start, start + 2) !== this.doubleSlash) {
      this.logger.error(TemplateParserImpl.hostMustContainDoubleSlash)
      throw new Error(TemplateParserImpl.hostMustContainDoubleSlash)
    }

    // (start + 2) to begin past the double slash
    let end: number = start + 2
    while (end < template.length) {
      if (
        template[end] === this.slash ||
        template[end] === this.portSeparator ||
        template[end] === this.querySeparator
      ) {
        const txt: string = template.substring(start, end)
        return [txt, end]
      }
      end++
    }

    const text: string = template.substring(start, end)
    return [text, end]
  }

  private parsePort(template: string, start: number): [string, number] {
    if (!template || !start) {
      return
    }
    let end: number = start
    while (end < template.length) {
      // exclude the slash or query separator
      if (template[end] === this.slash || template[end] === this.querySeparator) {
        const txt: string = template.substring(start, end)
        return [txt, end]
      }
      end++
    }
    const text: string = template.substring(start, end)
    return [text, end]
  }
  //endregion parse

  private isValid(key: string, template: string): boolean {
    if (!key || !template) {
      if (!key) {
        this.logger.info(`missing template for key: ${key}`)
      } else if (!template) {
        this.logger.info(`missing key for template: ${template}`)
      }
      return false
    }

    if (this.cache.has(key)) {
      this.logger.error(`key '${key}' already exists`)
      return false
    }

    return true
  }
}
