/**
 * The available interval options for requesting premium minutecast forecasts.
 */
export enum MinuteForecastIntervalType {
  /**
   * Every minute. Gets 120 intervals in the 2-hr minutecast period.
   */
  OneMinute = 1,

  /**
   * Every 5 minutes. Gets 24 intervals in the 2-hr minutecast period.
   */
  FiveMinute = 5,

  /**
   * Every 15 minutes. Gets 8 intervals in the 2-hr minutecast period.
   */
  FifteenMinute = 15,
}
