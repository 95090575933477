// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 * Encapsulates the information used to cache the location partner id.
 */
export class LocationPartnerInfo {
  /**
   * The branding partner id.
   */
  public partnerId: number = undefined
}
