/* eslint-disable no-unused-vars */
/**
 * The types of percent formats.
 */
export enum PercentFormatType {
  /**
   * Generic.
   */
  Generic,

  /**
   * Cloud Cover.
   */
  CloudCover,

  /**
   * Humidity.
   */
  Humidity,

  /**
   * Precipitation Probability.
   */
  PrecipitationProbability,
}
