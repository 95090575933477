// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { ApiTokens } from '../core/api/ApiTokens'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { LanguageValidRule } from '../core/validation/LanguageValidRule'
import { IntegerGreaterThanRule } from '../core/validation/NumberGreaterThanRule'
import { ObjectNotNullOrUndefinedRule } from '../core/validation/ObjectNotNullOrUndefinedRule'
import { LanguagesRequest } from './requests/LanguagesRequest'
import { TranslationGroupsRequest } from './requests/TranslationGroupsRequest'
import { TranslationsByGroupRequest } from './requests/TranslationsByGroupRequest'
import { TranslationRouteResolver } from './TranslationRouteResolver'

/**
 * Resolves routes for [TranslationService] methods.
 */
export class TranslationRouteResolverImpl implements TranslationRouteResolver {
  private readonly sdkSettings: SdkSettings
  private readonly routeResolver: ApiRouteResolver

  // region routes
  private readonly translationGroups = 'TranslationGroups'
  private readonly languages = 'Languages'
  private readonly translationsByGroup = 'TranslationsByGroup'

  private readonly routeTemplates: Map<string, string> = new Map([
    [this.translationGroups, 'translations/v1/groups?apikey={apikey}'],
    [this.languages, 'translations/v1/languages?apikey={apikey}'],
    [
      this.translationsByGroup,
      'translations/v1/groups/{groupId}?apikey={apikey}&language={language}',
    ],
  ])
  // endregion routes

  // region validators
  private readonly translationsByGroupRequestValidator: AsyncValidator<TranslationsByGroupRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => IntegerGreaterThanRule.checkRule(r.groupId, 0, 'group id'),
      (r) => LanguageValidRule.checkRule(r.language),
    ])

  private readonly languagesRequestValidator: AsyncValidator<LanguagesRequest> = new AsyncValidator(
    [(r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request')],
  )

  private readonly translationGroupsRequestValidator: AsyncValidator<TranslationGroupsRequest> =
    new AsyncValidator([(r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request')])
  // endregion validators

  constructor(sdkSettings: SdkSettings) {
    this.sdkSettings = sdkSettings
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, this.sdkSettings)
  }

  // region language / meta
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getLanguagesUrl(
    request: LanguagesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.languages,
      request,
      this.languagesRequestValidator,
      requestChain,
      new Map<string, any>([]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getTranslationGroupsUrl(
    request: TranslationGroupsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.translationGroups,
      request,
      this.translationGroupsRequestValidator,
      requestChain,
      new Map<string, any>([]),
    )
  }
  // endregion language / meta

  // region translations
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getTranslationsByGroupUrl(
    request: TranslationsByGroupRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.translationsByGroup,
      request,
      this.translationsByGroupRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.groupId, request?.groupId],
        [ApiTokens.language, request?.language],
      ]),
    )
  }
  // endregion translations
}
