/**
 * Represents a subset of api unit types dealing with air quality concentration.
 * These values map to api unit type IDs.
 * @see <a href="https://developer.accuweather.com/unit-types">here</a> for more information.
 * @param value the integer representation of this enum.
 */
export enum ConcentrationUnits {
  /**
   * Micrograms-per-cubic-meter
   */
  MicrogramsPerCubicMeter = 31,
}
