// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNullOrUndefined } from '../../util/Helpers'
import { GetServiceResponse } from '../GetServiceResponse'
import { RequestChain } from '../http/RequestChain'
import { ServiceResponse } from '../ServiceResponse'
import { CacheOptions } from './CacheOptions'
import { PersistentCache } from './PersistentCache'
import { ServiceResponseTransformCache } from './ServiceResponseTransformCache'

/**
 * Provides a way to cache a list as a `Map`, so the items can be retrieved by key.
 *
 * @param TKey The type of key used to retrieve items from cache.
 * @param TData The type of data being cached.
 */
export class ServiceResponseMapCache<TKey, TData> {
  private readonly cache: ServiceResponseTransformCache<TData[], Map<TKey, TData>>

  constructor(
    getMapKey: (data: TData) => TKey,
    cache: PersistentCache,
    options: CacheOptions = new CacheOptions(),
  ) {
    this.cache = new ServiceResponseTransformCache(
      (items) => {
        const map: Map<TKey, TData> = new Map()
        for (const item of items) {
          const key = getMapKey(item)
          map.set(key, item)
        }
        return map
      },
      cache,
      options,
    )
  }

  /**
   * Adds data to the cache if it doesn't already exist.
   *
   * @param TRequest The type of request used to fetch data.
   * @param key The cache key.
   * @param getData The method used to fetch the data.
   * @param request The request used to fetch the data.
   * @param requestChain The request chain.
   * @return A ServiceResponse with the data.
   */
  public async getArrayOrAdd<TRequest>(
    key: any,
    getData: GetServiceResponse<TData[], TRequest>,
    request: TRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<TData[]>> {
    const mapResponse = await this.cache.getOrAdd(key, getData, request, requestChain)
    if (mapResponse.hasError || isNullOrUndefined(mapResponse.data)) {
      return mapResponse.transformError<[TData]>()
    }

    if (mapResponse.data.size > 0) {
      return ServiceResponse.create(
        Array.from(mapResponse.data.values()),
        mapResponse.URL,
        ServiceResponse.rawDataFromCache,
        mapResponse.template,
      )
    } else {
      return ServiceResponse.fromError(Error('unable to locate type'))
    }
  }

  /**
   * Gets an item from the `Map`, adding the data to the cache if necessary.
   *
   * @param TRequest The type of request used to fetch data.
   * @param key The cache key.
   * @param getData The method used to fetch the data.
   * @param request The request used to fetch the data.
   * @param itemKey The key used to take the item from the `Map`.
   * @param requestChain The request chain.
   * @return A ServiceResponse with the data.
   */
  public async getOrAdd<TRequest>(
    key: any,
    getData: GetServiceResponse<TData[], TRequest>,
    request: TRequest,
    itemKey: TKey,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<TData>> {
    const mapResponse = await this.cache.getOrAdd(key, getData, request, requestChain)
    if (mapResponse.hasError || isNullOrUndefined(mapResponse.data)) {
      return mapResponse.transformError<TData>()
    }
    const data = mapResponse.data.get(itemKey)
    if (isNullOrUndefined(data)) {
      return ServiceResponse.fromError(Error('unable to locate type'), mapResponse.URL)
    } else {
      return ServiceResponse.create(
        data,
        mapResponse.URL,
        mapResponse.rawData,
        mapResponse.template,
      )
    }
  }
}
