// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined } from '../../../util/Helpers'
import { PressureTendencyCode } from './PressureTendencyCode'

/**
 * Checks if a given value is a valid quantity
 * @param quantity the quantity to check.
 */
export function isQuantity(quantity: any): boolean {
  return (
    isNotNullOrUndefined(quantity) &&
    (isNotNullOrUndefined(quantity.Value) || isNotNullOrUndefined(quantity.value)) &&
    (isNotNullOrUndefined(quantity.UnitType) || isNotNullOrUndefined(quantity.unitType))
  )
}
/**
 * Gets the value from a quantity.
 * @param quantity the quantity.
 */
export function getQuantityValue(quantity: any): number {
  // todo: either this needs to be smarter to handle multiple types of 'models'
  // i.e. quantityRange, MetricAndImperialQuantityRange, RecordTemperatures
  if (isNotNullOrUndefined(quantity)) {
    return isNotNullOrUndefined(quantity.Value) ? quantity.Value : quantity.value
  }
}
/**
 * Gets the unit from a quantity.
 * @param quantity the quantity.
 */
export function getQuantityUnit(quantity: any): string {
  if (isNotNullOrUndefined(quantity)) {
    return isNotNullOrUndefined(quantity.Unit) ? quantity.Unit : quantity.unit
  }
}
/**
 * Gets the unitType from a quantity.
 * @param quantity the quantity.
 */
export function getQuantityUnitType(quantity: any): number {
  if (isNotNullOrUndefined(quantity)) {
    return isNotNullOrUndefined(quantity.UnitType) ? quantity.UnitType : quantity.unitType
  }
}

// region measurements
/**
 * Checks if a given value is a valid wind
 * @param wind the wind to check.
 */
export function isWind(wind: any): boolean {
  return (
    isNotNullOrUndefined(wind) &&
    (isQuantity(wind.Speed) || isQuantity(wind.speed)) &&
    (isCompassDirection(wind.Direction) || isCompassDirection(wind.direction))
  )
}

/**
 * Gets the wind speed.
 * @param wind the wind.
 */
export function getWindSpeed(wind: any): any {
  if (isNotNullOrUndefined(wind)) {
    return isNotNullOrUndefined(wind.Speed) ? wind.Speed : wind.speed
  }
}

/**
 * Gets the wind direction.
 * @param wind the wind.
 */
export function getWindDirection(wind: any): any {
  if (isNotNullOrUndefined(wind)) {
    return isNotNullOrUndefined(wind.Direction) ? wind.Direction : wind.direction
  }
}

/**
 * Checks if a given value is a valid compass direction
 * @param direction the direction to check.
 */
export function isCompassDirection(direction: any): boolean {
  return (
    isNotNullOrUndefined(direction) &&
    (isNotNullOrUndefined(direction.Degrees) || isNotNullOrUndefined(direction.degrees))
  )
}
/**
 * Gets the degrees of the direction.
 * @param direction the direction.
 */
export function getDirectionDegrees(direction: any): any {
  if (isNotNullOrUndefined(direction)) {
    return isNotNullOrUndefined(direction.Degrees) ? direction.Degrees : direction.degrees
  }
}
/**
 * Gets the english text of the direction.
 * @param direction the direction.
 */
export function getDirectionEnglishText(direction: any): any {
  if (isNotNullOrUndefined(direction)) {
    return isNotNullOrUndefined(direction.English) ? direction.English : direction.english
  }
}
/**
 * Gets the localized text of the direction.
 * @param direction the direction.
 */
export function getDirectionLocalizedText(direction: any): any {
  if (isNotNullOrUndefined(direction)) {
    return isNotNullOrUndefined(direction.Localized) ? direction.Localized : direction.localized
  }
}

/**
 * Checks if a given value is a valid pressure tendency
 * @param tendency the tendency to check.
 */
export function isPressureTendency(tendency: any): boolean {
  return isNotNullOrUndefined(tendency) && isNotNullOrUndefined(tendency.Code)
}
/**
 * Gets a pressure tendency symbol
 * @param tendency the tendency.
 */
const upArrow = '↑'
const leftRightArrow = '↔'
const downArrow = '↓'
export function getPressureTendencySymbol(tendency: any): string {
  if (isPressureTendency(tendency)) {
    switch (tendency.Code) {
      case PressureTendencyCode.Rising:
        return upArrow
      case PressureTendencyCode.Steady:
        return leftRightArrow
      case PressureTendencyCode.Falling:
        return downArrow
    }
  }
  return ''
}
// endregion measurements

// region metric and imperial
/**
 * Checks if a given value is a valid metricAndImperialQuantities
 * @param maiq the metric and imperial quantities to check.
 */
export function isMetricAndImperialQuantities(maiq: any): boolean {
  return (
    isNotNullOrUndefined(maiq) &&
    (isNotNullOrUndefined(maiq.Metric) || isNotNullOrUndefined(maiq.metric)) &&
    (isNotNullOrUndefined(maiq.Imperial) || isNotNullOrUndefined(maiq.imperial))
  )
}
/**
 * Gets the metric
 * @param maiq the metric and imperial quantities.
 */
export function getQuantity(maiq: any, isMetric: boolean): any {
  if (isMetricAndImperialQuantities(maiq)) {
    if (isMetric) {
      return isNotNullOrUndefined(maiq.Metric) ? maiq.Metric : maiq.metric
    }
    return isNotNullOrUndefined(maiq.Imperial) ? maiq.Imperial : maiq.imperial
  }
}

/**
 * Gets the current wind speed.
 * @param wind the current wind (metric and imperial).
 */
export function getCurrentWindSpeed(wind: any, isMetric: boolean): any {
  if (isNotNullOrUndefined(wind)) {
    return isNotNullOrUndefined(wind.Speed)
      ? getQuantity(wind.Speed, isMetric)
      : getQuantity(wind.speed, isMetric)
  }
}
// endregion metric and imperial
