/**
 * Encapsulates the parameters used to make requests to the appropriate API endpoint.
 * @constructor Initializes an instance of LocationByKeyRequest with the given language and location key.
 * This request can be used to make requests to the ILocationService to get a location by key.
 * @param language The language (i.e. en-us) in which the response is returned.
 * @param locationKey The unique ID of the location. Location keys can be obtained from the location service.
 */
export class LocationByKeyRequest {
  /**
   * The language (i.e. en-us) in which the response is returned.
   */
  public readonly language: string

  /**
   * The unique ID of the location. Location keys can be obtained from the location service.
   */
  public readonly locationKey: string

  /**
   * Gets or sets the details flag. True indicates that the response should include the full details.
   */
  public details = false

  constructor(language: string, locationKey: string, details = false) {
    this.language = language
    this.locationKey = locationKey
    this.details = details
  }
}
