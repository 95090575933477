// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Logger } from '../../../core/Logger'
import { formatNumberWithGroupings, isNullOrUndefined } from '../../../util/Helpers'

export class NumbFormatter {
  private _map: Map<string, any>
  private readonly logger: Logger = Logger.getInstance()
  private readonly englishISO = 'en-us'

  /**
   * number
   */
  public formatNumber(value: number, language: string): string {
    const field = this.getField(language, 'group')
    return formatNumberWithGroupings(value.toString(), field)
  }

  public setMap(map: Map<string, any>) {
    this._map = map
  }

  private getLanguage(language: string): any {
    if (isNullOrUndefined(this._map)) {
      this.logger.error('No map created need to pass at least 1 language to the units')
      throw new Error('No map created need to pass at least 1 language to the units')
    } else if (!this._map.has(language)) {
      this.logger.error(`Couldn't find a language pack for: ${language}`)
      throw new Error(`Couldn't find a language pack for: ${language}`)
    }
    return this._map.get(language)
  }

  private getField(language: string, fieldName: string): any {
    let lang = this.getLanguage(language)
    if (isNullOrUndefined(lang)) {
      lang = this.getLanguage(this.englishISO)
    }
    return lang[fieldName]
  }
}
