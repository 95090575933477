/* eslint-disable no-unused-vars */
/**
 * The types of precipitation display formats.
 */
export enum PrecipitationDisplayType {
  /**
   * Rain.
   */
  Rain,

  /**
   * Snow.
   */
  Snow,

  /**
   * Ice.
   */
  Ice,
}
