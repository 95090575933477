// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 * Provides a method used to validate an object.
 */
export class GeopositionValidRule {
  /**
   * Checks whether a geoposition's coordinates are within range.
   * @param latitude The latitude to be validated.
   * @param longitude The longitude to be validated.
   * @return If value is invalid, an exception; otherwise, undefined.
   */
  public static checkRule(latitude: number, longitude: number): Error {
    if (latitude <= -90 || latitude >= 90) {
      return Error(`latitude must be between -90 and 90. supplied value: ${latitude}`)
    }
    if (longitude <= -180 || longitude >= 180) {
      return Error(`longitude must be between -180 and 180. supplied value: ${longitude}`)
    }
    return undefined
  }
}
