// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiHttpService } from '../core/api/ApiHttpService'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { PartnerRouteResolver } from './PartnerRouteResolver'
import { PartnerService } from './PartnerService'
import { BrandingPartnerByLocationKeyRequest } from './requests/BrandingPartnerByLocationKeyRequest'
import { BrandingPartnersRequest } from './requests/BrandingPartnersRequest'

/**
 * Gets branding partner data for a specific location and language.
 * Only some locations have a branding partner, and usually only for specific language(s).
 */
export class PartnerServiceImpl implements PartnerService {
  protected readonly serviceName = 'PartnerService'
  private readonly httpService: ApiHttpService
  private readonly routeResolver: PartnerRouteResolver

  constructor(httpService: ApiHttpService, routeResolver: PartnerRouteResolver) {
    this.httpService = httpService
    this.routeResolver = routeResolver
  }

  // region branding partners
  /**
   * @deprecated Please use ContentService.getPartnerByLocationKey instead.
   * Gets branding partner data for a specific location.
   * @param request The request.
   * @return A service response containing either a BrandingPartner or error information.
   */
  public async getBrandingPartnerByLocationKey(
    request: BrandingPartnerByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getBrandingPartnerByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getBrandingPartnerByLocationKey',
        true,
      ),
    )
  }

  /**
   * @deprecated Please use ContentService.getPartnerByLocationKey instead.
   * Gets branding partner data.
   * @param request The request.
   * @return A service response containing either a list of [BrandingPartner]s or error information.
   */
  public async getBrandingPartners(
    request: BrandingPartnersRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getBrandingPartnersUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getBrandingPartners', true),
    )
  }
  // endregion branding partners
}
