import { BaseApiSettings } from './BaseApiSettings'

/**
 * Settings for how to connect to the API.
 */
export class ApiSettings extends BaseApiSettings {
  public readonly apiKey: string

  /**
   * Initializes a new `ApiSettings`.
   * @param apiKey the API key used to connect to the API.
   * @param baseAddress the API host base address.
   */
  constructor(apiKey: string, baseAddress = 'https://api.accuweather.com/') {
    super(baseAddress)
    this.apiKey = apiKey
  }
}
