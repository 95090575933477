/**
 * Encapsulates parameters used to make requests to [ContentService].
 */
export class PartnersRequest {
  /**
   * Gets or sets the language (i.e. en-us) for which the response should be returned in.
   */
  public language: string

  /**
   * Initializes a new [PartnersRequest].
   * @param language The language (i.e. en-us) for which the response should be returned in.
   */
  constructor(language: string) {
    this.language = language
  }
}
