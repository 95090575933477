import { ArticleCaseType } from './ArticleCaseType'
import { PeriodNameFormatWidth } from './PeriodNameFormatWidth'

/**
 * Encapsulates the parameters necessary for making formatting requests of the [LocalizationService].
 */
export class PeriodFormatOptions {
  /**
   * Gets or sets the language code.
   */
  public languageCode: string

  /**
   * Gets or sets the format width.
   */
  public formatWidth: PeriodNameFormatWidth

  /**
   * Gets or sets the article case type.
   */
  public articleCaseType: ArticleCaseType = ArticleCaseType.StandAlone

  /**
   * Initializes a new [PeriodFormatOptions].
   * @param languageCode The language code.
   * @param formatWidth The format width.
   */
  constructor(
    languageCode: string,
    formatWidth: PeriodNameFormatWidth = PeriodNameFormatWidth.Short,
  ) {
    this.languageCode = languageCode
    this.formatWidth = formatWidth
  }
}
