// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ProductType } from '../../core/support/ProductType'

/**
 * Encapsulates the base information used to cache location data.
 */
export class BaseLocationCacheInfo {
  /**
   * The location key- used as the cache key.
   */
  public key = ''

  /**
   * The url slug.
   */
  public urlSlug = ''

  /**
   * The station code- used for most weather endpoints.
   */
  public stationCode = ''

  /**
   * The station gmt offset- used for most weather endpoints.
   */
  public locationGmtOffset = 0

  /**
   * The climo code- used for most climo endpoints.
   */
  public climo: string = undefined

  /**
   * The country code.
   */
  public countryCode: string = undefined

  /**
   * The admin code.
   */
  public adminCode: string = undefined

  /**
   * The dma ID.
   */
  public dmaCode: string = undefined

  /**
   * The latitude.
   */
  public latitude = 0.0

  /**
   * The longitude.
   */
  public longitude = 0.0

  /**
   * The time zone code.
   */
  public timeZoneCode: string = undefined

  /**
   * The list of available products.
   */
  public dataSets: ProductType[] = []
}
