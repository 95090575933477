export type Zero = 0
export class CacheOptions {
  /**
   * The time-to-live in cache, in seconds.
   * If 0, the item will not be cached.
   * Default is `Infinity`, meaning the item will never expire.
   */
  public ttl: number

  /**
   * Whether the time-to-live will be 'reset' each time the item is accessed.
   * If true, `ttl` will be treated as time-to-idle.
   */
  public isSlidingExpiry = false

  /**
   * @param ttl The time-to-live in cache, in seconds.
   * @param isSlidingExpiry Whether the time-to-live will be 'reset' each time the item is accessed.
   */
  constructor(ttl = Infinity, isSlidingExpiry = false) {
    this.ttl = ttl
    this.isSlidingExpiry = isSlidingExpiry
  }
}
