// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNullOrEmpty, isNullOrUndefined } from '../../util/Helpers'
import { RouteResolver } from '../http/RouteResolver'
import { SdkSettings } from '../settings/SdkSettings'
import { ApiTokens } from './ApiTokens'

/**
 * A custom route resolver that automatically prepends the API_KEY token/value pair to the args used to replace tokens.
 */
export class ApiRouteResolver extends RouteResolver {
  private readonly settings: SdkSettings

  constructor(templates: Map<string, string>, settings: SdkSettings) {
    super(templates)
    this.settings = settings
  }

  // Overrides the base implementation to include the apiKey token/value.
  protected makeArgs(args: Map<string, any>): Map<string, any> {
    const pairs = super.makeArgs(args)
    // if the api key wasn't passed in (or it's null, undefined, or empty), use the one from settings
    if (
      isNullOrUndefined(pairs.get(ApiTokens.apiKey)) ||
      isNullOrEmpty(pairs.get(ApiTokens.apiKey))
    ) {
      pairs.set(ApiTokens.apiKey, this.settings.apiSettings.apiKey)
    }
    return pairs
  }
}
