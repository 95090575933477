/**
 * Provides a collection of private vals used to replace {tokens} in map route templates.
 */
export class MapTokens {
  /**
   * Used to replace {baseurl} in api map templates.
   */
  public static readonly baseUrl = 'baseurl'

  /**
   * Used to replace {cbt} in map url templates.
   */
  public static readonly cacheBuster = 'cbt'

  /**
   * Used to replace {categories} in api map templates.
   */
  public static readonly categories = 'categories'

  /**
   * Used to replace {colorTable} in map url templates.
   */
  public static readonly colorTable = 'colorTable'

  /**
   * Used to replace {date} in map url templates.
   */
  public static readonly date = 'date'

  /**
   * Used to replace {displayMode} in map url templates.
   */
  public static readonly displayMode = 'displayMode'

  /**
   * Used to replace {rightlon} in map url templates.
   */
  public static readonly easternLongitude = 'rightlon'

  /**
   * Used to replace {height} in map url templates.
   */
  public static readonly height = 'height'

  /**
   * Used to replace {includeBoundingBox} in map url templates.
   */
  public static readonly includeBoundingBox = 'includeBoundingBox'

  /**
   * Used to replace {attribute} in map url templates.
   */
  public static readonly includeAttribution = 'attribute'

  /**
   * Used to replace {limit} in map url templates.
   */
  public static readonly limit = 'limit'

  /**
   * Used to replace {lowerright} in api map templates.
   */
  public static readonly lowerRight = 'lowerright'

  /**
   * Used to replace {mapType} in map url templates.
   */
  public static readonly mapType = 'mapType'

  /**
   * Used to replace {minweight} in api map templates.
   */
  public static readonly minWeight = 'minweight'

  /**
   * Used to replace {toplat} in map url templates.
   */
  public static readonly northernLatitude = 'toplat'

  /**
   * Used to replace {quadkey} in map url templates.
   */
  public static readonly quadkey = 'quadkey'

  /**
   * Used to replace {products} in map url templates.
   */
  public static readonly products = 'products'

  /**
   * Used to replace {showBorders} in map url templates.
   */
  public static readonly showBorders = 'showBorders'

  /**
   * Used to replace {bottomlat} in map url templates.
   */
  public static readonly southernLatitude = 'bottomlat'

  /**
   * Used to replace {upperleft} in api map templates.
   */
  public static readonly upperLeft = 'upperleft'

  /**
   * Used to replace {leftlon} in map url templates.
   */
  public static readonly westernLongitude = 'leftlon'

  /**
   * Used to replace {timestep} in api url templates.
   */
  public static readonly timeStep = 'timestep'

  /**
   * Used to replace {width} in map url templates.
   */
  public static readonly width = 'width'

  /**
   * Used to replace {x} in map url templates.
   */
  public static readonly x = 'x'

  /**
   * Used to replace {x1} in map url templates.
   */
  public static readonly x1 = 'x1'

  /**
   * Used to replace {x2} in map url templates.
   */
  public static readonly x2 = 'x2'

  /**
   * Used to replace {y} in map url templates.
   */
  public static readonly y = 'y'

  /**
   * Used to replace {y1} in map url templates.
   */
  public static readonly y1 = 'y1'

  /**
   * Used to replace {y2} in map url templates.
   */
  public static readonly y2 = 'y2'

  /**
   * Used to replace {zoom} in api map templates.
   */
  public static readonly zoom = 'zoom'

  /**
   * Used to replace {eventKey} in api map templates.
   */
  public static readonly eventKey = 'eventKey'

  /**
   * Used to replace {basinIds} in api map templates.
   */
  public static readonly basinIds = 'basinIds'

  /**
   * Used to replace {bottommerc} in api map templates.
   */
  public static readonly bottommerc = 'bottommerc'

  /**
   * Used to replace {leftmerc} in api map templates.
   */
  public static readonly leftmerc = 'leftmerc'

  /**
   * Used to replace {rightmerc} in api map templates.
   */
  public static readonly rightmerc = 'rightmerc'

  /**
   * Used to replace {topmerc} in api map templates.
   */
  public static readonly topmerc = 'topmerc'

  /**
   * Used to replace {frame} in api map templates.
   */
  public static readonly frame = 'frame'
}
