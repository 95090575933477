import { TextMetaType } from './TextMetaType'

/**
 * Provides meta information for some text, specifically the [TextMetaType] of text.
 */
export class TextMeta {
  /**
   * Gets or sets the type of text.
   */
  public type: TextMetaType

  /**
   * Gets or sets the text.
   */
  public text: string
  /**
   * Initializes a new [TextMeta].
   * @param type the type of text.
   * @param text the text.
   */
  constructor(type: TextMetaType, text: string) {
    this.type = type
    this.text = text
  }
}
