/**
 * The date-time pattern shows how to combine separate patterns for date and time into a single pattern.
 * There are four formats: full, long, medium, and short.
 *
 * The determination of which to use is normally based on the date style, for example:
 *      If the date has a full month and weekday name, the full combining pattern will be used to combine that with a time.
 *      If the date has numeric month, the short version of the combining pattern will be used to combine that with a time.
 */
export enum DateTimeFormatWidth {
  /**
   * Should contain hour (h/H), minute (mm), second (ss), and time zone (zzzz), i.e. "h:mm:ss zzzz" or "HH:mm:ss zzzz".
   */
  Full,

  /**
   * Should contain hour(h/H), minute(mm), second(ss), and time zone (z), i.e. "h:mm:ss z" or "HH:mm:ss z".
   */
  Long,

  /**
   * Should contain hour(h/H), minute(mm), and second(ss), i.e. "h:mm:ss" or "HH:mm:ss".
   */
  Medium,

  /**
   * Should contain hour(h/H) and minute(mm), i.e. "h:mm" or "HH:mm".
   */
  Short,
}
