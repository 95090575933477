import { AttributionSourceType } from '../AttributionSourceType'

/**
 * Encapsulates parameters used to make requests to [DataAttributionService].
 */
export class AttributionSourcesRequest {
  /**
   * Gets or sets the language (i.e. en-us) for which the response should be returned in.
   */
  public language: string

  /**
   * Gets or sets the type of sources by which to filter the results.
   * Optional; if undefined/null, all data sources will be returned.
   */
  public attributionSourceType?: AttributionSourceType

  /**
   * Initializes a new [AttributionSourcesRequest].
   * @param language The language (i.e. en-us) in which the response is returned.
   */
  constructor(language: string) {
    this.language = language
  }
}
