/**
 * Represents the level of product availability.
 */
export enum AvailabilityLevel {
  /**
   * Supported
   */
  Supported,

  /**
   * Unsupported
   */
  Unsupported,

  /**
   * TemporarilyUnavailable
   */
  TemporarilyUnavailable,
}
