import { isNotNullOrUndefined } from '../util/Helpers'

/**
 * Provides language utility methods.
 */
export class LanguageUtil {
  private static readonly english = 'en-us'
  private static readonly languages = new Set<string>([
    'ar',
    'ar-az',
    'az-latn-az',
    'az',
    'bg',
    'bn-in',
    'bn',
    'bs',
    'ca-es',
    'ca',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'en-gb',
    'en-us',
    'es',
    'es-ar',
    'es-mx',
    'et',
    'fa',
    'fi',
    'fr',
    'fr-ca',
    'gu',
    'he',
    'hi',
    'hr',
    'hu',
    'id',
    'is',
    'it',
    'ja',
    'kk',
    'kn',
    'ko',
    'lt',
    'lv',
    'mk',
    'mr',
    'ms',
    'my',
    'nl',
    'no',
    'pa',
    'pl',
    'pt',
    'pt-br',
    'pt-pt',
    'ro',
    'ru',
    'sk',
    'sl',
    'sr',
    'sr-me',
    'sv',
    'sw',
    'ta',
    'te',
    'th',
    'tl',
    'tr',
    'uk',
    'ur',
    'uz',
    'vi',
    'zh',
    'zh-cn',
    'zh-hk',
    'zh-tw',
  ])

  /**
   * Gets a supported locale.
   * If [locale] is culture-specific and no match is found,
   * will attempt to locate the culture-neutral locale.
   * If no culture-neutral locale is found, will return "en-us".
   * @param locale the locale.
   * @return a valid locale.
   */
  public static getSupportedLocale(locale: string): string {
    if (isNotNullOrUndefined(locale)) {
      const loc = locale.toLowerCase()
      if (LanguageUtil.languages.has(loc)) return loc
      if (loc.length > 2) {
        const neutral = loc.substring(0, 2)
        if (LanguageUtil.languages.has(neutral)) return neutral
      }
    }
    return LanguageUtil.english
  }
}
