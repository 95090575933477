// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { ApiTokens } from '../core/api/ApiTokens'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { ProductType } from '../core/support/ProductType'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { LanguageValidRule } from '../core/validation/LanguageValidRule'
import { LocationKeyValidRule } from '../core/validation/LocationKeyValidRule'
import { ObjectNotNullOrUndefinedRule } from '../core/validation/ObjectNotNullOrUndefinedRule'
import { ProductAvailabilityService } from '../productavailability/ProductAvailabilityService'
import { ContextualRouteResolver } from './ContextualRouteResolver'
import { CurrentAirQualityByLocationKeyRequest } from './requests/CurrentAirQualityByLocationKeyRequest'
import { DailyAirQualityByLocationKeyRequest } from './requests/DailyAirQualityByLocationKeyRequest'
import { DailyIndexValuesRequest } from './requests/DailyIndexValuesRequest'
import { GroupIndexValuesByLocationKeyRequest } from './requests/GroupIndexValuesByLocationKeyRequest'
import { HourlyAirQualityByLocationKeyRequest } from './requests/HourlyAirQualityByLocationKeyRequest'
import { HourlyIndexValuesRequest } from './requests/HourlyIndexValuesRequest'
import { IndexGroupsRequest } from './requests/IndexGroupsRequest'
import { IndexGroupTypeValidRule } from './requests/IndexGroupTypeValidRule'
import { IndexInfoByTypeRequest } from './requests/IndexInfoByTypeRequest'
import { IndexTypeValidRule } from './requests/IndexTypeValidRule'
import { IndexValuesByLocationKeyRequest } from './requests/IndexValuesByLocationKeyRequest'
import { IndicesInfoByGroupRequest } from './requests/IndicesInfoByGroupRequest'
import { IndicesInfoRequest } from './requests/IndicesInfoRequest'
import { LocalDailyIndexValuesByLocationKeyRequest } from './requests/LocalDailyIndexValuesByLocationKeyRequest'
import { LocalDailyIndicesValuesByLocationKeyRequest } from './requests/LocalDailyIndicesValuesByLocationKeyRequest'
import { LocalHourlyIndexValuesByLocationKeyRequest } from './requests/LocalHourlyIndexValuesByLocationKeyRequest'
import { LocalHourlyIndicesValuesByLocationKeyRequest } from './requests/LocalHourlyIndicesValuesByLocationKeyRequest'

/**
 * Resolves routes for [ContextualService] methods.
 */
export class ContextualRouteResolverImpl implements ContextualRouteResolver {
  protected readonly sdkSettings: SdkSettings
  protected readonly routeResolver: ApiRouteResolver
  protected readonly productAvailabilityService: ProductAvailabilityService

  // region routes
  protected readonly currentAirQualityByLocationKey = 'CurrentAirQualityByLocationKey'
  protected readonly dailyAirQualityByLocationKey = 'DailyAirQualityForecastsByLocationKey'
  protected readonly hourlyAirQualityByLocationKey = 'HourlyAirQualityForecastsByLocationKey'
  protected readonly indexGroups = 'IndexGroups'
  protected readonly indicesInfo = 'IndicesInfo'
  protected readonly indicesInfoByGroup = 'IndicesInfoByGroup'
  protected readonly indexInfoByType = 'IndexInfoByType'
  protected readonly indexValuesByLocationKey = 'IndexValuesByLocationKey'
  protected readonly groupIndexValuesByLocationKey = 'GroupIndexValuesByLocationKey'
  protected readonly localDailyIndicesValuesByLocationKey = 'LocalDailyIndicesValuesByLocationKey'
  protected readonly localDailyIndexValuesByLocationKey = 'LocalDailyIndexValuesByLocationKey'
  protected readonly localHourlyIndicesValuesByLocationKey = 'LocalHourlyIndicesValuesByLocationKey'
  protected readonly localHourlyIndexValuesByLocationKey = 'LocalHourlyIndexValuesByLocationKey'

  protected readonly routeTemplates: Map<string, string> = new Map([
    [
      this.currentAirQualityByLocationKey,
      'airquality/v2/currentconditions/{locationKey}?apikey={apikey}&language={language}&pollutants={details}',
    ],
    [
      this.dailyAirQualityByLocationKey,
      'airquality/v2/forecasts/daily/{dayCount}day/{locationKey}?apikey={apikey}&language={language}',
    ],
    [
      this.hourlyAirQualityByLocationKey,
      'airquality/v2/forecasts/hourly/{hourCount}hour/{locationKey}?apikey={apikey}&language={language}&pollutants={details}',
    ],
    [this.indexGroups, 'indices/v1/daily/groups.json?apikey={apikey}&language={language}'],
    [this.indicesInfo, 'indices/v1/daily.json?apikey={apikey}&language={language}'],
    [
      this.indicesInfoByGroup,
      'indices/v1/daily/groups/{groupId}.json?apikey={apikey}&language={language}',
    ],
    [this.indexInfoByType, 'indices/v1/daily/{indexId}.json?apikey={apikey}&language={language}'],
    [
      this.indexValuesByLocationKey,
      'indices/v1/daily/{dayCount}day/{locationKey}/{indexId}.json?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.groupIndexValuesByLocationKey,
      'indices/v1/daily/{dayCount}day/{locationKey}/groups/{groupId}.json?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.localDailyIndicesValuesByLocationKey,
      'indices/v1/local/daily/{dayCount}day/{locationKey}.json?apikey={apikey}&language={language}',
    ],
    [
      this.localDailyIndexValuesByLocationKey,
      'indices/v1/local/daily/{dayCount}day/{locationKey}/{indexId}.json?apikey={apikey}&language={language}',
    ],
    [
      this.localHourlyIndicesValuesByLocationKey,
      'indices/v1/local/hourly/{hourCount}hour/{locationKey}.json?apikey={apikey}&language={language}',
    ],
    [
      this.localHourlyIndexValuesByLocationKey,
      'indices/v1/local/hourly/{hourCount}hour/{locationKey}/{indexId}.json?apikey={apikey}&language={language}',
    ],
  ])
  // endregion routes

  // region validators
  // region index meta
  protected readonly indexInfoByTypeRequestValidator: AsyncValidator<IndexInfoByTypeRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => IndexTypeValidRule.checkRule(r.indexType),
    ])

  protected readonly indicesInfoByGroupRequestValidator: AsyncValidator<IndicesInfoByGroupRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => IndexGroupTypeValidRule.checkRule(r.indexGroupType),
    ])

  protected readonly indicesInfoRequestValidator: AsyncValidator<IndicesInfoRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
    ])

  protected readonly indexGroupsRequestValidator: AsyncValidator<IndexGroupsRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
    ])
  // endregion index meta

  // region index data
  protected readonly localHourlyIndexValuesByLocationKeyRequestValidator: AsyncValidator<LocalHourlyIndexValuesByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => IndexTypeValidRule.checkRule(r.indexType),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => this.checkValidLocalIndexValuesHourCountRule(r),
    ])

  protected readonly localHourlyIndicesValuesByLocationKeyRequestValidator: AsyncValidator<LocalHourlyIndicesValuesByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => this.checkValidLocalIndexValuesHourCountRule(r),
    ])

  protected readonly localDailyIndexValuesByLocationKeyRequestValidator: AsyncValidator<LocalDailyIndexValuesByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => IndexTypeValidRule.checkRule(r.indexType),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => this.checkValidLocalIndexValuesDayCountRule(r),
    ])

  protected readonly localDailyIndicesValuesByLocationKeyRequestValidator: AsyncValidator<LocalDailyIndicesValuesByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => this.checkValidLocalIndexValuesDayCountRule(r),
    ])

  protected readonly groupIndexValuesByLocationKeyRequestValidator: AsyncValidator<GroupIndexValuesByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => IndexGroupTypeValidRule.checkRule(r.indexGroupType),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => this.checkValidIndexValuesDayCountRule(r),
    ])

  protected readonly indexValuesByLocationKeyRequestValidator: AsyncValidator<IndexValuesByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => IndexTypeValidRule.checkRule(r.indexType),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => this.checkValidIndexValuesDayCountRule(r),
    ])
  // endregion index data

  // region air quality
  protected readonly currentAirQualityByLocationKeyRequestValidator: AsyncValidator<CurrentAirQualityByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
    ])
  protected readonly dailyAirQualityByLocationKeyRequestValidator: AsyncValidator<DailyAirQualityByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => this.checkValidAirQualityDayCountRule(r),
    ])
  protected readonly hourlyAirQualityByLocationKeyRequestValidator: AsyncValidator<HourlyAirQualityByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => this.checkValidAirQualityHourCountRule(r),
    ])
  // endregion air quality
  // endregion validators

  constructor(sdkSettings: SdkSettings, productAvailabilityService: ProductAvailabilityService) {
    this.sdkSettings = sdkSettings
    this.productAvailabilityService = productAvailabilityService
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, this.sdkSettings)
    this.addValidatorRules()
  }

  // region indices
  // region index meta
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getIndexInfoByTypeUrl(
    request: IndexInfoByTypeRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.indexInfoByType,
      request,
      this.indexInfoByTypeRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.indexId, request?.indexType],
        [ApiTokens.language, request?.language],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getIndicesInfoByGroupUrl(
    request: IndicesInfoByGroupRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.indicesInfoByGroup,
      request,
      this.indicesInfoByGroupRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.groupId, request?.indexGroupType],
        [ApiTokens.language, request?.language],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getIndicesInfoUrl(
    request: IndicesInfoRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.indicesInfo,
      request,
      this.indicesInfoRequestValidator,
      requestChain,
      new Map<string, any>([[ApiTokens.language, request?.language]]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getGroupsUrl(
    request: IndexGroupsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.indexGroups,
      request,
      this.indexGroupsRequestValidator,
      requestChain,
      new Map<string, any>([[ApiTokens.language, request?.language]]),
    )
  }
  // endregion index meta
  // region index data
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getLocalHourlyIndexValuesByLocationKeyUrl(
    request: LocalHourlyIndexValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.localHourlyIndexValuesByLocationKey,
      request,
      this.localHourlyIndexValuesByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.hourCount, request?.hourCount],
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.indexId, request?.indexType],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getLocalHourlyIndicesValuesByLocationKeyUrl(
    request: LocalHourlyIndicesValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.localHourlyIndicesValuesByLocationKey,
      request,
      this.localHourlyIndicesValuesByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.hourCount, request?.hourCount],
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getLocalDailyIndexValuesByLocationKeyUrl(
    request: LocalDailyIndexValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.localDailyIndexValuesByLocationKey,
      request,
      this.localDailyIndexValuesByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.dayCount, request?.dayCount],
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.indexId, request?.indexType],
        [ApiTokens.language, request?.language],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getLocalDailyIndicesValuesByLocationKeyUrl(
    request: LocalDailyIndicesValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.localDailyIndicesValuesByLocationKey,
      request,
      this.localDailyIndicesValuesByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.dayCount, request?.dayCount],
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getGroupValuesByLocationKeyUrl(
    request: GroupIndexValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.groupIndexValuesByLocationKey,
      request,
      this.groupIndexValuesByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.dayCount, request?.dayCount],
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.groupId, request?.indexGroupType],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getIndexValuesByLocationKeyUrl(
    request: IndexValuesByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.indexValuesByLocationKey,
      request,
      this.indexValuesByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.dayCount, request?.dayCount],
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.indexId, request?.indexType],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.details],
      ]),
    )
  }
  // endregion index data
  // endregion indices

  // region air quality
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getCurrentAirQualityUrl(
    request: CurrentAirQualityByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.currentAirQualityByLocationKey,
      request,
      this.currentAirQualityByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.details, request?.includePollutants],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getDailyAirQualityForecastsUrl(
    request: DailyAirQualityByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.dailyAirQualityByLocationKey,
      request,
      this.dailyAirQualityByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.dayCount, request?.dayCount],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getHourlyAirQualityForecastsUrl(
    request: HourlyAirQualityByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.hourlyAirQualityByLocationKey,
      request,
      this.hourlyAirQualityByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request?.locationKey],
        [ApiTokens.language, request?.language],
        [ApiTokens.hourCount, request?.hourCount],
        [ApiTokens.details, request?.includePollutants],
      ]),
    )
  }

  // we can assume request has been checked for null before being passed to the validation rules
  protected checkValidIndexValuesDayCountRule(request: DailyIndexValuesRequest): Error {
    if (
      request.dayCount === 15 ||
      request.dayCount === 10 ||
      request.dayCount === 5 ||
      request.dayCount === 1
    ) {
      return
    }
    return new Error('invalid day count. valid values are 1, 5, 10, and 15.')
  }
  protected checkValidLocalIndexValuesDayCountRule(request: DailyIndexValuesRequest): Error {
    if (request.dayCount === 5 || request.dayCount === 1) {
      return
    }
    return new Error('invalid day count. valid values are 1 and 5.')
  }
  protected checkValidLocalIndexValuesHourCountRule(request: HourlyIndexValuesRequest): Error {
    if (
      request.hourCount === 72 ||
      request.hourCount === 24 ||
      request.hourCount === 12 ||
      request.hourCount === 1
    ) {
      return
    }
    return new Error('invalid hour count. valid values are 1, 12, 24, and 72.')
  }

  // we can assume request has been checked for null before being passed to the validation rules
  protected checkValidAirQualityDayCountRule(request: DailyAirQualityByLocationKeyRequest): Error {
    if (request.dayCount > 0 && request.dayCount < 8) {
      return
    }
    return new Error('invalid day count. valid values are 1-7.')
  }
  protected checkValidAirQualityHourCountRule(
    request: HourlyAirQualityByLocationKeyRequest,
  ): Error {
    if (
      request.hourCount === 96 ||
      request.hourCount === 72 ||
      request.hourCount === 48 ||
      request.hourCount === 24 ||
      request.hourCount === 12 ||
      request.hourCount === 1
    ) {
      return
    }
    return new Error('invalid hour count. valid values are 1, 12, 24, 48, 72, and 96.')
  }
  // endregion air quality

  private addValidatorRules() {
    this.localDailyIndicesValuesByLocationKeyRequestValidator.addAsyncRule((r, rc) =>
      this.productAvailabilityService.checkLocation(
        ProductType.DailyLocalIndices,
        r.language,
        r.locationKey,
        rc,
      ),
    )
    this.localDailyIndexValuesByLocationKeyRequestValidator.addAsyncRule((r, rc) =>
      this.productAvailabilityService.checkLocation(
        ProductType.DailyLocalIndices,
        r.language,
        r.locationKey,
        rc,
      ),
    )
    this.localHourlyIndicesValuesByLocationKeyRequestValidator.addAsyncRule((r, rc) =>
      this.productAvailabilityService.checkLocation(
        ProductType.HourlyLocalIndices,
        r.language,
        r.locationKey,
        rc,
      ),
    )
    this.localHourlyIndexValuesByLocationKeyRequestValidator.addAsyncRule((r, rc) =>
      this.productAvailabilityService.checkLocation(
        ProductType.HourlyLocalIndices,
        r.language,
        r.locationKey,
        rc,
      ),
    )

    this.currentAirQualityByLocationKeyRequestValidator.addAsyncRule((r, rc) =>
      this.productAvailabilityService.checkLocation(
        ProductType.AirQualityCurrentConditions,
        r.language,
        r.locationKey,
        rc,
      ),
    )
    this.dailyAirQualityByLocationKeyRequestValidator.addAsyncRule((r, rc) =>
      this.productAvailabilityService.checkLocation(
        ProductType.AirQualityForecasts,
        r.language,
        r.locationKey,
        rc,
      ),
    )
    this.hourlyAirQualityByLocationKeyRequestValidator.addAsyncRule((r, rc) =>
      this.productAvailabilityService.checkLocation(
        ProductType.AirQualityForecasts,
        r.language,
        r.locationKey,
        rc,
      ),
    )
    // this.premiumAirQualityByLocationKeyRequestValidator
    //     .addAsyncRule((r, rc) => this.productAvailabilityService.checkLocation(ProductType.PremiumAirQuality, r.language, r.locationKey, rc))
  }
}
