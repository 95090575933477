import { AxiosRequestConfig, Method, ResponseType } from 'axios'
import { isNullOrUndefined } from '../../util/Helpers'
import { SdkHttpSettings } from '../settings/SdkHttpSettings'

export class AccuAxiosRequestConfig implements AxiosRequestConfig {
  public url?: string
  public method?: Method = 'GET'
  public responseType?: ResponseType = 'json'
  public timeout?: number
  public headers?: any

  // public baseURL?: string
  // public transformRequest?: AxiosTransformer | AxiosTransformer[]
  // public transformResponse?: AxiosTransformer | AxiosTransformer[]
  // public params?: any
  // public paramsSerializer?: (params: any) => string
  // public data?: any
  // public withCredentials?: boolean
  // public adapter?: AxiosAdapter
  // public auth?: AxiosBasicCredentials
  // public xsrfCookieName?: string
  // public xsrfHeaderName?: string
  // public onUploadProgress?: (progressEvent: any) => void
  // public onDownloadProgress?: (progressEvent: any) => void
  // public maxContentLength?: number
  // public validateStatus?: (status: number) => boolean
  // public maxRedirects?: number
  // public socketPath?: string | null
  // public httpAgent?: any
  // public httpsAgent?: any
  // public proxy?: AxiosProxyConfig | false
  // public cancelToken?: CancelToken

  constructor(httpSettings?: SdkHttpSettings) {
    if (isNullOrUndefined(httpSettings)) {
      return
    }
    if (httpSettings) this.timeout = httpSettings.timeoutPerTry
    this.headers = {
      /*
        Right now this is interfering with request disable until user agents are allowed. AWX-615
        "User-Agent": "AccuWeather AccuNodeSDK Web Request Client/1.0" + (isNullOrUndefined(httpSettings.userAgentSuffix) ? '' : httpSettings.userAgentSuffix)
      */
    }

    // add additional settings here as needed
  }
}
