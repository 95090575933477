// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import './Extenstions'

/**
 * Waits the given number of milliseconds.
 * @param ms The number of milliseconds to sleep.
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Checks if a given value is null or undefined.
 * @param val the value to check.
 */
export function isNullOrUndefined(val: any): boolean {
  return val === null || val === undefined
}

/**
 * Checks if a given value is not null or undefined.
 * @param val the value to check.
 */
export function isNotNullOrUndefined(val: any): boolean {
  return !isNullOrUndefined(val)
}

/**
 * Checks a given `val` for null or undefined and returns it if it has a value, else return `def`.
 * @param val the value to check for null or undefined.
 * @param def the default value if `val` is null or undefined.
 */
export function valueOrDefault<T>(val: T, def: T): T {
  if (isNotNullOrUndefined(val)) {
    return val
  } else {
    return def
  }
}

/**
 * Checks whether a given value is within a given range
 * @param val The value to check.
 * @param min The range min.
 * @param max The range max.
 */
export function isInRange(val: number, min: number, max: number) {
  return val >= min && val <= max
}

/**
 * Gets a map's values as a list
 */
export function toList<T, K>(map: Map<K, T>): T[] {
  if (isNotNullOrUndefined(map)) {
    const result = Array<T>()
    for (const i in map) {
      // tslint:disable-line
      result.push(map[i])
    }
    return result
  }
}

// region string
/**
 * Checks if a given string is not null, undefined, or empty.
 * @param val the value to check.
 */
export function isNotNullOrEmpty(val: string): boolean {
  return isNotNullOrUndefined(val) && val.length > 0
}

/**
 * Checks if a given string is null, undefined, or empty.
 * @param val the value to check.
 */
export function isNullOrEmpty(val: string): boolean {
  return isNullOrUndefined(val) || val.length === 0
}

/**
 * Checks if a given string is not null, undefined, or blank (containing only whitespace).
 * @param val the value to check.
 */
export function isNotNullOrBlank(val: string): boolean {
  return isNotNullOrUndefined(val) && !val.isBlank()
}

/**
 * Checks if a given string is null, undefined, or blank (containing only whitespace).
 * @param val the value to check.
 */
export function isNullOrBlank(val: string): boolean {
  return isNullOrUndefined(val) || val.isBlank()
}

/**
 * Pads a number with leading zeros.
 * @param num The value to be padded.
 * @param size The width of the result.
 */
export function pad(value: number, size: number) {
  let s = value + ''
  while (s.length < size) {
    s = '0' + s
  }
  return s
}
/**
 * Indicates whether a given number is an integer.
 * @param n The number to check.
 * @returns True, if the number is an integer; otherwise, false.
 */
export function isInt(n: number): boolean {
  return n % 1 === 0
}

/**
 * Replaces numeric tokens in a string format pattern.
 * @param text The format pattern.
 * @param args The replacements.
 * @example formatString("Hello {0}{1}", "World", "!")
 */
export function formatString(text: string, ...args: any[]): string {
  return text.replace(/{(\d+)}/g, (match, num) => {
    return typeof args[num] !== 'undefined' ? args[num].toString() : match
  })
}

export function formatNumberWithGroupings(text: string, group: string): string {
  if (text.indexOf('.') > -1) {
    return text.replace(/(\d)(?=(\d{3})+\.)/g, '$1' + group)
  }

  return text.replace(/\B(?=(\d{3})+(?!\d))/g, group)
}

// endregion string

// region
export function enumValues(type: any) {
  let keys = Object.keys(type).filter((k) => typeof type[k as any] === 'number')
  if (keys.length === 0) {
    // keys must not be numbers
    keys = Object.keys(type)
  }
  const values = keys.map((k) => type[k as any])
  return values
}
export function objectValues(obj: any) {
  return Object.keys(obj).map((k) => obj[k])
}
// endregion

// region date
const seconds = 1000
const minute = 60 * seconds
const hour = 60 * minute
const day = 24 * hour

/**
 * Adds the specified number of days to a `Date` object.
 * @param date the date to which days should be added.
 * @param days the number of days to add to the date.
 */
export function addDaysToDate(days: number, date: Date = new Date()): Date {
  return addDurationToDate(daysInterval(days), date)
}
/**
 * Creates an interval of n days.
 * @param minutes The number of days.
 */
export function daysInterval(days: number): number {
  if (isNullOrUndefined(days)) {
    days = 1
  }
  return days * day
}

/**
 * Adds the specified number of hours to a `Date` object.
 * @param date the date to which hours should be added.
 * @param minutes the number of hours to add to the date.
 */
export function addHoursToDate(hours: number, date: Date = new Date()): Date {
  return addDurationToDate(hoursInterval(hours), date)
}
/**
 * Creates an interval of n hours.
 * @param minutes The number of hours.
 */
export function hoursInterval(hours: number): number {
  if (isNullOrUndefined(hours)) {
    hours = 1
  }
  return hours * hour
}

/**
 * Adds the specified number of minutes to a `Date` object.
 * @param date the date to which minutes should be added.
 * @param minutes the number of minutes to add to the date.
 */
export function addMintuesToDate(minutes: number, date: Date = new Date()): Date {
  return addDurationToDate(minutesInterval(minutes), date)
}
/**
 * Creates an interval of n minutes.
 * @param minutes The number of minutes.
 */
export function minutesInterval(minutes: number): number {
  if (isNullOrUndefined(minutes)) {
    minutes = 1
  }
  return minutes * minute
}

/**
 * Adds the specified duration (in milliseconds) to a `Date` object.
 * @param date the date to which minutes should be added.
 * @param minutes the number of milliseconds to add to the date.
 */
export function addDurationToDate(ms: number, date: Date = new Date()): Date {
  return isNullOrUndefined(ms) || ms === 0 ? date : new Date(date.getTime() + ms)
}

/**
 * Formats a given date to an iso8601 string.
 * @param date the date to be formatted.
 */
export function dateToIso8601String(date: Date): string {
  // todo: should we also take a timezone here?
  let result: string
  if (isNotNullOrUndefined(date)) {
    result = date.toISOString() // Exptected date output 2021-01-26 10:10:33
  }
  return result ?? ''
}
// endregion

// region list
/**
 * Indicates whether a given list is not null, undefined, or empty.
 * @param val the value to check.
 */
export function isNotNullOrEmptyList<T>(val: T[]): boolean {
  return isNotNullOrUndefined(val) && val.length > 0
}
/**
 * Indicates whether a given list is null, undefined, or empty.
 * @param val the value to check.
 */
export function isNullOrEmptyList<T>(val: T[]): boolean {
  return isNullOrUndefined(val) || val.length === 0
}

export function firstOrDefault<T>(items: T[], predicate: (t: T) => boolean, defaultValue?: T): T {
  if (isNotNullOrUndefined(items)) {
    for (const i in items) {
      if (predicate(items[i])) {
        return items[i]
      }
    }
  }
  return defaultValue
}

export function findInList<T>(items: T[], predicate: (t: T) => boolean): T[] {
  if (isNotNullOrUndefined(items)) {
    const result: T[] = []
    for (const i in items) {
      if (predicate(items[i])) {
        result.push(items[i])
      }
    }
    return result
  }
}
// endregion list

/**
 * Indicates whether a value is of a given type.
 */
export function isType(val: any, type: string): boolean {
  return typeof val === type
}

const dash = '-'
const punctuationChars: string[] = ['.', ',', ':', ';', '?', '!', "'", '`', '\\']
const dashChars: string[] = ['-', ' ', '(', ')', '+', '/', '&', '*']
/**
 * Converts a string into a url slug.
 * @return The url slug.
 */
export function slugify(s: string): string {
  if (isNullOrEmpty(s)) {
    return s
  }

  let prev = '\0'
  let chars: string[] = []
  for (let i = 0; i < s.length; i++) {
    const c = s.charAt(i)

    // remove (exclude) punctuation
    if (punctuationChars.indexOf(c) > -1) {
      continue
    }

    if (dashChars.indexOf(c) > -1) {
      if (prev === dash) {
        continue
      }
      // replace some chars with '-'
      chars.push(dash)
      prev = dash
    } else {
      // replace anything else with itself (lowercase)
      chars.push(c.toLowerCase())
      prev = c
    }
  }

  // remove trailing dash...
  if (chars.length > 0) {
    const lastIx = chars.length - 1
    if (chars[lastIx] === dash) {
      chars = chars.splice(lastIx, 1)
    }
  }

  // ...but if there's nothing, use just a dash
  if (chars.length === 0) {
    chars.push(dash)
  }

  return chars.join('')
}

// /**
//  * Indicates whether a value is of a given type.
//  */
// export function isT<T>(val: any): val is T {
//   if (val instanceof T) {
//     return val as T;
//   }
// }

// /**
//  * Casts a value to of a given type, but only if the value is an instance of that type.
//  * Note: `type` must match the type name for `T`.
//  */
// export function asType<T>(val: any): val is T {
//   if (val instanceof T) {
//     return val as T;
//   }
// }

/**
 * Adds all items from one list to another list.
 * @param items The base list.
 * @param toAdd The list to add.
 */
export function addAllToList<T>(items: T[], toAdd: T[]): T[] {
  return Array.prototype.push.apply(items, toAdd)
}

/**
 * Adds all items from one object to another map.
 * @param items The base map.
 * @param toAdd The object to add.
 */
export function addAllToMap<TValue>(
  items: Map<string, TValue>,
  toAdd: any,
  overwriteIfSet = true,
): Map<string, TValue> {
  if (isNotNullOrUndefined(items) && isNotNullOrUndefined(toAdd)) {
    if (toAdd instanceof Map) {
      toAdd.forEach((value, key) => {
        // handle Map<TKey, TValue>
        if (!items.has(key) || overwriteIfSet) {
          items.set(key, value)
        }
      })
    } else {
      for (const key in toAdd) {
        if (!items.has(key) || overwriteIfSet) {
          items.set(key, toAdd[key])
        }
      }
    }
  }
  return items
}
