/**
 * Represents a subset of api unit types dealing with speed.
 * These values map to api unit type IDs.
 * @see <a href="https://developer.accuweather.com/unit-types">here</a> for more information.
 */
export enum SpeedUnits {
  /**
   * Kilometers-per-hour
   */
  KilometersPerHour = 7,

  /**
   * Knots
   */
  Knots = 8,

  /**
   * Miles-per-hour
   */
  MilesPerHour = 9,

  /**
   * Meters-per-second
   */
  MetersPerSecond = 10,
}

/**
 * Gets whether the [SpeedUnits] is metric.
 */
export function isMetricSpeed(units: SpeedUnits): boolean {
  switch (units) {
    case SpeedUnits.KilometersPerHour:
    case SpeedUnits.MetersPerSecond:
      return true
    default:
      return false
  }
}
