/**
 * Encapsulates the map types.
 */
export enum MapType {
  /**
   * Radar
   */
  Radar,

  /**
   * Satellite
   */
  Satellite,

  /**
   * Standard Satellite
   */
  StandardSatellite,

  /**
   * Future Radar
   */
  FutureRadar,

  /**
   * Five day precipitation
   */
  Precipitation,

  /**
   * 24-hour rainfall forecast
   */
  TwentyFourHourRainfallForecast,

  /**
   * 24-hour ice forecast
   */
  TwentyFourHourIceForecast,

  /**
   * 24-hour snowfall
   */
  TwentyFourHourSnowfallForecast,

  /**
   * Past 24-hour snowfall accumulation
   */
  PastTwentyFourHourSnowfall,

  /**
   * Temperature Contour
   */
  TemperatureContour,

  /**
   * Visible Satellite
   */
  VisibleSatellite,

  /**
   * Water Vapor
   */
  WaterVapor,

  /**
   * Watches and Warnings
   */
  WatchesAndWarnings,

  /**
   * Tropical
   */
  Tropical,

  /**
   * Air quality current
   */
  AirQualityCurrent,

  /**
   * Global Color Satellite
   */
  GlobalColorSatellite,

  /**
   * Enhanced Global Color Satellite
   */
  EnhancedGlobalColorSatellite,

  /**
   * World Wind
   */
  WorldWind,
}
