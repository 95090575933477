// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { ApiTokens } from '../core/api/ApiTokens'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { LanguageValidRule } from '../core/validation/LanguageValidRule'
import { LocationKeyValidRule } from '../core/validation/LocationKeyValidRule'
import { IntegerGreaterThanRule } from '../core/validation/NumberGreaterThanRule'
import { ObjectNotNullOrUndefinedRule } from '../core/validation/ObjectNotNullOrUndefinedRule'
import { StringNotNullOrWhiteSpaceRule } from '../core/validation/StringNotNullOrWhiteSpaceRule'
import { dateToIso8601String } from '../util/Helpers'
import { AlmanacRouteResolver } from './AlmanacRouteResolver'
import { BodyPathsByLocationKeyRequest } from './requests/BodyPathsByLocationKeyRequest'
import { MoonsByDateRequest } from './requests/MoonsByDateRequest'
import { MoonsByPhaseRequest } from './requests/MoonsByPhaseRequest'
import { TideForecastsByLocationKeyRequest } from './requests/TideForecastsByLocationKeyRequest'
import { TideStationInfoRequest } from './requests/TideStationInfoRequest'

/**
 * Resolves routes for [AlmanacService] methods.
 */
export class AlmanacRouteResolverImpl implements AlmanacRouteResolver {
  protected readonly requestName = 'request'
  protected readonly sdkSettings: SdkSettings
  protected readonly routeResolver: ApiRouteResolver
  // endregion routes

  // region validators
  protected readonly dayCount = 'day count'
  private readonly stationIdToken = 'stationId'
  private readonly allowErrorToken = 'allowError'
  private readonly phaseCountToken = 'phaseCount'

  // region routes
  private readonly bodyPathsByLocationKeyRequest = 'BodyPathsByLocationKeyRequest'
  private readonly bodyPathsByLocationKey = 'BodyPathsByLocationKey'
  private readonly moonsByDate = 'MoonsByDate'
  private readonly moonsByPhase = 'MoonsByPhase'

  private readonly tideForecastsByLocationKey = 'TideForecastsByLocationKey'
  private readonly tideStationInfo = 'TideStationInfo'

  private readonly routeTemplates: Map<string, string> = new Map([
    [
      this.bodyPathsByLocationKey,
      'astronomy/v1/{locationKey}.json?apikey={apikey}&start={startDate}&days={dayCount}',
    ],
    [this.moonsByDate, 'astronomy/v1/moons.json?apikey={apikey}&start={startDate}&days={dayCount}'],
    [
      this.moonsByPhase,
      'astronomy/v1/moons.json?apikey={apikey}&start={startDate}&phases={phaseCount}',
    ],
    [
      this.tideForecastsByLocationKey,
      'tidal/v1/forecasts/{dayCount}day/{locationKey}.json?apikey={apikey}&language={language}',
    ],
    [
      this.tideStationInfo,
      'tidal/v1/stations/{stationId}.json?apikey={apikey}&language={language}&allowError={allowError}',
    ],
  ])

  private readonly tideStationInfoRequestValidator: AsyncValidator<TideStationInfoRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, this.requestName),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => StringNotNullOrWhiteSpaceRule.checkRule(r.stationId, 'station ID'),
    ])

  private readonly tideForecastsByLocationKeyRequestValidator: AsyncValidator<TideForecastsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, this.requestName),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => this.checkValidTideForecastsDayCount(r),
    ])

  private readonly moonsByPhaseRequestValidator: AsyncValidator<MoonsByPhaseRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, this.requestName),
      (r) => IntegerGreaterThanRule.checkRule(r.phaseCount, 0, 'phase count'),
    ])

  private readonly moonsByDateRequestValidator: AsyncValidator<MoonsByDateRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, this.requestName),
      (r) => IntegerGreaterThanRule.checkRule(r.dayCount, 0, this.dayCount),
    ])

  private readonly bodyPathsByLocationKeyRequestValidator: AsyncValidator<BodyPathsByLocationKeyRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, this.requestName),
      (r) => LanguageValidRule.checkRule(r.language),
      (r) => LocationKeyValidRule.checkRule(r.locationKey),
      (r) => IntegerGreaterThanRule.checkRule(r.dayCount, 0, this.dayCount),
    ])
  // endregion validators

  constructor(sdkSettings: SdkSettings) {
    this.sdkSettings = sdkSettings
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, this.sdkSettings)
  }

  public checkValidTideForecastsDayCount(request: TideForecastsByLocationKeyRequest): Error {
    if (
      request.dayCount === 45 ||
      request.dayCount === 25 ||
      request.dayCount === 15 ||
      request.dayCount === 10 ||
      request.dayCount === 5 ||
      request.dayCount === 1
    ) {
      return
    }
    return new Error('invalid day count. valid values are 1, 5, 10, 15, 25, and 45.')
  }

  // region astronomy
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getMoonPhasesByCountUrl(
    request: MoonsByPhaseRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.moonsByPhase,
      request,
      this.moonsByPhaseRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.startDate, dateToIso8601String(request.startDate)],
        [this.phaseCountToken, request.phaseCount],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getMoonPhasesByDateUrl(
    request: MoonsByDateRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.moonsByDate,
      request,
      this.moonsByDateRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.startDate, dateToIso8601String(request.startDate)],
        [ApiTokens.dayCount, request.dayCount],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getBodyPathsByLocationKeyUrl(
    request: BodyPathsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.bodyPathsByLocationKey,
      request,
      this.bodyPathsByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request.locationKey],
        [ApiTokens.startDate, dateToIso8601String(request.startDate)],
        [ApiTokens.dayCount, request.dayCount],
      ]),
    )
  }
  // endregion astronomy

  // region tides
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getTideStationInfoUrl(
    request: TideStationInfoRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.tideStationInfo,
      request,
      this.tideStationInfoRequestValidator,
      requestChain,
      new Map<string, any>([
        [this.stationIdToken, request.stationId],
        [ApiTokens.language, request.language],
        [this.allowErrorToken, request.allowError],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getTideForecastsByLocationKeyUrl(
    request: TideForecastsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.tideForecastsByLocationKey,
      request,
      this.tideForecastsByLocationKeyRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.locationKey, request.locationKey],
        [ApiTokens.language, request.language],
        [ApiTokens.dayCount, request.dayCount],
      ]),
    )
  }
  // endregion tides
}
