// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { ApiTokens } from '../core/api/ApiTokens'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { ActiveStormsRequest } from './requests/ActiveStormsRequest'
import { StormByIdRequest } from './requests/StormByIdRequest'
import { StormPositionRequest } from './requests/StormPositionRequest'
import { StormsByBasinRequest } from './requests/StormsByBasinRequest'
import { StormsByYearRequest } from './requests/StormsByYearRequest'
import { TropicalRouteResolver } from './TropicalRouteResolver'

/**
 * Used to resolve routes for the `TropicalService`
 */
export class TropicalRouteResolverImpl implements TropicalRouteResolver {
  protected readonly basinIdToken = 'basinId'
  protected readonly stormIdToken = 'stormId'
  protected readonly stormSourceToken = 'source'
  protected readonly includeLandmarks = 'includeLandmarks'
  protected readonly radiiGeometry = 'radiiGeometry'
  protected readonly windowGeometryToken = 'windowGeometry'
  // endregion routes

  // region validators
  protected readonly activeStormsRequestValidator: AsyncValidator<ActiveStormsRequest> =
    new AsyncValidator([])
  protected readonly stormsByYearRequestValidator: AsyncValidator<StormsByYearRequest> =
    new AsyncValidator([])
  protected readonly stormsByBasinRequestValidator: AsyncValidator<StormsByBasinRequest> =
    new AsyncValidator([])
  protected readonly stormByIdRequestValidator: AsyncValidator<StormByIdRequest> =
    new AsyncValidator([])
  // how else can/should we validate the year/stormId of the requests?
  // endregion validators

  protected readonly routeResolver: ApiRouteResolver
  // region routes
  private readonly activeStorms = 'ActiveStorms'
  private readonly stormsByYear = 'StormsByYear'
  private readonly stormsByBasin = 'StormsByBasin'
  private readonly stormById = 'StormById'
  private readonly stormForecasts = 'StormForecasts'
  private readonly stormPositions = 'StormPositions'
  private readonly currentStormPosition = 'CurrentStormPosition'

  private readonly routeTemplates: Map<string, string> = new Map([
    [this.activeStorms, 'tropical/v1/gov/storms/active?apikey={apikey}&language={language}'],
    [
      this.stormsByYear,
      'tropical/v1/gov/storms/{year}?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.stormsByBasin,
      'tropical/v1/gov/storms/{year}/{basinId}?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.stormById,
      'tropical/v1/gov/storms/{year}/{basinId}/{stormId}?apikey={apikey}&language={language}&details={details}',
    ],
    [
      this.stormForecasts,
      'tropical/v1/gov/storms/{year}/{basinId}/{stormId}/forecasts?apikey={apikey}&language={language}&details={details}&radiigeometry={radiiGeometry}&windowgeometry={windowGeometry}',
    ],
    [
      this.stormPositions,
      'tropical/v1/gov/storms/{year}/{basinId}/{stormId}/positions?apikey={apikey}&language={language}&details={details}&radiigeometry={radiiGeometry}&includelandmarks={includeLandmarks}',
    ],
    [
      this.currentStormPosition,
      'tropical/v1/gov/storms/{year}/{basinId}/{stormId}/positions/current?apikey={apikey}&language={language}&details={details}&radiigeometry={radiiGeometry}&includelandmarks={includeLandmarks}',
    ],
  ])

  constructor(sdkSettings: SdkSettings) {
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, sdkSettings)
  }

  // region storms
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getActiveStormsUrl(
    request: ActiveStormsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return this.routeResolver.resolveAsync(
      this.activeStorms,
      request,
      this.activeStormsRequestValidator,
      requestChain,
      new Map<string, any>([[ApiTokens.language, request.language]]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getStormsByYearUrl(
    request: StormsByYearRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return this.routeResolver.resolveAsync(
      this.stormsByYear,
      request,
      this.stormsByYearRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request.language],
        [ApiTokens.year, request.year],
        [ApiTokens.details, request.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getStormsByBasinUrl(
    request: StormsByBasinRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return this.routeResolver.resolveAsync(
      this.stormsByBasin,
      request,
      this.stormsByBasinRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request.language],
        [ApiTokens.year, request.year],
        [this.basinIdToken, request.basinId],
        [ApiTokens.details, request.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getStormByIdUrl(
    request: StormByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return this.routeResolver.resolveAsync(
      this.stormById,
      request,
      this.stormByIdRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request.language],
        [ApiTokens.year, request.year],
        [this.basinIdToken, request.basinId],
        [this.stormIdToken, request.stormId],
        [ApiTokens.details, request.details],
      ]),
    )
  }
  // endregion storms

  // region storm forecasts
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getStormForecastsUrl(
    request: StormByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return this.routeResolver.resolveAsync(
      this.stormForecasts,
      request,
      this.stormByIdRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request.language],
        [ApiTokens.year, request.year],
        [this.basinIdToken, request.basinId],
        [this.stormIdToken, request.stormId],
        [ApiTokens.details, request.details],
        [this.radiiGeometry, request.radiiGeometry],
        [this.windowGeometryToken, request.windowGeometry],
      ]),
    )
  }
  // endregion storm forecasts

  // region storm positions
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getStormPositionsUrl(
    request: StormPositionRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return this.routeResolver.resolveAsync(
      this.stormPositions,
      request,
      this.stormByIdRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request.language],
        [ApiTokens.year, request.year],
        [this.basinIdToken, request.basinId],
        [this.stormIdToken, request.stormId],
        [this.radiiGeometry, request.includeRadiiGeometry],
        [this.includeLandmarks, request.includeLandmarks],
        [ApiTokens.details, request.details],
      ]),
    )
  }

  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getCurrentStormPositionUrl(
    request: StormPositionRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return this.routeResolver.resolveAsync(
      this.currentStormPosition,
      request,
      this.stormByIdRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request.language],
        [ApiTokens.year, request.year],
        [this.basinIdToken, request.basinId],
        [this.stormIdToken, request.stormId],
        [this.radiiGeometry, request.includeRadiiGeometry],
        [this.includeLandmarks, request.includeLandmarks],
        [ApiTokens.details, request.details],
      ]),
    )
  }
  // endregion storm positions
}
