import { Module, ActionContext } from 'vuex'
import { SdkState, RootState } from '../types'
import { getHomeData } from '@/services/home.service'
import { RouteLocationNormalized } from 'vue-router'
import { DateTime } from '@/utils/extensions/date-extension'
import AWLocation from '@/models/Location'

const home: Module<SdkState, RootState> = {
  state: {
    minutecast: undefined,
    currentConditions: undefined,
    weatherEvents: undefined,
    dailyForecast: undefined,
    hourlyForescast: undefined,
    alerts: undefined,
    activeStorm: null,
    isWinter: false,
    location: undefined,
    locationIndex: 0,
  },
  mutations: {
    setSdkData(state, data) {
      Object.assign(state, data)
    },
  },
  actions: {
    async fetchSdkData(
      { commit }: ActionContext<SdkState, RootState>,
      p: RouteLocationNormalized | undefined,
    ) {
      try {
        const date = DateTime()
        const hasLongPeriodUpdate = date.getMinutes() > 56 || date.getMinutes() < 4
        const storedLocation = localStorage.getItem('location') ?? ''
        const hasMultipleLocations = ((p?.query.key as string | undefined) ?? '').includes('-')
        const [
          minutecast,
          currentConditions,
          weatherEvents,
          dailyForecast,
          hourlyForescast,
          alerts,
          activeStorm,
          isWinter,
          location,
        ] = await getHomeData(
          p?.params,
          p?.query,
          storedLocation && (JSON.parse(storedLocation) as AWLocation).Key === p?.query.key
            ? (JSON.parse(storedLocation) as AWLocation)
            : undefined,
          {
            hourly: hasLongPeriodUpdate || !this.state.home.hourlyForescast || hasMultipleLocations,
            daily: hasLongPeriodUpdate || !this.state.home.dailyForecast || hasMultipleLocations,
          },
          this.state.home.locationIndex,
        )
        const indices = ((p?.query.key as string | undefined) ?? '')?.split('-')
        const locationIndex =
          indices.length > 1 && this.state.home.locationIndex < indices.length - 1
            ? this.state.home.locationIndex + 1
            : 0
        const data = {
          minutecast,
          currentConditions,
          weatherEvents,
          dailyForecast,
          hourlyForescast,
          alerts,
          activeStorm,
          isWinter,
          location,
          locationIndex,
        }

        commit('setSdkData', data)
        return data
      } catch (error) {
        console.error('Error getting sdk data:', error)
        return Promise.reject(error)
      }
    },
    clearSdkData({ commit }: ActionContext<SdkState, RootState>) {
      const data = {
        minutecast: undefined,
        currentConditions: undefined,
        weatherEvents: undefined,
        dailyForecast: undefined,
        hourlyForescast: undefined,
        alerts: undefined,
        activeStorm: null,
        isWinter: false,
        location: undefined,
        locationIndex: 0,
      }
      console.log('clear data')
      commit('setSdkData', data)
      return data
    },
  },
}

export default home
