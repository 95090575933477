declare global {
  interface Date {
    // eslint-disable-next-line no-unused-vars
    getDayOfWeek(isShort?: boolean, offset?: number): string | undefined
    // eslint-disable-next-line no-unused-vars
    toTimeString(hasMinutes?: boolean, hasAmPm?: boolean, gmtOffset?: number): string
    fullDate(gmtOffset?: number, hasMinutes?: boolean, hasAmPm?: boolean): string
    // eslint-disable-next-line no-unused-vars
    applyUtcOffset(gmtOffset: number): Date
    milisecondsUntilNextMinute(): number
  }
}

Date.prototype.applyUtcOffset = function (gmtOffset: number): Date {
  const currentUTC = DateTime()
  const offsetDiff = gmtOffset * 60 + currentUTC.getTimezoneOffset()
  const time = this != null ? this.getTime() : currentUTC.getTime()
  this.setTime(time + offsetDiff * 60 * 1000)
  return this
}

Date.prototype.getDayOfWeek = function (isShort = true, offset: number): string | undefined {
  const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
  const shortNames = daysOfWeek.map((day) => day.slice(0, 3))
  const newDate = offset ? this.applyUtcOffset(offset) : this
  const dayIndex = newDate.getDay() ?? 0
  return isShort ? shortNames[dayIndex] : daysOfWeek[dayIndex]
}

Date.prototype.toTimeString = function (
  hasMinutes = true,
  is12Hours: boolean | undefined = false,
  gmtOffset?: number | undefined,
): string {
  const formatOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    hour12: is12Hours,
  }

  if (gmtOffset !== undefined) {
    this.applyUtcOffset(gmtOffset)
  }

  if (hasMinutes) {
    formatOptions.minute = 'numeric'
  }

  const format = new Intl.DateTimeFormat('es-ES', formatOptions)
  const formattedTime = format.format(this)

  if (is12Hours) {
    const amPm = this.getHours() < 12 ? 'AM' : 'PM'
    let time = formattedTime.split(' ')[0]
    time = time === '0' ? '12' : time
    if (time?.startsWith('0')) time = time?.replace('0:', '12:')
    return `${time} ${amPm}`
  }

  return formattedTime
}

Date.prototype.milisecondsUntilNextMinute = function (): number {
  return (60 - this.getSeconds()) * 1000
}

Date.prototype.fullDate = function (
  gmtOffset?: number | undefined,
  hasMinutes = true,
  is12Hours: boolean | undefined = true,
): string {
  const dateFull: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: hasMinutes ? 'numeric' : undefined,
    hour12: is12Hours,
  }

  if (gmtOffset !== undefined) {
    this.applyUtcOffset(gmtOffset)
  }

  if (hasMinutes) {
    dateFull.minute = 'numeric'
  }
  const format = new Intl.DateTimeFormat('en-US', dateFull)
  return format.format(this).replace('at', '')
}

export const DateTime = (dateTimeString?: string) =>
  dateTimeString ? new Date(dateTimeString) : new Date()
