import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'default',
    component: () => import('../views/home/HomeView.vue'),
    async beforeEnter(to, from, next) {
      store.commit('setLoading', true)
      next()
      store.commit('setLoading', false)
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/HomeView.vue'),
    async beforeEnter(to, from, next) {
      store.commit('setLoading', true)
      next()
      store.commit('setLoading', false)
    },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/SearchView.vue'),
    async beforeEnter(to, from, next) {
      store.commit('setLoading', true)
      next()
      store.commit('setLoading', false)
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('../views/privacy-policy/PrivacyPolicy.vue'),
    async beforeEnter(to, from, next) {
      store.commit('setLoading', true)
      next()
      store.commit('setLoading', false)
    },
  },
  {
    path: '/terms-of-use',
    name: 'termsOfUse',
    component: () => import('../views/terms-of-use/TermsOfUse.vue'),
    async beforeEnter(to, from, next) {
      store.commit('setLoading', true)
      next()
      store.commit('setLoading', false)
    },
  },
  {
    path: '/location',
    name: 'location',
    component: () => import('../views/location/LocationView.vue'),
    async beforeEnter(to, from, next) {
      from.meta.shouldKeepAlive = false
      store.commit('setLoading', true)
      try {
        await store.dispatch('fetchSdkData', to)
        next()
      } catch (error) {
        console.error('Error getting data', error)
        next()
      } finally {
        store.commit('setLoading', false)
      }
    },
  },
  {
    path: '/screensaver/:locationKey',
    name: 'screensaver',
    component: () => import('../views/screensaver/ScreensaverView.vue'),
    async beforeEnter(to, from, next) {
      from.meta.shouldKeepAlive = false
      store.commit('setLoading', true)
      try {
        await store.dispatch('fetchScreensaverData', to)
        next()
      } catch (error) {
        console.error('Error getting data', error)
        next()
      } finally {
        store.commit('setLoading', false)
      }
    },
  },
]

const router = createRouter({ history: createWebHashHistory(), routes })

export default router
