declare global {
  interface Number {
    toDirectionString(): string
  }
}

Number.prototype.toDirectionString = function (): string {
  if (Number(this) >= 0 && Number(this) < 360) {
    const directions = ['W', 'NW', 'N', 'NE', 'E', 'SE', 'S', 'SW']
    const index = Math.round((Number(this) % 360) / 45)
    return directions[index] ?? 'Invalid'
  }
  return 'Invalid'
}

export function random(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
