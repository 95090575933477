import { LocationCacheInfoRequest } from '../../core/http/LocationCacheInfoRequest'

/**
 * Encapsulates parameters used to make requests to WeatherService.
 */
export class HourlyForecastsByLocationKeyRequest implements LocationCacheInfoRequest {
  /**
   * Gets or sets the language (i.e. en-us) for which the response should be returned in.
   */
  public readonly language: string

  /**
   * Gets or sets the location key for the request. Location keys can be obtained from the location service.
   */
  public readonly locationKey: string

  /**
   * Gets or sets if the weather values should be metric or imperial.
   */
  public isMetric = false

  /**
   * Gets or sets whether the response should include the full details.
   */
  public details = false

  /**
   * Gets or sets the number of hours to include in the response.
   * Valid values are: 1, 12, 24 (1-day), 72 (3-day), 120 (5-day), and 240 (10-day).
   * Default is 12.
   */
  public hourCount = 12

  /**
   * @param language the language for which the response should be returned.
   * @param locationKey the location key for the request.
   */
  constructor(language: string, locationKey: string) {
    this.language = language
    this.locationKey = locationKey
  }
}
