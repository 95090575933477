// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiHttpService } from '../core/api/ApiHttpService'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { AlmanacRouteResolver } from './AlmanacRouteResolver'
import { AlmanacService } from './AlmanacService'
import { BodyPathsByLocationKeyRequest } from './requests/BodyPathsByLocationKeyRequest'
import { MoonsByDateRequest } from './requests/MoonsByDateRequest'
import { MoonsByPhaseRequest } from './requests/MoonsByPhaseRequest'
import { TideForecastsByLocationKeyRequest } from './requests/TideForecastsByLocationKeyRequest'
import { TideStationInfoRequest } from './requests/TideStationInfoRequest'

/**
 * Provides methods to get astronomy (moon and sun) information and tidal information.
 * See <a href="http://apidev.accuweather.com/developers/astronomy">here</a> for more information on astronomy.
 * See <a href="http://apidev.accuweather.com/developers/TidalForecastAPIGuide">here</a> for more information on tidal forecasts.
 */
export class AlmanacServiceImpl implements AlmanacService {
  protected readonly serviceName = 'AlmanacService'
  private readonly httpService: ApiHttpService
  private readonly routeResolver: AlmanacRouteResolver

  constructor(httpService: ApiHttpService, routeResolver: AlmanacRouteResolver) {
    this.httpService = httpService
    this.routeResolver = routeResolver
  }

  // region astronomy
  /**
   * Gets a list of major moon phases.
   * @param request The request.
   * @return A service response containing either a list of [MoonPhase]s or error information.
   */
  public async getMoonPhasesByCount(
    request: MoonsByPhaseRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getMoonPhasesByCountUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getMoonPhasesByCount'),
    )
  }

  /**
   * Gets a list of major moon phases.
   * @param request The request.
   * @return A service response containing either a list of [MoonPhase]s or error information.
   */
  public async getMoonPhasesByDate(
    request: MoonsByDateRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getMoonPhasesByDateUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getMoonPhasesByDate'),
    )
  }

  /**
   * Gets a list of body paths info.
   * @param request The request.
   * @return A service response containing either a list of [BodyPaths]s or error information.
   */
  public async getBodyPathsByLocationKey(
    request: BodyPathsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getBodyPathsByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getBodyPathsByLocationKey'),
    )
  }
  // endregion astronomy

  // region tides
  /**
   * Gets information about individual Tidal Stations, including time zone, country, administrative area, and geoposition.
   * @param request The request.
   * @return A service response containing either a TideStationInfo or error information.
   */
  public async getTideStationInfo(
    request: TideStationInfoRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    // api returns a list?
    const response: ServiceResponse<any[]> = await this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getTideStationInfoUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getTideStationInfo'),
    )

    // ...but we just want the first (only) one
    // tide data is not guaranteed to be available, so pass `true` to allow empty
    return response.takeFirst(requestChain, null, true)
  }

  /**
   * Returns a list of daily tidal forecasts for every tidal station that falls within the area defined by the given locationKey.
   * Available for 1, 5, 10, 15, 25, or 45 days.
   * @param request The request.
   * @return A service response containing either a list of [TideForecast]s or error information.
   */
  public async getTideForecastsByLocationKey(
    request: TideForecastsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getTideForecastsByLocationKeyUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getTideForecastsByLocationKey'),
    )
  }
  // endregion tides
}
