/**
 * Encapsulates parameters used to make requests to [ContentService].
 */
export class PartnerByIdRequest {
  /**
   * Gets or sets the language (i.e. en-us) for which the response should be returned in.
   */
  public language: string

  /**
   * Gets or sets the ID of the partner.
   */
  public id: string

  /**
   * Initializes a new [PartnerByIdRequest].
   * @param language The language (i.e. en-us) for which the response should be returned in.
   * @param id The ID of the partner.
   */
  constructor(language: string, id: string) {
    this.language = language
    this.id = id
  }
}
