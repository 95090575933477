// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiRouteResolver } from '../core/api/ApiRouteResolver'
import { ApiTokens } from '../core/api/ApiTokens'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { AsyncValidator } from '../core/validation/AsyncValidator'
import { ObjectNotNullOrUndefinedRule } from '../core/validation/ObjectNotNullOrUndefinedRule'
import { isNotNullOrUndefined } from '../util/Helpers'
import { AttributionSourceType } from './AttributionSourceType'
import { DataAttributionRouteResolver } from './DataAttributionRouteResolver'
import { AttributionSourcesRequest } from './requests/AttributionSourcesRequest'

/**
 * Resolves routes for [DataAttributionService] methods.
 */
export class DataAttributionRouteResolverImpl implements DataAttributionRouteResolver {
  private readonly sdkSettings: SdkSettings
  private readonly routeResolver: ApiRouteResolver

  // region routes
  private readonly attributionSources = 'AttributionSources'

  private readonly routeTemplates: Map<string, string> = new Map([
    [
      this.attributionSources,
      'content/v1/datasources?apikey={apikey}&language={language}&datatype={type}',
    ],
  ])
  // endregion routes

  // region validators
  private readonly attributionSourcesRequestValidator: AsyncValidator<AttributionSourcesRequest> =
    new AsyncValidator([
      (r) => ObjectNotNullOrUndefinedRule.checkRule(r, 'request'),
      (r) => this.checkSourceType(r.attributionSourceType),
    ])
  // endregion validators

  constructor(sdkSettings: SdkSettings) {
    this.sdkSettings = sdkSettings
    this.routeResolver = new ApiRouteResolver(this.routeTemplates, this.sdkSettings)
  }

  // region sources
  /**
   * Gets the appropriate url for the method/request.
   * @param request The request.
   * @param requestChain: The request chain.
   * @return A service response containing either a string (the url) or error information.
   */
  public async getAttributionSourcesUrl(
    request: AttributionSourcesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<string>> {
    return await this.routeResolver.resolveAsync(
      this.attributionSources,
      request,
      this.attributionSourcesRequestValidator,
      requestChain,
      new Map<string, any>([
        [ApiTokens.language, request?.language],
        [ApiTokens.type, request?.attributionSourceType],
      ]),
    )
  }

  private checkSourceType(type: AttributionSourceType): Error {
    if (isNotNullOrUndefined(type) && type === AttributionSourceType.Unknown) {
      return new Error('invalid attribution source type.')
    }
  }
  // endregion sources
}
