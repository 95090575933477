// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Logger } from '../Logger'
import { CacheItem } from './CacheItem'
import { CacheOptions } from './CacheOptions'
import { PersistentCache } from './PersistentCache'

export class PersistentCacheImpl implements PersistentCache {
  private cache = new Map<any, CacheItem>()
  private readonly logger = Logger.getInstance()

  /**
   * Checks if the key exists in the cache.
   * @param key the key.
   * @return true if the key exists in the cache, false otherwise.
   */
  public containsKey<TKey>(key: TKey): boolean {
    if (this.cache.has(key)) {
      const item = this.cache.get(key)
      if (!item.isExpired) {
        return true
      }
    }
    return false
  }

  /**
   * Attempts to retrieve a value from cache.
   * @param key the key.
   * @return the value, if it exists in cache, otherwise, null.
   */
  public get<TKey, TValue>(key: TKey): TValue {
    if (this.cache.has(key)) {
      const item = this.cache.get(key)
      if (!item.isExpired) {
        item.request() // 'bump' if sliding expiry
        return item.value as TValue
      }
      // it's expired; remove it
      this.cache.delete(key)
    }
    return undefined
  }

  /**
   * Adds a value to cache with the given key.
   * @param key the key.
   * @param value the value.
   * @param cacheOptions the cache options, including time-to-live.
   * @return true, if the operation completed successfully; otherwise, false.
   */
  public set<TKey, TValue>(key: TKey, value: TValue, cacheOptions: CacheOptions): boolean {
    if (cacheOptions.ttl !== 0) {
      try {
        this.cache.set(key, new CacheItem(value, cacheOptions))
        return true
      } catch (error) {
        this.logger.error(error)
      }
    }
    return false
  }
}
