// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNullOrUndefined } from '../../util/Helpers'
import { GetServiceResponse } from '../GetServiceResponse'
import { RequestChain } from '../http/RequestChain'
import { ServiceResponse } from '../ServiceResponse'
import { CacheOptions } from './CacheOptions'
import { PersistentCache } from './PersistentCache'
import { ServiceResponseCache } from './ServiceResponseCache'

/**
 * Provides a way to cache a single item after transforming it from one type to another.
 *
 * @param TServiceData The type of original data.
 * @param TTransform The type to transform the data to.
 */
export class ServiceResponseTransformCache<TServiceData, TTransform> {
  private readonly transform: (data: TServiceData) => TTransform
  private readonly cache: ServiceResponseCache<TTransform>

  constructor(
    transform: (data: TServiceData) => TTransform,
    cache: PersistentCache,
    options: CacheOptions = new CacheOptions(),
  ) {
    this.transform = transform
    this.cache = new ServiceResponseCache<TTransform>(cache, options)
  }

  /**
   * Adds an item to the cache if it doesn't already exist.
   *
   * @param TRequest The type of request used to fetch the original data.
   * @param key The cache key.
   * @param getData The method used to fetch the original item.
   * @param request The request used to fetch the original data.
   * @param requestChain The request chain.
   * @return A ServiceResponse with the transformed data.
   */
  public async getOrAdd<TRequest>(
    key: any,
    getData: GetServiceResponse<TServiceData, TRequest>,
    request: TRequest,
    requestChain: RequestChain,
  ): Promise<ServiceResponse<TTransform>> {
    const transformDataGetter = async (
      r: TRequest,
      rc: RequestChain,
    ): Promise<ServiceResponse<TTransform>> => {
      const response = await getData(r, rc)
      return this.getTransformData(response)
    }
    return await this.cache.getOrAdd(key, transformDataGetter, request, requestChain)
  }

  /**
   * Adds an item to the cache if it doesn't already exist.
   *
   * @param TRequest The type of request used to fetch the original data.
   * @param key The cache key.
   * @param getData The method used to fetch the original item.
   * @param request The request used to fetch the original data.
   * @param requestChain The request chain.
   * @return A ServiceResponse with the transformed data.
   */
  public async getOrAddWithOriginal<TRequest>(
    key: any,
    getData: GetServiceResponse<TServiceData, TRequest>,
    request: TRequest,
    requestChain: RequestChain,
  ): Promise<ServiceResponse<[TTransform, TServiceData]>> {
    let originalData: TServiceData

    const originalDataGetter = async (
      r: TRequest,
      rc: RequestChain,
    ): Promise<ServiceResponse<TTransform>> => {
      const response = await getData(r, rc)
      originalData = response.data
      return this.getTransformData(response)
    }

    const getOrAdd = await this.cache.getOrAdd(key, originalDataGetter, request, requestChain)
    if (getOrAdd.hasError) {
      return getOrAdd.transformError<[TTransform, TServiceData]>()
    }
    return ServiceResponse.create(
      [getOrAdd.data, originalData],
      getOrAdd.URL,
      getOrAdd.rawData,
      getOrAdd.template,
    )
  }

  /**
   * Adds the response data to the cache, overwriting any existing value if necessary.
   *
   * @param key The cache key.
   * @param serviceResponse The response with the data to be transformed and cached.
   * @return A ServiceResponse with the transformed data.
   */
  public add(
    key: any,
    serviceResponse: ServiceResponse<TServiceData>,
  ): ServiceResponse<TTransform> {
    return this.cache.add(key, this.getTransformData(serviceResponse))
  }

  private getTransformData(
    serviceResponse: ServiceResponse<TServiceData>,
  ): ServiceResponse<TTransform> {
    if (serviceResponse.hasError) {
      const error =
        isNullOrUndefined(serviceResponse.error) || isNullOrUndefined(serviceResponse.error.error)
          ? Error('no serviceResponse')
          : serviceResponse.error.error
      return ServiceResponse.fromError<TTransform>(error, serviceResponse.URL)
    }
    if (isNullOrUndefined(serviceResponse.data)) {
      return ServiceResponse.fromError<TTransform>(
        Error('value not found in response for cache'),
        serviceResponse.URL,
      )
    }
    return ServiceResponse.create(
      this.transform(serviceResponse.data),
      serviceResponse.URL,
      serviceResponse.rawData,
      serviceResponse.template,
    )
  }
}
