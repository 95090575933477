import { MinuteForecastByLocationKeyRequest } from './MinuteForecastByLocationKeyRequest'
import { MinuteForecastIntervalType } from './MinuteForecastIntervalType'

/**
 * Encapsulates parameters used to make requests to WeatherService.
 */
export class MinuteForecastPremiumByLocationKeyRequest extends MinuteForecastByLocationKeyRequest {
  /**
   * Gets or sets the interval to return minutecast data for.
   */
  public intervalType: MinuteForecastIntervalType = MinuteForecastIntervalType.FifteenMinute

  /**
   * Gets or sets whether the response should include the full details.
   */
  public details = false
}
