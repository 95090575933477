// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiHttpService } from '../core/api/ApiHttpService'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { MapRouteResolver } from './MapRouteResolver'
import { MapService } from './MapService'
import { AccuCastRequest } from './requests/AccuCastRequest'
import { ActiveProductsRequest } from './requests/ActiveProductsRequest'
import { AvailableProductsRequest } from './requests/AvailableProductsRequest'
import { BlankTilesRequest } from './requests/BlankTilesRequest'
import { FramesByBoundingBoxRequest } from './requests/FramesByBoundingBoxRequest'
import { FramesByProductsRequest } from './requests/FramesByProductsRequest'
import { ProductsByBoundingBoxRequest } from './requests/ProductsByBoundingBoxRequest'
import { RadarCoverageRequest } from './requests/RadarCoverageRequest'
import { RadarProductsMetaRequest } from './requests/RadarProductsMetaRequest'
import { StaticMapRequest } from './requests/StaticMapRequest'
import { ThunderstormAlertsRequest } from './requests/ThunderstormAlertsRequest'
import { TileJsonRequest } from './requests/TileJsonRequest'
import { TilesByQuadkeyRequest } from './requests/TilesByQuadkeyRequest'
import { TilesByZxyRequest } from './requests/TilesByZxyRequest'
import { TilesByZyxRequest } from './requests/TilesByZyxRequest'
import { WorldWindRequest } from './requests/WorldWindRequest'

/**
 * Used to find information about maps.
 * See <a href="http://apidev.accuweather.com/developers/maps-api-guide">here</a> for more information.
 */
export class MapServiceImpl implements MapService {
  public static readonly mapServiceName = 'MapService' // should be internal
  protected readonly serviceName = MapServiceImpl.mapServiceName
  protected readonly httpService: ApiHttpService
  private readonly routeResolver: MapRouteResolver

  constructor(httpService: ApiHttpService, routeResolver: MapRouteResolver) {
    this.httpService = httpService
    this.routeResolver = routeResolver
  }

  // region tiles
  /**
   * The ZXY URL format is a standard Google format.
   * It requires the zoom level (z), the x coordinate (x), the y coordinate (y), and a full time and date for the requested weather data.
   * These four variables determine which tile is returned by the call and what information it contains.
   *
   * If request.Date is not supplied, the most recent tile will be returned.
   * The call is redirected (via 302 redirect) to an altered response URL that includes the appropriate date and time.
   * @param request the request
   * @param requestChain the request chain
   * @return a service response containing either a string (the image url) or error information.
   */
  public getTilesByZxyUrl(
    request: TilesByZxyRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.getTilesByZxyUrl(request, requestChain)
  }

  /**
   * The ZYX URL format is nearly identical to ZXY but will return the most recent tile for the provided location.
   * Date and time are not necessary for this call.
   * The call is redirected (via 302 redirect) to an altered response URL that includes the appropriate date and time.
   * @param request the request
   * @param requestChain the request chain
   * @return a service response containing either a string (the image url) or error information.
   */
  public getTilesByZyxUrl(
    request: TilesByZyxRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.getTilesByZyxUrl(request, requestChain)
  }

  /**
   * This method gets tiles using Microsoft’s quadtree key (quadkey) format.
   * Quadkey is an alternative method for identifying individual tiles in a map grid.
   * Instead of separating values, it combines all identifying factors into a solid numerical string.
   *
   * Tile requests that yield no graphical data will redirect to a blank tile.
   * @param request the request
   * @param requestChain the request chain
   * @return a service response containing either a string (the image url) or error information.
   */
  public getTilesByQuadkeyUrl(
    request: TilesByQuadkeyRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.getTilesByQuadkeyUrl(request, requestChain)
  }

  /**
   * If a tile request yields no graphical data, the API returns a generic blank tile.
   * The URL redirects to a static address that contains the blank tile.
   * This will call that explicitly.
   * @param request the request
   * @param requestChain the request chain
   * @return a service response containing either a string (the image url) or error information.
   */
  public getBlankTilesUrl(
    request: BlankTilesRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.getBlankTilesUrl(request, requestChain)
  }
  // endregion tiles

  // region products
  /**
   * Returns a list of available products within Global Radar.
   * This function can be used to move the map to a specific product.
   * @param request the request
   * @param requestChain the request chain
   * @return a service response containing either a [MapProductList] or error information.
   */
  public async getAvailableProducts(
    request: AvailableProductsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getAvailableProductsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getAvailableProducts'),
    )
  }

  /**
   * Returns a list of products within the current map bounding box.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either a MapProductList or error information.
   */
  public async getActiveProducts(
    request: ActiveProductsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getActiveProductsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getActiveProducts'),
    )
  }

  /**
   * Returns a list of products within a specified map bounding box.
   * The top left corner of the box is defined by X1, Y1;
   * the bottom right corner of the box is defined by X2, Y2.
   * @param request the request
   * @param requestChain the request chain
   * @return a service response containing either a [MapProductList] or error information.
   */
  public async getProductsByBoundingBox(
    request: ProductsByBoundingBoxRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getProductsByBoundingBoxUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getProductsByBoundingBox'),
    )
  }
  // endregion products

  // region frames
  /**
   * Returns a list of mutual frames for all listed products.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either a FrameList or error information.
   */
  public async getFramesByProducts(
    request: FramesByProductsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getFramesByProductsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getFramesByProducts'),
    )
  }

  /**
   * Returns a list of frames within the current map bounding box.
   * The response includes attributions.
   * Returns a list of mutual frames for all listed products.
   * @param request the request
   * @param requestChain the request chain
   * @return a service response containing either a [FrameList] or error information.
   */
  public async getFramesByBoundingBox(
    request: FramesByBoundingBoxRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getFramesByBoundingBoxUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getFramesByBoundingBox'),
    )
  }
  // endregion frames

  // region global coverage (radar only)
  /**
   * Lists full information for each product, including bounding boxes.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either a MapProductList or error information.
   */
  public async getProductsMeta(
    request: RadarProductsMetaRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getProductsMetaUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getProductsMeta'),
    )
  }

  /**
   * Returns a map that shows radar coverage.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either a string or error information.
   */
  public getRadarCoverageUrl(
    request: RadarCoverageRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.getRadarCoverageUrl(request, requestChain)
  }
  // endregion global coverage (radar only)

  // region overlay
  /**
   * Returns a list of thunderstorm alert objects.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either a list of [ThunderstormAlert]s or error information.
   */
  public async getThunderstormAlerts(
    request: ThunderstormAlertsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getThunderstormAlertsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getThunderstormAlerts'),
    )
  }

  /**
   * Returns TileJSON representing snowfall accumulation.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either MapBox TileJSON or error information.
   */
  public async getSnowfallTileJson(
    request: TileJsonRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return await this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getSnowfallTileJsonUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getSnowfallTileJson'),
    )
  }

  /**
   * Returns TileJSON representing lightning over the past 2 hours.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either MapBox TileJSON or error information.
   */
  public async getLightningTileJson(
    request: TileJsonRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return await this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getLightningTileJsonUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getLightningTileJson'),
    )
  }
  // endregion overlay

  // region accucast
  /**
   * Returns a list of community-supplied observations.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either a AccuCast or error information.
   */
  public async getAccuCast(
    request: AccuCastRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getAccuCastUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getAccuCast'),
    )
  }
  // endregion accucast

  // region static
  /**
   * Returns a static map url.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either a URL or error information.
   */
  public getStaticMapUrl(
    request: StaticMapRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string> {
    return this.routeResolver.getStaticMapUrl(request, requestChain)
  }
  // endregion static

  /**
   * Returns the appropriate api url for the method/request.
   * @param request The request.
   * @param requestChain the request chain
   * @return A service response containing either a URL or error information.
   */
  getWorldWindUrl(request: WorldWindRequest, requestChain?: RequestChain): ServiceResponse<string> {
    return this.routeResolver.getWorldWindUrl(request, requestChain)
  }
}
