// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface String {
  capitalize(): string
  paramValueOf(param: string): string
}

String.prototype.capitalize = function (): string {
  return this.replace(/\b\w/g, (l) => l.toUpperCase())
}

String.prototype.paramValueOf = function (param: string): string {
  return (
    this.split('&')
      .find((f) => f.includes(param))
      ?.split('=')[1] ?? ''
  )
}
