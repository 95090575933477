/**
 * Provides settings for how tokens should be replaced for parsed templates.
 */
export class TokenReplaceSettings {
  /**
   * Provides a default [TokenReplaceSettings] object.
   */
  public static default: TokenReplaceSettings = new TokenReplaceSettings()
  /**
   * Gets or sets whether a null value should replace a {token} with an empty string.
   * If false, no replacement will be made. The default is true.
   */
  public replaceNullWithEmpty = true

  /**
   * Gets or sets whether a value should be url-encoded when replacing a {token} (not supported yet).
   * The default is false.
   */
  public replaceUrlEncoded = false

  /**
   * Gets or sets whether to set a value in [TemplateValues].<code>values</code> if there is already a value for a certain key.
   * If true, the value will be overwritten; otherwise, any new value will be ignored.
   * The default is false.
   */
  public overwriteIfSet = false

  /**
   * Gets or sets whether to include the protocol part of a url when replacing a parsed template.
   * If true, the protocol will be excluded from the result.
   * The default is false.
   */
  public excludeProtocol = false

  /**
   * Gets or sets whether to include protocol, host, and port parts of a url when replacing a parsed template.
   * If true, protocol, host, and port parts of a url will be excluded from the result.
   * The default is false.
   */
  public ensureRelativeUrl = false
}
