/**
 * Encapsulates the parameters used to make requests to Alerts API endpoints.
 */
export class AlertsByLocationKeyRequest {
  /**
   * Gets or sets the language (i.e. en-us) in which the response is returned.
   */
  public language: string

  /**
   * Gets or sets the unique ID of the location.
   * Location keys can be obtained from the location service.
   */
  public locationKey: string

  /**
   * Gets or sets whether the response should include the full text.
   */
  public details = false

  /**
   * Initializes an instance of [AlertsByLocationKeyRequest] with the given language and location key.
   * This request can be used to make requests to the [AlertService].
   * @param language The language (i.e. en-us) in which the response is returned.
   * @param locationKey The unique ID of the location. Location keys can be obtained from the location service.
   */
  constructor(language: string, locationKey: string) {
    this.language = language
    this.locationKey = locationKey
  }
}
