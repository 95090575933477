/**
 * Encapsulates the parameters used to make requests to ProductAvailabilityService.
 */
export class ProductCollectionByLocationKeyRequest {
  /** The language code. */
  public readonly language: string

  /** The location key. */
  public readonly locationKey: string

  constructor(language: string, locationKey: string) {
    this.language = language
    this.locationKey = locationKey
  }
}
