/**
 * The different types of weather conditions.
 * There are no codes for 9, 10, 27, 28?
 */

export enum ConditionCode {
  /**
   * Unknown.
   */
  Unknown = 0,

  /**
   * Sunny.
   */
  Sunny = 1,

  /**
   * Mostly Sunny.
   */
  MostlySunny = 2,

  /**
   * Partly Sunny.
   */
  PartlySunny = 3,

  /**
   * Intermittent Clouds.
   */
  IntermittentClouds = 4,

  /**
   * Hazy Sunshine.
   */
  HazySunshine = 5,

  /**
   * Mostly Cloudy.
   */
  MostlyCloudy = 6,

  /**
   * Cloudy.
   */
  Cloudy = 7,

  /**
   * Dreary.
   */
  Dreary = 8,

  /**
   * Fog.
   */
  Fog = 11,

  /**
   * Showers.
   */
  Showers = 12,

  /**
   * Mostly Cloudy Showers.
   */
  MostlyCloudyShowers = 13,

  /**
   * Partly Sunny Showers.
   */
  PartlySunnyShowers = 14,

  /**
   * Thunderstorms.
   */
  ThunderStorms = 15,

  /**
   * Mostly Cloudy Thunderstorms.
   */
  MostlyCloudyTStorms = 16,

  /**
   * Partly Sunny Thunderstorms.
   */
  PartlySunnyTStorms = 17,

  /**
   * Rain.
   */
  Rain = 18,

  /**
   * Flurries.
   */
  Flurries = 19,

  /**
   * Mostly Cloudy Flurries.
   */
  MostlyCloudyFlurries = 20,

  /**
   * Partly Sunny Flurries.
   */
  PartlySunnyFlurries = 21,

  /**
   * Snow.
   */
  Snow = 22,

  /**
   * Mostly Cloudy Snow.
   */
  MostlyCloudySnow = 23,

  /**
   * Ice.
   */
  Ice = 24,

  /**
   * Sleet.
   */
  Sleet = 25,

  /**
   * Freezing Rain.
   */
  FreezingRain = 26,

  /**
   * RainSnow
   */
  RainSnow = 29,

  /**
   * Hot.
   */
  Hot = 30,

  /**
   * Cold.
   */
  Cold = 31,

  /**
   * Windy.
   */
  Windy = 32,

  /**
   * Clear (Nighttime).
   */
  MoonClear = 33,

  /**
   * Mostly Clear (Nighttime).
   */
  MoonMostlyClear = 34,

  /**
   * Partly Cloudly (Nighttime).
   */
  MoonPartlyCloudy = 35,

  /**
   * Intermittent Clouds (Nighttime).
   */
  MoonIntermittentClouds = 36,

  /**
   * Hazy Moonlight (Nighttime).
   */
  MoonHazyMoonlight = 37,

  /**
   * Mostly Cloudy (Nighttime).
   */
  MoonMostlyCloudy = 38,

  /**
   * Partly Cloudy Showers (Nighttime).
   */
  MoonPartlyCloudyShowers = 39,

  /**
   * Mostly Cloudy Showers (Nighttime).
   */
  MoonMostlyCloudyShowers = 40,

  /**
   * Partly Cloudy Thunderstorms (Nighttime).
   */
  MoonPartlyCloudyTStorms = 41,

  /**
   * Mostly Cloudy Thunderstorms (Nighttime).
   */
  MoonMostlyCloudyTStorms = 42,

  /**
   * Mostly Cloudly Flurries (Nighttime).
   */
  MoonMostlyCloudyFlurries = 43,

  /**
   * Mostly Cloudy Snow (Nighttime).
   */
  MoonMostlyCloudySnow = 44,
}
