import { isNotNullOrUndefined, isNullOrUndefined } from '../../util/Helpers'

const map: Map<string, any> = new Map([
  [
    'Dangerously Dry',
    {
      category: 'Dangerously Dry',
      beginOutdoorDewpoint: null,
      endOutdoorDewpoint: -14.99,
      description:
        'Exposure will lead to health effects. Immediately use a humidifier. Drink extra water. For dry skin, use a moisturizing lotion; for dry eyes, use moisturizing eye drops; for dry lips, use a moisturizing lipbalm.',
      hexColor: '',
    },
  ],
  [
    'Extremely Dry',
    {
      category: 'Extremely Dry',
      beginOutdoorDewpoint: -15,
      endOutdoorDewpoint: 4.99,
      description:
        'Health effects will be immediately felt by sensitive groups. Healthy individuals are likely to experience symptoms such as irritated skin, dry eyes, itchy throat, difficulty breathing, or bloody nose. Use humidifier. Drink extra water. For dry skin, use a moisturizing lotion; for dry eyes, use moisturizing eye drops; for dry lips, use a moisturizing lipbalm.',
      hexColor: '',
    },
  ],
  [
    'Very Dry',
    {
      category: 'Very Dry',
      beginOutdoorDewpoint: 5,
      endOutdoorDewpoint: 18.99,
      description:
        'Health effects can be immediately felt by sensitive groups. With prolonged exposure, healthy individuals may experience symptoms such as irritated skin, dry eyes, itchy throat, difficulty breathing, or bloody nose. Use a humidifier. Drink extra water. For dry skin, use a moisturizing lotion; for dry eyes, use moisturizing eye drops; for dry lips, use a moisturizing lipbalm.',
      hexColor: '',
    },
  ],
  [
    'Dry',
    {
      category: 'Dry',
      beginOutdoorDewpoint: 19,
      endOutdoorDewpoint: 29.99,
      description:
        'The indoor humidity is unhealthy for sensitive groups. If you are feeling symptoms such as irritated skin, dry eyes, itchy throat, difficulty breathing, or bloody nose: use a humidifier. Drink extra water. For dry skin, use a moisturizing lotion; for dry eyes, use moisturizing eye drops; for dry lips, use a moisturizing lipbalm.',
      hexColor: '',
    },
  ],
  [
    'Slightly Dry',
    {
      category: 'Slightly Dry',
      beginOutdoorDewpoint: 30,
      endOutdoorDewpoint: 40.99,
      description:
        'The indoor humidity is generally acceptable for most individuals. However, sensitive groups may experience minor to moderate symptoms from long-term exposure. Consider using a humidifier to increase comfort and reduce symptoms.',
      hexColor: '',
    },
  ],
  [
    'Ideal Humidity',
    {
      category: 'Ideal Humidity',
      beginOutdoorDewpoint: 41,
      endOutdoorDewpoint: 55.99,
      description:
        'The indoor relative humidity is ideal for most individuals; enjoy your regular routine.',
      hexColor: '',
    },
  ],
  [
    'Slightly Humid',
    {
      category: 'Slightly Humid',
      beginOutdoorDewpoint: 56,
      endOutdoorDewpoint: 62.99,
      description:
        'The indoor humidity is generally acceptable for most individuals. However, sensitive groups may experience minor to moderate symptoms from long-term exposure. Consider using a dehumidifier to increase comfort and reduce symptoms.',
      hexColor: '',
    },
  ],
  [
    'Humid',
    {
      category: 'Humid',
      beginOutdoorDewpoint: 63,
      endOutdoorDewpoint: 66.99,
      description:
        'The indoor humidity is unhealthy for sensitive groups. If you are feeling symptoms such as increased sweating, fatigue, difficulty breathing, or mold-allergy symptoms (sneezing, red eyes, skin rash): use a dehumidifier and/or air conditioner.',
      hexColor: '',
    },
  ],
  [
    'Very Humid',
    {
      category: 'Very Humid',
      beginOutdoorDewpoint: 67,
      endOutdoorDewpoint: 71.99,
      description:
        'Health effects can be immediately felt by sensitive groups. With prolonged exposure, healthy individuals may experience such as increased sweating, fatigue, difficulty breathing, or mold-allergy symptoms (sneezing, red eyes, skin rash): use a dehumidifier and/or air conditioner.',
      hexColor: '',
    },
  ],
  [
    'Extremely Humid',
    {
      category: 'Extremely Humid',
      beginOutdoorDewpoint: 72,
      endOutdoorDewpoint: 77.99,
      description:
        'Health effects will be immediately felt by sensitive groups. Healthy individuals are likely to experience symptoms Health effects will be immediately felt by sensitive groups. Healthy individuals are likely to experience symptoms such as increased sweating, fatigue, difficulty breathing, or mold-allergy symptoms (sneezing, red eyes, skin rash): use a dehumidifier and/or air conditioner.',
      hexColor: '',
    },
  ],
  [
    'Dangerously Humid',
    {
      category: 'Dangerously Humid',
      beginOutdoorDewpoint: 78,
      endOutdoorDewpoint: null,
      description:
        'Exposure will lead to serious health effects. Limit time spent in this environment. Immediately use a dehumidifier and/or air conditioner.',
      hexColor: '',
    },
  ],
])

const entries = Array.from(map.entries())

export function toIndoorHumidityCategoryInfo(dewPoint: number) {
  if (isNotNullOrUndefined(dewPoint)) {
    for (const info of entries) {
      if (isInCategory(dewPoint, info[1])) {
        return info[1]
      }
    }
  }
}

function isInCategory(val: number, info: any): boolean {
  const lo = info.beginOutdoorDewpoint
  const hi = info.endOutdoorDewpoint

  if (isNotNullOrUndefined(lo) && isNotNullOrUndefined(hi)) {
    return val >= lo && val <= hi // in range
  }

  if (isNullOrUndefined(lo)) {
    // at the bottom of the range and lower than the min
    return isNotNullOrUndefined(hi) && val <= hi
  }

  // at the top of the range and higher than the max
  return val >= lo
}
