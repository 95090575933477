/* eslint-disable no-unused-vars */
/**
 * The unit data is provided in three widths: long, short, and narrow. *
 * Note that if in your locale certain abbreviations are always understood to mean particular units, then different abbreviations should generally be used even in the narrow form for other units.
 * For example, if “m” is always understood to mean “meters”, then even in the narrow form a different abbreviation should be used for “minutes”—perhaps “min”, “mn”, or even “m.”.
 */
export enum UnitFormatWidth {
  /**
   * Typically a fully spelled-out name.
   */
  Long,

  /**
   * A distinctive abbreviated form, not easily confused with another unit.
   */
  Short,

  /**
   * As short as possible for situations with limited display space.
   * Typically this form eliminates spaces and punctuation to the extent possible, and uses abbreviations that might only be clear in context.
   */
  Narrow,
}
