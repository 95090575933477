<template>
  <div class="loading-screen" :class="className">
    <div class="loading-screen-body">
      <img class="logo" src="@/assets/images/accuweather-logo.svg" />
      <span class="spinner"></span>
    </div>
  </div>
</template>

<script lang="ts" src="./loading-screen.component.ts"></script>
<style scoped lang="scss" src="./loading-screen.scss"></style>
