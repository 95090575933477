// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 * Provides a method used to validate an object.
 */
export class IntegerGreaterThanRule {
  /**
   * Checks whether an integer is greater than a given value.
   * @param value The value to be validated.
   * @param min The minimum accepted value.
   * @param name The parameter name. Defaults to "value".
   * @return If `value` is invalid, an exception; otherwise, undefined.
   */
  public static checkRule(value: number, min: number, name: string = this.parameterName): Error {
    if (value > min) {
      return undefined
    } else {
      return Error(`${name} must be greater than ${min}. supplied value: ${value}`)
    }
  }
  private static readonly parameterName = 'value'
}
