// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import { ApiHttpService } from '../core/api/ApiHttpService'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { Logger } from '../core/Logger'
import { BasinId } from '../core/models/BasinId'
import { ServiceResponse } from '../core/ServiceResponse'
import { isNotNullOrUndefined } from '../util/Helpers'
import { BasinInfoProvider } from './metadata/BasinInfoProvider'
import { StormLevel } from './metadata/StormLevel'
import { ActiveStormsRequest } from './requests/ActiveStormsRequest'
import { StormByIdRequest } from './requests/StormByIdRequest'
import { StormPositionRequest } from './requests/StormPositionRequest'
import { StormsByBasinRequest } from './requests/StormsByBasinRequest'
import { StormsByYearRequest } from './requests/StormsByYearRequest'
import { TropicalRouteResolver } from './TropicalRouteResolver'

/**
 * Provides methods to get government-issued tropical storm data.
 */
export class TropicalServiceImpl {
  protected readonly serviceName = 'TropicalService'
  private readonly logger: Logger = Logger.getInstance()
  protected readonly httpService: ApiHttpService
  private readonly routeResolver: TropicalRouteResolver
  private readonly basinInfoProvider: BasinInfoProvider

  constructor(httpService: ApiHttpService, routeResolver: TropicalRouteResolver) {
    this.httpService = httpService
    this.routeResolver = routeResolver
    this.basinInfoProvider = new BasinInfoProvider()
  }

  /**
   * Gets a list of government-issued storm names.
   *
   * @param request The request.
   * @return A service response containing either a list of [String]s or error information.
   */
  public getStormNames(
    request: StormsByBasinRequest,
    requestChain?: RequestChain,
  ): ServiceResponse<string[]> {
    try {
      const data = this.basinInfoProvider.getStormNames(request.basinId, request.year)
      return ServiceResponse.create(data)
    } catch (err) {
      return ServiceResponse.fromError(err)
    }
  }

  // region storms
  /**
   * Gets a list of potential government-issued active storms.
   *
   * @param request The request.
   * @return A service response containing either a list of [Storm]s or error information.
   */
  public async getActiveStorms(
    request: ActiveStormsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getActiveStormsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getActiveStorms', true, () => []),
    )
  }

  /**
   * Gets a list of government-issued storms for the specified year.
   *
   * @param request The request.
   * @return A service response containing either a list of [Storm]s or error information.
   */
  public async getStormsByYear(
    request: StormsByYearRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getStormsByYearUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getStormsByYear', true, () => []),
    )
  }

  /**
   * Gets a list of government-issued storms for the specified basin.
   *
   * @param request The request.
   * @return A service response containing either a list of [Storm]s or error information.
   */
  public async getStormsByBasin(
    request: StormsByBasinRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getStormsByBasinUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getStormsByBasin', true, () => []),
    )
  }

  /**
   * Gets a storm for the specified basin, year, and government-issued ID.
   *
   * @param request The request.
   * @return A service response containing either a [Storm] or error information.
   */
  public async getStormById(
    request: StormByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getStormByIdUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getStormById', true),
    )
  }
  // endregion storms

  // region storm forecasts
  /**
   * Gets forecasts for an individual government-issued storm.
   *
   * @param request The request.
   * @return A service response containing either a list of [StormForecast]s or error information.
   */
  public async getStormForecasts(
    request: StormByIdRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    const response: ServiceResponse<any[]> = await this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getStormForecastsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getStormForecasts', true, () => []),
    )
    if (!response.hasError) {
      this.validateStormLevels(response.data, request.basinId, request.year, request.stormId)
    }
    return response
  }
  // endregion storm forecasts

  // region storm positions
  /**
   * Gets the past positions for an individual government-issued storm.
   *
   * @param request The request.
   * @return A service response containing either a list of [StormPosition]s or error information.
   */
  public async getStormPositions(
    request: StormPositionRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    const response: ServiceResponse<any[]> = await this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getStormPositionsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getStormPositions', true, () => []),
    )
    if (!response.hasError) {
      this.validateStormLevels(response.data, request.basinId, request.year, request.stormId)
    }
    return response
  }

  /**
   * Gets the current position for an individual government-issued storm.
   *
   * @param request The request.
   * @return A service response containing either a [StormPosition] or error information.
   */
  public async getCurrentStormPosition(
    request: StormPositionRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any[]>> {
    const response: ServiceResponse<any[]> = await this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getCurrentStormPositionUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getCurrentStormPosition',
        true,
        () => [],
      ),
    )
    if (!response.hasError) {
      this.validateStormLevel(response.data, request.basinId, request.year, request.stormId)
    }
    return response
  }
  // endregion storm positions

  private validateStormLevels(items: any[], basinId: BasinId, year: number, stormId: number) {
    if (isNotNullOrUndefined(items) && items.length > 0) {
      for (const item of items) {
        if (!this.validateStormLevel(item, basinId, year, stormId)) {
          break
        }
      }
    }
  }

  private validateStormLevel(item: any, basinId: BasinId, year: number, stormId: number): boolean {
    if (isNotNullOrUndefined(item) && item.Status === StormLevel.Unknown) {
      this.logger.error(`Unknown storm level: ${basinId}|${year}|${stormId}`)
      return false
    }
    return true
  }
}
