/* eslint-disable no-unused-vars */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined, isNullOrUndefined } from '../../util/Helpers'

/**
 * Provides an abstract way to validate an object, by attaching a set of functions to act as validation rules.
 */
export class Validator<T> {
  private readonly rules: Array<(t: T) => Error>

  /**
   * Initializes a new `Validator`.
   * @param rules the list of validation rules.
   */
  constructor(rules: Array<(t: T) => Error>) {
    if (isNullOrUndefined(rules)) {
      this.rules = []
    } else {
      this.rules = rules
    }
  }

  /**
   * Adds a rule to the collection of validation rules.
   * @param rule The validation rule.
   */
  public addRule(rule: (t: T) => Error): Validator<T> {
    if (isNotNullOrUndefined(rule)) {
      this.rules.push(rule)
    }
    return this
  }

  /**
   * Gets whether the given `arg` is valid.
   * @param arg The item to be validated.
   * @return The `Error` returned from the first rule that fails, or undefined if all rules pass.
   */
  public validate(arg: T): Error {
    let error: Error

    for (const rule of this.rules) {
      error = rule(arg)
      if (isNotNullOrUndefined(error)) {
        return error
      }
    }
    return undefined
  }
}
