// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiHttpService } from '../core/api/ApiHttpService'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { AlertRouteResolver } from './AlertRouteResolver'
import { AlertService } from './AlertService'
import { AlertsByLocationKeyRequest } from './requests/AlertsByLocationKeyRequest'

/**
 * Get severe weather alerts from official Government Meteorological Agencies and leading global weather alert providers.
 */
export class AlertServiceImpl implements AlertService {
  protected readonly serviceName = 'AlertService'
  protected readonly httpService: ApiHttpService
  private readonly routeResolver: AlertRouteResolver

  constructor(httpService: ApiHttpService, routeResolver: AlertRouteResolver) {
    this.httpService = httpService
    this.routeResolver = routeResolver
  }

  /**
   * Gets severe weather alerts data for a specific location.
   * @param request The request.
   * @return A service response containing either a list of [Alert]s or error information.
   */
  public async getAlertsByLocationKey(
    request: AlertsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getAlertsByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getAlertsByLocationKey',
        true,
        () => [],
      ),
    )
  }

  /**
   * Gets AccuWeather alerts data for a specific location.
   * @param request The request.
   * @return A service response containing either a list of [AccuAlert]s or error information.
   */
  public async getAccuAlertsByLocationKey(
    request: AlertsByLocationKeyRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getAccuAlertsByLocationKeyUrl(r, rc),
      new HttpRequestOptions(
        requestChain,
        this.serviceName,
        'getAccuAlertsByLocationKey',
        true,
        () => [],
      ),
    )
  }
}
