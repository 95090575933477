// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiHttpService } from '../core/api/ApiHttpService'
import { CacheOptions } from '../core/cache/CacheOptions'
import { ServiceResponseCache } from '../core/cache/ServiceResponseCache'
import { HttpRequestOptions } from '../core/http/HttpRequestOptions'
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { SdkSettings } from '../core/settings/SdkSettings'
import { isNotNullOrUndefined } from '../util/Helpers'
import { LanguagesRequest } from './requests/LanguagesRequest'
import { TranslationGroupsRequest } from './requests/TranslationGroupsRequest'
import { TranslationsByGroupRequest } from './requests/TranslationsByGroupRequest'
import { TranslationRouteResolver } from './TranslationRouteResolver'
import { TranslationService } from './TranslationService'

/**
 * Provides methods to get available languages and translations.
 */
export class TranslationServiceImpl implements TranslationService {
  protected readonly serviceName = 'TranslationService'
  private readonly allLanguages = 'AllLanguages'
  private readonly allTranslationGroups = 'AllTranslationGroups'

  private readonly httpService: ApiHttpService
  private readonly routeResolver: TranslationRouteResolver
  private readonly languagesCache: ServiceResponseCache<any[]>
  private readonly translationGroupsCache: ServiceResponseCache<any[]>

  constructor(
    sdkSettings: SdkSettings,
    httpService: ApiHttpService,
    routeResolver: TranslationRouteResolver,
  ) {
    this.httpService = httpService
    this.routeResolver = routeResolver
    if (sdkSettings.cacheSettings.shouldCacheLanguages) {
      const cacheOptions = new CacheOptions(sdkSettings.cacheSettings.languagesExpiry)
      this.languagesCache = new ServiceResponseCache<any[]>(
        sdkSettings.persistentCache,
        cacheOptions,
      )
      this.translationGroupsCache = new ServiceResponseCache<any[]>(
        sdkSettings.persistentCache,
        cacheOptions,
      )
    }
  }

  // region language / meta
  /**
   * Gets a list of available languages and the metadata for those languages.
   * @param request The request.
   * @return A service response containing either a list of [Language]s or error information.
   */
  public async getLanguages(
    request: LanguagesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(this.languagesCache)) {
      return this.languagesCache.getOrAdd(
        this.allLanguages,
        (r, rc) => this.getLanguagesHttp(r, rc),
        request,
        requestChain,
      )
    }
    return this.getLanguagesHttp(request, requestChain)
  }

  /**
   * Gets a list of translation groups, each of which contains a list phrases that are available for translation.
   * @param request The request.
   * @return A service response containing either a list of [TranslationGroup]s or error information.
   */
  public async getTranslationGroups(
    request: TranslationGroupsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    if (isNotNullOrUndefined(this.translationGroupsCache)) {
      return this.translationGroupsCache.getOrAdd(
        this.allTranslationGroups,
        (r, rc) => this.getTranslationGroupsHttp(r, rc),
        request,
        requestChain,
      )
    }
    return this.getTranslationGroupsHttp(request, requestChain)
  }
  // endregion language / meta

  // region translations
  /**
   * Gets all translated phrases for a specific group, in the desired language.
   * @param request The request.
   * @return A service response containing either a list of [Translation]s or error information.
   */
  public async getTranslationsByGroup(
    request: TranslationsByGroupRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getTranslationsByGroupUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getTranslationsByGroup'),
    )
  }
  private async getLanguagesHttp(
    request: LanguagesRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getLanguagesUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getLanguages'),
    )
  }
  private async getTranslationGroupsHttp(
    request: TranslationGroupsRequest,
    requestChain?: RequestChain,
  ): Promise<ServiceResponse<any>> {
    return this.httpService.get(
      request,
      (r, rc) => this.routeResolver.getTranslationGroupsUrl(r, rc),
      new HttpRequestOptions(requestChain, this.serviceName, 'getTranslationGroups'),
    )
  }
  // endregion translations
}
