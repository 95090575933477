import { ConversionInfo, convertQuantity } from './ConversionInfo'
import {
  Centimeters,
  DistanceConversion,
  Feet,
  Inches,
  Kilometers,
  Meters,
  Miles,
  Millimeters,
  NauticalMiles,
} from './DistanceConversion'
import { LinearUnits } from './LinearUnits'

const distanceInfo: Map<LinearUnits, ConversionInfo<DistanceConversion>> = new Map([
  [
    LinearUnits.Feet,
    new ConversionInfo(LinearUnits.Feet, new Feet(), (v: number, c: any) => c.toFeet(v)),
  ],
  [
    LinearUnits.Inches,
    new ConversionInfo(LinearUnits.Inches, new Inches(), (v: number, c: any) => c.toInches(v)),
  ],
  [
    LinearUnits.Miles,
    new ConversionInfo(LinearUnits.Miles, new Miles(), (v: number, c: any) => c.toMiles(v)),
  ],
  [
    LinearUnits.Millimeters,
    new ConversionInfo(LinearUnits.Millimeters, new Millimeters(), (v: number, c: any) =>
      c.toMillimeters(v),
    ),
  ],
  [
    LinearUnits.Centimeters,
    new ConversionInfo(LinearUnits.Centimeters, new Centimeters(), (v: number, c: any) =>
      c.toCentimeters(v),
    ),
  ],
  [
    LinearUnits.Meters,
    new ConversionInfo(LinearUnits.Meters, new Meters(), (v: number, c: any) => c.toMeters(v)),
  ],
  [
    LinearUnits.Kilometers,
    new ConversionInfo(LinearUnits.Kilometers, new Kilometers(), (v: number, c: any) =>
      c.toKilometers(v),
    ),
  ],
  [
    LinearUnits.NauticalMiles,
    new ConversionInfo(LinearUnits.NauticalMiles, new NauticalMiles(), (v: number, c: any) =>
      c.toNauticalMiles(v),
    ),
  ],
])

/**
 * Converts a distance from one unit type to another.
 * @param distance The distance to convert.
 * @param type The unit type to convert to.
 * @return The converted distance.
 */
export function convertDistance(distance: any, type: LinearUnits) {
  return convertQuantity(distance, type, distanceInfo)
}
