/**
 * Represents a subset of api unit types dealing with temperature.
 * These values map to api unit type IDs.
 * @see <a href="https://developer.accuweather.com/unit-types">here</a> for more information.
 */
export enum TemperatureType {
  /**
   * Celsius
   */
  Celsius = 17,

  /**
   * Fahrenheit
   */
  Fahrenheit = 18,

  /**
   * Kelvin
   */
  Kelvin = 19,
}

/**
 * Gets whether the [TemperatureType] is metric.
 */
export function isMetricTemperature(units: TemperatureType): boolean {
  return units === TemperatureType.Celsius
}
