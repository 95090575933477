// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 * Provides a method used to validate an object.
 */
export class NumberBetweenRangeRule {
  /**
   * Checks whether an integer is within range.
   * @param value The value to be validated.
   * @param min The minimum accepted value.
   * @param max The maximum accepted value.
   * @param name The parameter name. Defaults to "value".
   * @return If `value` is invalid, an exception; otherwise, undefined.
   */
  public static checkRule(
    value: number,
    min: number,
    max: number,
    name: string = this.parameterName,
  ): Error {
    if (value >= min && value <= max) {
      return undefined
    } else {
      return Error(
        `${name} must be between ${min} and ${max} (inclusive). supplied value: ${value}`,
      )
    }
  }

  private static readonly parameterName: string = 'value'
}
