// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { RequestChain } from '../core/http/RequestChain'
import { ServiceResponse } from '../core/ServiceResponse'
import { isNotNullOrUndefined } from '../util/Helpers'
import { MapService } from './MapService'
import { MapType } from './MapType'
import { ProductValues } from './ProductValues'
import { FramesByMapTypeRequest } from './requests/FramesByMapTypeRequest'
import { FramesByProductsRequest } from './requests/FramesByProductsRequest'
import { TilesByZxyRequest } from './requests/TilesByZxyRequest'
import { TilesZxyByMapTypeRequest } from './requests/TilesZxyByMapTypeRequest'

const displayModes = new Map<MapType, number>([
  [MapType.StandardSatellite, 18],
  [MapType.EnhancedGlobalColorSatellite, 20],
  [MapType.GlobalColorSatellite, 10],
])

function getTileProductsForMapType(mapType: MapType): ProductValues[] {
  const products: ProductValues[] = []
  let product
  switch (mapType) {
    case MapType.Precipitation:
      product = new ProductValues(111, 1020)
      break
    case MapType.TwentyFourHourRainfallForecast:
      product = new ProductValues(111, 1030)
      break
    case MapType.TwentyFourHourIceForecast:
      product = new ProductValues(111, 1040)
      break
    case MapType.TwentyFourHourSnowfallForecast:
      product = new ProductValues(111, 1010)
      break
    case MapType.TemperatureContour:
      product = new ProductValues(26, 1010)
      break
    case MapType.WorldWind:
    case MapType.StandardSatellite:
    case MapType.Satellite:
    case MapType.FutureRadar:
    case MapType.VisibleSatellite:
    case MapType.WaterVapor:
    case MapType.Radar:
    default:
      product = undefined
      break
  }
  if (isNotNullOrUndefined(product)) {
    products.push(product)
  }
  return products
}

function getFrameProductsForMapType(mapType: MapType): ProductValues[] {
  const products: ProductValues[] = []
  let product
  switch (mapType) {
    case MapType.FutureRadar:
      product = new ProductValues(51, 1100)
      break
    case MapType.Precipitation:
      product = new ProductValues(111, 1020)
      break
    case MapType.TwentyFourHourRainfallForecast:
      product = new ProductValues(111, 1030)
      break
    case MapType.TwentyFourHourIceForecast:
      product = new ProductValues(111, 1040)
      break
    case MapType.TwentyFourHourSnowfallForecast:
      product = new ProductValues(111, 1010)
      break
    case MapType.TemperatureContour:
      product = new ProductValues(26, 1010)
      break
    case MapType.WorldWind:
      product = new ProductValues(26, 1020)
      break
    case MapType.Radar:
      product = new ProductValues(51, 1001)
      break
    case MapType.Satellite:
    case MapType.StandardSatellite:
    case MapType.VisibleSatellite:
    case MapType.WaterVapor:
    default:
      product = undefined
      break
  }
  if (isNotNullOrUndefined(product)) {
    products.push(product)
  }
  return products
}

/**
 * Returns a list of frames for a given [MapType]. This method will determine the appropriate products
 * for the specified [MapType] and call [MapService.getFramesByProducts] and return the result.
 * @param request the request
 * @param requestChain the request chain
 * @return a service response containing either a [FrameList] or error information.
 */
export async function getFramesByMapType(
  mapService: MapService,
  request: FramesByMapTypeRequest,
  requestChain?: RequestChain,
): Promise<ServiceResponse<any>> {
  const framesRequest = new FramesByProductsRequest(
    request.mapType,
    getFrameProductsForMapType(request.mapType),
  )
  framesRequest.refreshCache = request.refreshCache
  return mapService.getFramesByProducts(framesRequest, requestChain)
}

/**
 * This method will determine the appropriate products and [TilesByZxyRequest.displayMode] for the
 * specified [MapType] and call [MapService.getTilesByZxyUrl] and return the result.
 * @param request the request
 * @param requestChain the request chain
 * @return a service response containing either a string (the tile url) or error information.
 */
export function getTilesZxyUrlByMapType(
  mapService: MapService,
  request: TilesZxyByMapTypeRequest,
  requestChain?: RequestChain,
): ServiceResponse<any> {
  const tileRequest = new TilesByZxyRequest(request.mapType, request.date)
  tileRequest.apiKey = request.apiKey

  // perform any necessary request massaging here
  // to retrieve 'standard satellite' tile urls the display mode needs to be set to 18
  if (displayModes.has(request.mapType)) {
    tileRequest.displayMode = displayModes.get(request.mapType)
  }
  tileRequest.products = getTileProductsForMapType(request.mapType)
  return mapService.getTilesByZxyUrl(tileRequest, requestChain)
}
