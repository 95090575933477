import { ConversionInfo, convertQuantity } from './ConversionInfo'
import {
  KiloPascals,
  MercuryInches,
  MercuryMillimeters,
  Millibars,
  PoundsPerSquareInch,
  PressureConversion,
} from './PressureConversion'
import { PressureUnits } from './PressureUnits'

const pressureInfo: Map<PressureUnits, ConversionInfo<PressureConversion>> = new Map([
  [
    PressureUnits.PoundsPerSquareInch,
    new ConversionInfo(
      PressureUnits.PoundsPerSquareInch,
      new PoundsPerSquareInch(),
      (v: number, c: any) => c.toPoundsPerSquareInch(v),
    ),
  ],
  [
    PressureUnits.Millibars,
    new ConversionInfo(PressureUnits.Millibars, new Millibars(), (v: number, c: any) =>
      c.toMillibars(v),
    ),
  ],
  [
    PressureUnits.MillimetersOfMercury,
    new ConversionInfo(
      PressureUnits.MillimetersOfMercury,
      new MercuryMillimeters(),
      (v: number, c: any) => c.toMercuryMillimeters(v),
    ),
  ],
  [
    PressureUnits.InchesOfMercury,
    new ConversionInfo(PressureUnits.InchesOfMercury, new MercuryInches(), (v: number, c: any) =>
      c.toMercuryInches(v),
    ),
  ],
  [
    PressureUnits.KiloPascals,
    new ConversionInfo(PressureUnits.KiloPascals, new KiloPascals(), (v: number, c: any) =>
      c.toKiloPascals(v),
    ),
  ],
  [
    PressureUnits.HectoPascals,
    new ConversionInfo(PressureUnits.HectoPascals, new Millibars(), (v: number, c: any) =>
      c.toMillibars(v),
    ),
  ], // same as millibars
])

/**
 * Converts a pressure from one unit type to another.
 * @param pressure The pressure to convert.
 * @param type The unit type to convert to.
 * @return The converted pressure.
 */
export function convertPressure(pressure: any, type: PressureUnits) {
  return convertQuantity(pressure, type, pressureInfo)
}
