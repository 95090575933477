// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined, isNullOrUndefined, valueOrDefault } from '../../util/Helpers'
import { Tokenizer } from '../../util/templateParsing/Tokenizer'
import { TokenReplaceSettings } from '../../util/templateParsing/TokenReplaceSettings'
import { RequestChain } from '../http/RequestChain'
import { Logger } from '../Logger'
import { ServiceResponse } from '../ServiceResponse'
import { AsyncValidator } from '../validation/AsyncValidator'
import { Validator } from '../validation/Validator'

/**
 * Provides methods to build urls from route templates.
 */
export class RouteResolver {
  private readonly templates: Map<string, string>
  private readonly tokenizer: Tokenizer
  private readonly defaultSettings: TokenReplaceSettings
  private readonly logger: Logger = Logger.getInstance()

  constructor(templates: Map<string, string>) {
    this.templates = templates
    this.tokenizer = new Tokenizer()
    this.defaultSettings = new TokenReplaceSettings()
    this.defaultSettings.replaceNullWithEmpty = true

    for (const key of Array.from(templates.keys())) {
      const template = templates.get(key)
      if (isNotNullOrUndefined(template)) {
        this.addTemplate(key, template)
      }
    }
  }

  /**
   * Registers multiple templates.
   * @param templates The tokenized templates to be registered.
   * @return this
   */
  public addTemplates(templates: Map<string, string>): RouteResolver {
    for (const it of Array.from(templates.entries())) {
      this.addTemplate(it[0], it[1])
    }
    return this
  }

  /**
   * Builds a url by finding a tokenized template with the given name and replacing the tokens with args supplied.
   * @param name The name of the template.
   * @param args The args used to replace the tokens. Each key should be a token, and its value will be used to replace that {token} in the found template.
   * @param settings The settings for how to handle token replacement.
   * @return If no template is found, null; otherwise, the fully-replaced url.
   */
  public getUrl(name: string, args: Map<string, any>, settings?: TokenReplaceSettings): string {
    const template = this.tokenizer.createValues(name)
    return this.tokenizer.replace(
      template,
      args,
      isNullOrUndefined(settings) ? this.defaultSettings : settings,
    )
  }

  /**
   * Validates a request and -if no error found (or if no validator supplied)-
   * builds a url by finding a tokenized template and replacing the tokens with args supplied.
   * @param name The name of the template.
   * @param request The request being validated.
   * @param validator The validator.
   * @param requestChain the request chain.
   * @param args The args used to replace the tokens. Each key should be a token, and its value will be used to replace that {token} in the found template.
   * @param settings The settings for how to handle token replacement.
   * @return If there are any errors, a ServiceResponse with the appropriate error; otherwise, a ServiceResponse with the fully-replaced url as data.
   */
  public resolve<TRequest>(
    name: string,
    request: TRequest,
    validator: Validator<TRequest>,
    requestChain: RequestChain,
    args: Map<string, any>,
    settings: TokenReplaceSettings = this.defaultSettings,
  ): ServiceResponse<string> {
    return this.doResolve(
      name,
      validator.validate(request),
      settings,
      requestChain?.isDryRun ?? false,
      args,
    )
  }

  /**
   * Validates a request and -if no error found (or if no validator supplied)-
   * builds a url by finding a tokenized template and replacing the tokens with args supplied.
   * @param name The name of the template.
   * @param request The request being validated.
   * @param validator The validator.
   * @param requestChain the request chain.
   * @param args The args used to replace the tokens. Each key should be a token, and its value will be used to replace that {token} in the found template.
   * @param settings The settings for how to handle token replacement.
   * @return If there are any errors, a ServiceResponse with the appropriate error; otherwise, a ServiceResponse with the fully-replaced url as data.
   */
  public async resolveAsync<TRequest>(
    name: string,
    request: TRequest,
    validator: AsyncValidator<TRequest>,
    requestChain: RequestChain,
    args: Map<string, any>,
    settings: TokenReplaceSettings = this.defaultSettings,
  ): Promise<ServiceResponse<string>> {
    return this.doResolve(
      name,
      await validator.validate(request, requestChain),
      settings,
      requestChain?.isDryRun ?? false,
      args,
    )
  }

  /**
   * Converts a variable list of objects into a list of key/value pairs.
   * @param args The list of args to be converted.
   * @return A list of key/value pairs.
   */
  protected makeArgs(args: Map<string, any>): Map<string, any> {
    return args
  }

  /**
   * Registers a tokenized template by key.
   * @param key The key used to retrieve the template.
   * @param template The tokenized template to add.
   * @return this
   */
  private addTemplate(key: string, template: string): RouteResolver {
    this.templates.set(key, template)
    if (!this.tokenizer.addTemplate(key, template)) {
      this.logger.warn(`unable to add tokenizer template: ${key} === '${template}'`)
    }
    return this
  }

  private doResolve(
    name: string,
    error: Error,
    settings: TokenReplaceSettings,
    keepTemplate: boolean,
    args: Map<string, any>,
  ): ServiceResponse<string> {
    if (isNullOrUndefined(error)) {
      try {
        const url = keepTemplate
          ? valueOrDefault(this.tokenizer.getTemplate(name).template, '')
          : valueOrDefault(this.getUrl(name, this.makeArgs(args), settings), '')
        return ServiceResponse.create(url, url, url, this.tokenizer.getTemplate(name).template)
      } catch (err) {
        return ServiceResponse.fromError<string>(err)
      }
    } else {
      return ServiceResponse.fromError(error)
    }
  }
}
