// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined } from '../../util/Helpers'
import { PersistentCache } from '../cache/PersistentCache'
import { PersistentCacheImpl } from '../cache/PersistentCacheImpl'
import { LogLevel } from '../Logger'
import { ApiSettings } from './ApiSettings'
import { CacheSettings } from './CacheSettings'
import { CmsSettings } from './CmsSettings'
import { LocalizationSettings } from './LocalizationSettings'

/**
 * Settings for how to use the SDK.
 */
export class SdkSettings {
  public readonly apiSettings: ApiSettings
  public readonly cmsSettings: CmsSettings
  public readonly cacheSettings: CacheSettings
  public readonly persistentCache: PersistentCache
  public readonly i18nSettings: LocalizationSettings

  /** Gets or sets the log level */
  public logLevel: LogLevel = LogLevel.info

  /**
   * Indicates whether to include rawData in service response.
   */
  public showApiDetails = false

  /**
   * Initializes a new `SdkSettings`.
   * @param apiSettings the `ApiSettings` used to connect to the API.
   */
  constructor(
    apiSettings: ApiSettings,
    cmsSettings = new CmsSettings(),
    cacheSettings = new CacheSettings(),
    persistentCache: PersistentCache = null,
    i18nSettings: LocalizationSettings = new LocalizationSettings(),
  ) {
    this.apiSettings = apiSettings
    this.cmsSettings = cmsSettings
    this.cacheSettings = cacheSettings
    this.persistentCache = persistentCache ?? new PersistentCacheImpl()
    this.i18nSettings = i18nSettings
  }

  /**
   * Sets the user agent suffix.
   * @param suffix A custom name to append to the User-Agent header when making service requests.
   */
  public setUserAgentSuffix(suffix: string) {
    if (isNotNullOrUndefined(this.apiSettings)) {
      this.apiSettings.userAgentSuffix = suffix
    }
    if (isNotNullOrUndefined(this.cmsSettings)) {
      this.cmsSettings.userAgentSuffix = suffix
    }
  }
}
