// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Logger } from '../../../core/Logger'
import {
  isInRange,
  isNotNullOrUndefined,
  isNullOrUndefined,
  objectValues,
} from '../../../util/Helpers'
import { ArticleCaseType } from '../../options/dates/ArticleCaseType'
import { CustomDateFormatOptions } from '../../options/dates/CustomDateFormatOptions'
import { DateFormatOptions } from '../../options/dates/DateFormatOptions'
import { DateTimeFormat } from '../../options/dates/DateTimeFormat'
import { DateTimeFormatWidth } from '../../options/dates/DateTimeFormatWidth'
import { DayPeriod } from '../../options/dates/DayPeriod'
import { DayPeriodFormatOptions } from '../../options/dates/DayPeriodFormatOptions'
import { DayPeriodWidth } from '../../options/dates/DayPeriodWidth'
import { DurationFormatOptions } from '../../options/dates/DurationFormatOptions'
import { DurationFormatWidth } from '../../options/dates/DurationFormatWidth'
import { PeriodFormatOptions } from '../../options/dates/PeriodFormatOptions'
import { PeriodNameFormatWidth } from '../../options/dates/PeriodNameFormatWidth'

// should be internal
export class DateFormatProvider {
  // this has a format of d.hh:mm:ss.ms
  // but allows for including days with the total hour count
  public static readonly dayDurationPattern = '{0}.'
  public static readonly durationPatterns = new Map<DurationFormatWidth, string>([
    [DurationFormatWidth.Hours, '{1}'],
    [DurationFormatWidth.Minutes, '{1}:{2}'],
    [DurationFormatWidth.Seconds, '{1}:{2}:{3}'],
    [DurationFormatWidth.Milliseconds, '{1}:{2}:{3}.{4}'],
  ])
  private static readonly wideMonthYear = 'yyyyMMMM'
  private static readonly wideMonthDay = 'MMMMd'
  private static readonly abbreviatedMonthDay = 'MMMd'
  private static readonly monthNumberDayNumber = 'Md'

  private readonly logger: Logger = Logger.getInstance()

  private _map: Map<string, any>

  public setMap(map: Map<string, any>) {
    this._map = map
  }
  public getMonths(formatOptions: PeriodFormatOptions): Map<string, string> {
    if (isNotNullOrUndefined(formatOptions)) {
      const settings = this.getDateFormatSettings(formatOptions.languageCode)
      if (isNotNullOrUndefined(settings)) {
        return this.getPeriods(
          settings,
          formatOptions.formatWidth,
          formatOptions.articleCaseType,
          (s: any): any => {
            if (isNotNullOrUndefined(s)) {
              return s.months
            }
          },
        )
      }
    }
  }
  public getMonthName(date: Date, formatOptions: PeriodFormatOptions): string {
    if (isNotNullOrUndefined(date)) {
      const months = this.getMonths(formatOptions)
      if (isNotNullOrUndefined(months)) {
        // date.month is zero-based; map is indexed 1-12
        const month = date.getMonth() + 1
        return months[month]
      }
    }
  }

  public getDays(formatOptions: PeriodFormatOptions): Map<string, string> {
    if (isNotNullOrUndefined(formatOptions)) {
      const settings = this.getDateFormatSettings(formatOptions.languageCode)
      if (isNotNullOrUndefined(settings)) {
        return this.getPeriods(
          settings,
          formatOptions.formatWidth,
          formatOptions.articleCaseType,
          (s: any): any => {
            if (isNotNullOrUndefined(s)) {
              return s.days
            }
          },
        )
      }
    }
  }
  public getDayName(date: Date, formatOptions: PeriodFormatOptions): string {
    if (isNotNullOrUndefined(date)) {
      const days = this.getDays(formatOptions)
      if (isNotNullOrUndefined(days)) {
        const day = date.getDay()
        return objectValues(days)[day]
      }
    }
  }

  public getDayPeriodName(date: Date, formatOptions: DayPeriodFormatOptions): string {
    if (isNotNullOrUndefined(date)) {
      const days = this.getDayPeriods(formatOptions)
      if (isNotNullOrUndefined(days)) {
        const period = this.getDayPeriod(date, formatOptions.dayPeriodWidth)
        return days[period]
      }
    }
  }
  public getDayPeriodWidth(pattern: string): DayPeriodWidth {
    if (isNotNullOrUndefined(pattern)) {
      if (pattern.includes('B')) {
        return DayPeriodWidth.Wide
      }
      if (pattern.includes('b')) {
        return DayPeriodWidth.Short
      }
    }
    return DayPeriodWidth.Narrow
  }

  public getDatePattern(formatOptions: DateFormatOptions): string {
    if (isNotNullOrUndefined(formatOptions)) {
      const settings = this.getDateFormatSettings(formatOptions.languageCode)
      if (isNotNullOrUndefined(settings)) {
        const format = settings.dateFormats
        return this.getPatternByWidth(format, formatOptions.formatWidth)
      }
    }
  }
  public getTimePattern(formatOptions: DateFormatOptions): string {
    if (isNotNullOrUndefined(formatOptions)) {
      const settings = this.getDateFormatSettings(formatOptions.languageCode)
      if (isNotNullOrUndefined(settings)) {
        const format = settings.timeFormats
        return this.getPatternByWidth(format, formatOptions.formatWidth)
      }
    }
  }
  public getDateTimePattern(formatOptions: DateFormatOptions): string {
    if (isNotNullOrUndefined(formatOptions)) {
      const settings = this.getDateFormatSettings(formatOptions.languageCode)
      if (isNotNullOrUndefined(settings)) {
        const format = settings.dateTimeFormats
        return this.getPatternByWidth(format, formatOptions.formatWidth) // should this be wrapped in replaceCustomAmPm()??
      }
    }
  }
  public getDateTimePatternCustom(formatOptions: CustomDateFormatOptions): string {
    if (isNullOrUndefined(formatOptions)) {
      return
    }

    const settings = this.getDateFormatSettings(formatOptions.languageCode)
    if (isNullOrUndefined(settings)) {
      return
    }
    switch (formatOptions.format) {
      case DateTimeFormat.AbbreviatedMonthDay:
        return this.getAvailableFormat(
          DateFormatProvider.abbreviatedMonthDay,
          settings.dateTimeFormats,
        )

      case DateTimeFormat.WideMonthDay:
        return this.getAvailableFormat(DateFormatProvider.wideMonthDay, settings.dateTimeFormats)

      case DateTimeFormat.WideMonthYear:
        return this.getAvailableFormat(DateFormatProvider.wideMonthYear, settings.dateTimeFormats)

      case DateTimeFormat.MonthNumberDayNumber:
        return this.getAvailableFormat(
          DateFormatProvider.monthNumberDayNumber,
          settings.dateTimeFormats,
        )

      case DateTimeFormat.NarrowTime: {
        const pattern = settings.timeFormats.short.includes('H') ? 'H' : 'h'
        return this.getAvailableFormat(pattern, settings.dateTimeFormats)
      }
    }
  }
  public getDurationPattern(formatOptions: DurationFormatOptions): string {
    if (isNullOrUndefined(formatOptions)) {
      return
    }
    const pattern = DateFormatProvider.durationPatterns.get(formatOptions.formatWidth)
    return formatOptions.includeDaysWithHours
      ? pattern
      : DateFormatProvider.dayDurationPattern + pattern
  }

  private getDayPeriods(formatOptions: PeriodFormatOptions): any {
    if (isNotNullOrUndefined(formatOptions)) {
      const settings = this.getDateFormatSettings(formatOptions.languageCode)
      if (isNotNullOrUndefined(settings)) {
        return this.getPeriods(
          settings,
          formatOptions.formatWidth,
          formatOptions.articleCaseType,
          (s: any): any => {
            if (isNotNullOrUndefined(s)) {
              return s.dayPeriods
            }
          },
        )
      }
    }
  }

  private getDayPeriod(date: Date, dayPeriodWidth: DayPeriodWidth): DayPeriod {
    // var hhmm = SimpleDateFormat("HH:mm", Locale.getDefault()).format(date).split(":")
    const h = date.getHours()
    const m = date.getMinutes()

    switch (dayPeriodWidth) {
      case DayPeriodWidth.Narrow:
        return this.getDayPeriodNarrow(h)
      case DayPeriodWidth.Short:
        return this.getDayPeriodShort(h, m)
      case DayPeriodWidth.Wide:
        return this.getDayPeriodWide(h, m)
    }
  }
  private getDayPeriodNarrow(h: number): DayPeriod {
    return h < 12 ? DayPeriod.Am : DayPeriod.Pm
  }
  private getDayPeriodShort(h: number, m: number): DayPeriod {
    if (h === 0 && m === 0) {
      return DayPeriod.Midnight
    }
    if (h === 12 && m === 0) {
      return DayPeriod.Noon
    }
    return this.getDayPeriodNarrow(h)
  }
  private getDayPeriodWide(h: number, m: number): DayPeriod {
    if (h === 0 && m === 0) {
      return DayPeriod.Midnight
    }
    if (h === 12 && m === 0) {
      return DayPeriod.Noon
    }

    if (isInRange(h, 17, 22)) {
      return DayPeriod.Evening
    }
    if (isInRange(h, 11, 16)) {
      return DayPeriod.Afternoon
    }
    if (isInRange(h, 5, 10)) {
      return DayPeriod.Morning
    }
    return DayPeriod.Night
  }
  private getAvailableFormat(key: string, formats: any): string {
    // formats is DateTimeFormats
    if (isNotNullOrUndefined(formats) && isNotNullOrUndefined(formats.availableFormats)) {
      return formats.availableFormats[key]
    }
  }

  // region internal
  private getPatternByWidth(settings: any, formatWidth: DateTimeFormatWidth): string {
    // settings is FormatDateByWidth
    if (isNotNullOrUndefined(settings)) {
      let pattern: string
      switch (formatWidth) {
        case DateTimeFormatWidth.Full: {
          pattern = settings.full
          break
        }
        case DateTimeFormatWidth.Long: {
          pattern = settings.long
          break
        }
        case DateTimeFormatWidth.Medium: {
          pattern = settings.medium
          break
        }
        case DateTimeFormatWidth.Short: {
          pattern = settings.short
          break
        }
      }
      return this.replaceDayOfWeek(pattern)
    }
  }

  private getPeriods(
    settings: any,
    formatWidth: PeriodNameFormatWidth,
    articleCaseType: ArticleCaseType,
    getPeriodSettings: (settings: any) => any,
  ): Map<string, string> {
    // settings is DateFormatSettings
    if (isNotNullOrUndefined(settings)) {
      // patternsByWidth is ArticleCaseSettings
      const patternsByWidth = getPeriodSettings(settings)
      if (isNotNullOrUndefined(patternsByWidth)) {
        // articleCaseType === 'format' or 'stand-alone'
        const patternsByWidthSettings = patternsByWidth[articleCaseType]
        return this.getPeriodPatterns(patternsByWidthSettings, formatWidth)
      }
    }
  }

  private getPeriodPatterns(
    settings: any,
    formatWidth: PeriodNameFormatWidth,
  ): Map<string, string> {
    // settings is PeriodPatternsByWidthSettings
    if (isNotNullOrUndefined(settings)) {
      switch (formatWidth) {
        case PeriodNameFormatWidth.Abbreviated:
          return settings.abbreviated
        case PeriodNameFormatWidth.Narrow:
          return settings.narrow
        // Days is the only period that has 'Short' formats
        case PeriodNameFormatWidth.Short:
          return settings.short || settings.narrow
        case PeriodNameFormatWidth.Wide:
          return settings.wide
      }
    }
  }

  private getDateFormatSettings(language: string): any {
    if (isNullOrUndefined(this._map)) {
      this.logger.error('No map created need to pass at least 1 language to the dates')
      throw new Error('No map created need to pass at least 1 language to the dates')
    } else if (!this._map.has(language)) {
      this.logger.error(`Couldn't find a language pack for: ${language}`)
      throw new Error(`Couldn't find a language pack for: ${language}`)
    }
    return this._map.get(language)
  }

  private replaceDayOfWeek(pattern: string): string {
    if (isNotNullOrUndefined(pattern)) {
      return pattern.replace('c', 'E')
    }
  }
  // endregion internal
}
