/**
 * The different ways to view a month, resulting in different day counts.
 */
export enum MonthCountType {
  /**
   * The 'true' month, from day 1 through day n.
   * This is the default.
   */
  List,

  /**
   * The set of days containing full weeks (ie. Sun -> Sat) where the 'true' month is a subset.
   * Includes days from the previous and/or next month - depending on the day of week for the 1st of the month.
   */
  Calendar,
}
