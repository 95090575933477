import { LocationCacheInfoRequest } from '../../core/http/LocationCacheInfoRequest'

/**
 *  Encapsulates parameters used to make requests to the WeatherService
 */
export class CurrentConditionsByLocationKeyRequest implements LocationCacheInfoRequest {
  /**
   * Gets or sets the language (i.e. en-us) for which the response should be returned in.
   */
  public readonly language: string

  /**
   * Gets or sets the location key for the request. Location keys can be obtained from the location service.
   */
  public readonly locationKey: string

  /**
   * Gets or sets whether the response should include the full details.
   */
  public details = false

  /**
   * @param language the language for which the response should be returned.
   * @param locationKey the location key for the request. Location keys can be obtained from the location service.
   */
  constructor(language: string, locationKey: string) {
    this.language = language
    this.locationKey = locationKey
  }
}
