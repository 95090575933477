/**
 * Provides methods to convert temperature values to other TemperatureType.
 *
 * See also AccuWeather.Common.Measurements (api)
 */
export abstract class TemperatureConversion {
  /**
   * Converts a temperature to fahrenheit.
   * @param value The original value to be converted.
   * @return The converted temperature, in fahrenheit.
   */
  public abstract toFahrenheit(value: number): number

  /**
   * Converts a temperature to celsius.
   * @param value The original value to be converted.
   * @return The converted temperature, in celsius.
   */
  public abstract toCelsius(value: number): number

  /**
   * Converts a temperature to kelvin.
   * @param value The original value to be converted.
   * @return The converted temperature, in kelvin.
   */
  public abstract toKelvin(value: number): number
}

/**
 * Provides methods to convert temperatures in celsius to other TemperatureType.
 */
export class Celsius extends TemperatureConversion {
  /**
   * Converts a temperature to fahrenheit.
   * @param value The original value to be converted.
   * @return The converted temperature, in fahrenheit.
   *
   * °F = °C × 9⁄5 + 32
   */
  public toFahrenheit(value: number): number {
    return value * (9 / 5) + 32
  }

  /**
   * Converts a temperature to celsius.
   * @param value The original value to be converted.
   * @return The converted temperature, in celsius.
   * (no conversion)
   */
  public toCelsius(value: number): number {
    return value
  }

  /**
   * Converts a temperature to kelvin.
   * @param value The original value to be converted.
   * @return The converted temperature, in kelvin.
   * K = °C + 273.15
   */
  public toKelvin(value: number): number {
    return value + 273.15
  }
}

/**
 * Provides methods to convert temperatures in fahrenheit to other TemperatureType.
 */
export class Fahrenheit extends TemperatureConversion {
  /**
   * Converts a temperature to fahrenheit.
   * @param value The original value to be converted.
   * @return The converted temperature, in fahrenheit.
   * (no conversion)
   */
  public toFahrenheit(value: number): number {
    return value
  }

  /**
   * Converts a temperature to celsius.
   * @param value The original value to be converted.
   * @return The converted temperature, in celsius.
   * °C = (°F − 32) × 5⁄9
   */
  public toCelsius(value: number): number {
    return (value - 32) * (5 / 9)
  }

  /**
   * Converts a temperature to kelvin.
   * @param value The original value to be converted.
   * @return The converted temperature, in kelvin.
   * K = (°F + 459.67) × 5⁄9
   */
  public toKelvin(value: number): number {
    return (value + 459.67) * (5 / 9)
  }
}

/**
 * Provides methods to convert temperatures in kelvin to other TemperatureType.
 */
export class Kelvin extends TemperatureConversion {
  /**
   * Converts a temperature to fahrenheit.
   * @param value The original value to be converted.
   * @return The converted temperature, in fahrenheit.
   * °F = K × 9⁄5 − 459.67
   */
  public toFahrenheit(value: number): number {
    return value * (9 / 5) - 459.67
  }

  /**
   * Converts a temperature to celsius.
   * @param value The original value to be converted.
   * @return The converted temperature, in celsius.
   * °C = K − 273.15
   */
  public toCelsius(value: number): number {
    return value - 273.15
  }

  /**
   * Converts a temperature to kelvin.
   * @param value The original value to be converted.
   * @return The converted temperature, in kelvin.
   * (no conversion)
   */
  public toKelvin(value: number): number {
    return value
  }
}
