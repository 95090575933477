export enum DayPeriodWidth {
  /**
   * B
   */
  Wide,

  /**
   * b
   */
  Short,

  /**
   * a
   */
  Narrow,
}
