// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNotNullOrUndefined, isNullOrUndefined } from '../../util/Helpers'
import { RequestChain } from '../http/RequestChain'
import { Validator } from './Validator'

/**
 * Provides an abstract way to validate an object asynchronously, by attaching a set of async functions
 * to act as validation rules.
 * Can also accept synchronous rules, which will be applied first during validation.
 */
export class AsyncValidator<T> {
  private readonly validator: Validator<T>
  private readonly asyncRules: Array<(req: T, rc: RequestChain) => Promise<Error>> = []

  /**
   * Initializes a new `AsyncValidator`.
   * @param rules The validation rules.
   */
  constructor(rules: Array<(req: T) => Error>) {
    this.validator = new Validator(rules)
  }

  /**
   * Adds a rule to the collection of synchronous rules.
   * @param rule The validation rule.
   */
  public addRule(rule: (req: T) => Error): AsyncValidator<T> {
    this.validator.addRule(rule)
    return this
  }

  /**
   * Adds a rule to the collection of asynchronous rules.
   * @param rule The validation rule.
   */
  public addAsyncRule(rule: (req: T, rc: RequestChain) => Promise<Error>): AsyncValidator<T> {
    if (isNotNullOrUndefined(rule)) {
      this.asyncRules.push(rule)
    }
    return this
  }

  /**
   * Gets whether the given `arg` is valid.
   * @param arg The item to be validated.
   * @param requestChain The request chain.
   * @return The `Error` returned from the first rule that fails, or undefined if all rules pass.
   */
  public async validate(arg: T, requestChain?: RequestChain): Promise<Error> {
    let error: Error = this.validator.validate(arg)
    if (isNullOrUndefined(error)) {
      for (const rule of this.asyncRules) {
        error = await rule(arg, requestChain)
      }
    }
    return error
  }
}
