import { addDurationToDate } from '../../util/Helpers'
import { CacheOptions } from './CacheOptions'

export class CacheItem {
  /**
   * The value to be cached.
   */
  public value: any

  /**
   * The cache options.
   */
  public cacheOptions: CacheOptions

  private expireDate: Date
  /**
   * Gets whether the item is expired.
   */
  public get isExpired(): boolean {
    return this.expireDate < new Date()
  }

  /**
   * @param value The value to be cached.
   * @param cacheOptions The cache options.
   */
  constructor(value: any, cacheOptions: CacheOptions = new CacheOptions()) {
    this.value = value
    this.cacheOptions = cacheOptions
    this.expireDate = this.addTTL(new Date())
  }

  /**
   * Mark the item as requested.
   */
  public request() {
    if (this.cacheOptions.isSlidingExpiry) {
      this.expireDate = this.addTTL(this.expireDate)
    }
  }

  private addTTL(date: Date): Date {
    return addDurationToDate(this.cacheOptions.ttl, date)
  }
}
