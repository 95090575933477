// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { LocationKeyValidRule } from './LocationKeyValidRule'

/**
 * Provides methods used to validate location keys.
 */
export class LocationKeyIsCityRule {
  /**
   * Checks whether a location key is for a city (i.e. not POI, etc).
   * @param locationKey The value to be validated.
   * @return If [locationKey] is invalid, an exception; otherwise, null.
   */
  public static validate(locationKey: string): Error {
    const error = LocationKeyValidRule.checkRule(locationKey)
    if (error != null) {
      return error
    }

    if (LocationKeyIsCityRule.isPOI(locationKey)) {
      return Error('point-of-interest location key not allowed')
    }

    // todo: what other patterns need to be excluded?

    // todo: map of locationKey name pattern to location type belongs somewhere else

    return null
  }
  private static poiSuffix = '_POI'

  private static isPOI(locationKey: string): boolean {
    return (
      locationKey.substr(Math.max(0, locationKey.length - 4)) === LocationKeyIsCityRule.poiSuffix
    )
  }
}
