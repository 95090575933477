import { MetaTemplate } from './MetaTemplate'
import { TextMeta } from './TextMeta'

/**
 * Provides a collection of values for a given template.
 */
export class TemplateValues {
  /*internal*/
  public meta: TextMeta[] = []
  private values: Map<string, any> = new Map()

  /**
   * Initializes a new [TemplateValues].
   * @param template the parsed meta data for a tokenized route.
   */
  constructor(template: MetaTemplate) {
    // copy the meta
    if (template) {
      for (const meta of template.meta) {
        this.meta.push(meta)
      }
    }
  }

  /**
   * Gets whether the given key is present in the values dictionary.
   * @param key The key.
   * @return If the key exists in the dictionary, true; otherwise, false.
   */
  public hasKey(key: string) {
    return this.values.has(key)
  }

  /**
   * Gets the value for the given key.
   * @param key The key.
   * @return If the key exists in the dictionary, the value for that key; otherwise, null.
   */
  public getValue(key: string) {
    return this.hasKey(key) ? this.values.get(key) : null
  }

  /**
   * Sets the value for the given key.
   * @param key The key.
   * @param value The value.
   * @return The value supplied.
   */
  public setValue(key: string, value: any) {
    if (key) {
      this.values.set(key, value)
    }
    return value
  }
}
