import { LocationCacheInfoRequest } from '../../core/http/LocationCacheInfoRequest'
import { RequestChain } from '../../core/http/RequestChain'
import { RouteResolver } from '../../core/http/RouteResolver'
import { ServiceResponse } from '../../core/ServiceResponse'
import { AsyncValidator } from '../../core/validation/AsyncValidator'
import { isNullOrUndefined } from '../../util/Helpers'
import { LocationService } from '../LocationService'
import { LocationCacheInfo } from './LocationCacheInfo'

export async function resolveLocationCacheInfoUrl(
  routeResolver: RouteResolver,
  locationService: LocationService,
  routeName: string,
  request: LocationCacheInfoRequest,
  validator: AsyncValidator<LocationCacheInfoRequest>,
  requestChain: RequestChain,
  getArgs: (info: LocationCacheInfo) => Map<string, any>,
): Promise<ServiceResponse<string>> {
  const response = await locationService.getCacheInfo(
    request.language,
    request.locationKey,
    requestChain,
  )
  if (response.hasError || isNullOrUndefined(response.data)) {
    return response.transformError()
  }
  return routeResolver.resolveAsync(
    routeName,
    request,
    validator,
    requestChain,
    getArgs(response.data),
  )
}
