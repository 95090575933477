// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isNullOrBlank } from '../../util/Helpers'

/**
 * Provides a method used to validate an object.
 */
export class SearchTermValidRule {
  /**
   * Checks whether a string is a valid serarch term.
   * @param value The value to be validated.
   * @return If `value` is invalid, an exception; otherwise, undefined.
   */
  public static checkRule(value: string): Error {
    if (isNullOrBlank(value)) {
      return Error('search term cannot blank')
    }

    // 100 is reasonable, right?
    if (value.length > 100) {
      return Error('search term cannot exceed 100 characters')
    }

    return undefined
  }
}
