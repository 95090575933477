/**
 * The different timespan rounding widths.
 */
export enum DurationFormatWidth {
  /**
   * Hours
   */
  Hours,

  /**
   * Minutes
   */
  Minutes,

  /**
   * Seconds
   */
  Seconds,

  /**
   * Milliseconds
   */
  Milliseconds,
}
