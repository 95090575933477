import { BasinId } from '../../core/models/BasinId'
import { isNotNullOrUndefined } from '../../util/Helpers'
import storms from './storm-names'
/**
 * @see <a href="https://public.wmo.Int/en/About-us/FAQs/faqs-tropical-cyclones/tropical-cyclone-naming"/>.
 * @see <a href="https://www.nhc.noaa.gov/aboutnames.shtml"/>.
 */
export class BasinInfoProvider {
  private startYear = 2017

  public getStormNames(basinId: BasinId, year: number): string[] {
    const basinInfo = this.getBasinInfo(basinId)
    basinInfo.basinId = basinId // set the basin id
    return this.getStormNamesWithFromInfo(basinInfo, year)
  }

  private getStormNamesWithFromInfo(basinInfo: any, year: number): string[] {
    const result: string[] = []

    if (
      isNotNullOrUndefined(basinInfo) &&
      year >= new Date().getFullYear() &&
      (basinInfo.basinId === BasinId.AL || basinInfo.basinId === BasinId.EP)
    ) {
      // rotate the same names every 6 years
      const adjustedYear = (year % 6) + this.startYear
      const stormNames = basinInfo.Storms[`${adjustedYear}`]
      if (isNotNullOrUndefined(stormNames)) {
        for (const storm of stormNames) {
          result.push(storm)
        }
      }
    }
    return result
  }

  private getBasinInfo(basinId: BasinId): any {
    return storms.Basins[basinId]
  }
}
