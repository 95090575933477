/**
 * The types of available formats not explicitly defined as full/long/medium/short (@see [DateTimeFormatWidth]).
 * We can add to this enum without needing to change ILocalizationService to add method signatures.
 */
export enum DateTimeFormat {
  /**
   * "h tt" or "HH"
   */
  NarrowTime,

  /**
   * "MMMM yyyy"
   */
  WideMonthYear,

  /**
   * "MMMM d"
   */
  WideMonthDay,

  /**
   * "MMM d"
   */
  AbbreviatedMonthDay,

  /**
   * "M/d"
   */
  MonthNumberDayNumber,
}
