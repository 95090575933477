/**
 * The possible storm position statuses
 */
export enum StormLevel {
  /**
   * Unknown
   */
  Unknown = 'Unknown',

  /**
   * Cyclonic storm
   */
  CyclonicStorm = 'Cyclonic storm',

  /**
   * Deep depression
   */
  DeepDepression = 'Deep depression',

  /**
   * Depression
   */
  Depression = 'Depression',

  /**
   * Extratropical storm
   */
  ExtratropicalStorm = 'Extremely severe cyclonic storm',

  /**
   * Extremely severe cyclonic storm
   */
  ExtremelySevereCyclonicStorm = 'Extratropical storm',

  /**
   * Hurricane—category 1
   */
  HurricaneCategory1 = 'Hurricane - Category 1',

  /**
   * Hurricane—category 2
   */
  HurricaneCategory2 = 'Hurricane - Category 2',

  /**
   * Hurricane—category 3
   */
  HurricaneCategory3 = 'Hurricane - Category 3',

  /**
   * Hurricane—category 4
   */
  HurricaneCategory4 = 'Hurricane - Category 4',

  /**
   * Hurricane—category 5
   */
  HurricaneCategory5 = 'Hurricane - Category 5',

  /**
   * Intense tropical cyclone
   */
  IntenseTropicalCyclone = 'Intense tropical cyclone',

  /**
   * Moderate tropical storm
   */
  ModerateTropicalStorm = 'Moderate tropical storm',

  /**
   * Post-tropical cyclone
   */
  PostTropicalCyclone = 'Post-tropical cyclone',

  /**
   * Potential tropical cyclone
   */
  PotentialTropicalCyclone = 'Potential tropical cyclone',

  /**
   * Severe cyclonic storm
   */
  SevereCyclonicStorm = 'Severe cyclonic storm',

  /**
   * Severe tropical storm
   */
  SevereTropicalStorm = 'Severe tropical storm',

  /**
   * Subtropical
   */
  Subtropical = 'Subtropical',

  /**
   * Subtropical storm
   */
  SubtropicalStorm = 'Subtropical storm',

  /**
   * Subtropical depression
   */
  SubtropicalDepression = 'Subtropical depression',

  /**
   * Super cyclonic storm
   */
  SuperCyclonicStorm = 'Super cyclonic storm',

  /**
   * Tropical cyclone
   */
  TropicalCyclone = 'Tropical cyclone',

  /**
   * Tropical cyclone—category 1
   */
  TropicalCycloneCategory1 = 'Tropical cyclone - Category 1',

  /**
   * Tropical cyclone—category 2
   */
  TropicalCycloneCategory2 = 'Tropical cyclone - Category 2',

  /**
   * Tropical cyclone—category 3
   */
  TropicalCycloneCategory3 = 'Tropical cyclone - Category 3',

  /**
   * Tropical cyclone—category 4
   */
  TropicalCycloneCategory4 = 'Tropical cyclone - Category 4',

  /**
   * Tropical cyclone—category 5
   */
  TropicalCycloneCategory5 = 'Tropical cyclone - Category 5',

  /**
   * Tropical depression
   */
  TropicalDepression = 'Tropical depression',

  /**
   * Tropical disturbance
   */
  TropicalDisturbance = 'Tropical disturbance',

  /**
   * Tropical storm
   */
  TropicalStorm = 'Tropical storm',

  /**
   * Tropical rain storm,
   */
  TropicalRainStorm = 'Tropical Rainstorm',

  /**
   * Typhoon
   */
  Typhoon = 'Typhoon',

  /**
   * Very intense tropical cyclone
   */
  VeryIntenseTropicalCyclone = 'Very intense tropical cyclone',

  /**
   * Very severe cyclonic storm
   */
  VerySevereCyclonicStorm = 'Very severe cyclonic storm',

  /**
   * Very strong typhoon'
   */
  VeryStrongTyphoon = 'Very strong typhoon',

  /**
   * Violent typhoon
   */
  ViolentTyphoon = 'Violent typhoon',
}
