// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Logger } from '../../../core/Logger'
import { formatString, isNotNullOrUndefined, pad, toList } from '../../../util/Helpers'
import { TimeSpan } from '../../../util/TimeSpan'
import { CustomDateFormatOptions } from '../../options/dates/CustomDateFormatOptions'
import { DateFormatOptions } from '../../options/dates/DateFormatOptions'
import { DayPeriodFormatOptions } from '../../options/dates/DayPeriodFormatOptions'
import { DayPeriodWidth } from '../../options/dates/DayPeriodWidth'
import { DurationFormatOptions } from '../../options/dates/DurationFormatOptions'
import { PeriodFormatOptions } from '../../options/dates/PeriodFormatOptions'
import { DateFormatProvider } from './DateFormatProvider'
import { IntlOptions } from './IntlOptions'

// internal
export class DateFormatter {
  private dateFormatProvider = new DateFormatProvider()
  private readonly logger: Logger = Logger.getInstance()
  private readonly wordBoundary = '\\b'

  public setMap(map: Map<string, any>) {
    this.dateFormatProvider.setMap(map)
  }
  // region months / days
  public monthNames(formatOptions: PeriodFormatOptions): string[] {
    const months = this.dateFormatProvider.getMonths(formatOptions)
    if (isNotNullOrUndefined(months)) {
      return toList(months)
    }
  }
  public monthName(date: Date, formatOptions: PeriodFormatOptions): string {
    return this.dateFormatProvider.getMonthName(date, formatOptions)
  }

  public dayNames(formatOptions: PeriodFormatOptions): string[] {
    const days = this.dateFormatProvider.getDays(formatOptions)
    if (isNotNullOrUndefined(days)) {
      return toList(days)
    }
  }
  public dayName(date: Date, formatOptions: PeriodFormatOptions): string {
    return this.dateFormatProvider.getDayName(date, formatOptions)
  }
  // endregion months/days

  // region dayPeriods
  // endregion dayPeriods

  // region datetime format
  public date(date: Date, formatOptions: DateFormatOptions): string {
    if (isNotNullOrUndefined(date)) {
      const pattern = this.dateFormatProvider.getDatePattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        return this.formatDate(date, pattern, formatOptions.languageCode)
      }
    }
  }

  public time(date: Date, formatOptions: DateFormatOptions): string {
    if (isNotNullOrUndefined(date)) {
      let pattern = this.dateFormatProvider.getTimePattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        pattern = this.replaceDayPeriodFormat(date, formatOptions, pattern)
        return this.formatDate(date, pattern, formatOptions.languageCode)
      }
    }
  }

  public datetime(date: Date, formatOptions: DateFormatOptions): string {
    if (isNotNullOrUndefined(date)) {
      const pattern = this.dateFormatProvider.getDateTimePattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        return this.formatDate(date, pattern, formatOptions.languageCode)
      }
    }
  }

  public datetimeCustom(date: Date, formatOptions: CustomDateFormatOptions): string {
    if (isNotNullOrUndefined(date)) {
      const pattern = this.dateFormatProvider.getDateTimePatternCustom(formatOptions)
      // pattern = dateFormatProvider.replaceCustomWideMonth(pattern, languageCode, date)
      return this.formatDate(date, pattern, formatOptions.languageCode)
    }
  }
  // endregion datetime format

  // region duration
  /**
   * Formats the duration per the given options.
   * @param duration The duration, in milliseconds, to be formatted.
   * @param formatOptions The format options.
   * @return The formatted date text, or null if an error occurred.
   */
  public duration(duration: number, formatOptions: DurationFormatOptions): string {
    if (isNotNullOrUndefined(duration)) {
      const pattern = this.dateFormatProvider.getDurationPattern(formatOptions)
      if (isNotNullOrUndefined(pattern)) {
        const timespan = new TimeSpan(duration)
        const hours = formatOptions.includeDaysWithHours
          ? Math.trunc(timespan.totalHours)
          : timespan.hours
        const min = pad(timespan.minutes, 2)
        const sec = pad(timespan.seconds, 2)
        const ms = pad(timespan.milliseconds, 3)
        return formatString(pattern, timespan.days, hours, min, sec, ms)
      }
    }
  }
  // endregion duration

  // private replace(pattern: string, original: string, replacement: string): string {
  //     var escaped = replacement?.replace("'", "''")
  //     return pattern?.replace(original, "'$escaped'")
  // }

  private getDayPeriodReplacement(periodWidth: DayPeriodWidth): string {
    switch (periodWidth) {
      case DayPeriodWidth.Wide:
        return 'B'
      case DayPeriodWidth.Short:
        return 'b'
      default:
        return ''
    }
  }
  private replaceDayPeriodFormat(
    date: Date,
    formatOptions: DateFormatOptions,
    format: string,
  ): string {
    const periodWidth = this.dateFormatProvider.getDayPeriodWidth(format)
    const replacement = this.getDayPeriodReplacement(periodWidth)
    switch (replacement) {
      case 'b': // fallthrough
      case 'B': {
        const req = new DayPeriodFormatOptions(
          formatOptions.languageCode,
          periodWidth,
          formatOptions.periodNameFormatWidth,
        )
        const period = this.dateFormatProvider.getDayPeriodName(date, req)
        // return this.replace(format, replacement, period)
        return period
      }
      default:
        return format
    }
  }

  private formatDate(date: Date, pattern: string, language: string): string {
    try {
      const options: IntlOptions = new IntlOptions(pattern)
      return new Intl.DateTimeFormat(language, options.options).format(date)
    } catch (error) {
      this.logger.error(`failed to format date: ${error}`)
    }
  }

  private replaceWord(word: string, replacement: string, pattern: string): string {
    const regex = new RegExp(this.wordBoundary + word + this.wordBoundary)
    return pattern.replace(regex, replacement)
  }
}
